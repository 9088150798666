// 百问百答
import React,{Component} from 'react'
import {connect} from 'react-redux'
import {Button,message,Modal, Form, Input} from 'antd'
import ImagesUpload from '@/components/image-upload-banner'
import BasicTable from '../../components/Table'
import BasicModal from '../../components/modal_edit'
import BraftEditor from 'braft-editor'
import api from '../../fetch/api'


class Ask extends Component {
    state = {
        pageNum:'1',
        pageSize:'10',
        title:'',
        current:{},
        total:1,
        visible: false ,
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        data:[],
        columns:[
        { title: '问答Id', dataIndex: 'id', key: 'id' },
        { title: '名称', dataIndex: 'name', key: 'name' },
        { title: '类型', dataIndex: 'tagName', key: 'tagName' },
        { title: '名字', dataIndex: 'issuerName', key: 'issuerName' },
        { title: '发布人头像', dataIndex: 'issuerImg', key: 'issuerImg',render: (text, record) => (
          <div style={{width:"60px",heigth:"60px",margin:"5px",overflow:"hidden"}}>
            <img src={text} style={{width:"100%"}}></img>
          </div>
      )  },
        { title: '图片', dataIndex: 'img', key: 'img', 
        render: (text, record) => (
            <div>
               {   Array.isArray(text)?
            //    <span> {Array.isArray(text)}</span>
                   text.map((items,index)=>{
                    return(
                      <div key={index}
                       style={{width:"60px",heigth:"60px",margin:"5px",overflow:"hidden",display:"flex"}}>
                       {/* <span>{items}={index}</span> */}
                       {/* {index > 0&&{text[0]==text[1]}?<img src={items} style={{width:"100%"}}></img>:''} */}
                       <img src={items} style={{width:"100%"}}></img>
                      </div>
                    )
                })
                :<span>wutu</span> 
               }
            </div>
        )
    },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                { 
                  <span>
                    <Button   type='primary' onClick={this.showUpdateModal.bind(this,record)} style={{margin:'0 10px'}}>修改</Button>
                    <Button type='danger' onClick={this.deleteAnswerContent.bind(this,record)}>删除</Button>
                  </span>
                }
             </div>
            )
        }],
        
    }
    componentDidMount() {
        this.getAnswerContentList({pageNum:1,pageSize:10})
       
    }
    componentWillMount(){
        this.getAnswerContentList({pageNum:1,pageSize:10})
    }
    deleteAnswerContent(record){
          api.deleteAnswerContent(record.id).then( res => {
            //   console.log(res,'删除 ',record.id)
              if(res.code == 200 ){
                message.success('删除成功');
                this.getAnswerContentList({pageNum:this.state.pageNum})
              }else{
                message.error('删除失败');  
              }
          })
    }
    changePage(page) {
        // console.log(page,'page')
        this.setState({
            pageNum:page
        })
        let temp = {pageNum:page,pageSize:this.state.pageSize}
        this.getAnswerContentList(temp)
    }
   
    showModal = () => {
        this.setState({
            title:'新增百问百答',
            visible: true,
        });
    };
    showUpdateModal = (record) => {
        if(record.img == null) record.img = []
        if(record.img.length>0){
            const imgArr = record.img.map((imgUrl,index)=>({
                uid: '-' +　index,
                name: imgUrl,
                status: 'done',
                url: imgUrl,
            }))
            record.fileList = imgArr
            console.log(imgArr,'imgARR')
        }
        this.setState({
         current:record,
         title:'修改百问百答',
         visible: true,
        });
        console.log(record,'11',this.state.current)
    };
    onClose() {
        this.setState({
          visible:false,
          current:{}
        })
    }
    getAnswerContentList(data){
       api.getAnswerContentPage(data).then( res => {
        // api.getAnswerContentList(data).then( res => {
            let temp =[]
            res.data.content.map((item,index)=>{
                // console.log(item.img,item.img!='',item.img!=null)
                if(item.img!=''&&item.img!=null){
                    temp = item.img.split(',')
                    item.img = temp
                    if(temp[0] == ''){
                        temp.splice(0,1)
                    }
                }
               
            })
            console.log(temp,'问答',res)
            this.setState({
                // data:res,
                data:res.data.content,
                total:res.data.totalElements
            })
        }).catch( err => {
            console.log(err,'err')
        }) 
        
    }
    submit (data) { 
        console.log(data,'submit')
        let page ={pageNum:this.state.pageNum,pageSize:this.state.pageSize}
        if(this.state.current.id){
            data.id = this.state.current.id
            api.putAnswerContent(data).then(res =>{
                if(res.code == 200) {
                    message.success('修改成功');
                    this.onClose()
                    this.getAnswerContentList(page)
                }
            })
        } else {
            api.postAnswerContent(data).then(res =>{
                if(res.code == 200) {
                    message.success('新增成功');
                    this.getAnswerContentList(page)
                    this.setState({
                        visible:false,
                        current:{}
                    })
                }
            })
        }
    }

    render() {
        return (
            <div >
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 10 }}>
                    <div> <Button   type='primary' onClick={this.showModal} style={{margin: 30}}>新增</Button></div>
                </div>
               
                <BasicTable 
                    data={this.state.data} 
                    columns={this.state.columns} 
                    row={this.state.row} 
                    changePage={this.changePage.bind(this)}
                    total={this.state.total} 
                    rowKey='id'
                >
                </BasicTable>
                <BasicModal 
                 visible={this.state.visible} 
                 onClose={this.onClose.bind(this)} 
                 type='ask'
                 foot='true'
                 title={this.state.title}
                 data={this.state.current} 
                 submit={this.submit.bind(this)}>
                </BasicModal>
            </div>
            
        )
    }
}
export default Form.create()(Ask); 