import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, Modal,message,Table,Pagination } from 'antd'
import axios from 'axios'
import BasicTable from '../../components/Table'
import copy from 'copy-to-clipboard';
import BasicDrawer from '../../components/drawer'
import BasicModal from '../../components/modal_order'
import { bindActionCreators } from 'redux'
import actions from '../../store/actions'
import SearchHeader from '../../components/search_header'
import API from '../../fetch/api'
import Tools from '@/global/tool'
class OrderList extends Component {
    lessStyle = {
    }
    componentWillMount() {
        this.props.getOrderList()
        // console.log(this.props.query)
    }
    state = {
        pageNum:1,
        data: [],
        visible: false,
        total: 30,
        columns: [
        {
            title: '用户名', dataIndex: 'userName', key: 'userName', render: (text, record) => (
                <span onClick={() => this.userInfo(record)}>{record.userName}</span>
            )
        },
        { title: '收货人', dataIndex: 'name', key: 'name' },
        // { title: '订单编号', dataIndex: 'id', key: 'id' },
        { title: '下单时间', dataIndex: 'createdTime', key: 'createdTime' },
        { title: '联系方式', dataIndex: 'phone', key: 'phone' },
        { title: '收货地址', dataIndex: 'add', key: 'add' },
        { title: '状态', dataIndex: 'statusInfo', key: 'statusInfo' },
        { title: '价格', dataIndex: 'total', key: 'total' },
        // { title: '联系人', dataIndex: 'contact', key: 'concact' },
        // { title: '时间', dataIndex: 'createdTime', key: 'createdTime' },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                    <span>
                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                        <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                        
                    </span>
                    <span>
                        {
                            (this.state.searchObj.status == 'SELF_CANCEL_CHECK')?
                            <Button onClick={() => this.passFn(record)} style={{marginLeft:'10px'}}>通过</Button>:''
                        }
                    </span>
                    {/* <span>
                        {
                            (this.state.searchObj.status == 'SELF_CANCEL_CHECK')?
                            <Button onClick={() => this.closeOrder(record)} style={{marginLeft:'10px'}}>驳回</Button>:''
                        }

                    </span> */}
                    <span>
                        {
                            (this.state.searchObj.status == 'PAID')?
                            <Button onClick={() => this.delPay(record)} style={{marginLeft:'10px'}}>取消</Button>:''
                        }
                    </span>
                </div>
            )
        },
        ],
        rowSelections: {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // console.log(selected, selectedRows, changeRows);
            },
        },
        drawerStatus: false,
        modalStatus: false,
        modalType: '',
        searchObj: {
            phone: '',
            page: 1,
            status: '',
            // userId:''
        },
        total: 1,
        statusInfo: [
        ],
        userInfo:{}
    }
    // 展示用户的手机号及项目地址
    userInfo(data){
        // console.log(data)
        this.setState({
            userInfo:data,
            visible: true,
        });
    }
    onCopy(){
        if(copy(`${this.state.userInfo.phone} , ${this.state.userInfo.add}`)){
            message.success("复制成功")
            this.setState({
                visible: false,
            })
        }else message.error("复制失败，请手动复制")
    }
    handleOk = e => {
        // console.log(e);
        this.setState({
            visible: false,
        });
    };
    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false,
        });
    };
    // 取消已支付订单
    delPay(recode){
        // console.log(recode)
        API.delPay(recode.id).then(res=>{
            // console.log('取消已支付订单成功')
            // console.log(res)
            if(res.code === 200){
                message.info("取消成功")
            }
            this.getList({status:recode.status})
        })
    }
    // 删除
    deleteFn(record){
        API.delOrder(record.id).then( res => {
          //   console.log(res,'删除 ',record.id)
            if(res.code == 200 ){
              message.success('删除成功');
              this.getList({pageNum:this.state.pageNum})
            }else{
              message.error('删除失败');  
            }
        })
    }
    checkItem(item) {
        // console.log(this.state.pageNum)
        // console.log(item, typeof(item), typeof(JSON.stringify(item)),this)
        this.props.history.push({ pathname: '/app/order/detail/' + item.id +'?id=' + item.id,query:{
            page:this.state.pageNum
        } })
       // this.setState({
        //     modalStatus: true
        // })
        // this.refs.basicDrawer.openDrawer()
    }
    closeOrder(data) {
        // console.log(data)
        API.orderCancel(data.id).then((res)=>{
            if(res.code === 200){
                message.info("操作成功")
                this.getList({pageNum:1,status:'SELF_CANCEL_CHECK'})
            }
        })
        // console.log(data.id)
        // console.log(data.reason)
        // API.delOrder(data.id,data.reason).then(res=>{
        //     // massage:'取消订单成功'
        //     console.log(res)
        // })
    }
    passFn(data){
        // console.log(data,'退款审核')
        // console.log(data.id)
        // console.log(data.reason)
        API.orderRefund(data.id).then(res => {
            // console.log(res,'退款')
            if(res.code == 200){
                message.success('审核通过')
                this.getList({pageNum:this.state.pageNum,status:'SELF_CANCEL_CHECK'})
            }else{
                message.error("审核失败")
            }
        }) 
    }
    closeModal() {
        this.setState({
            modalStatus: false
        })
    }
    openDrawer() {
        this.setState({
            drawerStatus: true
        })
    }
    changePage(page) {
        console.log(page)
        let obj = {...this.state.searchObj}
        obj.page = page
        this.getList(obj)
        this.setState({
            pageNum:page
        })
        // this.getList({page:page})
    }
    onClose() {
        this.setState({
            drawerStatus: false
        })
    }
    getPerson(data) {
        // console.log(data)
        this.setState({
            drawerStatus: false,
            assignPerson: data.name
        })
    }
    searchHandler(data) {
        // console.log(data, 'search value')
    }
    getStatus(data) {
        // console.log(data, 'status')
        let obj = this.state.searchObj
        obj.status = data
        this.setState({
            searchObj: obj,
        })
        // console.log(this.state.searchObj.status,'1123')
        this.getList({status:data})
    }
    statusInfo() {
        axios.get('http://192.168.1.159:443/enum').then(res => {
            console.log(res, 'ressss')
        })
    }
    getDate(moment, date) {
        let obj = this.state.searchObj
        obj.startTime = date[0]
        obj.endTime = date[1]
        this.setState({
            searchObj: obj
        })
    }
    getValue = (data) => {
        // console.log(data,"++++++")
        let obj = this.state.searchObj
        obj.phone = data.target.value
        this.setState({
            searchObj: obj
        })
    }
    getOrder= (data) => {
        console.log( data.target.value)
        let obj = this.state.searchObj
        obj.userName= data.target.value
        this.setState({
            searchObj:obj
        })
    }
    statusCheck(data) {
        switch (data) {
            case 'UNPAY':
                return '未支付';
            case 'PAID':
                return '已支付';
            case 'TRANSIT':
                return '已发货';
            case 'SELF_CANCEL_CHECK':
                return '审核中';
            case 'SELF_CANCEL_SUCCESS':
                return '取消成功';
            case 'COMPLETE':
                return '已完成';
            case 'FINISH':
                return '已评价';
            case 'SYS_CANCEL_SUCCESS':
                return '超时取消';
            default:
                console.log(data)
        }
    }
    commitSearch() {
        // 这里id改成phone，对应上面searchObj下的id也需要改
        console.log(this.state.searchObj,'searchObj')
        // let id = this.state.searchObj.id
        // this.getList(`id=${this.state.searchObj.id}`)
        // this.getList({phone:this.state.searchObj.phone})
        this.getList(this.state.searchObj)
    }
    getList(data) {
        // 获取商品列表
        console.log(data)
        API.orderList(data).then(res => {
            console.log(res,'hhhhhhhh')
            if (res.code == 200) {
                let list = res.data.content
                // console.log(list,'list')
                list.forEach(item => {
                    // console.log(JSON.parse(item.address),typeof(item.address))
                    item.createdTime = Tools.timeChecker(item.createdTime)
                    item.statusInfo = this.statusCheck(item.status)
                    item.add = JSON.parse(item.address).address +''+JSON.parse(item.address).detail
                    item.name = JSON.parse(item.address).name 
                    item.phone = JSON.parse(item.address).phone
                    item.userName = item.cyFullUser.name
                })
                this.setState({
                    data: list,
                    total: res.data.totalElements
                })
            }
        })
    }
    componentDidMount() {
        if(this.props.location.query){
            let obj = {...this.state.searchObj}
            obj.page = this.props.location.query.page
            this.getList(obj)
            this.setState({
                searchObj:obj,
                pageNum:this.props.location.query.page
            })
            // console.log(this.props.location.query.page)
        }else{
            this.getList('')
        }
        // this.statusInfo()
    }
    // 导出中奖名单
    excelDownload(id,item){
        // console.log(id,item.activityName)

        // const href = `http://192.168.1.172:443/admin/order/exportOrders`
        const href = `https://www.huikeji.shop/admin/order/exportOrders`
        // const href = `https://www.huikeji.shop:8045/admin/order/exportOrders`
          axios.get(href,{
                headers: {
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
                },
                responseType:'blob'             //转化后台传过来的文件流。很重要！
              },
              )
                .then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a');
                    link.href = url;
                    // 从header中获取服务端命名的文件名
                    const fileName = `商城订单.xlsx`;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
        })
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                    {/* <p style={{width:"20%"}}>{this.props.title}</p> */}
                    <div style={{ display: 'flex',width: "60%",justifyContent: 'space-between'}}>
                        <div className="flex-row" style={{ width: "50%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>手机号:</p><Input onChange={this.getValue} className="inline-input" style={{width:'300px'}}></Input></div>
                        <div className="flex-row" style={{ width: "50%",marginRight:'10px' }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>用户名：</p><Input onChange={this.getOrder} className="inline-input" style={{width:'300px'}}></Input></div>
                        {/* <div className="flex-row" style={{ width: "20%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>时间:</p><DatePicker.RangePicker className="RangePicker" onChange={this.getDate}></DatePicker.RangePicker></div> */}
                        <div style={{ width: "20%" }}><Button onClick={() => this.commitSearch()}>搜索</Button></div>
                        {/* onClick={this.exportList.bind(this,record.id,record)} */}
                        <div><Button style={{marginLeft:"20px"}} onClick={this.excelDownload}>导出</Button></div>
                    </div>
                    <div className="flex-row" style={{ width: "15%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 ,marginRight:10}}>订单状态:</p>
                        <Select style={{width:100}} className="inline-input" defaultValue='请选择' onChange={this.getStatus.bind(this)}>
                            <Select.Option value='UNPAY'>未支付</Select.Option>
                            <Select.Option value='PAID'>已支付</Select.Option>
                            <Select.Option value='TRANSIT'>已发货</Select.Option>
                            <Select.Option value='COMPLETE'>已完成</Select.Option>
                            <Select.Option value='FINISH'>已评价</Select.Option>
                            <Select.Option value='SELF_CANCEL_CHECK'>审核中</Select.Option>
                            <Select.Option value='SELF_CANCEL_SUCCESS'>取消成功</Select.Option>
                            <Select.Option value='SYS_CANCEL_SUCCESS'>超时取消</Select.Option>
                        </Select></div> 
                    {/* {<div ><Button onClick={() => this.props.searchHandler}>刷新</Button></div>} */}
                    {/* <div><Button onClick={() => this.addPart()}></Button></div> */}
                    {/* <div style={{ width: "10%" }}><Button onClick={() => this.commitSearch()}>搜索</Button></div> */}
                </div>
                <BasicTable data={this.state.data} total={this.state.total} columns={this.state.columns} pageNum={this.props.location.query ? this.props.location.query.page : this.state.pageNum} changePage={this.changePage.bind(this)} row={this.state.rowSelections} rowKey='id'></BasicTable>
                <Modal
                    title="用户信息"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[]}
                    >
                    <p  className='Box' >{this.state.userInfo.phone},{this.state.userInfo.add}</p>
                    <Button style={{ marginTop:20 }} type="primary" onClick={this.onCopy.bind(this)}>复制</Button>
                </Modal>
            </div>
        )
    }

}
function mapGetter(state) {
    return {
        list: state.list
    }
}
function mapActions(dispatch) {
    return {
        getOrderList: bindActionCreators(actions.getOrders, dispatch)
    }
}
export default connect(mapGetter, mapActions)(OrderList) 