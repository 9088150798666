// 线下活动
import React,{Component} from 'react'
import {Button,message,Modal, Form, Input,Switch} from 'antd'
import BasicTable from '../../components/Table'
import EditModal from '../../components/modal_edit'
import PerModal from '../../components/modal_activityPersonnel'
import api from '../../fetch/api'
import axios from 'axios'

class Activity extends Component {
    state = {
        pageNum:1,
        pageSize:10,
        total:1,
        visible: false ,
        perVisible:false,
        activityId:'',
        current:{},
        perCurrent:[],
        data:[],
        columns:[
         { title: '问答Id', dataIndex: 'id', key: 'id' },
         { title: '名称', dataIndex: 'name', key: 'name' },
         { title: '所属城市', dataIndex: 'districtName', key: 'districtName' },
         { title: '详细地址', dataIndex: 'address', key: 'address' },
         { title: '活动状态', dataIndex: 'activityStatus', key: 'activityStatus' },
         { title: '报名费用', dataIndex: 'price', key: 'price' },
         { title: '报名人数', dataIndex: 'willNum', key: 'willNum' },
         { title: '截止报名时间', dataIndex: 'expirationDate', key: 'expirationDate' },
         { title: '活动开始时间', dataIndex: 'beginDate', key: 'beginDate' },
         { title: '活动结束时间', dataIndex: 'endDate', key: 'endDate' },
         { title: '是否置顶', dataIndex: 'isTop', key: 'isTop',
          render:(text,record)=>(
             <div>
                  {
                      <Switch disabled   checked={text}/>
                  }   
             </div> 
          ) },
         { title: '图片', dataIndex: 'img', key: 'img', 
         render: (text, record) => (
            <div>
               {   Array.isArray(text)?
            //    <span> {Array.isArray(text)}</span>
                   text.map((items,index)=>{
                    return(
                      <div key={index}
                       style={{width:"60px",heigth:"60px",margin:"5px",overflow:"hidden",display:"flex"}}>
                        <img src={items} style={{width:"100%"}}></img>
                      </div>
                    )
                })
                :<span>wutu</span> 
               }
            </div>
         )
        },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                { 
                  <span>
                    <Button onClick={this.checkItem.bind(this,record)} >报名人员</Button>
                    <Button   type='primary' onClick={this.showUpdateModal.bind(this,record)} style={{margin:'0 10px'}}>修改</Button>
                    <Button type='danger' onClick={this.deleteOfflineActivity.bind(this,record)}>删除</Button>
                  </span>
                }
             </div>
            )
        }],
        
    }
    componentDidMount() {
        this.getOfflineActivityList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
    }
    componentWillMount(){
        this.getOfflineActivityList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
    }
    deleteOfflineActivity(record){
          api.deleteOfflineActivity(record.id).then( res => {
            //   console.log(res,'删除 ',record.id)
              if(res.code == 200 ){
                message.success('删除成功');
                this.getOfflineActivityList({pageNum:this.state.pageNum})
              }else{
                message.error('删除失败');  
              }
          })
    }
    checkItem = (item) => {
        // let obj =`offlineActivityId=${item.id}&pageNum=${1}&pageSize=${10}`
        let obj =`offlineActivityId=${item.id}`
        this.setState({
            activityId:item.id,
            perVisible: true,
            perTitle:'报名人员',
        });
        api.activityPerList(obj).then(res=>{
            // console.log(res,'21')
            if(res.code == 200){
                this.setState({
                    perCurrent: res.data.content, 
                })
            }
        })
    }
    changePage(page) {
        console.log(page,'page')
        this.setState({
            pageNum:page
        })
        let temp = {pageNum:page,pageSize:this.state.pageSize}
        this.getOfflineActivityList(temp)
        
    }
    onChangeSwitch(checked){
        console.log(`switch t22o ${checked}`);  
    }
    // modal框
    showModal = () => {
        this.setState({
            visible: true,
            title:'新增活动',
        });
    };
    showUpdateModal = (record) => {
      console.log(this.state.form,'11',record)
    //   let arr =[]
    //   arr.push(name:)
    //   record.tagIds = record.label
      if(record.img == null) record.img = []
        if(record.img.length>0){
            const imgArr = record.img.map((imgUrl,index)=>({
                uid: '-' +　index,
                name: imgUrl,
                status: 'done',
                url: imgUrl,
            }))
            // console.log(imgArr,'imgARR')
            record.fileList = imgArr
        }
        this.setState({
         current:record,
         title:'修改活动',
         visible: true,
        });
    };
 
    

    getOfflineActivityList(data){
        api.getOfflineActivityPage(data).then( res => {
            let temp =[]
            res.data.content.map((item,index)=>{
                if(item.img!=''&&item.img!=null){
                    // temp = item.img.split(';').slice(1)
                    temp = item.img.split(',')
                    item.img = temp
                }
               
            })
            console.log(temp,'问答',res)
            this.setState({
                // data:res,
                data:res.data.content,
                total:res.data.totalElements
            })
        }).catch( err => {
            console.log(err,'err')
        }) 
        
    }
    onClose() {
        this.setState({
            perVisible: false,
            visible: false,
            perCurrent:[],
            current:{}
        })
    }
     // 人员表格导出
    perSubmit(){
        console.log(this.state.activityId)
        let id = this.state.activityId
        // const href = `https://www.huikeji.shop:8045/admin/offlineActivityUser/export/${id}`
        const href = `https://www.huikeji.shop/admin/offlineActivityUser/export/${id}`
        // const href = `http://192.168.1.172:443/admin/offlineActivityUser/export`
          axios.get(href,{
                headers: {
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
                },
                responseType:'blob'             //转化后台传过来的文件流。很重要！
              },
              )
                .then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a');
                    link.href = url;
                    // 从header中获取服务端命名的文件名
                    const fileName = `用户名单.xlsx`;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
        })
        this.onClose()
    }
    async submit(data) {
        // data.content = JSON.stringify(data.content)
        console.log(data,'data------',this.state.current)
        // console.log(data,'data------')
        if(data.img.length == 0){
            data.img = this.state.current.img.toString()
            console.log(data,'data------')
        }
        if(this.state.current.id){
            data.id = this.state.current.id
            // 修改套餐
            await api.putOfflineActivity(data)
        } else {
            // 新增套餐
            await api.postOfflineActivity(data)
        }
        this.onClose()
        this.getOfflineActivityList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
    }
    render() {
        return (
            <div >
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 10 }}>
                    <div> <Button   type='primary' onClick={this.showModal} style={{margin: 30}}>新增</Button></div>
                </div>
               
                <BasicTable 
                    data={this.state.data} 
                    columns={this.state.columns} 
                    row={this.state.row} 
                    changePage={this.changePage.bind(this)}
                    total={this.state.total} 
                    rowKey='id'
                >
                </BasicTable>
                <PerModal 
                  perData={this.state.perCurrent} 
                  visible={this.state.perVisible} 
                  title={this.state.perTitle}
                  onClose={this.onClose.bind(this)} 
                  perSubmit={this.perSubmit.bind(this)}
                > </PerModal>
                <EditModal 
                  data={this.state.current} 
                  visible={this.state.visible} 
                  title={this.state.title}
                  onClose={this.onClose.bind(this)} 
                  type='acivity' 
                  submit={this.submit.bind(this)}></EditModal>
                
            </div>
            
        )
    }
}
export default Form.create()(Activity); 