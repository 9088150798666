import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from 'antd'
import actions from '../../store/actions/index'
import { bindActionCreators } from 'redux'
import BasicTable from '../../components/Table'
import EditModal from '../../components/modal_edit'
import SearchHeader from '../../components/search_header'
import API from '@/fetch/api'
import Tool from '@/global/tool'
class AreaManage extends Component {
    state = {
        keyword:"",
        data: [],
        columns: [
            { title: '套餐类别', dataIndex: 'mealType', key: 'mealType' },
            { title: '套餐面积', dataIndex: 'area', key: 'area' },
            { title: '套餐价格', dataIndex: 'price', key: 'price' },
            // { title: '套餐内容', dataIndex: 'content', key: 'content' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                        <span>
                            <Button type='primary' style={{marginRight:10}} onClick={() => this.editMeal(record)}>编辑</Button>
                        </span>
                        <span>
                            <Button type='danger' onClick={() => this.deleteMeal(record)}>删除</Button>
                        </span>
                    </div>
                )
            },
        ],
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },

        },
        editStatus: false,
        total:1,
        current:{}
    }
    onClose() {
        this.setState({
            editStatus: false,
            current:{}
        })
    }
    addMeal() {
        this.setState({
            editStatus: true
        })
    }
    editMeal(data) {
        this.setState({
            editStatus: true,
            current:data
        })
    }
    // 根据id删除套餐
    deleteMeal(data) {
        API.deleteMeal(data.id).then(res=>{
            if(res.code == 200) {
                console.log(res)
                this.getList('')
            }
        })
    }
    changePage(page) {
        this.getList({pageNum:page})
    }
    commitSearch() {
        this.getList({keyword:this.state.keyword})
    }
    getValue(e) {
        console.log(e.target.value)
        this.setState({
            keyword:e.target.value
        })
    }
    async submit(data) {
        data.content = JSON.stringify(data.content)
        console.log(data,'data------',this.state.current)
        if(this.state.current.id){
            data.id = this.state.current.id
            // 修改套餐
            await API.updateMeal(data)
        } else {
            // 新增套餐
            await API.meal(data)
        }
        this.onClose()
        this.getList('')
    }
    getList(data) {
        // 获取所有活动
        API.mealList(data).then(res=>{
            if(res.code == 200) {
                console.log(res)
                console.log(this,'this')
                let list = res.data.content
                list.forEach(item =>{
                    item.createdTime = Tool.timeChecker(item.createdTime)
                })
                this.setState({
                    data:list,
                    total:res.data.totalElements
                })
            }
        })
    }
    
    componentDidMount() {
        this.getList('')
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                    {/* <p style={{width:"20%"}}>{this.props.title}</p> */}
                    <div className="flex-row" style={{ width: "25%" }}> <p style={{ width: 100 }}>零件名称：</p><Input onChange={this.getValue.bind(this)} className="inline-block" width={200}></Input></div>
                    {/* <div className="flex-row" style={{width:"25%"}}> <p style={{width:100}}>{this.props.secondKey+'：'}</p><Input value={this.state.second} onChange={this.changeSecond} className="inline-block" width={200}></Input></div> */}
                    {/* {<div ><Button onClick={() => this.props.searchHandler}>刷新</Button></div>} */}
                    <div><Button type='primary' onClick={() => this.addMeal()}>新增</Button></div>
                    <div style={{ width: "20%" }}><Button onClick={this.commitSearch.bind(this)}>搜索</Button></div>
                </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} changePage={this.changePage.bind(this)} total={this.state.total} rowKey='id'>

                </BasicTable>

                <EditModal data={this.state.current} visible={this.state.editStatus} onClose={this.onClose.bind(this)} type='strainer' submit={this.submit.bind(this)}></EditModal>
            </div>
        )
    }
}
export default connect()(AreaManage);
