import React, { Component } from 'react'
import { Input ,Button,Select,Modal,Form,DatePicker,message} from 'antd'
import { browserHistory } from 'react-router';
import PropTypes from "prop-types";
import options from '../pages/store/areaTree.js'
import { withRouter } from "react-router";
import API from '@/fetch/api'
const { Option } = Select;
 
 
class SeachHead extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };
    constructor(props) {
        super(props)
    }
    state = {
        mealData:[],
        engineerData:[],
        addressData:[],
        userData:[],
        phoneData:[],
        phoneInitial:[],
        modalAdd:false,
        identity:true,
        clear:false,
        dateString:'',
        mealId:'',
        userId:'',
        phone:'',
        cyInfo:'',
        engineerId:'',
        modalAdd2:false,
        userOnSearchValue:'',
        pageSize: 100,
        scrollPage: 1,
        keyWords: "",
    }
    componentWillMount(){
        API.mealListAll('').then(res => {
            // console.log(res,'维保套餐')
            if(res.code ==200){
                this.setState({
                    mealData:res.data
                })
            }
        })
        API.engineerAllList('').then(res =>{
            console.log(res,'3333333')
                if(res.code == 200) {
                    if(res.data)
                    this.state.engineerData = res.data
                    this.setState({
                        engineerData:this.state.engineerData
                    })
                }
                console.log(this.state.engineerData,'工程师id') 
        })
      this.getAllHasIdenityUser()    
    }
    commitSearch() {
        console.log('enter commit11221212')
        let obj = {}
        obj[this.props.firstKey] = this.state.first
        obj[this.props.secondKey] = this.state.second
        console.log(obj,'搜索数据')
        this.props.searchHandler(obj)
    }
    changeFirst=(e)=>{
        // console.log(e.target.value,'搜索数据11')
        this.setState({
            first:e.target.value
        })
    }
    changeSecond=(e)=>{
        // console.log(e.target.value,'搜索数据22')
        this.setState({
            second:e.target.value
        })
    }
    refreshHandler(){
        this.props.refreshHandler()
    }
    // static getDerivedStateFromProps(nextProps,nextState){
    //   console.log(nextProps,'搜索数据22',nextState)
    // }
    enterSearch=(e) =>{
        this.commitSearch()
    }
    handleSelect= (value)=> { 
        this.state.identity=value
        this.setState({
            identity:value
        })
        console.log(this.state.identity);
      }
    onChangeFatherName = () => {
        const { onChangeFatherName } = this.props;
        onChangeFatherName(this.state.identity);
        console.log(this.state.identity,'222')
      };
    handleOk = e => {
        console.log(this.state.engineerId,this.state.mealId,this.state.userId);
        let obj ={}
        obj.cyData = JSON.stringify(this.state.cyInfo)
        obj.engineerId = this.state.engineerId
        obj.mealId = this.state.mealId
        obj.reserveTime = this.state.dateString
        obj.userId = this.state.userId
        obj.userPhone = this.state.phone
        API.maintaince(obj).then(res => {
            console.log(res,'新增保养工单')
            if(res.code == 200 ){
                message.success('添加成功')
                this.props.getList({state:'ACCEPT_AND_HEAR_A_CASE'})
            }else{
                message.error('添加失败！')
            }
        })
        this.setState({
            modalAdd: false,
        });
    };
 
    handleCancel = e => {
        this.setState({
            modalAdd: false,
        });
    };
        
    addMaintenance=()=>{
        this.setState({
            modalAdd:true
        })
    }
    phoneChange(value){
      this.props.form.setFieldsValue({
        address:"",
        name:""
      })
        // this.state.phone = value
        // this.getAllHasIdenityUser(`identity=${this.state.identity}&pageSize=1000&phone=${value}`)
       
        let {phoneData,userData,userId} = this.state
        userData = phoneData.filter(item => item.phone == value)
        userId = userData.userId
        API.cyFullUser(value).then(res =>{
          console.log(res,'地址')
          if(res.code == 200){
              this.setState({
                  addressData:res.data
              })} 
        })
        this.setState({
          phone:value,
          userData,
          userId

      })
        console.log(value,'选中的手机号',this.state.userData,this.state.userId);
    }
    changeUser=(e,valueItem)=>{
        console.log(e,valueItem.props.children,'用户')
        if(e != this.state.userId){
            // this.state.addressData = []
            this.setState({
                clear:true,
                // addressData:this.state.addressData
            })
        }
        this.state.userId = e
        this.setState({
            userId:this.state.userId
        })
        /** 
        API.userList(`identity=${this.state.identity}&pageSize=1000000&name=${valueItem.props.children}`).then(res => { 
            if (res.code == 200 ) {
                console.log(res, 'this11111')
                this.setState({
                    phoneData: res.data.result,
                })
            }
        })
        */
    }
    changeMeal=(e)=>{
        console.log(e,'套餐')
        this.state.mealId = e
        this.setState({
            mealId:this.state.mealId
        })
    }
    changeEngineer=(e)=>{
        console.log(e,'师傅')
        this.state.engineerId = e
        this.setState({
            engineerId:this.state.engineerId
        })
    }
    onChangeTime=(value, dateString)=> {
        this.state.dateString =  dateString
        this.setState({
        dateString:this.state.dateString
    })
    console.log(this.state.dateString)
    }
    changeAddress=(e)=>{
        console.log(e,'地址')
        this.state.addressData.forEach((item,index)=>{
            console.log(item,index)
            console.log(e == item.hth)
            if(e == item.hth){
                this.state.cyInfo = item
                this.setState({
                    cyInfo:this.state.cyInfo
                })
            }
            console.log(this.state.cyInfo,'cyInfo')
        })
    }
    addWarranty(){
        this.setState({
            modalAdd2:true
        })
    }
    handleOk2 = e => {
        // console.log(JSON.stringify(this.state.cyInfo),'cyInfo')
        // console.log(this.state.engineerId,'engineerId')
        // console.log(this.state.userId,'userId')
        // console.log(this.state.phone,'phone')
        let data={
            cyData:JSON.stringify(this.state.cyInfo),
            engineerId:this.state.engineerId,
            userId:this.state.userId,
            userPhone:this.state.phone
        }
        API.addWarranty(data).then(res=>{
            if(res.code==200){
                message.success('新增成功')
                this.props.history.push('/app/maintain/maintain');
            }else {
                message.error('新增失败')
            }
        })
        this.setState({
            modalAdd2: false,
        });
    }
    handleCancel2 = e => {
        this.setState({
            modalAdd2: false,
        });
    };
    onSearch(val) {
        console.log('search:', val);

    }
   
    userOnSearch(value){
        this.setState({
            userOnSearchValue:value
        })
        if(value==''){
            this.getAllHasIdenityUser()
        }else {
            this.getAllHasIdenityUser(`identity=${this.state.identity}&pageSize=1000000&name=${value}`)
        }
    }
    phoneOnSearch(value){
      let that = this
      if (!this.timer) {
        this.timer = setTimeout(function(){
          // that.searchValue(value)
          const datas = [] 
          const {phoneInitial} = that.state
          phoneInitial.forEach(item => {
            if (item.phone.indexOf(value) > -1) {
              datas.push(item)
            }
          })
          // 然后只显示符合搜索条件的所有数据中的前100条
          that.setState({phoneData: datas.slice(0,100)})
          that.timer = null
        },300)
      }
        if(value==''){
          that.getAllHasIdenityUser()
        }else {
          that.getAllHasIdenityUser(`identity=${this.state.identity}&pageSize=1000000&phone=${value}`)
        }
    }
    handleScroll = e => {
      e.persist();
      const { target } = e;
      const rmHeight = target.scrollHeight - target.scrollTop;
      const clHeight = target.clientHeight;
      if (rmHeight === 0 && clHeight === 0) {
        this.setState({ scrollPage: 1 });
      } else {
        if (rmHeight < clHeight + 5) {
          console.log(111, rmHeight, clHeight);
          const { scrollPage } = this.state;
          this.setState({ scrollPage: scrollPage + 1 });
          // scrollPage = scrollPage + 1;
          this.loadOption(scrollPage + 1);
        }
      }
      // console.log(e.target)
    };
    loadOption = pageIndex => {
      const { pageSize, keyWords,phoneInitial } = this.state;
      // 通过每页的数据条数和页数得到总的需要展示的数据条数
      const newPageSize = pageSize * (pageIndex || 1);
      let newOptionsData = [],len; // len 能展示的数据的最大条数
      if (phoneInitial.length > newPageSize) {
        // 如果总数据的条数大于需要展示的数据
        len = newPageSize;
      } else {
        // 否则
        len = phoneInitial.length;
      }
      // 如果有搜索的话，就走这里
      if (!!keyWords) {
        let data_ = phoneInitial.filter(item => item.indexOf(keyWords) > -1) || [];
        data_.forEach((item, index) => {
          if (index < len) {
            newOptionsData.push(item);
          }
        });
      } else {
        phoneInitial.forEach((item, index) => {
          if (index < len) {
            newOptionsData.push(item);
          }
        });
      }
      this.setState({ phoneData: newOptionsData });
    };
   
    // 获取全部已认证用户
    getAllHasIdenityUser(data=`identity=${this.state.identity}&pageSize=1000000`){
        API.userList(data).then(res => { 
            if (res.code == 200 ) {
                console.log(res, 'this11111')
                this.setState({
                    // userData: res.data.result,
                    phoneInitial:res.data.result,
                    phoneData:res.data.result.slice(0,100),
                    total: res.data.totalCount
                })
            }
        })
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const renderItem=(item) =>(
            <Select.Option value={item.id} key={item.id}>{item.mealType?item.mealType:'暂无数据'}
            </Select.Option>
        )
        const renderItemEngineer=(item) =>(
            <Select.Option value={item.id} key={item.id}>{item.realname?item.realname:'暂无数据'}
            </Select.Option>
        )
        const renderItemAddress=(item) =>(
            <Select.Option value={item.hth} key={item.hth}>{item.xxdz?item.xxdz:'暂无数据'}
            </Select.Option>
        )
        const renderItemUser=(item) =>(
            <Select.Option value={item.id} key={item.id}>{item.name?item.name:''}
            </Select.Option>
        )
        const renderItemPhone=(item) =>(
            <Select.Option value={item.phone} key={item.id}>{item.phone?item.phone:''}
            </Select.Option>
        )
        return (
            <div className={this.props.headerType}>
                <p style={{width:"20%"}}>{this.props.title}</p>
                <div > <p style={{width:100}}>{this.props.firstKey+'：'}</p><Input.Search value={this.state.first} onChange={this.changeFirst} className="inline-block" width={200} onPressEnter={this.enterSearch}></Input.Search></div>
                <div > <p style={{width:100}}>{this.props.secondKey+'：'}</p><Input.Search value={this.state.second} onChange={this.changeSecond} className="inline-block" width={200}></Input.Search></div>
                {/* { this.props.headerType == 'refresh-header'? <div><Button onClick={() => this.refreshHandler()}>刷新</Button></div>:''} */}
                { this.props.headerType == 'refresh-header'? <div><Button onClick={() => this.refreshHandler()}>返回</Button></div>:''}
                { this.props.headerType == 'normal-header'? <div><Button onClick={() => this.refreshHandler()}>返回</Button></div>:''}
                <div style={{width:"20%"}}><Button onClick={() => this.commitSearch()}>搜索</Button></div>
                { this.props.ifAdd == 'true'? <div><Button onClick={() => this.addWarranty()} style={{marginRight:'10px'}}>新增</Button></div>:''}
                <Modal
                    title="新增报修工单"
                    visible={this.state.modalAdd2}
                    onOk={this.handleOk2}
                    width={700}
                    onCancel={this.handleCancel2}
                    >
                    <Form style={{width:'680px'}} onSubmit={this.handleSubmit}>
                        <div style={{width:'640px',display:'flex',justifyContent:'space-between'}}>
                        <Form.Item label="联系方式">
                        {getFieldDecorator('phone', {
                            rules: [
                                { required: true, message: '请输入手机号!' },
                                { pattern: /^1\d{10}$/, message: '请正确输入手机号!' }
                            ],
                        })(
                            // 手机号选择输入框
                            <Select
                            showSearch
                            onPopupScroll={this.handleScroll}
                            style={{ width: '193px' }}
                            placeholder="请选择手机号"
                            optionFilterProp="children"
                            onSearch={value=>this.phoneOnSearch(value)}
                            onChange={value=>this.phoneChange(value)}
                            value={this.state.phone}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                this.state.phoneData.map(item =>(
                                    renderItemPhone(item)
                                )
                                )
                            }
                        </Select>
                        )}
                        </Form.Item>
                        <Form.Item label="用户姓名" style={{width:320}}>
                        {getFieldDecorator('name', {
                            initialValue:'',
                            rules: [{ required: true, message: '请输入名字!' }],
                        })(<Select 
                            showSearch 
                            // optionFilterProp="children"
                            placeholder='请选择用户'
                            onSearch={value=>this.userOnSearch(value)}
                            value={this.state.userOnSearchValue} 
                            style={{width:'193px'}}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value,valueItem)=>this.changeUser(value,valueItem)}>
                            {this.state.userData.map(item =>(
                                    renderItemUser(item)
                                )
                                )}
                        </Select>)}
                        </Form.Item>
                        
                        </div>
                        <div style={{width:'640px',display:'flex',justifyContent:'space-between'}}>
                        <Form.Item label="详细地址">
                        {getFieldDecorator('address', {
                            rules: [{ required: true, message: '请先选择用户!' }],
                        })(<Select 
                            placeholder='请选择项目地址' 
                            style={{width:'560px'}}
                            allowClear={this.state.clear}
                            onChange={this.changeAddress}>
                            {this.state.addressData.map(item =>(
                                    renderItemAddress(item)
                                )
                                )}
                        </Select> )}
                        </Form.Item>
                        </div>
                        <div style={{width:'640px',display:'flex',justifyContent:'space-between'}}>
                        <Form.Item label="指派师傅">
                        {getFieldDecorator('12', {
                            rules: [{ required: true, message: '请输入内容!' }],
                        })(<Select 
                            placeholder='请指派师傅' 
                            style={{width:'180px'}}
                            onChange={this.changeEngineer}>
                            {this.state.engineerData.map(item =>(
                                    renderItemEngineer(item)
                                )
                                )}
                        </Select>)}
                        </Form.Item>
                        </div>
                    </Form>
                </Modal>
                { this.props.addShow == 'show'? <div><Button onClick={() => this.addMaintenance()} style={{marginRight:'10px'}}>新增</Button></div>:''}
                <Modal
                    title="新增保养工单"
                    visible={this.state.modalAdd}
                    onOk={this.handleOk}
                    width={700}
                    onCancel={this.handleCancel}
                    >
                    <Form style={{width:'680px'}} onSubmit={this.handleSubmit}>
                        <div style={{width:'640px',display:'flex',justifyContent:'space-between'}}>
                        <Form.Item label="联系方式">
                        {getFieldDecorator('phone', {
                            rules: [
                                { required: true, message: '请输入手机号!' },
                                { pattern: /^1\d{10}$/, message: '请正确输入手机号!' }
                            ],
                        })(
                            // 手机号选择输入框
                            <Select
                            showSearch
                            onPopupScroll={this.handleScroll}
                            style={{ width: '193px' }}
                            placeholder="请选择手机号"
                            optionFilterProp="children"
                            onSearch={value=>this.phoneOnSearch(value)}
                            onChange={value=>this.phoneChange(value)}
                            value={this.state.phone}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                this.state.phoneData.map(item =>(
                                    renderItemPhone(item)
                                )
                                )
                            }
                        </Select>
                        )}
                        </Form.Item>
                        <Form.Item label="用户姓名" style={{width:320}}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: '请输入名字!' }],
                        })(<Select 
                            showSearch 
                            placeholder='请选择用户'
                            onSearch={value=>this.userOnSearch(value)}
                            value={this.state.userOnSearchValue} 
                            style={{width:'193px'}}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value,valueItem)=>this.changeUser(value,valueItem)}>
                            {this.state.userData.map(item =>(
                                    renderItemUser(item)
                                )
                                )}
                        </Select>)}
                        </Form.Item>
                       
                        </div>
                        <div>
                            <Form.Item label="详细地址">
                                {getFieldDecorator('address', {
                                    rules: [{ required: true, message: '请先选择用户!' }],
                                })(<Select 
                                    placeholder='请选择项目地址' 
                                    style={{width:'560px'}}
                                    allowClear={this.state.clear}
                                    onChange={this.changeAddress}>
                                    {this.state.addressData.map(item =>(
                                            renderItemAddress(item)
                                        )
                                        )}
                                </Select> )}
                            </Form.Item>
                        </div>
                        <div style={{width:'640px',display:'flex',justifyContent:'space-between'}}>
                            <Form.Item label="保养时间">
                            {getFieldDecorator('createTime', {
                                rules: [{ required: true, message: '请输保养时间!' }],
                            })(<DatePicker 
                                showTime
                                // onhandleDate={this.props.onhandleDate(this.state.dateString)}  
                                placeholder="请选择保养时间"
                                onChange={this.onChangeTime} 
                                onOk={this.onOk} /> )}
                            </Form.Item>
                            <Form.Item label="保养套餐" style={{width:320}}>
                            {getFieldDecorator('meal', {
                                rules: [{ required: true, message: '请输入内容!' }],
                            })(<Select 
                                placeholder='请选择保养套餐' 
                                style={{width:'193px'}}
                                onChange={this.changeMeal}>
                                {this.state.mealData.map(item =>(
                                        renderItem(item)
                                    )
                                    )}
                            </Select>)}
                            </Form.Item>
                        </div>
                        <div style={{width:'640px',display:'flex',justifyContent:'space-between'}}>
                        <Form.Item label="指派师傅">
                        {getFieldDecorator('12', {
                            rules: [{ required: true, message: '请输入内容!' }],
                        })(<Select 
                            placeholder='请指派师傅' 
                            style={{width:'180px'}}
                            onChange={this.changeEngineer}>
                            {this.state.engineerData.map(item =>(
                                    renderItemEngineer(item)
                                )
                                )}
                        </Select>)}
                        </Form.Item>
                        
                        </div>
                    
                    </Form>
                </Modal>
                {this.props.showState? <div>
                    { this.props.show?
                    <Select 
                        // defaultValue="请选择订单状态" 
                        // allowClear = {this.props.allowClear}
                        value={this.props.clear?"请选择订单状态":this.props.selectValue}
                        // value={this.props.selectValue || "请选择订单状态"} 
                        // defaultValue={this.props.defaultValue} 
                        // defaultValue={this.props.defaultValue} 
                        // style={{ width: 160 }}
                        key='0' 
                        onChange={this.props.handleSelect}
                    >
                        <Option value="CHECK_IN">待接单</Option>
                        <Option value="ACCEPT_AND_HEAR_A_CASE">保养中</Option>    
                        <Option value="TO_BE_CONFIRMED">待确认</Option>    
                        <Option value="CONFIRMED">已确认</Option>    
                        <Option value="PAY_SUCCESS">已支付</Option>    
                        <Option value="REPAIR_SUCCESS">已完成</Option>    
                        <Option value="CANCEL">已取消</Option>    
                        <Option value="ADMINCANCEL">后台取消</Option>    
                    </Select>
                    :
                    (!this.props.select ? <Select 
                        defaultValue="氚云客户" 
                        style={{ width: 100 }}
                        key='0' 
                        onChange={this.props.handleSelect}
                    >
                        <Option value="已认证">氚云客户</Option>
                        <Option value="未认证">意向客户</Option>    
                    </Select> : '')
                    }
                </div>:''}
            </div>

        )
    }
}
const SeachHeader = Form.create({ name: 'seach' })(SeachHead)
export default SeachHeader