import mall from './modules/mall'
import admin from './modules/admin'
import upload from './modules/upload'
import SatisfactionQuery from './modules/SatisfactionQuery'

// console.log(admin,'release')
export default {
    ...mall,
    ...admin,
    ...upload,
    ...SatisfactionQuery
}