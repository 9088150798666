import api from '@/fetch/api'

const getEngineerList=(state=[],action)=>{
    switch(action.type){
        case 'GET_ENGINEER_LIST':
            let list=[]
            let promise = new Promise((resolve,reject) =>{
                api.engineerList('').then(res =>{
                    console.log(res)
                  if(res.code == 200) {
                      resolve(res.data)
                  }
                })
            })
            console.log('enter enter ')
            console.log(promise.then())
           promise.then(res =>{
              if(res.data && res.data.result)
              return res.result
           })
          
            default :
            return []
    }
}
export default {
    getEngineerList
}