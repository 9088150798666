import React, { Component } from 'react'
import { Form, Input, Button, Select,Icon } from 'antd'
import API from '@/fetch/api'

const renderOptions = (item) => (
    <Select.Option value={item.lqbm} key={item.id}>{item.name}</Select.Option>)
class RawForm extends Component {
    const
    constructor(props) {
        super(props)
    }
    state = {
        couponSeqNo:'',
        // lqbm:'',
        coupons:[],
        positionList: [
            { name: '高级工程师', id: '1' },
            { name: '中级工程师', id: '2' },
            { name: '初级工程师', id: '3' },
        ],
        formStyle: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }
    submitForm = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.id = this.props.data.id
            if (!err) {
                console.log(values)
                this.props.submit(values)
            }
        });
    }
    submit() {
        this.submitForm()
    }
    // 组件加载完成
    componentDidMount(){
        console.log(this.props.data)
        this.getCoupon()
        this.initial()
        
    }
    async initial() {
        let obj = {
            airVolume: '',
            price: '',
        }
        if (this.props.data) {
            let seqData = await API.couponList()
            console.log(seqData.data)
            let data = this.props.data
            // console.log(this.state.coupons,"------------")
            // console.log(data.couponSeqNo)
            // console.log(this.state.coupons[1].seqNo)
            // x.seqNo改为x.lqbm 2023-7-11
            let index = seqData.data.findIndex(x => x.lqbm === data.couponSeqNo)
            if(index === -1){
                
            }else{
                obj.couponSeqNo = seqData.data[index].name
            }
            console.log(data, 'data++++++++')
            obj.airVolume = data.airVolume
            obj.price = data.price
        }
        this.props.form.setFieldsValue(obj)
    }
      // 获取优惠券列表
    getCoupon() {
        API.couponList().then(res =>{
            console.log(res,'优惠券列表哈哈哈')
            if(res.code == 200){
                // console.log(JSON.parse(res.data))
                this.setState(
                    {
                        coupons:res.data
                    }
                )
            }
        })
    }
   // 下拉框改变时触发
   handleChangeCoup(value){
        console.log(value,'yhuq')
        let index = this.state.coupons.findIndex(x => x.name === value)
        this.state.couponSeqNo = value
    } 
    render() {
        const { getFieldDecorator,getFieldValue } = this.props.form;
    
        return (
            <Form onSubmit={this.submitForm} >
                <div style={this.state.formStyle} className="edit-form-container">
                    <Form.Item label="一体机风量">
                        {getFieldDecorator('airVolume', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入一体机风量'
                                }
                            ]   
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    <Form.Item label="价格">
                        {getFieldDecorator('price', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入价格'
                                }
                            ]
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    <Form.Item label="优惠券">
                        {/* {getFieldDecorator('couponName', { */}
                        {getFieldDecorator('couponSeqNo', {
                            rules: [
                                {
                                    required: false,
                                    message:'请选择优惠券'
                                }
                            ]
                        })(<Select  onChange={this.handleChangeCoup.bind(this)} placeholder='请选择优惠券' style={{ width: 200 }} showSearch>
                        {this.state.coupons.map(item => (
                            renderOptions(item)
                        ))}
                        </Select>)}
                    </Form.Item>
                </div>
            
                <Form.Item >
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        )
    }
}
const MenuForm = Form.create({ name: 'edit' })(RawForm)
export default MenuForm;