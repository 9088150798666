import React,{Component} from 'react'
import {Form,Input,Button,Select, Radio,Checkbox} from 'antd'
import BraftEditor from 'braft-editor'
import BasicUpload from '@/components/upload'
import API from '@/fetch/api'
import EditableFormTable from './EditableFormTable';

const renderOptions = (item) => (
    <Select.Option value={item.lqbm} key={item.lqbm}>{item.name?item.lqbm +" "+ item.name:''}</Select.Option>)
const recommend = (item) => (
    <Select.Option value={item} key={item}>{item}</Select.Option>)
const renderOptionsType = (item) => (
        <Select.Option value={item.id} key={item.id}>{item.content}</Select.Option>
    )
/* const avatar = (scr) => (
        <Avatar shape="square" size={64} scr={scr} />) */
class RawForm extends Component {
    /* static defaultProps = {
        msg: 1
    } */
    constructor(props) {
        super(props)
        // console.log(this.props)
    }
    state={
        merchandiseSpecList:[],
        data:[],
        categoryId:'',
        context:'',
        name:'',
        description:'',
        iPrice:'',
        stock:'',
        discountPrice:'',
        suggest:'',
        couponSeqNo:'',
        coupons:[],
        focusImage: '',
        images: [],
        categories:[],
        typeList:[],
        suggest:[0,1,2,3,4,5,6,7,8,9,10],
        formStyle:{
            display:'flex',
            flexWrap:'wrap',
            alignItems:'center',
            justifyContent:'space-between'
        },
        editorState: BraftEditor.createEditorState(''), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        uploadConfig: {
            action: 'https://up-z2.qiniup.com',
            listType: 'picture',
            accept: 'image/*',
            multiple: false,
            fileList: [],
            data: { token: '' },
            type: 'image',
            name: 'file',
            limit: 1,
            title: '上传图片'
        },
        num:123,
        msg1:{},
        iconLoading:false,
        conditionType:"不限",
        conditions:["普通会员","VIP会员","铂金VIP","钻石VIP","超级VIP"]
    }
    isEqual(a, b) {
        //如果a和b本来就全等
        if (a === b) {
            //判断是否为0和-0
            return a !== 0 || 1 / a === 1 / b;
        }
        //判断是否为null和undefined
        if (a == null || b == null) {
            return a === b;
        }
        //接下来判断a和b的数据类型
        var classNameA=toString.call(a),
            classNameB=toString.call(b);
        //var classNameA = typeof(a), (兼容IE的写法)
            //classNameB = typeof(b);
        //如果数据类型不相等，则返回false
        if (classNameA !== classNameB) {
            return false;
        }
        if (a instanceof Date)
        {
            classNameA = '[object Date]';
        }
    
        //如果数据类型相等，再根据不同数据类型分别判断
        switch (classNameA) {
            case '[object RegExp]':
            case '[object String]':
            case 'string':
                //进行字符串转换比较
                return '' + a === '' + b;
            case 'number':
            case '[object Number]':
                //进行数字转换比较,判断为NaN
                if (+a !== +a) {
                    return +b !== +b;
                }
                //判断是否为0或-0
                return +a === 0 ? 1 / +a === 1 / b : +a === +b;
            case '[object Date]':
            case '[object Boolean]':
            case 'boolean':
                return +a === +b;
        }
        //如果是对象类型
        if (classNameA == '[object Object]' || classNameA=='object') {
            //获取a和b的属性长度
            var propsA = Object.getOwnPropertyNames(a),
                propsB = Object.getOwnPropertyNames(b);
            if (propsA.length != propsB.length) {
                return false;
            }
            for (var i = 0; i < propsA.length; i++) {
                var propName = propsA[i];
                //如果对应属性对应值不相等，则返回false
                if (a[propName] !== b[propName]) {
                    return false;
                }
            }
            return true;
        }
        //如果是数组类型
        if (classNameA == '[object Array]') {
            if (a.toString() == b.toString()) {
                return true;
            }
            return false;
        }
    }
    // 这个工具类函数存起来   以后会很有用
    // 获取后台返回的token值
    getQi() {
        API.QiAuth().then(res => {
            // console.log(res)
            if (res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = { token: res.data }
                this.setState({
                    uploadConfig: obj,
                })
            }
        })
    }
    getTableDate(data){
        // data.seqNo= data.couponSeqNo
        console.log(data)
        this.setState({
            merchandiseSpecList:data
        })
    }
    getUrlList(data) {
        data = [...data];
        // console.log('data', data)
        let title = [];
        let focusImg = {};
        data.forEach((fileInfo, index) => {
            if(index <= 0) {
                return focusImg = fileInfo;
            }
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }
            title.push(fileInfo.url);
        })
        // console.log('获取url列表', {
        //     images:title,
        //     focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
        //     uploadConfig: {
        //         ...this.state.uploadConfig,
        //         fileList: data
        //     }
        // })
        this.setState({
            images:title,
            focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
        // console.log(this.state.images)
        // console.log(this.state.focusImage, 'goodsData')
    }
    submitForm=(e) => {
        
        this.setState({ iconLoading: true })
        // console.log("11111")
        // console.log(e)
        const { outputHTML,merchandiseSpecList} = this.state
        // console.log(this.state.focusImage)
        console.log(this.state.outputHTML)
        console.log(this.state.couponSeqNo)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log('--------------------子组件修改', this.state, values.context)
            values.focusImage=this.state.focusImage
            values.images=this.state.images.toString()
            values.conditions = JSON.stringify(this.state.conditions)
            values.conditionType = this.state.conditionType
            if(merchandiseSpecList){
                values.merchandiseSpecList = merchandiseSpecList
            }else{
                values.merchandiseSpecList=[]
            }
            if(values.merchandiseSpec){
                values.merchandiseSpecList.forEach((item)=>{
                    item.merchandiseSpec = values.merchandiseSpec + ":" + item.specValue
                })
            }else{
                values.merchandiseSpecList.forEach((item)=>{
                    item.merchandiseSpec = "规格" + ":" + item.specValue
                })
            }
            if (!err) {
                values.context = outputHTML
                console.log(values)
                this.props.submit(values)
        }
        
    });
    }
    // 获取优惠券列表
    getCoupon() {
        API.couponList().then(res =>{
            // console.log(res,'优惠券列表哈哈哈')
            if(res.code == 200){
                // console.log(JSON.parse(res.data))
                const data = res.data.filter((item)=>{
                    return item.lqbm
                })
                // console.log(data,"过滤优惠券列表")
                this.setState(
                    {
                        coupons:data
                    }
                )
            }
        })
    }
    componentWillMount() {
        this.categoryList('')
        this.typeList("")
        // console.log('路由参数', this.props.query.count)
    }
    // 组件加载完成时执行
    componentDidMount() {
        // console.log('enter')
        this.getCoupon()
        this.getQi();
        
    }
    // 获取父组件传递过来的参数
    async componentWillReceiveProps(nextProps){
        console.log('nextProps', nextProps.msg)
        // this.setState({
        //     iconLoading : msg.iconLoading
        // })
        let msg = nextProps.msg
        // console.log('msg', msg)
        // 这个地方修改会 死循环
        // this.setState({
        //     msg1:msg
        // })
        if (!this.isEqual(this.props.msg, nextProps.msg)){
            const { price:iprice, images=[], focusImage='' } = nextProps.msg
            const imgsArr = images.split(',').concat(focusImage).reverse().map((imgUrl, index) =>({
                uid: '-' +　index,
                name: imgUrl,
                status: 'done',
                url: imgUrl,
            }))
            let seqData = await API.couponList()
            // console.log("===========",seqData.data)
            // console.log("nextProps.msg",nextProps.msg)
            // console.log("nextProps.msg.couponSeqNo",nextProps.msg.couponSeqNo)
            // let index = seqData.data.findIndex(x => x.seqNo === nextProps.msg.couponSeqNo)
            // console.log(index)
            // console.log('imgsArr',imgsArr)
            // if(index === -1){
            //     nextProps.msg.couponSeqNo = ""
            // }else{
            //     nextProps.msg.couponSeqNo = seqData.data[index].seqNo
            // }
            this.props.form.setFieldsValue({
                ...nextProps.msg,
                iprice,
            })
            const data =  nextProps.msg.merchandiseSpecList.filter((item)=>{
                return item.specValue
            })
            const arr = {...data[0]}
            if(arr.merchandiseSpec){
                const data = arr.merchandiseSpec.split(":")[0]
                // console.log(data)
                this.props.form.setFieldsValue({
                    merchandiseSpec:data
                })
            }
            this.setState({ 
                editorState: BraftEditor.createEditorState(nextProps.msg.context),
                focusImage,
                images,
                merchandiseSpecList:nextProps.msg.merchandiseSpecList,
                uploadConfig: {
                    ...this.state.uploadConfig,
                    fileList: imgsArr
                },
                iconLoading : msg.iconLoading === 2 ? false : true,
                conditionType:nextProps.msg.conditionType,
                conditions:nextProps.msg.conditionType === "会员专属" ? JSON.parse(nextProps.msg.conditions) : ["普通会员","VIP会员","铂金VIP","钻石VIP","超级VIP"]
            })
        }
    }

    removeFile(data) {
        // console.log('this.getUrlList', this.getUrlList)
        this.getUrlList(data);
    }
    submit() {
        this.submitForm()
    }
    handleEditorChange = (editorState) => {
        // 富文本的内容改变时，触发
        this.setState({ 
            editorState: editorState,
            outputHTML: editorState.toHTML()
        })
        console.log(this.state.outputHTML,'outputHTML商品添加')
    }
    // 下拉框改变时触发
    handleChangeCoup(value){
        console.log(value,'conpins')
        this.setState({
            couponSeqNo:value
        })
    }
    getInputData(data){

        // if(this.props.msg.id)
        this.setState({
            nameInput:data
        })
    }
    // 获取商品分类列表
    async categoryList(data) {
      // 2023-7-18
        // let arr = await API.getCategory(data)
        let arr = await API.getGoodsCategory(data)
        // console.log(arr,'goods fele')
        this.setState({
            categories: arr.data.content
        })
    }
    // 商品分类下拉框
    changeCategory(data) {
        // console.log(data, '下拉框事件')
        let obj = this.state.goodsData
        obj.categoryId = data
        this.setState({
            goodsData: obj
        })
        
    }
    // 获取商品类型列表（商品是否是虚拟商品）
    async typeList(data) {
        let arr = await API.merchandiseType(data)
        this.setState({
            typeList: arr.data
        })
    }
    // 商品类型下拉框
    changeType(data){
        // console.log(data,'下拉选择类型')
        for(let key in this.state.typeList){
            // console.log(key,this.state.typeList[key],'12312')
            if(data == key){
                // this.state.type = this.state.typeList[key]
                this.setState({
                    type : this.state.typeList[key]
                })
            }
        }
        // let obj = this.state.goodsData
        // obj.merchandiseType = data
        // this.setState({
        //     // type:this.state.type,
        //     goodsData: obj
        // })
    }
    onPurChange(e){
        console.log(e)
        this.setState({
            conditionType:e.target.value
        })
    }
    vipBox(value){
        console.log(value)
        this.setState({
            conditions:value
        })
    }
    render() {
        const { form, msg } = this.props;
        const { getFieldDecorator } = form;
        const { editorState, outputHTML, } = this.state
        // console.log(this.state.categories)
        return (
            <div style={{width:"100%"}}>
                <Form 
                    onSubmit={this.submitForm}
                >
                    <div style={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-end",alignItems:"flex-end"}}>
                        <Form.Item label="商品分类">
                            {getFieldDecorator('categoryId', {
                                rules: [
                                    {
                                        required: false,
                                        message:'请选择商品类型'
                                    }
                                ]
                            })(<Select placeholder='请选择商品类型' style={{ width: 400 }} showSearch>
                            {this.state.categories.map(item => (
                                renderOptionsType(item)
                            ))}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="是否是虚拟商品">
                            {getFieldDecorator('merchandiseType', {
                                rules: [
                                    {
                                        required: false,
                                        message:'请选择是否是虚拟商品'
                                    }
                                ]
                            })(<Select style={{ width: 400 }} onChange={this.changeType.bind(this)} placeholder='请选择商品类型'>
                            {Object.keys(this.state.typeList).map(key => {
                                return (
                                <Select.Option value={key} key={key}>{this.state.typeList[key]}</Select.Option>
                                )})}
                        </Select>)}
                        </Form.Item>
                        <Form.Item label="商品规格">
                            {getFieldDecorator('merchandiseSpec')(<Input style={{width:400}}></Input>)}
                        </Form.Item>
                        <Form.Item label="介绍">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message:'请输入介绍'
                                    }
                                ]
                            })(<Input style={{width:400}}></Input>)}
                        </Form.Item>
                        <Form.Item label="推荐度">
                            {getFieldDecorator('suggest', {
                                rules: [
                                    {
                                        required: false,
                                        message:'请选择推荐度'
                                    }
                                ]
                            })(<Select style={{ width: 400 }} showSearch>
                            {this.state.suggest.map(item => (
                                recommend(item)
                            ))}
                            </Select>)}
                        </Form.Item>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-end",alignItems:"flex-end"}}>
                        <Form.Item label="商品名称">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message:'商品名称'
                                    }
                                ],
                            })(<Input style={{width:400}}></Input>)}
                        </Form.Item>
                        <Form.Item label="库存" style={{display:'inline-block'}}>
                            {getFieldDecorator('stock', {
                                rules: [
                                    {
                                        required: true,
                                        message:'请输入库存'
                                    }
                                ]
                            })(<Input style={{width:400}}></Input>)}
                        </Form.Item>
                        <Form.Item label="商品售价">
                            {getFieldDecorator('iprice', {
                                rules: [
                                    {
                                        required: true,
                                        message:'请输入售价'
                                    }
                                ]
                            })(<Input style={{width:400}}></Input>)}
                        </Form.Item>
                        <Form.Item label="换购价格" style={{display:'inline-block'}}>
                            {getFieldDecorator('discountPrice', {
                                rules: [
                                    {
                                        required: false,
                                        message:'请输入换购价格'
                                    }
                                ]
                            })(<Input style={{width:400}}></Input>)}
                        </Form.Item> 
                        <Form.Item label="优惠券">
                            {getFieldDecorator('couponSeqNo', {
                            // {getFieldDecorator('seqNo', {
                                rules: [
                                    {
                                        required: false,
                                        message:'请选择优惠券'
                                    }
                                ]
                            })(<Select allowClear onChange={this.handleChangeCoup.bind(this)} placeholder='请选择优惠券' style={{ width: 400 }} showSearch>
                            {this.state.coupons.map(item => (
                                renderOptions(item)
                            ))}
                            </Select>)}
                        </Form.Item>
                        
                        </div>
                    </div>
                </Form>
                <BasicUpload {...this.state.uploadConfig} getUrlList={this.getUrlList.bind(this)} style={{ marginRight: '30px' }}></BasicUpload>
                <div style={{margin:20}}>
                    <span>商品购买资格设置：</span>
                    <Radio.Group onChange={this.onPurChange.bind(this)} value={this.state.conditionType}>
                        <Radio value="不限">不限</Radio>
                        <Radio value="会员专属">会员专属</Radio>
                        <Radio value="未认证用户">未认证用户</Radio>
                    </Radio.Group>
                </div>
                {this.state.conditionType === "会员专属" ? <Checkbox.Group onChange={this.vipBox.bind(this)} defaultValue={this.state.conditions} style={{ width: '100%',marginLeft:145 }}>
                    <Checkbox value="普通会员">普通会员</Checkbox>
                    <Checkbox value="VIP会员">VIP会员</Checkbox>
                    <Checkbox value="铂金VIP">铂金VIP</Checkbox>
                    <Checkbox value="钻石VIP">钻石VIP</Checkbox>
                    <Checkbox value="超级VIP">超级VIP</Checkbox>
                </Checkbox.Group>:""}
                <EditableFormTable merchandiseSpecList={this.state.merchandiseSpecList} getTableDate={this.getTableDate.bind(this)}  />
                <BraftEditor
                    value={editorState}
                    onChange={this.handleEditorChange}
                />
                <Button style={{margin:15}} type="primary" loading={this.state.iconLoading} onClick={this.submitForm.bind(this)}>确认添加</Button>
            </div>
        )
    }
}
const GoodsForm =Form.create({name:'edit'})(RawForm)
export default GoodsForm;