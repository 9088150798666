import React, { Component } from 'react'
import { Upload, Button, message, Icon } from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import actions from '@/store/actions'


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
class ImagesUpload extends Component {
    constructor(props) {
        super(props)
    
    }
    state ={
        config: {
            listType: this.props.listType,
            accept:this.props.accept,
            action:this.props.action,
            method:this.props.method,
            data:this.props.data,
            fileList:this.props.fileList,
            headers:this.props.headers,
            multiple:this.props.multiple,
            // fileList: [{uid: '-1', url: "http://cdn.huikeji.shop/ACgIABAEGAAgruqG_wUovKqRwwEwlgE4lgE.png"}],
            onChange:(data)=>{
                console.log(data,this.props.list.getFileList,'what this') 
            
                    if(!data.file.status){
                        console.log('删除图片')
                        data.fileList.forEach((item,index) =>{
                            console.log('enter loop')
                            console.log('enter condition',item.uid,data.file.uid)
                            if(item.uid == data.file.uid){
                                console.log('enter condition--',item.uid,data.file.uid,index)
                                data.fileList.splice(index,1)
                            
                            }
                        })
                    }
                if(data.file.status === 'done'){
                    console.log('upload complete',this.props)
                    data.file.response.uid = data.file.uid
                    // this.props.addFile(data.file.response)
                    console.log(data.fileList,this,'this')
                    this.props.getUrlList(data.fileList)
                }
                this.props.getUrlList(data.fileList)
            },
            onSuccess: (data) => {
                if (data) this.props.getHash && this.props.getHash(data)
            },
            onRemove:(data)=>{
                let obj = data.originFileObj
                // this.props.removeFile(obj)
            },
            beforeUpload:(data)=>{
                console.log('type', data, this.props)
                // console.log(!data.type.includes(this.props.type),data.type,this.props.type)
                if(!data.type.includes(this.props.type)){
                    message.info('格式不正确，已取消上传！');
                    console.log(data,'data data data')
                    return false
                }
            }
        }
    }
    componentWillMount() {
        // console.log(this.state.config)
        
    }
    componentWillReceiveProps(props) {
        // console.log(this.props.list.getFileList,'?????--',this.props)
        console.log(props, 'componentWillReceiveProps')
        let obj = this.state.config
        obj.data = props.data
        console.log(obj.data, 'obj data')
        this.setState({
            config:obj
        })
    }
    render() {
        const obj = { ...this.state.config, fileList: this.props.fileList }
        console.log(obj)
        return (
            <div style={{margin:'10px'}}>
                <Upload {...obj}>
                    {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                    <Button>
                        <Icon type="upload" /> {this.props.title?this.props.title:'上传'}
                    </Button>
                </Upload>
            </div>
        )
    }
}
function mapGetter(state){
    return {
        list:state
    }
}
function mapAction(dispatch){
    return {
        addFile: bindActionCreators(actions.addFile,dispatch),
        removeFile:bindActionCreators(actions.removeFile,dispatch)
    }
}
export default connect(mapGetter,mapAction)(ImagesUpload)