import React from 'react'
import { Tag, Input, Button  } from 'antd'
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';
import Styles from './index.module.css'
export const Activity = ({ item }) => Com => class Hoc extends React.Component {
  state = {
    value: item.name,
    inputVisible: item.inputVisible,
    style: [ Styles.demo, item.inputVisible ? Styles.border : '']
  }
  change = (e) => {
    this.setState({ value: e.target.value })
  }
  render() {
    console.log('Styles', Styles)
    return (
      <div>
        <div className={ Styles.divStyle } onClick={ () => this.props.click(item.id) }>
          <Input className={ this.state.style.join(' ') }  className={ Styles.inputStyle } disabled={ this.state.inputVisible } value={ this.state.value } onChange={ this.change } onBlur={ (e)=> this.props.blur( e,item.id) } placeholder="请输入标题" />
          <Button style={{marginLeft:10}} danger round onClick={ (e) => this.props.Delete(e, item.id) }>-</Button>
        </div>
        <Com data={ item } Confirm={this.props.valChange} Delete={this.props.valChange} />
        <hr/>
      </div>
    )
  }
}
export class EditableTagGroup extends React.Component {
  constructor(props) {
      super(props)
  }
  state = {
    tags: this.props.data.child.map(x => x.name),
    id: this.props.data.id,
    inputVisible: false,
    inputValue: '',
  };
  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag)
    this.props.Delete(tags, this.state.id)
    this.setState({ tags })
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value })
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    console.log(tags,"++++++")
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.props.Confirm(tags, this.state.id)
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  };

  saveInputRef = input => {
    this.input = input;
  };

  forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  render() {
    const { tags, inputVisible, inputValue } = this.state;
    const tagChild = tags.map(this.forMap);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginBottom: 16, marginTop: 16 }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from', 
              duration: 100,
              onComplete: e => {
                e.target.style = '';
              },
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} className="site-tag-plus">
            <PlusOutlined /> 添加选项
          </Tag>
        )}
      </div>
    );
  }
}

