import React,{Component} from 'react'
import {Table,Button,Input,message,Popconfirm,Select} from 'antd'
import API from '@/fetch/api'
import Tool from '@/global/tool'
import ImagesUpload from '@/components/image-upload-banner'
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const { Option } = Select;
var children = [];
// const data = ["1","2","3"]

const articleType = (item) => (<Select.Option value={item} key={item}>{item}</Select.Option>)
const articleList = (item) => (<Select.Option value={item.id} key={item.id}>{item.name}{item.activityName}</Select.Option>)

class Banner extends Component {
    state={
        dataSource: [],
        type:'',
        data:[],
        dataList:[],
        selectShow:false,
        articleTypeId:'',
        articleType:'',
        article:[],
        url:'',
        urlInput:'',
        columns:[
            {
                title: 'Sort',
                dataIndex: 'sort',
                width: 100,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            {
                title:'ID',
                key:'id',
                dataIndex:'id'

            },
            {
                title:'图片',
                key:'url',
                dataIndex:'url',
                render:(record)=>(
                    <img src={record} height="150px" alt=''/>
                )
    
            },
            {
                title:'创建时间',
                key:'createdTime',
                dataIndex:'createdTime'

            },
            {
                title: "操作", dataIndex: 'action', key: 'action',
                render : (text, record, index) => (
                    <div>
                        <Button style={{marginLeft:'10px'}}
                        onClick={() => this.deleteImage(record)} 
                        type='danger'>删除</Button>
                    </div>
                ),
            },
        ],
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            accept:'image/*',
            multiple:true,
            data:{token:''},
            type:'image',
            name:'file',
            // multi_selection: false
        },
        imgType:[],
        ifSelect:'none',
        index:0,
        isNewButton:'inline-block',
        isPutButton:'none'
    }


    onSortEnd = ({ oldIndex, newIndex }) => {
        const { data } = this.state;
        if (oldIndex !== newIndex) {
            console.log(data,"+++++++++")
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items: ', newData);
            // console.log("1111")
            this.setState({ data: newData });
            let dataCeshi = []
            for(let i = 0;i<newData.length;i++){
                delete newData[i].self
                console.log(newData[i])
                dataCeshi.push(newData[i])
            }
            // console.log(dataCeshi)
            API.putImageType(dataCeshi).then((res)=>{
                console.log(res)
            })
        }
        
    };    
    
    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { data } = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };
    // 获取图片类型
    async  getImagetype(){
        let img = await API.getImageType();
        console.log(img,'hahhahhhas')
        this.setState({
            imgType:img.data
        })
        console.log(img.data)
        img.data = img.data || []
        children = img.data.map(item => (
            <Option key={item.key}>{ item.value }</Option>
        ))
        /* for (let i = 0; i < img.data.length; i++) {
            children.push(<Option key={img.data[i].key}>{img.data[i].value}</Option>);
        } */
    }
    componentWillUnmount (){
        this.setState({
            imgType:''
        })
    }
    deleteImage(data) {
        console.log(data,"++++++++")
        API.deleteImage(data.id).then((res) => {
            // 重新加载数据
            // 根据下拉框传的值获取该类型的图片
            this.handleChangeSelect(data.type)
        });
    }
    // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            // console.log(res)
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                // console.log(obj.data)
                this.setState({
                    uploadConfig:obj
                })
                // console.log(this.state.uploadConfig)
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data){
        console.log(data,'imga')
        data.forEach(item =>{
            // debugger;
            console.log(item)
            if(item.response && item.response.hash){
                this.state.url='http://cdn.huikeji.shop/'+item.response.hash
            }
            /* this.setState({
                ...this.state.uploadConfig,
                fileList:this.state.url
            }) */
        })
    }
    // 文章类型选择框改变时执行
    handleChangeArticle(value){
        console.log(value,'改变那些')
        this.setState({
            articleType:value
        })
        if(value == '资讯'){
          API.getinformationContentPage('').then( res => {
            this.getList(res)
          })
        }else if(value == '热点'){
          API.getHotspotPage('').then( res => {
            this.getList(res)
          })
        }else if(value == '百问百答'){
          API.getAnswerContentPage('').then( res => {
            this.getList(res)
          })
        }else if(value == '线上活动'){
          API.getActivi('').then(res=>{
            this.setState({
              dataList: res.data.content ? res.data.content : [],
              selectShow:true,
            })
          })
        }else if(value == '线下活动'){
          API.getOfflineActivityPage('').then( res => {
            this.getList(res)
          })
        }else{
          this.setState({
            selectShow:false
        })
        }
    }
    // 文章类型对应的详情页
    handleChangeArticleDetail(value){
    console.log(value,'改变那些')
    this.setState({
        articleTypeId:value
    })
    
    }
    // 选择框改变时获取选择框内的值
    handleChangeSelect(value) {
        console.log(value,'hahha')
        // 设置type值
        this.setState({
            type:value
        })
        // 根据输入框的值，拿到该项的索引值
        let index = this.state.imgType.findIndex(x => x.key === value)
        console.log(index)
        this.setState({
            index:index
        })
        // 根据索引值判断这条数据中的是否有二级菜单，然后判断二级菜单是否显示
        if(this.state.imgType[index].hasLink === true){
            // 当有二级菜单时，拿到二级菜单的值，渲染到二级选择框
            this.setState({
                ifSelect:'inline-block',
                article:this.state.imgType[index].links
            })
            console.log(this.state.article,'shizeha,a,')
        }else{
            this.setState({
                ifSelect:'none'
            })
        }
        if(this.state.imgType[index].isSingle){

        }
        // 根据选择框的类型，获取对应的图片
        API.getAllImages({type:value}).then(
            res=>{
                if(res.code == 200){
                    let list = res.data
                    if(this.state.imgType[index].isSingle === 'true' && list.length === 1){
                        this.setState({
                            isNewButton:'none',
                            isPutButton:'inline-block'
                        })
                    }else{
                        this.setState({
                            isNewButton:'inline-block',
                            isPutButton:'none'
                        })
                    }
                    console.log(res.data)
                    list.map(item =>{
                        item.createdTime = Tool.timeChecker(item.createdTime)
                        item.self = this
                    })
                }
                /* console.log(data)
                var data = res.data
                console.log(data) */

                // console.log(res)
                // this.state.data
                let datatest = res.data
                
                console.log()
                for(let i =0;i<datatest.length;i++){
                    datatest[i].index = i
                    // console.log(datatest[i])
                }
                this.setState({
                    data:datatest
                })
                
            }
        )
    }
    // 获取input框的输入内容
    handleChangeInput (e){
      console.log(e.target.value,'input')
      this.setState({
        urlInput:e.target.value
    })
    }
    // 点击提交时执行
    submit(){
        let data = [{}]
        data[0].type = this.state.type
        data[0].articleType = this.state.articleType
        data[0].url=this.state.url
        data[0].active=true
        data[0].href=this.state.urlInput || this.state.articleTypeId
        console.log(data)
        API.uploadImage(data).then(res=>{
            // console.log(res)
            this.handleChangeSelect(this.state.type)
        })
    }
    componentDidMount() {
        this.getQi()
        this.getImagetype()
        
    }
    componentWillMount(){
        this.getImagetype()
    }
    // 获取资讯getinformationContentPage、热点、问答列表
    getList(res){
      let temp =[]
      res.data.content.map((item,index)=>{
          if(item.img!=''&&item.img!=null){
              temp = item.img.split(',')
              item.img = temp
          }
          
      })
      this.setState({
          selectShow:true,
          dataList:res.data.content,
      })
      
  }
    // item = this.state.imgType.find(x => x.key === this.state.type)
    renderTnfo = ()=>{
        const item = this.state.imgType.find(x => x.key === this.state.type)
        if(this.state.type === "SHOP"){
            return <span><Button style={{marginLeft:20,display:this.state.isNewButton }} type='primary' onClick={this.submit.bind(this)}>新增</Button> <span> 288px * 150px</span> </span>
        }if(this.state.type === "HOME"){
            return <span><Button style={{marginLeft:20,display:this.state.isNewButton }} type='primary' onClick={this.submit.bind(this)}>新增</Button> <span> 375px *569px</span> </span>
        }if(!this.state.type){
            return ""
        }if(this.state.type === "SALES"){
            return <span><Button style={{marginLeft:20,display:this.state.isNewButton }} type='primary' onClick={this.submit.bind(this)}>{ this.state.data.length === 1 && item.isSingle  ? '修改' : '新增' }</Button><span> 297px * 85px</span></span>
        }else{
            return <Button style={{marginLeft:20,display:this.state.isNewButton }} type='primary' onClick={this.submit.bind(this)}>{ this.state.data.length === 1 && item.isSingle  ? '修改' : '新增' }</Button>
        }
    }
    render() {
        const { data } = this.state;
        const item = this.state.imgType.find(x => x.key === this.state.type)
        const DraggableContainer = props => (
            <SortableContainer
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={this.onSortEnd}
                {...props}
            />
        );
        return (
            <div>
                <div style={{margin:'10px'}}>
                    <ImagesUpload {...this.state.uploadConfig} getUrlList={this.getUrlList.bind(this)} >添加</ImagesUpload>
                </div>
                <Select defaultValue="" onChange={this.handleChangeSelect.bind(this)} style={{ width: 300,marginTop:15,marginLeft:20,marginBottom:20 }} placeholder="please select">
                    {children}
                </Select>
                <Select  style={{ width: 200,marginLeft:20,display:this.state.ifSelect }}  onChange={this.handleChangeArticle.bind(this)} showSearch>
                    {this.state.article.map(item => (
                        articleType(item)
                    ))}
                </Select>
                {this.state.articleType == '外链' ? <Input  onChange={this.handleChangeInput.bind(this)} style={{ width: 380,marginLeft:20,display:this.state.ifSelect }} allowClear placeholder="请输入内容" />
                 : ''}
                 {this.state.selectShow  ? <Select  style={{ width: 300,marginLeft:20,display:this.state.ifSelect }}  onChange={this.handleChangeArticleDetail.bind(this)} showSearch>
                    {this.state.dataList.map(item => (
                        articleList(item)
                    ))}
                </Select>: ''}
                {this.renderTnfo()}
                <Table 
                pagination={false}
                dataSource={data} 
                columns={this.state.columns} 
                rowKey="index"
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: this.DraggableBodyRow,
                    },
                }}
                >
                </Table>
            </div>
        )
    }
}
export default Banner;