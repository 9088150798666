import React,{Component} from 'react'
import {Button} from 'antd'

class Counter extends Component {
    constructor(props){
        super(props)
    }
    state={
        time:0,
        timer:''
    }
    componentDidMount() {
        this.state.time =this.props.time
       this.counter()
       
    }
    counter() {
        let currentTime = this.state.time
        this.state.timer = setInterval(()=>{
            if(this.state.time>0) {
                this.setState({
                    time:currentTime--
                })
            } else {
                clearInterval(this.state.timer)
            }
           
        },1000)
    }
    componentWillUnmount() {
        clearImmediate(this.state.timer)
    }
    render(){
        return(
        <Button style={{border:'none'}}>{this.state.time}</Button>
        )
    }
}
export default Counter;