// import api from '../../../fetch/api'
// import { act } from 'react-dom/test-utils'

const getFileList = (state =[], action) => {
    console.log(action.type,action.type === 'ORDER_LIST','action')
    switch (action.type) {
        case 'ORDER_LIST':
            console.log('match type')
            return [{name:'ha',age:'23',sex:'male'}]
            // api.getList().then(res => {
            //     console.log(res, action.data)

            //     if (res.code == 200) {
            //         return res.data
            //     }
            // })
            // break;
            case 'ADD_FILE':
                state.push(action.data)
                return state
                case 'REMOVE_FILE':
                    let index = state.findIndex(item =>item.uid === action.data.uid);
                    state.splice(index,1);
                    return state;
        default:
            console.log('no matched')
            return []
    }

}
export default {
    getFileList
}