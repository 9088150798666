import React, { Component } from 'react'
import {  Button,  Card, Badge } from 'antd'
import { connect } from 'react-redux'
import API from '@/fetch/api'
import Tool from '@/global/tool'
import {  bindActionCreators } from 'redux'
import actions from '../store/actions'
import { Chart } from '@antv/g2'
import Counter from '../components/counter'
const maintainRender = (data) => (
  <div className="list-item" key={data.id} style={{display:"flex",justifyContent:"space-between"}}>
    <div >{data.name}</div>
    <div >
      {/* <Badge status="success" />{data.status} */}
      <Button style={{ backgroundColor: '#800080', 
      color: "#fff", 
      border: 'none' ,
      width:'100%',
      whiteSpace:'nowrap',
      overflow:'hidden',
      textOverflow:'ellipsis',}}>{data.position}</Button>
    </div>
  </div>
);
const orderRender = (data,self) => (
  // console.log(data,'qqqqqqqqq',self)
  <div className="order-item list-item" key={data.id} style={{display:"flex",justifyContent:"space-between"}}>
    <div className="flex-column" style={{width:'20%',whiteSpace:'nowrap',
      overflow:'hidden',
      textOverflow:'ellipsis'}}>
      <div >{data.username}</div>
      {/* <div >{data.order}</div> */}
    </div>
    <div>{data.createdTime}</div>
    <div className="flex-column">
    {
      data.type == "维修"?<div>
        <Button style={{ backgroundColor: '#800080', color: "#fff", border: 'none' }} onClick={() =>self.goTo(1)}>订单</Button>
        {/* <Counter time={data.createdTime}></Counter>*/}
      </div>:<div>
        <Button style={{ backgroundColor: '#800080', color: "#fff", border: 'none' }} onClick={() =>self.goTo(2)}>订单</Button>
        {/* <Counter time={data.createdTime}></Counter> */}
      </div>
    }
      
    </div></div>
);

const solvedRender = (data,self) => (
  <div className="order-item list-item" key={data.id} style={{display:"flex",justifyContent:"space-between"}}>
    <div className="flex-column" >
      <div>{data.username}</div>
      {/* <div>{data.order}</div> */}
    </div>
    <div>{data.createdTime}</div>
    <div className="flex-column">
    { 
      data.type == "维修"? < Button style={{ backgroundColor: 'red', color: "#fff", border: 'none' }} onClick={() => self.goTo(3)}>维修中</Button>:
      < Button style={{ backgroundColor: 'red', color: "#fff", border: 'none' }} onClick={() => self.goTo(4)}>维修中</Button>
    }
     </div></div>
);
class Home extends Component {
  // mixins=[Lifecycle]
  state = {
    date: new Date(),
    list: [1, 2, 3, 4, 5],
    array: [2, 5, 4, 6, 9],
    data:[],
    engineerData:[],
    maintainData:[],
    modalVisible: false,
    maintainer: [
      { name: '小明', status: '1', statusInfo: '空闲中', order: '2020-03-06', time: 60, orderStatus: '1' },
      { name: '小明', status: '2', statusInfo: '空闲中', order: '2020-03-06', time: 40, orderStatus: '1' },
      { name: '小明', status: '3', statusInfo: '空闲中', order: '2020-03-06', time: 33, orderStatus: '1' }
    ],
    current:1,


  }
  componentWillMount() {
    this.getEngineerList('')
    this.getList()
    this.getMaintainList()
  }
  changeDate(date, dateString) {
    console.log(date, dateString)
  }
  timesUp() {
    let timer = setInterval(() => { })
  }
  renderChart(data,type) {
    if(type == 'week'){
      data.forEach(item =>{
        item.date = item.date.substr(0,4)+'年第'+item.date.substr(4,5)+'周'
        // console.log(item.date)
      })
    }else{
      data.forEach(item =>{
        console.log(item.date)
        item.date = item.date.substr(0,4)+'年'+item.date.substr(4,2)+'月'+item.date.substr(6,7)+'日'
        console.log(item.date,item.date.substr(5,2))
      })
    }
    const c = this.refs.gChart
    console.log(c,'ccc')
    c.innerHTML = ''
    // c.removeChild(child)
    const chart = new Chart({
      container: c,
      height: 500,
      width: 500
    })
    chart.init()
    chart.clear()
    chart.data(data);
    chart.scale({
      // date: {
      //   range: [0, 1],
      // },
      count: {
        min: 0,
        nice: true,
      },
    });
    chart.tooltip({
      showCrosshairs: true, // 展示 Tooltip 辅助线
      shared: true,
    });
    chart.line().position('date*count').label('count');
    chart.point().position('date*count');
    chart.render();
  }
  goTo(data) {
    if(data == 1){
      this.props.history.push({pathname:'/app/maintain/unsolved'})
    }else if(data == 3){
      this.props.history.push({pathname:'/app/maintain/maintain'})
    }else{
      this.props.history.push({pathname:'/app/maintenance/index'})
    }
    // data == 1?this.props.history.push({pathname:'/app/maintain/unsolved'}):this.props.history.push({pathname:'/app/maintain/maintain'})
    
  }
  componentDidMount() {
    this.getChartData('week')
  }
  canvasStyle = {
    demo: {
      width: 300,
      height: 300
    }
  }
  showModalSave = location => {
    this.setState({
      modalVisible: true,
      location,
    });
  };
  changeType(data){
    console.log(data)
    this.setState({
      current:data =='week'?1:2
    })
    this.getChartData(data)
  }
  getChartData(type){
    API.maintainChart({type:type}).then(res =>{
      if(res.code == 200){
        // this.drawChart(data)
        this.renderChart(res.data,type)
      }
    })
  }
  async getList(){
    // 如果是编辑页面在获取数据
    let repairList = await API.repairList({orderStateItems :'ACCEPT_AND_HEAR_A_CASE'})
    // console.log(repairList,'1312313')
    if (repairList.code == 200) {
      if(repairList.data && repairList.data.result){
        console.log(repairList.data,'维修check')
        let list = repairList.data.result
        list.forEach(item => {
            item.createdTime = Tool.timeChecker(item.createdTime)
            item.type = '维修'
          })
        this.setState({
            data: list,
        })
      }
      this.setState({
        data: this.state.data,
    })
      console.log(this.state.data,'home')  
    }
    this.setState({
        data: this.state.data,
    })
    console.log(this.state.data)
    // 保养工单
    let maintainceList = await API.maintainceList({state:'CHECK_IN'})
    console.log(maintainceList,'1312313')
    if(maintainceList.code == 200){
        console.log(maintainceList.data,'保养check')
        console.log(this.state.data,'maintainceList')
      if( maintainceList.data.result){
        let maintainceCheckList = maintainceList.data.result
        let temp = this.state.data
        maintainceCheckList.forEach((item,index) => {
            item.type = '保养'
            item.createdTime = Tool.timeChecker(item.createdTime)
            temp.push(item)
        })
        // console.log(temp,'维修check')
        this.setState({
          data: this.state.data, 
        })
        }
      }
      console.log(this.state.data,'最后')
  }
  // getList() {
  //   API.repairList({orderStateItems:'CHECK_IN'}).then(res => {
  //       if (res.code == 200) {
  //         if(res.data && res.data.result){
  //           console.log(res.data,'维修check')
  //           let list = res.data.result
  //           list.forEach(item => {
  //               item.createdTime = Tool.timeChecker(item.createdTime)
  //               item.type = '维修'
  //             })
  //           this.setState({
  //               data: list,
  //           })
  //         }
  //         this.setState({
  //           data: this.state.data,
  //       })
  //        console.log(this.state.data,'home')  
  //       }
  //   })
  //  API.maintainceList({state:'CHECK_IN'}).then( res => {
  //         if(res.code == 200){
  //           console.log(res.data,'保养check')
  //           console.log(this.state.data,'maintainceList')
  //         if( res.data.result){
  //           let maintainceCheckList = res.data.result
  //           let temp = this.state.data
  //           maintainceCheckList.forEach((item,index) => {
  //               item.type = '保养'
  //               item.createdTime = Tool.timeChecker(item.createdTime)
  //               temp.push(item)
  //           })
  //           // console.log(temp,'维修check')
  //           this.setState({
  //             data: this.state.data, 
  //           })
  //           }
  //         }
  //     })
  //     console.log(this.state.data,'维修check')
  // }
  // async getMaintainList(){
  //   let repairList = await API.repairList({orderStateItems :'ACCEPT_AND_HEAR_A_CASE'})
  //   if (repairList.code == 200) {
  //     if(repairList.data && repairList.data.result){
  //       console.log(repairList.data,'维修跟踪accept_and')
  //       let list = repairList.data.result?repairList.data.result:[]
  //       list.forEach(item => {
  //           item.type = '维修'
  //           item.createdTime = Tool.timeChecker(item.createdTime)
  //       })
  //       this.setState({
  //           maintainData: repairList.data.result,
           
  //       })
  //       console.log(this.state.maintainData,1213213)
  //     }
       
  //   }
  //   this.setState({
  //     maintainData: this.state.maintainData,
  //   })
  //   let maintainceList = await API.maintainceList({state:'ACCEPT_AND_HEAR_A_CASE'})
  //   if(maintainceList.code == 200){
  //       console.log(maintainceList.data,'保养跟踪accept_and')
  //       console.log(this.state.maintainData,'跟踪accept_andmList')
  //     if(maintainceList.data && maintainceList.data.result){
  //       let mainList = maintainceList.data.result
  //       let temp = this.state.maintainData
  //       mainList.forEach((item,index) => {
  //         console.log(item,index)
  //           item.type = '保养'
  //           item.createdTime = Tool.timeChecker(item.createdTime)
  //           temp.push(item)
  //       })
  //       console.log(temp,'11111111111')
  //       console.log(this.state.maintainData,'1231251243')
  //       this.setState({
  //         data: this.state.maintainData, 
  //       })
  //       }
  //     }
  // }
  async getMaintainList(){
    let repairAcceptList = await API.repairList({orderStateItems :'ACCEPT_AND_HEAR_A_CASE'})
    if (repairAcceptList.code == 200) {
      if(repairAcceptList.data && repairAcceptList.data.result){
        console.log(repairAcceptList.data,'维修跟踪accept_and')
        let list = repairAcceptList.data.result?repairAcceptList.data.result:[]
        list.forEach(item => {
            item.type = '维修'
            item.createdTime = Tool.timeChecker(item.createdTime)
        })
        this.setState({
            maintainData: repairAcceptList.data.result,
           
        })
        console.log(this.state.maintainData,1213213)
      }
    }
    this.setState({
      maintainData: this.state.maintainData,
    })
    console.log(this.state.maintainData,'hahahahah')
    let maintainceAcceptList = await API.maintainceList({state:'ACCEPT_AND_HEAR_A_CASE'})
    if(maintainceAcceptList.code == 200){
      console.log(maintainceAcceptList.data,'保养跟踪accept_and')
      console.log(this.state.maintainData,'跟踪accept_andmList')
    if(maintainceAcceptList.data && maintainceAcceptList.data.result){
      let maintainceList = maintainceAcceptList.data.result
      let temp = this.state.maintainData
      maintainceList.forEach((item,index) => {
        console.log(item,index)
          item.type = '保养'
          item.createdTime = Tool.timeChecker(item.createdTime)
          temp.push(item)
      })
      console.log(temp,'11111111111')
      console.log(this.state.maintainData,'1231251243')
      this.setState({
        maintainData: this.state.maintainData, 
      })
      }
    }
  }
// getMaintainList() {
//   API.repairList({orderStateItems :'ACCEPT_AND_HEAR_A_CASE'}).then(res => {
//       if (res.code == 200) {
//         if(res.data && res.data.result){
//           console.log(res.data,'维修跟踪accept_and')
//           let list = res.data.result?res.data.result:[]
//           list.forEach(item => {
//               item.type = '维修'
//               item.createdTime = Tool.timeChecker(item.createdTime)
//           })
//           this.setState({
//               maintainData: res.data.result,
             
//           })
//           console.log(this.state.maintainData,1213213)
//         }
         
//       }
//   })
//   API.maintainceList({state:'ACCEPT_AND_HEAR_A_CASE'}).then( res => {
//     if(res.code == 200){
//       console.log(res.data,'保养跟踪accept_and')
//       console.log(this.state.maintainData,'跟踪accept_andmList')
//     if(res.data && res.data.result){
//       let maintainceList = res.data.result
//       let temp = this.state.maintainData
//       maintainceList.forEach((item,index) => {
//         console.log(item,index)
//           item.type = '保养'
//           item.createdTime = Tool.timeChecker(item.createdTime)
//           temp.push(item)
//       })
//       console.log(temp,'11111111111')
//       console.log(this.state.maintainData,'1231251243')
//       this.setState({
//         maintainData: this.state.maintainData, 
//       })
//       }
//     }
// })
// }
getEngineerList(data) {
  API.engineerList(data).then(res => {
      if (res.code == 200) {
         if(res.data && res.data.result){
          console.log(res, 'engineer data')
          this.setState({
              engineerData: res.data.result
          })
         }
      }
  })
}
  render() {
    return (
      <div className="container">
        <div className="top-container">
          <div className="top-item">
            <Card title="维修人员" >
              {this.state.engineerData.map((item) => {
                return maintainRender(item)
              })}
            </Card>
          </div>
          <div className="top-item">
            <Card title="未处理订单 ">
              {this.state.data.map((item) => {
                // console.log(item,'怎么会没数据')
                return orderRender(item,this)
              })}
            </Card>
          </div>
          <div className="top-item">
            <Card title="订单跟踪" >
              {this.state.maintainData.map((item) => {
                return  solvedRender(item,this)
              })}
            </Card>
          </div>
        </div>
        <div style={{backgroundColor:'#800080',color:'#fff'}}>
          <div className="flex-row">
            <div>
              近期维修情况
            </div>
            <div >
              <Button style={{margin:'10px',border:'none'}} className={this.state.current ==1?'active':''} onClick={()=>this.changeType('week')}>周</Button>
              <Button style={{margin:'10px',border:'none'}} className={this.state.current ==2?'active':''} onClick={()=>this.changeType('day')}>日</Button>
            </div>
          </div>
          <div ref="gChart" className="flex-center"></div>
        </div>

      </div>
    )
  }
}
function mapGetCounter(state) {
  return {
    count: state,
    list: state
  }
}
function mapChangeCounter(dispatch) {
  return {
    increseCounter: bindActionCreators(actions.increment, dispatch),
    decreseCounter: bindActionCreators(actions.decrement, dispatch),
    getlist: bindActionCreators(actions.getlist, dispatch)
  }
}
export default connect(mapGetCounter, mapChangeCounter)(Home)
