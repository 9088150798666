// import login from ''
// import pages from '../../pages'
// import routes from '../../routes/config'
import actions from '../../actions/index'
import api from '../../../fetch/api'
import resolve from 'resolve'
const admin = (state = '', action) => {
    switch (action.type) {
        case 'set-token':
            api.login(action.data).then(res => {
                console.log(res, 'ressss')
            })
            console.log(state, action, 'state')
            sessionStorage.setItem('token', action.data)
            console.log(state, 'this')
            // this.props.history.push({
            //     pathname:'/home'
            // })
            // this.filterRoute()
            return action.data;
        case 'add-person':
            let p = new Promise((resolve, reject) => {
                api.addAddmin(action.data).then(res => {
                   resolve(res)
                })
            })
           console.log(p.then())
            return 
        default:
            return 2;
    }
}

export default {
    admin
}