import React, { Component } from 'react'
import { Form, Input, Button, Select, Icon,TreeSelect, Modal  } from 'antd'
import { Activity, EditableTagGroup } from './activity'
import list from '../store/actions/modules/list';
    const activity = (item, self) => {
        const Component = Activity({ item })(EditableTagGroup)
        return <Component click={ self.ClickDis } Delete={ self.Delete } key={item.name} valChange={ self.Change } blur={ self.Blur }/>
    }
    class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        data:[],
        obj: {
            area: '',
            mealType: '',
            price: '',
            content: '',
        },
        content:[]
    }
    /* content = `[
        {name:'123',
        child:[
            {name:"111",value:''},
            {name:"222",value:''},
            {name:"333",value:''}
        ]},
        {name:'456',
        child:[
            {name:"444",value:''},
            {name:"555",value:''},
            {name:"666",value:''}
        ]}  
    ]` */
    ClickDis = (id) => {
        console.log('id',id)
        const data = [...this.state.data]
        const vis = data.find(x => x.id === id)
        if (vis.inputVisible){
            console.log('vis', vis)
            vis.inputVisible = false
            this.setState({ data })
        }
        console.log(1)
    }
    // 删除一级分类
    Delete = (e, id) => {
        console.log('delete')
        e.stopPropagation()
        let data = [...this.state.data]
        let index = this.state.data.findIndex(x => x.id === id)
        data.splice(index, 1)
        this.setState({ data })
    }
    // 二级选项
    Change = (val, id) => {
        console.log('change')
        const data = [...this.state.data]
        data.find(x => x.id === id).child = val.map(x => ({ name: x, value: '' }))
        this.setState({ data })
        console.log(this.state.data)
    }
    // 一级菜单
    Blur= (e, id) => {
        console.log('delete')
        e.persist()
        const data = [...this.state.data]
        data.find(x => x.id === id).name = e.target.value
        data.find(x => x.id === id).inputVisible = true
        this.setState({ data })
        console.log(this.state.data)
    }
    addClass= () => {
        const item = { name: '', id: this.state.data.length,inputVisible: false, child: [] }
        const data = [...this.state.data]
        data.push(item)
        this.setState({ data })
        console.log(4)
    }
    // 组件加载完成
    componentDidMount() {
        console.log(this.props)
        console.log(this.props.data)
        this.initial()
    }
    // 将value传给父组件
    submitForm = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            const {data} =this.state
            console.log(data)
            values.content = data
            if (!err) {
                console.log(values)
                this.props.submit(values)
            }
        })
    }
    submit = () => {
        this.submitForm()
    }
    initial() {
        let obj = {
            area: '',
            mealType: '',
            price: '',
            content: '',
        }
        if (this.props.data.content) {
            let data = this.props.data
            console.log(data, 'data++++++++')
            obj.area = data.area
            obj.mealType = data.mealType
            obj.price = data.price
            obj.content = data.content
            obj.frequency = data.frequency
            this.setState({ data: JSON.parse(data.content) })
        }
        this.props.form.setFieldsValue(obj)
    }
    inputChangeName(e){
        console.log(e.target.value)
    }
    render() {
        console.log('this.props',this.props)
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Form onSubmit={this.submitForm}>
                    <Form.Item label="套餐名称">
                        {getFieldDecorator('mealType', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入项目名称'
                                }
                            ]
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    <Form.Item label="套餐面积：" >
                        {getFieldDecorator('area', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入面积'
                                }
                            ]
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    <Form.Item label="套餐价格：">
                        {getFieldDecorator('price', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入价格'
                                }
                            ]
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    { console.log('this.state.data',this.state.data) }
                    { 
                        this.state.data.map(x => activity(x, this))
                    }
                    <Button style={{ display: 'block' }}  onClick={ this.addClass }>+</Button>
                    {/* <br /> */}
                    <Form.Item >
                        <Button style={{ display: 'block',marginTop:25 }} htmlType='submit' type='primary'>提交</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
    
}
const StrainerForm = Form.create({name:'edit'})(RawForm)
export default StrainerForm