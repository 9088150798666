import React from 'react';
import { Popconfirm, Form, Table, Button, message, Select,Upload, Icon,Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { EditableContext } from './TableContext';
import EditableCell from './EditableCell';
import API from '@/fetch/api'


const renderOptions = (item) => (
    <Select.Option value={item.seqNo} key={item.seqNo}>{item.name?item.name:''}</Select.Option>
)
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
class EditableTable extends React.Component {
    timer = null
    constructor(props) {
        super(props);
        this.state = {
            sum:0,
            inputData:"",
            disabledAll:true,
            disabledSpecs : true,
            merchandiseSpecList:[],
            data: [],
            array:[],
            coupons:[],
            editingKey: '',
            editingKeyAll:"",
            activityImg:"",
            imageUrl:[],
            isEdit: false,
            loading: false,
            index:"",
            uploadConfig:{
                action:'https://up-z2.qiniup.com',
                listType:'picture',
                accept:'image/*',
                multiple:false,
                data:{token:''},
                type:'image',
                name:'file',
                fileList: [],
                limit: 1,
                index:"",
                onChange:(data)=>{
                    console.log(data)
                    // console.log(data,this.props.list.getFileList,'what this')
                        if(!data.file.status){
                            data.fileList.forEach((item,index) =>{
                                if(item.uid == data.file.uid){
                                    data.fileList.splice(index,1)
                                }
                            })
                        }
                    if(data.file.status === 'done'){
                        data.file.response.uid = data.file.uid
                        this.getUrlList(data.fileList)
                    }
                    this.getUrlList(data.fileList)
                },
                onSuccess: (data) => {
                    // console.log(this.state.imageUrlIndex)
                    if (data) this.getHash && this.getHash(data,this.state.imageUrlIndex)
                },
                onRemove:(data)=>{
                    let obj = data.originFileObj
                    // this.removeFile(obj)
                },
                beforeUpload:(data)=>{
                    // console.log(!data.type.includes(this.props.type),data.type,this.props.type)
                    if(!data.type.includes(this.state.uploadConfig.type)){
                        message.info('格式不正确，已取消上传！');
                        return false
                    }
                }
            },
        };
        this.columns = [
            {
                title: '规格',
                dataIndex: 'specValue',
                width: '10%',
                editable: true,
            },
            {
                title: '库存',
                dataIndex: 'stock',
                width: '10%',
                editable: true,
            },
            {
                title: '商品售价（元）',
                dataIndex: 'iprice',
                width: '15%',
                editable: true,
            },
            {
                title: '换购价格（元）',
                dataIndex: 'discountPrice',
                width: '15%',
                editable: true,
            },
            {
                title: '优惠券',
                dataIndex: 'couponSeqNo',
                // dataIndex: 'seqNo',
                width: '15%',
                render: (text, record , index) => {
                    
                    return(
                        <Select allowClear disabled={this.state.disabledSpecs} onChange={this.handleChangeCoup.bind(this,text,index,record)} defaultValue={record.couponSeqNo} placeholder='请选择优惠券' style={{ width:'100%' }} showSearch>
                            {this.state.coupons.map(item => (
                                renderOptions(item)
                            ))}
                        </Select>
                    )
                },
            },
            {
                title: '预览图',
                dataIndex: 'image',
                width: '15%',
                editable: false,
                render:(text,record,index)=>{
                    const uploadButton = (
                        <div onClick={this.imageClick.bind(this,index)} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                            <Icon type={this.state.loading ? 'loading' : 'plus'} />
                            <div className="ant-upload-text" style={{marginTop:10}}>Upload</div>
                        </div>
                    );
                    const { imageUrl,uploadConfig } = this.state;
                    // console.log(text, 'text')
                    // uploadConfig.index = index
                    return <Upload {...uploadConfig}>
                        {imageUrl[index] ? <img onClick={this.imageClick.bind(this,index)} src={imageUrl[index]} alt="avatar" style={{ height: 100 }} /> : uploadButton}
                    </Upload>
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record,index) => {
                    // console.log(record)
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
                            <EditableContext.Consumer>
                                {(form) => (
                                    <button
                                        onClick={() => this.save(form, record.key,index)}
                                        style={{ marginRight: 8 }}
                                        type="button"
                                    >
                                        保存
                                    </button>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm
                                title="Sure to cancel?"
                                onConfirm={() => this.cancel(record.key)}
                            >
                                <button style={{ marginRight: 8 }} type="button">
                                    取消
                                </button>
                            </Popconfirm>
                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => this.delete(record.key,index)}
                            >
                                <button type="button">删除</button>
                            </Popconfirm>
                        </span>
                    ) : (
                        <button
                            type="button"
                            disabled={editingKey !== ''}
                            onClick={() => this.edit(record)}
                        >
                            编辑
                        </button>
                    );
                },
            },
        ]
    }
    componentDidMount() {
        this.getQi()
        this.getCoupon()
    }
    sumAll(list){
        console.log(list)
    }
    imageClick(index){
        // const { imageUrlIndex } = this.state
        console.log(index)
        this.setState({
            imageUrlIndex:index
        })

    }
    async componentWillReceiveProps(nextProps){
        console.log(nextProps,'merchandiseSpecList',this.state.data)
        const {merchandiseSpecList} = nextProps
        // merchandiseSpecList.forEach((item)=>{
        //     item.seqNo = item.couponSeqNo
        // })
        // this.state.data.forEach((item)=>{
        //     item.seqNo = item.couponSeqNo
        // })
        if (merchandiseSpecList && merchandiseSpecList.length > 0) {
            const imageUrl = merchandiseSpecList
            .filter(x => x.image !== undefined)
            .map(x => x.image)
            // console.log(imageUrl)
            const initImage = this.state.imageUrl
            this.setState({
                imageUrl: !initImage.length ? imageUrl : initImage,
                isEdit: true,
            })
        }
        if (this.state.data.length === 0) {
            let sum = 0
            const data = merchandiseSpecList.filter((item)=>{
                return item.specValue
            })
            data.forEach((item)=>{
                sum = sum + Number(item.stock)
            })
            this.setState({
                sum,
                data:data,
                merchandiseSpecList:data,
            })
        }
    }
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                imageUrl:[...this.state.imageUrl,imageUrl],
                loading: false,
                }),
            );
        }
    };
    // 获取优惠券列表
    getCoupon(data) {
        API.couponList(data).then(res =>{
            console.log(res.data,'222')
            if(res.code == 200){
                // console.log(JSON.parse(res.data))
                const data = res.data.filter((item)=>{
                    return item.lqbm
                })
                this.setState(
                    {
                        coupons:data
                    }
                )
            }
        })
    }
    // 优惠券下拉框改变时触发
    handleChangeCoup(value,text,index,record){
        console.log(value,text,index,record,'hhhhh')
        // console.log(index,'hhhhh')
        this.setState({
            couponSeqNo:record
        })
    }
    // 从七牛云获取图片token
    getQi() {
        API.QiAuth().then(res => {
            if (res.code === 200) {
                let obj = this.state.uploadConfig
                obj.data = { token: res.data }
                this.setState({
                    uploadConfig: obj,
                })
            }
        })
    }
    // 获取图片地址
    getUrlList(data){
        data.forEach(item =>{
            // debugger;
            // console.log(item)
            if(item.response && item.response.hash){
                
                this.props.form.setFieldsValue({
                    awardImg : 'http://cdn.huikeji.shop/' + item.response.hash
                })

                this.setState({
                    uploadConfig: {
                        ...this.state.uploadConfig,
                        // fileList: data
                    }
                })
            }
            // console.log(this.state.awardImg)
        })
    }
    getHash(data,index) {
        console.log(data,index)
        if(data.hash){
            // this.setState({
            //     array : [...this.state.array,'http://cdn.huikeji.shop/' + data.hash]
            // })
            const { imageUrl } = this.state
            const activityImg = 'http://cdn.huikeji.shop/' + data.hash
            // array.push(activityImg)
            // if(index){ // 这个判断干吗用的
            //     imageUrl.splice(index,1)
            // }
            // console.log(imageUrl,activityImg,"imageUrl-activityImg")
            // imageUrl[index] && imageUrl.splice(index,1,activityImg)
            imageUrl.splice(index,1,activityImg)
            console.log(imageUrl,activityImg,"imageUrl-activityImg")
            // index ? imageUrl[index] = activityImg : imageUrl = [...this.state.imageUrl,activityImg]
            this.setState({
                activityImg : 'http://cdn.huikeji.shop/' + data.hash,
                imageUrl:imageUrl[index] ? imageUrl : [...imageUrl,activityImg],
                uploadConfig: {
                    ...this.state.uploadConfig,
                    // fileList: [{
                    //     url: activityImg,
                    //     uid: '-1'
                    // }]
                }
            })
            // console.log(this.state.activityImg)
        }
    }
    isEditing = (record) => {
        const { editingKey } = this.state;
        return record.key === editingKey;
    };
    // 规格表格代码
    cancel = (key) => {
        if (key.length > 6) {
            const { data } = this.state;
            const newData = data;
            newData.splice(data.length - 1, 1);
            this.setState({ data: newData, editingKey: key });
        }
        this.setState({ editingKey: '' });
    };
    delete = (key,i) => {
        // console.log(this.state.data)
        // console.log(key,i)
        const { merchandiseSpecList,imageUrl } = this.state
        // console.log(merchandiseSpecList)
        const list = merchandiseSpecList
        list.splice(i,1)
        // console.log(list)
        const img = imageUrl
        imageUrl.splice(i,1)
        this.setState({
            merchandiseSpecList:list,
            imageUrl:img,
            couponSeqNo:""
        })
        this.props.getTableDate(list)

        // const { data } = this.state;
        // const newData = data;
        // const index = newData.findIndex((item) => key === item.key);
        // newData.splice(index, 1);
        this.setState({ data: list, editingKey: '',id:"" });
    };
    save(form, key,i) {
        // alert("1")
        console.log(form,key,i,'save')
        form.validateFields((error, row) => {
            const { id,imageUrl,merchandiseSpecList} = this.state
            // console.log(imageUrl,"imageUrlimageUrlimageUrl")
            const list = {...row}
            if(imageUrl[i]){
                // imageUrl[i] = imageUrl[i]
                // this.setState({imageUrl})
            }else{
                imageUrl[i] = ""
                this.setState({imageUrl})
            }
            console.log(imageUrl,"imageUrl")
            list.image = imageUrl[i]
            list.name = row.specValue
            list.couponSeqNo = this.state.couponSeqNo
            list.key = key
            // console.log(id)
            if(id){
                list.id = id
                this.setState({
                    id:""
                })
            }
            if(merchandiseSpecList[i]){
                merchandiseSpecList.splice(i,1,list)
            }else{
                merchandiseSpecList.push(list)
            }
            let sum = 0
            merchandiseSpecList.forEach((item=>{
                sum = sum +  Number(item.stock) 
            }))
            // console.log(sum)
            this.setState({
                sum,
                couponSeqNo:""
            })
            this.props.getTableDate(merchandiseSpecList)
            const { data } = this.state; 
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                this.setState({ data: newData, editingKey: '',disabledSpecs : true });
            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '',disabledSpecs : true });
            }
        });
    }
    edit = (data) => {
        console.log(data)
        // data.seqNo= data.couponSeqNo
        this.setState({ editingKey: data.key,disabledSpecs : false,id:data.id,couponSeqNo:data.couponSeqNo });
        // this.setState({ editingKey: data.key,disabledSpecs : false,id:data.id,seqNo:data.couponSeqNo });
    };
    handleAdd = () => {
        const { data, editingKey,activityImg } = this.state;
        if (editingKey !== '') {
            message.error('请先保存');
            return;
        }
        const key = new Date().toString();
        const row = {
            key,
            name:"",
            stock: "",
            price: "",
            swap:"",
            coupon:"",
            // seqNo:"",
            image:""
        };
        // data.seqNo= data.couponSeqNo
        console.log(row,'row',data)
        const newData = data;
        newData.splice(data.length, 1, row);
        this.setState({ data: newData, editingKey: key,disabledSpecs:false });
        // console.log(newData);
    };
    render() {
        // console.log(this.state.data, 'render')
        // 规格表格
        const components = {
            body: {
                cell: EditableCell,
            },
        };
        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: col.dataIndex === 'age' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });
        const { form } = this.props;
        return (
            <div>
                <EditableContext.Provider value={form}>
                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:"100%"}}>
                        <Button onClick={this.handleAdd} type="primary" style={{ marginLeft:20 }}>
                            添加规格
                        </Button>
                    </div>
                    <Table
                        style={{ margin:20 }}
                        components={components}
                        bordered 
                        dataSource={this.state.data}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination = {false}
                        // pagination={{
                        //     onChange: this.cancel,
                        // }}
                    />
                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:"100%"}}>
                        <p style={{ marginLeft:20 }}>
                        总计：{this.state.sum}
                        </p>
                    </div>
                </EditableContext.Provider>
            </div>
        );
    }
}

const EditableFormTable = Form.create()(EditableTable);
export default EditableFormTable;

EditableTable.propTypes = {
    form: PropTypes.object,
};