import React from "react";
import API from '@/fetch/api'
import BasicTable from '../../components/Table'
import { Cascader ,Table, Button,Modal,Input,message, Space,DatePicker, Select,Popconfirm,Form, Spin  } from 'antd';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash'
// const children = [];
const { Option } = Select;

export default class Seckill extends React.Component {
    constructor(props) {
        super(props)
        this.onSearch = _.debounce(this.onSearch, 800);
    }
    state={
        fenye:{
            page:0,
            size:10
        },
        activitPage:{pageNum:1,pageSize:10},
        visible: false,
        columns:[
            { title: 'ID', dataIndex: 'id', key: 'id' },
            { title: '活动名称', dataIndex: 'activityName', key: 'activityName' },
            { title: '参与人数', dataIndex: 'participantsCount', key: 'participantsCount' },
            { title: '活动状态', dataIndex: 'activityStatus', key: 'activityStatus' },
            { title: '开始时间', dataIndex: 'beginDate', key: 'beginDate' },
            { title: '结束时间', dataIndex: 'endDate', key: 'endDate' },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <space size="middle">
                        <Button type="primary" style={{marginRight:10}} onClick={this.edit.bind(this,record)}>编辑</Button>
                        <Button type="danger" style={{marginRight:10}} onClick={this.delete.bind(this,record)}>删除</Button>
                        <Button type="primary" style={{marginRight:10}} onClick={this.exportList.bind(this,record.id,record)}>中奖名单</Button>
                        <Button type="danger" disabled={record.activityStatus === "进行中" ? false : true} onClick={this.stop.bind(this,record.id)}>关闭</Button>
                    </space>
                ),
            }, 
        ],
        data:[],
        goodsList:[],
        id:"",
        startTime:'',
        endTime:'',
        iprice:'',
        stock:'',
        truePrice:'',
        goodsDate:{},
        routeId: '',
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // console.log(selected, selectedRows, changeRows);
            },
        },
        totals:18,
        fetching: false, // 加载状态,
        search: {
            keyword: '',
            page: 1,
            size: 8
        }
    }

    getActivi(data){
        API.getActivi(data).then((res)=>{
            this.setState({
                data:res.data.content ? res.data.content : [],
                totals:res.data.totalElements,
            })
        })
    }
    // 关闭活动
    stop(id){
        API.stopActivity(id).then(res=>{
            if(res.code === 200){
                message.info("活动已关闭")
                this.getActivi(this.state.activitPage)
            }else{
                message.error("活动关闭失败")
            }
        })
    }
    // 导出中奖名单
    exportList(id,item){
        // console.log(id,item.activityName)

        // const href = `http://192.168.1.163:443/admin/awardRecord/exportWinnings?activityId=${id}`
        const href = `https://www.huikeji.shop/admin/awardRecord/exportWinnings?activityId=${id}`
        // const href = `https://www.huikeji.shop:8045/admin/awardRecord/exportWinnings?activityId=${id}`
              axios.get(href,{
                headers: {
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
                },
                responseType:'blob'             //转化后台传过来的文件流。很重要！
              },
              )
                .then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a');
                    link.href = url;
                    // 从header中获取服务端命名的文件名
                    const fileName = `${item.activityName}中奖名单.xlsx`;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
        })
    }
    // 删除活动
    delete(data) {
        // console.log(data)
        if(data.activityStatus === "进行中"){
            message.error("当前活动正在进行中，不可删除")
        }else{
            API.delSeckill(data.id).then((res) => {
                // 重新加载数据
                if(res.code == 200){
                    message.success('删除成功')
                    this.getActivi(this.state.activitPage);
                }else{
                    message.error('删除失败')
                }
            });
        }
        
    }
    // 修改活动
    edit(recode){
        // console.log(recode)
        this.props.history.push({pathname:'/app/active/addSeckill/?count='+recode.id})
    }
    // 新增秒杀
    newAdd(){
        // this.setState({ visible: true, routeId: '0', goodsDate:{}, stock:'', iprice:'' });
        this.props.history.push('/app/active/addSeckill')
    }
    // 弹出层
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    // 确定
    handleOk = e => {
        // console.log(e);
        this.setState({
            visible: false,
        });
        const {goodsDate}=this.state
        // goodsDate.merchandiseId=this.state.id;
        // console.log('goodsDate',goodsDate)
        // goodsDate.startTime=this.state.startTime
        // goodsDate.endTime=this.state.endTime
        // goodsDate.truePrice=this.state.truePrice
        if(this.state.routeId === '1'){ 
            // console.log("执行了修改操作")
            API.putSeckill(goodsDate).then(res=>{
                if(res.code ==200){
                    message.success('修改成功')
                    this.getStoreList(this.state.fenye)
                }else{
                    message.error('修改失败')
                }
            })
        }else{
            API.postSeckil(goodsDate).then(res=>{
                // console.log("执行了新增操作")
                if(res.code ==200){
                    message.success('添加成功')
                    this.getStoreList(this.state.fenye)
                }else{
                    message.error('添加失败')
                }
            })
        }
        
    };
    // 取消
    handleCancel = e => {
        // console.log(e);
        this.setState({
            visible: false,
        });
    };
    // 时间弹窗
    onChange(value, dateString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        let obj = {
            ...this.state.goodsDate
        }
        obj.startTime = dateString[0] + ":00"
        obj.endTime = dateString[1] + ":00"
        this.setState({
            goodsDate: obj
        })
    }
    // 确定时间
    onOk(value) {
        // console.log(value)
        /* console.log('onOk: ', value);
        console.log(value[0]._d) */
        /* let a = moment(value[0]._d).format('YYYY-MM-DD h:mm:ss')
        let b = moment(value[1]._d).format('YYYY-MM-DD h:mm:ss')
        console.log(a)
        console.log(b) */
        
    }
    // 弹窗的选择框
    handleChange(value) {
        // console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
        // console.log(this.state.goodsList)
        // if (value instanceof Array) value = value[0]
        let index = this.state.goodsList.findIndex(x => x.name === value)
        // console.log(this.state.goodsList)
        let obj = {
            ...this.state.goodsDate,
        }
        obj.merchandiseId = this.state.goodsList[index].id
        obj.name = value

        // console.log('this.state.goodsDate', this.state.goodsList[index].iprice)
        this.setState({
            goodsDate:obj,
            fetching: false,
            stock:this.state.goodsList[index].stock,
            iprice:this.state.goodsList[index].iprice
            // goodsDate: obj
            // price:this.state.goodsList[index].price
        })
        // console.log(index)
    }
    // 下拉框获取焦点
    onBlur() {
        // console.log('blur');
    }
    // 获取焦点
    onFocus() {
        // console.log('focus');
    }
    // 根据输入框的值进行搜索
    onSearch(val) {
        let search = {...this.state.search}
        search.keyword = val
        this.setState({ search, fetching: true, goodsList: [] }, async ()=> {
            let { data } = await API.fuzzySearch(this.state.search)
            // console.log(data, '查询商品')
            this.setState({
                goodsList:data.content,
                fetching: false
            })
        })
    }
    // 获取商品列表
    async getTypelist(){
        let { data } = await API.fuzzySearch({keyword:'',page:0,size:100}); // 0 开始?
        // console.log(data.content)

        // let msg = []
        
        const goodsList = data.content ||　[]
        // for (let i = 0; i < data.content.length; i++) {
        //     msg.push(data.content[i]);
            
        // }
        // console.log(msg)
        this.setState({ goodsList })
        
    }
    
    // 组件加载完成时执行
    componentDidMount () {
      let data = this.state
      this.getStoreList(data.fenye)
      this.getTypelist();
      this.getActivi(data.activitPage)
    }
    // 根据页码获取不同页面的数据
    changePage(page){
        // console.log(page)
        this.state.activitPage.pageNum = page
        this.setState({
          activitPage:this.state.activitPage
        })
        let obj = this.state.fenye
        obj.page=page-1;
        obj.pageNum = page
        obj.pageSize = 10
        this.getStoreList(obj)
        this.getActivi(obj)
    }
    // 获取参与活动的商品
    getStoreList(data){
        /* let {size}=this.state
        let msg={
            page:page,
            size:size,
            // isActive: true,
        } */
        API.getSeckil(data).then(res=>{
            // console.log(res,'data');
            for (let i=0;i<res.data.content.length;i++){
                res.data.content[i].startTime = this.strTime(res.data.content[i].startTime)
                res.data.content[i].endTime = this.strTime(res.data.content[i].endTime)
            }
            /* this.setState({
                data:res.data.content,
                totals:res.data.totalElements,
            }) */
        })
    }
    strTime = (arr, Connector='-') => {
        return ( arr.splice(0,3).join(Connector) + ' ' + arr.join(':') )
    }
    
    // 秒杀价输入框
    inputChange(e){

        // console.log(e.target.value)
        let obj = {
            ...this.state.goodsDate
        }
        obj.truePrice = e.target.value
        this.setState({
            goodsDate:obj
        })
    }
    // 秒杀数量输入框
    totalChange(e){
        // console.log(e.target.value)
        let obj = {
            ...this.state.goodsDate
        }
        obj.total = e.target.value
        this.setState({
            goodsDate:obj
        })
    }
    /* onChange(value,list){
        console.log(value,"++++++++",list)
    } */
    
render() {

    const { RangePicker } = DatePicker;
    const { goodsDate, fetching } = this.state
    // const { Option } = Select;
    // console.log(goodsDate)
    // console.log(moment(goodsDate.startTime))
    // const { getFieldDecorator } = this.props.form;
    return (
        <div>
            <div 
            style={{display:"flex",justifyContent:"space-between",alignItems:"center",margin:20}}
            >
                <p style={{color:"red",fontSize:12}}>1.只可上传一个活动。2.进行中的活动无法修改，如需修改，请先关闭活动，然后修改提交</p>
                <Button 
                type='primary'
                onClick={this.newAdd.bind(this)}
                >新增</Button>
                <Modal
                    destroyOnClose
                    title={ this.state.routeId === '1' ? '修改限时秒杀商品' : '添加限时秒杀商品' }
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    >
                    <p 
                    style={{display:'flex',justifyContent:'flex-end',marginRight:' 45px',alignItems:'center',marginTop:'10px',paddingLeft:'30px'}}
                    >
                        选择商品 
                        {/* <Select 
                            showSearch
                            style={{width:'75%',marginLeft:'15px'}} 
                            onChange={this.handleChange.bind(this)} 
                            optionFilterProp="children"
                            disabled={ this.state.routeId === '1' }
                            onFocus={this.onFocus.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                            onSearch={this.onSearch.bind(this)}
                            value={this.state.goodsDate.name} 
                        >
                        {
                            this.state.goodsList.map((item) =>
                            <Option key={item.id} value={item.name}>{item.name}</Option>)
                        }
                        </Select> */}

                        <Select
                            showSearch
                            // mode="multiple"
                            value={this.state.goodsDate.name}
                            placeholder="请选择商品"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            disabled={ this.state.routeId === '1' }
                            onSearch={this.onSearch.bind(this)}
                            onChange={this.handleChange.bind(this)}
                            style={{width:'75%',marginLeft:'15px'}} 
                        >
                            {
                            this.state.goodsList.map((item) =>
                            <Option key={item.id} value={item.name}>{item.name}</Option>)
                            }
                        </Select>
                    </p>
                    <p 
                    style={{display:'flex',justifyContent:'flex-end',marginRight:' 45px',alignItems:'center',marginTop:'10px'}}
                    >
                        原价
                        <Input placeholder="" style={{width:'70%',marginLeft:'20px'}}
                            // onChange={this.inputChange.bind(this)}
                            value={this.state.iprice}
                        />
                    </p>
                    <p 
                    style={{display:'flex',justifyContent:'flex-end',marginRight:' 45px',alignItems:'center',marginTop:'10px'}}
                    >
                        秒杀价
                        <Input placeholder="" style={{width:'70%',marginLeft:'20px'}}
                            onChange={this.inputChange.bind(this)}
                            value={this.state.goodsDate.truePrice}
                        />
                    </p>
                    <p 
                    style={{display:'flex',justifyContent:'flex-end',marginRight:' 45px',alignItems:'center',marginTop:'10px'}}
                    >
                        库存
                        <Input placeholder="" style={{width:'70%',marginLeft:'20px'}}
                            // onChange={this.inputChange.bind(this)}
                            value={this.state.stock}
                        />
                    </p>
                    <p 
                    style={{display:'flex',justifyContent:'flex-end',marginRight:' 45px',alignItems:'center',marginTop:'10px'}}
                    >
                        秒杀数量
                        <Input placeholder="" style={{width:'70%',marginLeft:'20px'}}
                            onChange={this.totalChange.bind(this)}
                            value={this.state.goodsDate.total}
                        />
                    </p>
                    <p 
                    style={{display:'flex',justifyContent:'flex-end',marginRight:' 45px',alignItems:'center',marginTop:'10px'}}
                    >
                        选择时间
                            <RangePicker 
                            style={{width:'70%',marginLeft:'20px'}}
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={this.onChange.bind(this)}
                            onOk={this.onOk.bind(this)}
                            value={ goodsDate.startTime && goodsDate.endTime? [ moment(goodsDate.startTime), moment(goodsDate.endTime) ] : ''}
                            />
                    </p>
                </Modal>
            </div>
            {/* <Table
                columns={this.state.columns}
                expandable={{
                expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                rowExpandable: record => record.name !== 'Not Expandable',
                }}
                dataSource={this.state.data}
            /> */}
            <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} total={this.state.totals} changePage={this.changePage.bind(this)} rowKey='id'></BasicTable>
        </div>
    )
}
}