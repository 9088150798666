import React, { Component } from 'react'
import { Upload, Button, message, Icon, Space} from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import actions from '@/store/actions'
class ImagesUpload extends Component {
    constructor(props) {
        super(props)
    
    }
    state ={
        config: {
            listType: this.props.listType,
            accept:this.props.accept,
            action:this.props.action,
            method:this.props.method,
            data:this.props.data,
            headers:this.props.headers,
            multiple:this.props.multiple,
            // fileList:this.props.fileList,
            onChange:(data)=>{
                console.log(data,this.props,'what this') 
                    if(!data.file.status){
                        // console.log('enter cancel',this.props.fileList)
                        data.fileList.forEach((item,index) =>{
                            // console.log('enter loop')
                            // console.log('enter condition',item.uid,data.file.uid)
                            if(item.uid == data.file.uid){
                                // console.log('enter condition--',item.uid,data.file.uid,index)
                                data.fileList.splice(index,1)
                            
                            }
                        })
                    }
                if(data.file.status=='removed'){
                    // console.log('upload complete',this.props.fileList)
                    // data.file.response.uid = data.file.uid
                    // this.props.removeFile(data.file.response)
                    // console.log(data.fileList,this,'this')
                    this.props.getUrlList(data.fileList)  
                }
                if(data.file.status == 'done'){
                    // console.log('upload complete',this.props.fileList)
                    data.file.response.uid = data.file.uid
                    this.props.addFile(data.file.response)
                    // console.log(data.fileList,this,'this')
                    this.props.getUrlList(data.fileList)
                }
            },
            onRemove:(data)=>{
                // console.log(data,'removeFile')
                let obj = data.originFileObj
                this.props.removeFile(obj)
            },
            beforeUpload:(data)=>{
                // console.log(!data.type.includes(this.props.type),data.type,this.props.type)
                if(!data.type.includes(this.props.type)){
                    message.info('格式不正确，已取消上传！');
                    console.log(data,'data data data')
                    return false
                }
            }
 
        }
    }
    // componentWillMount() {
    //     console.log(this.props)
    //     console.log(this.props.list.getFileList,'_+_+_+_+')
        
    // }
    componentWillReceiveProps() {
        // console.log(this.state.fileList,'?????--',this.props.fileList)
        let obj = this.state.config
        obj.data = this.props.data
        // obj.fileList = this.props.fileList
        // console.log(obj)
        this.setState({
            config:obj
        })
    }
    render() {
        return (
            <div style={{margin:'10px'}}>
                <Upload {...this.state.config} >
                    <Button >
                        <Icon type="upload" /> {this.props.title?this.props.title:'上传'}
                    </Button>
                </Upload>
            </div>
        )
    }
}
function mapGetter(state){
    return {
        list:state
    }
}
function mapAction(dispatch){
    return {
        addFile: bindActionCreators(actions.addFile,dispatch),
        removeFile:bindActionCreators(actions.removeFile,dispatch)
    }
}
export default connect(mapGetter,mapAction)(ImagesUpload)