import React, { Component } from "react"
import { connect } from "react-redux"
import { Input,Button,message } from "antd"
import PageButton from "../../components/pageButton"
import BasicTable from "../../components/Table"
import api from "@/fetch/api"
class Integral extends Component {
	state = {
		data: [],
		phone:'',
		columns: [
			{ title: "用户昵称", dataIndex: "ownerId", key: "ownerId", width: 100 },
			{ title: "联系方式", dataIndex: "sjh", key: "phone" },
			{ title: "项目地址", dataIndex: "ownerDeptId", key: "ownerDeptId" },
			{ title: "原有积分", dataIndex: "f0000001", key: "original", width: 100 },
			{ title: "操作积分", dataIndex: "czjg", key: "operation", width: 100 },
			{ title: "积分变动时间", dataIndex: "modifiedTime", key: "modifiedTime"},
			{ title: "剩余积分", dataIndex: "xyjf", key: "surplus", width: 100 },
			{ title: "状态", dataIndex: "status", key: "status", width: 80 },
		],
		row: {
			onChange: (selectedRowKeys, selectedRows) => {
				console.log(
					`selectedRowKeys: ${selectedRowKeys}`,
					"selectedRows: ",
					selectedRows
				)
			},
			onSelect: (record, selected, selectedRows) => {
				console.log(record, selected, selectedRows)
			},
			onSelectAll: (selected, selectedRows, changeRows) => {
				console.log(selected, selectedRows, changeRows)
			},
		},
    total: 30,
		fenye: {
			pageNum: 1,
			pageSize: 10,
		},
		preVis: true,
		nextVis: false,
	}
	// getExcel() {
	//     Tools.exportExcel(this.state.initColumn,this.state.data,'测试excel.xlsx')
	// }
	// loadExcel(event) {
	//   Tools.importExcel(event).then(res =>{
	//       console.log(res)
	//   })
	// }
	eidtItem(data) {
		console.log(data, "check manager ")
	}
	checkItem(data) {
		this.props.history.push({
			pathname: "/app/integral/detail/" + data.key + "?count=" + data.key,
		})
	}
	changePage(page) {
    console.log(page)
    this.state.fenye.pageNum = page
    this.getList( this.state.fenye)
	}
	// 获取积分详情
	async getList(query) {
		let { data } = await api.integralList(query)
		console.log(data,'data')
		// let totals = data ? data : []
		this.setState({
			// data: this.state.data,
      // total: this.state.data.length,
      data: data.cyFullIntegralOperationalList,
			total:data.total,
			// query
    })
    console.log(this.state.data,this.state.total)
		// return totals.length
		// api.integralList(data).then(res => {
		//     console.log(res)
		//     // let query = { ...this.state.query }
		//     // if(res.data.length < data.pageSize){
		//     //     query.nextVis = true
		//     //     return this.setState({ query })
		//     // }
		//     // if (data.pageNum === 1 && this.state.flag){
		//     //     query.preVis = true
		//     //     return this.setState({ query })
		//     // }
		//     // query.preVis = false
		//     this.setState({
		//         data: res.data,
		//         total: res.data.length
		//         // query
		//     })
		// })
	}

	getValue = (data) => {
        console.log(data.target.value,"++++++")
        // let obj = this.state.searchObj
        // obj.phone = data.target.value
        this.setState({
            phone: data.target.value
        })
    }
	// 搜索
	commitSearch() {
        // 这里id改成phone，对应上面searchObj下的id也需要改
        // console.log(this.state.searchObj,'searchObj')
        // let id = this.state.phone
        // this.getList(`id=${this.state.searchObj.id}`)
        this.getList({phone:this.state.phone})
	}
	refreshHandler(){
		this.getList({})
		message.info('刷新成功')
	}
	// 组件加载完成
	componentDidMount() {
		this.getList(this.state.fenye)
	}
	componentWillMount() {
		// console.log(this.state.total)
		// this.fileList(`pageNum=1&pageSize=100`)
  }
   // 上一页
   pre = () => {
    const { fenye } = this.state
    let newFenye = { ...fenye }
    if (newFenye.pageNum === 1) return
    newFenye.pageNum = fenye.pageNum - 1

    this.setState({ fenye: newFenye, nextVis: false }, () => {
        this.getList(this.state.fenye)
        if (newFenye.pageNum === 1) {
            this.setState({ preVis: true })
        }
        })
    }
    // 下一页
    next = () => {
        const { fenye } = this.state
        let newFenye = { ...fenye }
        newFenye.pageNum = fenye.pageNum + 1
        this.setState({ fenye: newFenye, preVis: false }, async () => {
            const length = await this.getList(this.state.fenye)
            if (length < fenye.pageSize) {
                this.setState({ nextVis: true })
            }
        })
    }
	// integralList
	render() {
		return (
			<div>
				 <div style={{ display: 'flex',width: "40%",justifyContent: 'space-between',margin:'20px',}}>
					<div className="flex-row" style={{ width: "50%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>手机号:</p><Input onChange={this.getValue} className="inline-input" style={{width:'300px'}}></Input></div>
					<div style={{ width: "20%" }}><Button onClick={() => this.commitSearch()}>搜索</Button></div>
					<div><Button onClick={() => this.refreshHandler()}>返回</Button></div>
				</div>
				
        <BasicTable 
         data={this.state.data} 
         total={this.state.total} 
         columns={this.state.columns} 
         changePage={this.changePage.bind(this)} 
        //  pageShow={false}
         row={this.state.rowSelections} rowKey='id'>
        </BasicTable>
        {/* <PageButton
          preVis={this.state.preVis}
          nextVis={this.state.nextVis}
          pre={this.pre}
          next={this.next}
      ></PageButton> */}
			</div>
		)
	}
}

export default connect()(Integral)
