import React, { Component } from 'react'
import { Modal, Radio, Button, Table,Select,Input,DatePicker } from 'antd'
import API from '@/fetch/api'
import { debounce } from 'lodash'
import CountTextArea from './textarea';
const {Option} = Select
const { TextArea } = Input;
const { RangePicker } = DatePicker;
class BasicModal extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        value: 1,
        val:'',
        clear:false,
        targetValue:'',
        mealId:'',
        dateString:'',
        addList:[],
        jfrq:[],
        khxm:[],
        rbjxh:[],
        fl:[],
        xxdz:[],
        hth:[],
        obj:{}
    }
    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
    }
    
    componentWillReceiveProps (nextProps){
      // 2023-06-28会出现两次this.props.orderInfo，nextProps未判断
        console.log(nextProps,this.props.orderInfo,'拿数据')
        if(this.props.orderInfo.userId != undefined){
            API.commonList(this.props.orderInfo.userId).then(res =>{
                // console.log(res,'地址')
                if(res.code == 200){
                    this.setState({
                        addList:res.data
                    })}
            })
        }
    }


    componentDidUpdate() {
        // console.log(this.props.orderInfo.cyInfo)
        // console.log(this.props.sfgb)
    }
    changeState(){
        this.props.changeState(this.state.val)
    }
    changeMeal=(e)=>{
        console.log('获取工程师id')
        console.log(this.props.mealData,e,'dxfghjk')
        let data ={},obj = this.props.orderInfo
        data.id = obj.id
        data.mealId = e
        API.maintainceUpdate(data).then(res =>{
            console.log(res,'工程11师')
            if(res.code ==200){
                this.setState({
                    mealId:res.data.mealId
                })
            }
            console.log(this.state.mealId)
        })
    }
    unique=(arr)=> {
        return Array.from(new Set(arr))
    }
    onchangeAddress=(e)=>{
        // debugger
        console.log(e,'2112312')
        this.props.changeState(e)
        this.state.val = e
        let a = []
        a.push(e.jfrq)
        this.state.jfrq = this.unique(a)
        // this.state.jfrq.push(e.jfrq)
        let c = []
        c.push(e.xxdz)
        this.state.xxdz = this.unique(c)
        // this.state.xxdz.push(e.xxdz)
        let b = []
        b.push(e.hth)
        this.state.hth = this.unique(b)
        // this.state.hth.push(e.hth)
        let d = []
        d.push(e.ytjfl)
        this.state.fl = this.unique(d)
        // this.state.fl.push(e.ytjfl)
        let f = []
        f.push(e.nysbjqxh)
        this.state.rbjxh = this.unique(f)
        // this.state.rbjxh.push(e.nysbjqxh)
        this.setState({
            clear:true,
            val:this.state.val,
            jfrq: this.state.jfrq ,
            xxdz: this.state.xxdz, 
            hth: this.state.hth ,
            rbjxh:this.state.rbjxh,
            fl:this.state.fl
        })
        /* console.log(this.state.val,'慧科数据')
        console.log(this.state.jfrq,'慧科数据')
        console.log(this.state.xxdz,'慧科数据')
        console.log(this.state.hth,'慧科数据')
        console.log(this.state.rbjxh,'慧科数据')
        console.log(this.state.fl,'慧科数据') */
    }
    handleOk = () => {
        if (this.customTextArea && this.customTextArea.current) {
          //发送内容
            let value =this.customTextArea.current.textAreaRef.value
          //xxx
            console.log(value,'value')
        }
    }
    onTextValue=(value)=>{
    // console.log(value,'备注值')
    this.setState({
        targetValue:value
    })
    // console.log(this.state.targetValue,'备注值')
    }
    onChangeTime=(value, dateString)=> { this.setState({
            dateString
        })
    }
    onSearch(val) {
        console.log('search:', val);
    }
    // filterOption={(input, option) => {
    //     if (option && option.props && option.props.title) {
    //       return option.props.title === input || option.props.title.indexOf(input) !== -1
    //     } else {
    //       return true
    //     }
    //   }}
    onOk(value) {
        console.log('onOk: ', value);
    }
    onClose(){
        this.setState({
            jfrq:[],
            addList:[]
        })
    }
    render() {
        const { textValue } = this.state;
        const renderItem=(item) =>(
            <Select.Option value={item.id} key={item.id}>{item.mealType?item.mealType:'暂无数据'}
            </Select.Option>
        )
        return (
            <div>
                <Modal title={this.props.title} visible={this.props.visible} onCancel={this.props.onClose} width={800} onOk={this.props.onOk} destroyOnClose='true'>
                    <div className="order-info" style={{ color: "#ccc" }}>
                        <p style={{ fontSize: 20, fontWeight: 'bold', color: '#000' }}>订单信息</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <span style={{ display: 'block', width: '30%' }}>订单编号：{this.props.orderInfo.repairOrderCode}{this.props.orderInfo.maintenanceCode}</span>
                            <span style={{ display: 'block', width: '30%' }}>创建时间：{this.props.orderInfo.createdTime}</span>
                            {/* <span style={{ display: 'block', width: '30%' }}>报修时间：{this.props.orderInfo.repairTime}{this.props.orderInfo.reserveTime}</span> */}
                            {/* {this.props.orderInfo.state == 'CHECK_IN'?
                                <span style={{ display: 'block', width: '30%' }}>报修时间：{this.props.orderInfo.repairTime}</span>
                                :<span style={{ display: 'block', width: '30%' }}>交付时间：{this.props.orderInfo.repairTime}</span>
                                
                            } */}
                            <span style={{ display: 'block', width: '30%' }}>联系人员：{this.props.orderInfo.username}</span>
                            <span style={{ display: 'block', width: '30%' }}>联系方式：{this.props.orderInfo.userPhone}</span>
                            <span style={{ display: 'block', width: '30%' }}>客户预约时间：{this.props.orderInfo.reserveTime}</span>
                            <span style={{ display: 'block', width: '30%' }}></span>
                            {this.props.orderInfo.state != 'CHECK_IN'?<span style={{ display: 'block', width: '30%' }}>是否过保：{this.props.sfgb ? '是' : '否'}</span>
                        :''}
                            {/* <span style={{ display: 'block', width: '30%' }}>是否过保：{this.props.sfgb ? '是' : '否'}</span> */}
                        </div>
                        <p>详细地址：{this.props.orderInfo.addressDetail}{this.props.orderInfo.address}</p>
                        <div>加急状态： <Radio.Group onChange={this.onChange} value={this.state.value}>
                            <Radio value={1}>加急</Radio>
                            <Radio value={2}>正常</Radio>
                        </Radio.Group></div>
                        {
                            (this.props.orderType == 'maintain' && this.props.orderInfo.state == 'CHECK_IN')?
                            <div style={{display:'flex',flexDirection:'column'}}>
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>客户姓名：</p>
                                    <Select  
                                        onSearch={this.onSearch}
                                        showSearch 
                                        optionFilterProp="children" 
                                        style={{width:200}} 
                                        placeholder='请选择客户姓名' 
                                        onHandleChild={ this.props.onHandleChild(this.state.val) } 
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase())>= 0
                                        }
                                        onChange={this.onchangeAddress.bind(this)}>
                                    { 
                                    this.state.addList.map(function(val,index){ 
                                        console.log(val,'val')
                                        return <Option value={val} key={index} index={index}>{val.khxm}</Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>项目地址：</p>
                                    <Select style={{width:300}} allowClear={this.state.clear} placeholder='请选择项目地址' >
                                    { 
                                    this.state.xxdz.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>交付日期：</p>
                                    <Select style={{width:200}} allowClear={this.state.clear} placeholder='请选择交付日期'>
                                    { 
                                    this.state.jfrq.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>合同号：</p>
                                    <Select style={{width:300}} allowClear={this.state.clear} placeholder='请选择合同号'>
                                    { 
                                    this.state.hth.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p style={{width:70}}>风量：</p>
                                    <Select style={{width:200}} allowClear={this.state.clear} placeholder='风量'>
                                    { 
                                    this.state.fl.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>热泵机器型号：</p>
                                    <Select style={{width:300}} allowClear={this.state.clear} placeholder='请选择型号'>
                                    { 
                                    this.state.rbjxh.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                </div>
                                <div style={{display:'flex'}}>
                                <p style={{width:80}}>备注：</p>
                                <CountTextArea
                                    onTextValue={this.onTextValue}
                                    handleOk={this.handleOk}
                                    onhandleText={this.props.onhandleText(this.state.targetValue)}
                                    setRef={this.customTextArea}
                                    />
                                </div>
                            </div>
                            :'' 
                        }
                        <div style={{ height: 20 }}>{this.props.orderInfo.state + '-' + this.props.orderInfo.state == 'CHECK_IN'}
                        </div>
                        {
                        (this.props.orderType == 'maintenance' && this.props.orderInfo.state == 'CHECK_IN')?
                            <div style={{display:'flex',flexDirection:'column'}}> 
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>     
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>保养套餐：</p>
                                    <Select style={{width:200}}
                                    placeholder='请选择保养套餐' 
                                    onHandleType={ this.props.onHandleType(this.state.mealId) } onChange={this.changeMeal}>
                                    {this.props.mealData.map(item =>(
                                            renderItem(item)
                                    )
                                    )}
                                </Select>
                                </div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>修改保养时间：</p>
                                <DatePicker 
                                    showTime
                                    style={{width:300}} 
                                    onhandleDate={this.props.onhandleDate(this.state.dateString)}  
                                    placeholder="请选择保养时间"
                                    onChange={this.onChangeTime} 
                                    onOk={this.onOk} /> 
                                </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <p>客户姓名：</p>
                                        <Select 
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } 
                                            showSearch
                                        onSearch={this.onSearch} optionFilterProp="children" style={{width:200}} placeholder='请选择客户姓名' onHandle={ this.props.onHandle(this.state.val) } onChange={this.onchangeAddress.bind(this)}>
                                        { 
                                        this.state.addList.map(function(val,index){ 
                                            // console.log(val,'val')
                                            return <Option value={val} key={index} index={index}>{val.khxm}</Option>
                                        })
                                        }
                                        </Select>
                                    </div>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <p>项目地址：</p>
                                        <Select style={{width:300}} allowClear={this.state.clear} placeholder='请选择项目地址' >
                                        { 
                                        this.state.xxdz.map(function(val,index){ 
                                            return <Select.Option value={val} key={index}>{val}</Select.Option>
                                        })
                                        }
                                        </Select>
                                    </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>交付日期：</p>
                                    <Select style={{width:200}} allowClear={this.state.clear} placeholder='请选择交付日期'  >
                                    { 
                                    this.state.jfrq.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>合同号：</p>
                                    <Select style={{width:200}} placeholder='请选择合同号'>
                                    { 
                                    this.state.hth.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                </div>
                                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 0'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p style={{width:70}}>风  量：</p>
                                    <Select style={{width:200}} allowClear={this.state.clear} placeholder='风量' >
                                    { 
                                    this.state.fl.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                <div style={{display:'flex',alignItems:'center'}}>
                                <p>热泵机器型号：</p>
                                    <Select style={{width:300}} allowClear={this.state.clear} placeholder='请选择型号'>
                                    { 
                                    this.state.rbjxh.map(function(val,index){ 
                                        return <Select.Option value={val} key={index}>{val}</Select.Option>
                                    })
                                    }
                                    </Select>
                                </div>
                                </div>
                                <div style={{display:'flex'}}>
                                <p style={{width:80}}>备注：</p>
                                <CountTextArea onTextValue={this.onTextValue} handleOk={this.handleOk} onhandleText={this.props.onhandleText(this.state.targetValue)} setRef={this.customTextArea} />
                                </div>
                            </div>
                            :''
                        } 
                        <div>
                            {/* {this.props.orderInfo.state}
                            {(this.props.orderInfo.state != 'CHECK_IN' ).toString()} */}
                            {this.props.orderInfo.state == 'CHECK_IN' ? <Button style={{ backgroundColor: '#800080',margin:'10px 0', border: 'none', color: '#fff' }} onClick={() => this.props.assign()}>{this.props.assignPerson}</Button> : ''}
                        </div>
                    </div>
                    { (this.props.orderInfo.state != 'CHECK_IN' )? <div >
                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>维修员信息</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <span style={{ display: 'block', width: '30%' }}>维修员ID：{this.props.orderInfo.engineerId}</span>
                            <span style={{ display: 'block', width: '30%' }}>维修人员：{this.props.orderInfo.engineerName}</span>
                            <span style={{ display: 'block', width: '30%' }}>联系电话：{this.props.orderInfo.engineerPhone}</span>
                            <span style={{ display: 'block', width: '30%' }}>所在地区：{this.props.orderInfo.dossierAttribution}</span>
                            <span style={{ display: 'block', width: '30%' }}>职位职能：{this.props.orderInfo.position}</span>
                            {/* <span style={{ display: 'block', width: '30%' }}>维修状态：{this.props.orderInfo.state}</span> */}
                        </div>
                    </div>:''}
                    {(this.props.orderInfo.state != 'CHECK_IN' && this.props.orderType == 'maintain')? <div >
                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>维修信息</p>
                        <div >
                            <p style={{ display: 'block', width: '30%' }}>故障现象：{this.props.orderInfo.faultPhenomenon}</p>
                            <p style={{ display: 'block', width: '30%' }}>解决方式：{this.props.orderInfo.solution}</p>
                            <p style={{ display: 'block', width: '30%' }}>故障原因：{this.props.orderInfo.causeOfIssue}</p>
                            {/* <p style={{ display: 'block', width: '30%' }}>其他服务：{this.props.orderInfo.otherService}</p> */}
                        </div>
                    </div>:''}
                    {(this.props.orderInfo.state != 'CHECK_IN' )? <div >
                    <div >
                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>更换零件信息</p>
                        <div >
                            <Table dataSource={this.props.orderInfo.parts} columns={this.props.orderInfo.columns} pagination={false} rowKey={(record, index) => index} ></Table>

                        </div>
                    </div>
                    </div>:''}
                    {/* {(this.props.orderInfo.state == 'TO_BE_CONFIRMED')?<div >
                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>报修确认</p>
                        <div >
                            <p>维修师傅：已确认 </p>
                            <div>报修客户：待确认 <Button style={{ border: 'none', backgroundColor: '#fff', color: '#800080' }} onClick={()=>this.changeState()}>手动确认</Button></div>
                        </div>
                    </div>:''} */}
                </Modal>
            </div>
        )
    }
}
export default BasicModal