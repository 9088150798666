import axios from 'axios';
import NProgress from 'nprogress' // 引入nprogress插件
import 'nprogress/nprogress.css'
// import '../setupProxy.js'
const baseUrl = 'https://www.huikeji.shop/admin/';
// const baseUrl = '/api/';
axios.defaults.baseUrl = '/api/';
// const baseUrl = 'http://192.168.1.172:443/admin/';
// const baseUrl = 'http://139.196.72.115:6400/admin/';
// const baseUrl = 'https://www.huikeji.shop:8045/admin/';

// const instance = axios.create({

// })
// axios请求拦截器
axios.interceptors.request.use(
  config => {
    // console.log(config,'成功')
    NProgress.start() // 设置加载进度条(开始..)
    return config
  },
  error => {
    // console.log(error,'error')
    return Promise.reject(error)
  }
)
// axios响应拦截器
axios.interceptors.response.use(
  function(response) {
    // console.log(response,'response')
    NProgress.done() // 设置加载进度条(结束..)
    return response
  },
  function(error) {
    // console.log(error,'error22')
    return Promise.reject(error)
  }
)

const fetch = (url, method = 'GET', data = '') => {
  url = baseUrl + url;
  // console.log(method, '???')
  method = method.toUpperCase()
  if (method == 'GET') {
    let param = ''
    if (data != '') {
      for (let key in data) {
        param += '&' + key + '=' + data[key]
      }
      url = url + '?' + param.substr(1)
    }

  }
  return new Promise((resolve, reject) => {
    // console.log(resolve,reject,localStorage.getItem('token'))
    axios({
      method: method,
      url: url,
      data: data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'token': localStorage.getItem('token')
      }
    }).then(res => {
      // console.log(res)
      resolve(res.data)
      if (res.code == 401) {

      } else if (res.code == 500) {

      }
    })
    // .catch(err => {
    //   console.log(err,'err')
    // })
  })
}

Promise.prototype.finally = function (callback) {
  var Promise = this.constructor;
  return this.then(
    function (value) {
      Promise.resolve(callback()).then(
        function () {
          return value;
        }
      );
    },
    function (reason) {
      Promise.resolve(callback()).then(
        function () {
          throw reason;
        }
      );
    }
  );
}
export default fetch;