import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Switch,message,Modal } from 'antd'
import BasicTable from '../../components/Table'
import BasicDrawer from '../../components/drawer'
import BasicModal from '../../components/modal_order'
import { bindActionCreators } from 'redux'
import actions from '../../store/actions'
import SearchHeader from '../../components/search_header'
import BasicUpload from '@/components/upload'
import API from '@/fetch/api'
import Tool from '@/global/tool'
class Maintain extends Component {
    lessStyle = {

    }
    state={
        remark:'1111',
        cyInfo:'222',
        pageSize:10,
        sfgb:false
    }
    componentWillMount() {
        console.log(this, actions, 'this maintain page')
        this.props.getOrderList()
        console.log(this.props.location.pathname.split('/')[3])
        this.state.data = this.props.list
        this.pageCheck()
    }
    componentDidMount() {
        console.log(this.state.searchObj)
        this.getList(this.state.searchObj)
        this.getEngineerList({pageSize:'1000'})
        this.props.getList()
        this.state.data = this.props.list
        console.log(this.props, 'props', 'mount')
    }

    componentWillUpdate() {
        console.log('enter before update', this)

    }
    componentDidUpdate() {
        console.log('enter update')
    }
    state = {
        pageStatus: 'unsolved',
        pageNum:'1',
        pageSize:'10',
        data: [],
        total: 30,
        sureComplete:false,
        // columns: [{ title: '标题', dataIndex: 'title', key: 'title' },
        // { title: '地址', dataIndex: 'address', key: 'address' },
        // { title: '日期', dataIndex: 'date', key: 'date' },
        // { title: '联系人', dataIndex: 'contact', key: 'concact' },
        // { title: '时间', dataIndex: 'time', key: 'time' },
        // {
        //     title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
        //         <div>
        //             <span>
        //                 <Button onClick={() => this.checkItem(record)}>查看</Button>
                          
        //             </span>
        //             <span>
        //                 <Button onClick={() => this.checkItem(record)} type='primary' style={{marginLeft:'10px'}}>指派</Button>

        //             </span>
        //             <span>
        //                 <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
        //             </span>
        //         </div>
        //     )
        // },
        // ],
        engineerData: [
            { name: '小明', status: '1', statusInfo: '空闲中', order: '2020-03-06', time: '60', orderStatus: '1', id: 1 },
            { name: '小小明', status: '2', statusInfo: '空闲中', order: '2020-03-06', time: '60', orderStatus: '1', id: 2 },
            { name: '小明明', status: '3', statusInfo: '空闲中', order: '2020-03-06', time: '60', orderStatus: '1', id: 3 }
        ],
        rowSelections: {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },

        },
        drawerStatus: false,
        modalStatus: false,
        orderInfo: {},
        assignPerson: '',
        currentOrder:{},
        searchObj:{},
        code:""
    }
    changeStatus(data) {
        console.log(data)
    }
    pageCheck() {
        let obj = this.state.searchObj
        switch (this.props.location.pathname.split('/')[3]) {
            
            case 'unsolved':
                obj.orderStateItems ='CHECK_IN'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        // { title: '状态', dataIndex: 'status', key: 'status' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Switch checkedChildren='加急' unCheckedChildren='普通' onChange={() => this.changeStatus(record)}></Switch>
                                    </span>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}  style={{marginLeft:'10px'}}>查看</Button>
                                    </span>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)} type='primary' style={{marginLeft:'10px'}}>指派</Button>
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                    <span>
                                        <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
                                    </span>
                                </div>
                            )
                        },
                    ],

                })
                break;
            case 'maintain':
                obj.orderStateItems ='ACCEPT_AND_HEAR_A_CASE'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        { title: '状态', dataIndex: 'status', key: 'status' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                                    </span>
                                    <span>
                                        <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                </div>
                            )
                        },
                    ]
                })
                break;
            case 'unconfirm':
                obj.orderStateItems ='TO_BE_CONFIRMED'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        { title: '状态', dataIndex: 'status', key: 'status' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                                    </span>
                                    <span>
                                        {localStorage.getItem('role') === "ADMINX" ? <Button onClick={() => this.sureComplete(record)} style={{marginLeft:'10px'}}>确认完成</Button> : ""}
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                    {/* <span>
                                        <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
                                    </span> */}
                                </div>
                            )
                        },
                    ]
                })
                break;
                case 'confirmed':
                obj.orderStateItems ='CONFIRMED'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        { title: '状态', dataIndex: 'status', key: 'status' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                                    </span>
                                    <span>
                                        {localStorage.getItem('role') === "ADMINX" ? <Button onClick={() => this.sureComplete(record)} style={{marginLeft:'10px'}}>确认完成</Button> : ""}
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                </div>
                            )
                        },
                    ]
                })
                break;
            case 'complete':
                obj.orderStateItems ='REPAIR_SUCCESS'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        { title: '状态', dataIndex: 'status', key: 'status' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                    {/* <span>
                                        <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
                                    </span> */}
                                </div>
                            )
                        },
                    ]
                })
                break;
                case 'sure':
            
                obj.orderStateItems ='PAY_SUCCESS'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        { title: '状态', dataIndex: 'status', key: 'status' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                    {/* <span>
                                        <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
                                    </span> */}
                                </div>
                            )
                        },
                    ]
                })
                break;
            case 'cancel':
            
                // obj.orderStateItems ='CANCEL'||'ADMINCANCEL'
                obj.orderStateItems ='ADMINCANCEL'
                obj.pageNum =this.state.pageNum
                obj.pageSize =this.state.pageSize
                this.setState({
                    searchObj:obj,
                    columns: [
                        { title: '订单编号', dataIndex: 'repairOrderCode', key: 'repairOrderCode' },
                        { title: '报修人', dataIndex: 'username', key: 'username' },
                        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
                        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
                        { title: '状态', dataIndex: 'status', key: 'status' },
                        // { title: '状态', dataIndex: 'state', key: 'state' },
                        {
                            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                                <div>
                                    <span>
                                        <Button onClick={() => this.checkItem(record)}>查看</Button>
                                    </span>
                                    <span>
                                      <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                                    </span>
                                </div>
                            )
                        },
                    ]
                })
                break;
            default:
                // console.log('no match')
        }
        // console.log(this.props.location.pathname.split('/')[3])
        this.props.getList(this.props.location.pathname.split('/')[3])
    }
    /* look(item){
        console.log(item)
        // this.getDetail(item.id)
        this.setState({
            modalStatus:true,
            orderInfo:item
        })
    } */
    checkItem(item) {
        // console.log(item, 'item', this)
        let data = item
        data.orderStatus = this.props.location.pathname.split('/')[3]
        this.getDetail(item.id)
        this.setState({
            orderInfo: data,
        })
        // this.refs.basicDrawer.openDrawer()
    }
      // 删除
    deleteFn(record){
        API.repairDel(record.id).then( res => {
          //   console.log(res,'删除 ',record.id)
            if(res.code == 200 ){
              message.success('删除成功');
              this.getList(this.state.objSearch)
            }else{
              message.error('删除失败');  
            }
        })
    }
    // 取消
    cancelFn(data){
        // console.log(this.state.searchObj)
        // console.log(data.state)
        // this.getList({state:data.state})
        API.cancelRepair(data.id).then(res=>{
            // 删除之后重新获取数据
            if(res.code == 200){
                message.success('订单取消成功');         
                this.setState({
                    searchObj:data.state
                })
                this.props.history.push('/app/maintain/cancel');
            }else{
                message.error('订单取消失败');
            }
        })
    }
    // 确认完成
    sureComplete(date){
        // console.log(date.repairOrderCode)
        this.setState({
            sureComplete: true,
            code:date.repairOrderCode
        });
        // console.log(11111)
    }
    handleOk = e => {
        // console.log(e);
        // console.log(this.state.code)
        API.putRepair(this.state.code).then(res=>this.getList({orderStateItems:this.state.searchObj.orderStateItems}))
        this.setState({
            sureComplete: false,
        });
    };
    
    handleCancel = e => {
        // console.log(e);
        this.setState({
            sureComplete: false,
        });
    };
    closeModal() {
        this.setState({
            modalStatus: false
        })
    }
    closeSure() {}

    
    openDrawer(data) {
        this.setState({
            drawerStatus: true
        })
    }
    changePage(page) {
        // console.log(page,typeof(page))
        // console.log(this.state.searchObj,'objs')
        this.setState({
            pageNum:page
        })
        this.getList({pageNum:page,orderStateItems:this.state.searchObj.orderStateItems})
    }
    getDetail(data) {
        API.repairDetail(data).then(res =>{
            if(res.code == 200){
                // console.log(res,'order detail')
                // console.log(res.data.repairOrderDetail.snapshot,typeof res.data.repairOrderDetail.snapshot)
                // console.log(JSON.parse(res.data.repairOrderDetail.snapshot),typeof JSON.parse(res.data.repairOrderDetail.snapshot))
                let arr = JSON.parse(res.data.repairOrderDetail.snapshot)
                let spareParts = res.data.spareParts
                if(arr==null){

                }else {
                    arr.forEach((item,index)=>{
                        // console.log(21312313)
                        // console.log(item,index)
                        item.spareParts.sparePartNum = item.sparePartNum
                        spareParts.push(item.spareParts)
                    })
                }
                // console.log(JSON.parse(res.data.repairOrderDetail.snapshot.join()),typeof(JSON.parse(res.data.repairOrderDetail.snapshot.join())))
                // console.log(spareParts)
                if(res.data.repairOrderDetail.otherService != null || res.data.repairOrderDetail.otherServicePrice != null ){
                    res.data.spareParts.push({name:res.data.repairOrderDetail.otherService,price:res.data.repairOrderDetail.otherServicePrice,sparePartNum:'1'})
                }
                // console.log(res.data.spareParts)
                let obj = this.state.orderInfo
                obj.parts = res.data.spareParts
                obj.columns=[{ title: '零件名称', dataIndex: 'name', key: 'name', id: 1 },
                { title: '数量', dataIndex: 'sparePartNum', key: 'sparePartNum', id: 2 },
                { title: '单价', dataIndex: 'price', key: 'price', id: 3 }]
                this.setState({
                    modalStatus:true,
                    currentOrder:obj,
                    orderInfo:obj,
                    // sfgb:JSON.parse(this.state.orderInfo.cyInfo).sfgb
                })
                console.log(this.state.orderInfo )
                console.log(typeof this.state.orderInfo.cyInfo )
                // console.log(JSON.parse(this.state.orderInfo.cyInfo).sfgb )
                if(this.state.orderInfo.cyInfo != null){
                    this.setState({
                        sfgb:JSON.parse(this.state.orderInfo.cyInfo).sfgb
                    })
                }
            }
            console.log(this.state.orderInfo.cyInfo,'为什么')
            // console.log(JSON.parse(this.state.orderInfo.cyInfo).sfgb,'为什么')
        })
    }
    onClose() {
        this.setState({
            drawerStatus: false
        })
    }
    getEngineerList(data) {
        API.engineerList(data).then(res => {
            if (res.code == 200) {
                // console.log(res, 'engineer data')
                this.setState({
                    engineerData: res.data.result
                })
            }
        })
    }
    
    getPerson(data) {
        // console.log(data,'哈哈')
        // console.log(this.state.cyInfo,this.state.remark,'数据')
        let obj = {}
        obj.engineerId = data.id
        obj.cyInfo	 = JSON.stringify(this.state.cyInfo)
        obj.mealId	 =''
        obj.reserveTime	 =''
        obj.remark	 = this.state.remark
        // this.changeRepaire(obj)
        obj.orderId = this.state.currentOrder.id
        obj.userId= this.state.currentOrder.userId
        API.repairAssign(obj).then(res =>{
            if(res.code == 200){
                this.setState({
                    drawerStatus: false,
                    modalStatus:false,
                    assignPerson: data.name
                })
                this.getList({state:'CHECK_IN',pageNum:this.state.pageNum,pageSize:this.state.pageSize})
            }
        })
    
    }
    onHandleChild=(value)=>{
        // console.log(value,'value哈哈哈哈')
        this.state.cyInfo = value
        // console.log( this.state.cyInfo)
        //  this.setState({
        //     cyInfo: value
        //  })
    }
    onhandleText=(value)=>{
        // console.log(value,'备注值hhhhhhhhhh')
        this.state.remark = value
        // console.log( this.state.remark)
        //  this.setState({
        //     remark:value
        //  })
    }
    searchHandler(data) {
        // console.log(data["姓名"])
        let obj={}
        obj.orderStateItems = this.state.searchObj.orderStateItems
        obj.pageNum = 1
        obj.pageSize = 10
        obj.name = data["姓名"]? data["姓名"]:''
        obj.phone= data["联系方式"] ? data["联系方式"]:''
        // console.log(obj.code != null,'code')
        // console.log(obj,"++++++++++++")
        if(obj.phone || obj.name){
            this.getList(obj)
        }else{
            message.warning('请输入姓名或联系方式！')
        }
        // this.getList(obj)
    
    }
    refreshHandler(){
        this.getList(`pageNum=${this.state.pageNum}`)
        message.info('刷新成功')
    }
    changeRepaire(data){
        API.repairUpdate(data).then(res=>{
            // console.log(res)
        })
    }
    changeState(value) {
        // console.log("子组件传的值",value)
        /* this.setState({
            childData:value
        }) */
        this.setState({
            childData:value
        },() => {
            // childData:value
        })
        /* let obj = {}
        obj.state ='REPAIR_SUCCESS'
        obj.id = this.state.orderInfo.id */
        /* API.repairUpdate(obj).then(res=>{
            if(res.code == 200){
                this.setState({
                    modalStatus:false
                })
            }
        }) */
    }
    getList(data) {
        API.repairList(data).then(res => {
            console.log(res.data)
            if (res.code == 200) {
                if(res.data.result.length == 0){
                    message.warning('无相关数据……')
                }
                if(res.data && res.data.result){
                    let list = res.data.result
                    list.forEach(item => {
                        item.createdTime = Tool.timeChecker(item.createdTime)
                    //     if(item.state == 'ADMINCANCEL'){
                    //         item.status = '后台取消'
                    //     }
                    //    if (item.state == 'CANCEL'){
                    //         item.status = '已取消'
                    //     }
                        // item.repairTime = Tool.timeChecker(item.repairTime)
                        switch(item.state){
                            case 'CHECK_IN':item.status='待接单';  break;
                            case 'ACCEPT_AND_HEAR_A_CASE':item.status='维修中';  break;
                            case 'TO_BE_CONFIRMED':item.status='待确认';  break;
                            case 'CONFIRMED':item.status='已确认';  break;
                            case 'PAY_SUCCESS':item.status='已支付';  break;
                            case 'REPAIR_SUCCESS':item.status='已完成';  break;
                            case 'CANCEL':item.status='已取消';  break; 
                            case 'ADMINCANCEL':item.status='后台取消';  
                            break;
                        }
                    })
                    this.setState({
                        data: res.data.result,
                        total: res.data.totalCount
                    })
                    // console.log(this.state.total,'数量')
                }
            
            }
        })
    }

    render() {
        const  uploadConfig={
            action:'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            listType:'picture',
            defaultFileList: this.props.list.getFileList,
            accept:'image/*',
            multiple:true,
            // data:'photo.jpg',
            type:'image'
        }
        return (
            <div>
                <SearchHeader ifAdd="true" title="维修订单管理" firstKey="姓名" secondKey="联系方式" searchHandler={this.searchHandler.bind(this)} history ={this.props.history} headerType='refresh-header' refreshHandler={this.refreshHandler.bind(this)}></SearchHeader>
                <BasicTable data={this.state.data} total={this.state.total} columns={this.state.columns} changePage={this.changePage.bind(this)} row={this.state.rowSelections} rowKey='id'></BasicTable>
                <BasicDrawer data={this.state.engineerData} visible={this.state.drawerStatus} onClose={this.onClose.bind(this)} ref="basicDrawer" getPerson={this.getPerson.bind(this)} ></BasicDrawer>
                <Modal
                    visible={this.state.sureComplete}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>是否确认完成？</p>
                </Modal>
                <BasicModal 
                onHandleChild={this.onHandleChild}
                onhandleText={this.onhandleText}
                orderType='maintain' 
                orderInfo={this.state.orderInfo}
                sfgb={this.state.sfgb}
                visible={this.state.modalStatus} 
                onClose={this.closeModal.bind(this)}
                onOk={this.closeSure.bind(this)} 
                title="订单详情" 
                assign={this.openDrawer.bind(this)} assignPerson={this.state.assignPerson == '' ? '未指派人员' : this.state.assignPerson} type={this.state.modalType} changeState={this.changeState.bind(this)}></BasicModal>
                {/* <BasicUpload {...uploadConfig}></BasicUpload> */}
            </div>
        )
    }
}
function mapGetter(state) {
    return {
        list: state.list
    }
}
function mapActions(dispatch) {
    return {
        getOrderList: bindActionCreators(actions.getOrders, dispatch),
        getList: bindActionCreators(actions.getlist, dispatch)
    }
}
export default connect(mapGetter, mapActions)(Maintain)