import React,{Component} from 'react'
import {connect} from 'react-redux'
import BasicTable from '../../components/Table'
import BasicModal from '../../components/modal_order'
import API from '@/fetch/api'
import axios from 'axios';
import {Input,Button,message} from 'antd'
const { Search } = Input

class Satisfaction extends Component {
    // constructor(props) {
    //     super(props)
    // }
    state={
        remark:'1111',
        cyInfo:'222',
        pageSize:10,
        sfgb:false,
        data:[],
        columns:[
            { title: '地区', dataIndex: 'dossierAttribution', key: 'dossierAttribution'},
            // { title: '地区', dataIndex: 'addressDetail', key: 'addressDetail'},
            { title: '用户', dataIndex: 'name', key: 'name' },
            { title: '手机号', dataIndex: 'userPhone', key: 'userPhone' },
            // { title: '满意度', dataIndex: 'orderEvaluateItems', key: 'orderEvaluateItems'},
            { title: '客服人员态度', dataIndex: 'supportScore', key: 'supportScore'},
            { title: '上门服务人员态度', dataIndex: 'engineerScore', key: 'engineerScore'},
            { title: '问题解决满意否', dataIndex: 'orderScore', key: 'orderScore'},
            { title: '评价', dataIndex: 'evaluate', key: 'evaluate' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                        <span>
                            <Button onClick={() => this.checkItem(record)}>查看</Button>
                            <Button type='danger'style={{margin:'0 10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                        </span>
                    </div>
                )
            },
        ],
        rows:{
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // console.log(selected, selectedRows, changeRows);
            },
        },
        orderInfo: {},
        params:{},
        totalCount:0,
        orderStateItems:'PAY_SUCCESS',
        pageNum:1,
        pageSize:10
    }
    checkItem(item) {
        // console.log(item, 'item', this)
        let data = item
        data.orderStatus = this.props.location.pathname.split('/')[3]
        this.getDetail(item.id)
        this.setState({
            orderInfo: data,
        })
        // this.refs.basicDrawer.openDrawer()
    }
    deleteFn(record){
        API.cancelSatisfaction(record.id).then( res => {
          //   console.log(res,'删除 ',record.id)
            if(res.code == 200 ){
              message.success('删除成功');
              this.SatisfactionQuery()
            }else{
              message.error('删除失败');  
            }
        })
    }
    // excel模板下载
    excelDownload(){
        // window.location.href = `http:192.168.1.163:443/excel/spareParts.xlsx`  
        // const href = `http://192.168.1.172:443/admin/repair/exportSatisfaction`
        // const href = `https://www.huikeji.shop/admin/repair/exportSatisfaction`
        // const href = `https://www.huikeji.shop:8045/admin/repair/exportSatisfaction`
        const href = `https://www.huikeji.shop/admin/repair/exportSatisfaction`
        // const href = `https://www.huikeji.shop/admin/spareParts/getExcelDemo`
              axios.get(href,{
                headers: {
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
                },
                responseType:'blob'             //转化后台传过来的文件流。很重要！
              },
              )
                .then(res=>{
                console.log(res,'myidu')
                const url = window.URL.createObjectURL(new Blob([res.data],
                // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                const link = document.createElement('a');
                link.href = url;
                // 从header中获取服务端命名的文件名
                const fileName = '满意度.xlsx';
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
        })
    }
    getDetail(data) {
        API.repairDetail(data).then(res =>{
            if(res.code == 200){
                // console.log(res,'order detail')
                // console.log(res.data.repairOrderDetail.snapshot,typeof res.data.repairOrderDetail.snapshot)
                // console.log(JSON.parse(res.data.repairOrderDetail.snapshot),typeof JSON.parse(res.data.repairOrderDetail.snapshot))
                let arr = JSON.parse(res.data.repairOrderDetail.snapshot)
                let spareParts = res.data.spareParts
                if(arr==null){

                }else {
                    arr.forEach((item,index)=>{
                        // console.log(21312313)
                        // console.log(item,index)
                        item.spareParts.sparePartNum = item.sparePartNum
                        spareParts.push(item.spareParts)
                    })
                }
                // console.log(JSON.parse(res.data.repairOrderDetail.snapshot.join()),typeof(JSON.parse(res.data.repairOrderDetail.snapshot.join())))
                // console.log(spareParts)
                if(res.data.repairOrderDetail.otherService != null || res.data.repairOrderDetail.otherServicePrice != null ){
                    res.data.spareParts.push({name:res.data.repairOrderDetail.otherService,price:res.data.repairOrderDetail.otherServicePrice,sparePartNum:'1'})
                }
                // console.log(res.data.spareParts)
                let obj = this.state.orderInfo
                obj.parts = res.data.spareParts
                obj.columns=[{ title: '零件名称', dataIndex: 'name', key: 'name', id: 1 },
                { title: '数量', dataIndex: 'sparePartNum', key: 'sparePartNum', id: 2 },
                { title: '单价', dataIndex: 'price', key: 'price', id: 3 }]
                this.setState({
                    modalStatus:true,
                    currentOrder:obj,
                    orderInfo:obj,
                    // sfgb:JSON.parse(this.state.orderInfo.cyInfo).sfgb
                })
                // console.log(this.state.orderInfo )
                // console.log(typeof this.state.orderInfo.cyInfo )
                // console.log(JSON.parse(this.state.orderInfo.cyInfo).sfgb )
                if(this.state.orderInfo.cyInfo != null){
                    this.setState({
                        sfgb:JSON.parse(this.state.orderInfo.cyInfo).sfgb
                    })
                }
            }
            // console.log(this.state.orderInfo.cyInfo,'为什么')
            // console.log(JSON.parse(this.state.orderInfo.cyInfo).sfgb,'为什么')
        })
    }
    onHandleChild=(value)=>{
        // console.log(value,'value哈哈哈哈')
        this.state.cyInfo = value
        // console.log( this.state.cyInfo)
        //  this.setState({
        //     cyInfo: value
        //  })
    }
    onhandleText=(value)=>{
        // console.log(value,'备注值hhhhhhhhhh')
        this.state.remark = value
        // console.log( this.state.remark)
        //  this.setState({
        //     remark:value
        //  })
    }
    openDrawer(data) {
        this.setState({
            drawerStatus: true
        })
    }
    changeState(value) {
        // console.log("子组件传的值",value)
        /* this.setState({
            childData:value
        }) */
        this.setState({
            childData:value
        },() => {
            // childData:value
        })
        /* let obj = {}
        obj.state ='REPAIR_SUCCESS'
        obj.id = this.state.orderInfo.id */
        /* API.repairUpdate(obj).then(res=>{
            if(res.code == 200){
                this.setState({
                    modalStatus:false
                })
            }
        }) */
    }
    closeModal() {
        this.setState({
            modalStatus: false
        })
    }
    closeSure() {
        this.setState({
            modalStatus: false
        })
    }
    // componentWillMount() {
    //     console.log('enter?',this.props)
    // }
    // 用户的满意度查询
    SatisfactionQuery(page){
        let {params}=this.state
        params.orderStateItems=this.state.orderStateItems
        params.hideEvaluation=true
        params.pageNum=page||this.state.pageNum
        params.pageSize=this.state.pageSize
        API.SatisfactionQuery(params).then(res =>{
            console.log(res.data,"满意度列表")
            res.data.result.forEach((item,index)=>{
                item.key=index
                if(item.orderEvaluateItems==='VERY_GOOD'){
                    item.orderEvaluateItems='很好'
                }else if(item.orderEvaluateItems==='GOOD'){
                    item.orderEvaluateItems='好'
                }else if(item.orderEvaluateItems==='NORMAL'){
                    item.orderEvaluateItems='一般'
                }else{
                    item.orderEvaluateItems='差'
                }
            })
            this.setState({
                data:res.data.result,
                totalCount:res.data.totalCount
            })
        })
    }
    componentDidMount() {
        this.SatisfactionQuery()
    }
    // 分页
    changePage(page) {
        // this.setState({
        //     pageNum:page
        // })
        this.SatisfactionQuery(page)
    }
    onSearch(value){
        // console.log(value)
    }
    onSearchPhone(value){
        console.log(value)
        console.log(this.state.orderStateItems)
        let obj = {}
        obj.phone = value
        obj.orderStateItems = this.state.orderStateItems
        obj.pageNum = this.state.pageNum
        obj.pageSize = this.state.pageSize
        API.SatisfactionQuery(obj).then(res =>{
            console.log(res.data,'322')
            res.data.result.forEach((item,index)=>{
                item.key=index
                if(item.orderEvaluateItems==='VERY_GOOD'){
                    item.orderEvaluateItems='很好'
                }else if(item.orderEvaluateItems==='GOOD'){
                    item.orderEvaluateItems='好'
                }else if(item.orderEvaluateItems==='NORMAL'){
                    item.orderEvaluateItems='一般'
                }else{
                    item.orderEvaluateItems='差'
                }
            })
            this.setState({
                data:res.data.result,
                totalCount:res.data.totalCount
            })
        })
    }
    render() {
        return (
            <div >
                <div className="flex-row" style={{ margin:"20px" }}> 
                    <p>手机号：</p>
                    <Search placeholder="请输入手机号" allowClear onSearch={this.onSearchPhone.bind(this)} style={{ width: 200}}/>
                    <div><Button style={{marginLeft:"20px"}} onClick={this.excelDownload}>导出</Button></div>
                </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.rows} total={this.state.totalCount} changePage={this.changePage.bind(this)}></BasicTable>
                <BasicModal 
                onHandleChild={this.onHandleChild}
                onhandleText={this.onhandleText}
                orderType='maintain' 
                orderInfo={this.state.orderInfo}
                sfgb={this.state.sfgb}
                visible={this.state.modalStatus} 
                onClose={this.closeModal.bind(this)}
                onOk={this.closeSure.bind(this)} 
                title="订单详情" 
                assign={this.openDrawer.bind(this)} assignPerson={this.state.assignPerson == '' ? '未指派人员' : this.state.assignPerson} type={this.state.modalType} changeState={this.changeState.bind(this)}></BasicModal>
            </div>
        )
    }
}
export default connect()(Satisfaction); 