import React, { Component } from 'react'
import { Upload, Button, message, Icon } from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import actions from '@/store/actions'
class BasicUpload extends Component {
    constructor(props) {
        super(props)
    }
    state ={
        config: {
            listType: this.props.listType,
            accept:this.props.accept,
            action:this.props.action,
            method:this.props.method,
            fileList: this.props.fileList,
            data:this.props.data,
            headers:this.props.headers,
            multiple:this.props.multiple,
            onChange:(data)=>{
                // console.log(data.file.thumbUrl) 
                // console.log(this.props.list.getFileList)
                    if(!data.file.status){
                        // console.log('删除图片')
                        // console.log('enter cancel')
                        data.fileList.forEach((item,index) =>{
                            // console.log('enter loop')
                            // console.log('enter condition',item.uid,data.file.uid)
                            if(item.uid === data.file.uid){
                                // console.log('enter condition--',item.uid,data.file.uid,index)
                                data.fileList.splice(index,1)
                            }
                        })
                    }
            
                if(data.file.status === 'done'){
                    // console.log('upload complete',this.props)
                    // console.log('upload done')
                    data.file.response.uid = data.file.uid
                    // this.props.addFile(data.file.response)
                    // console.log(data.fileList,'this')
                    this.props.getUrlList(data.fileList)
                }
                this.props.getUrlList(data.fileList)
            },
            onRemove:(data)=>{
                // console.log('remove', data)
                // this.props.removeFile(data.uid);
            },
            beforeUpload:(data)=>{
                // console.log(!data.type.includes(this.props.type),data.type,this.props.type)
                if(!data.type.includes(this.props.type)){
                    message.info('格式不正确，已取消上传！');
                    // console.log(data,'data data data')
                    return false
                }
            }

        }
    }
    componentWillMount() {
        // console.log(this.props)
        // console.log(this.props.list.getFileList,'_+_+_+_+')
        
    }
    componentWillReceiveProps() {
        // console.log(this.props.list.getFileList,'?????--',this.props)
        let obj = this.state.config
        // console.log("========")
        // console.log(this.props.componentType)
        obj.data = this.props.data
        this.setState({
            config:obj
        })
    }
    render() {
        const obj = { ...this.state.config, fileList: this.props.fileList }
        const  type = this.props.type=='community'||'realtime'
        return (
            // <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:"100%",margin:20}}>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:"100%",}}>
                <Upload {...obj}>
                    <Button>
                        <Icon type="upload" /> {this.props.title?this.props.title:'上传'}
                    </Button>
                    {type?'': <span> 320px*310px,点击添加商品详情页轮播图 </span>}
                    {this.props.componentType=='acivity'?<span> 375px*375px </span>: ''}
                    {this.props.componentType=='community'?<span> 328px*328px </span>: ''}
                    {this.props.componentType=='realtime'?<span> 296px*153px </span>: ''}
                    {this.props.componentType=='ask'?<span> 375px*375px </span>: ''}
                    {this.props.componentType=='hotspot'?<span> 320px*150px </span>: ''}
                    {this.props.componentType=='store'?<span> 350px*150px </span>: ''}
                    {this.props.componentType=='eject'?<span> 320px*400px </span>: ''}
                    
                </Upload>
            </div>
        )
    }
}
function mapGetter(state){
    return {
        list:state
    }
}
function mapAction(dispatch){
    return {
        addFile: bindActionCreators(actions.addFile,dispatch),
        removeFile:bindActionCreators(actions.removeFile,dispatch)
    }
}
export default connect(mapGetter,mapAction)(BasicUpload)