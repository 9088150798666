// 资讯
import React,{Component} from 'react'
import {connect} from 'react-redux'
import {Button,message,Modal, Form, Input} from 'antd'
import ImagesUpload from '@/components/image-upload-banner'
import BraftEditor from 'braft-editor'
import BasicTable from '../../components/Table'
import BasicModal from '../../components/modal_edit'
import api from '../../fetch/api'


class Realtime extends Component {
    state = {
       
        current:{},
        pageNum:'1',
        pageSize:'10',
        total:1,
        visible: false ,
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        data:[],
        columns:[
         { title: '资讯Id', dataIndex: 'id', key: 'id' },
         { title: '名称', dataIndex: 'name', key: 'name' },
         { title: '图片', dataIndex: 'img', key: 'img', 
         render: (text, record) => (
            <div>
               {   Array.isArray(text)?
            //    <span> {Array.isArray(text)}</span>
                   text.map((items,index)=>{
                    return(
                      <div key={index}
                       style={{width:"60px",heigth:"60px",margin:"5px",overflow:"hidden",display:"flex"}}>
                       {/* <span>{items}={index}</span> */}
                       {/* {index > 0&&{text[0]==text[1]}?<img src={items} style={{width:"100%"}}></img>:''} */}
                       <img src={items} style={{width:"100%"}}></img>
                      </div>
                    )
                })
                :<span>wutu</span> 
               }
            </div>
         )},
         {title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                { 
                  <span>
                    <Button   type='primary' onClick={this.showUpdateModal.bind(this,record)} style={{margin:'0 10px'}}>修改</Button>
                    <Button type='danger' onClick={this.deleteInformationContent.bind(this,record)}>删除</Button>
                  </span>
                }
             </div>
            )
         }
        ],
        
       
    }
    componentDidMount() {
        this.getInformationContentList({pageNum:1,pageSize:10})
    }
    componentWillMount(){
        this.getInformationContentList({pageNum:1,pageSize:10})
    }
    deleteInformationContent(record){
          api.deleteInformationContent(record.id).then( res => {
              console.log(res,'删除 ',record.id)
              if(res.code == 200 ){
                message.success('删除成功');
                this.getInformationContentList({pageNum:this.state.pageNum})
              }else{
                message.error('删除失败');  
              }
          })
    }
    changePage(page) {
        // console.log(page,'page')
        this.setState({
            pageNum:page
        })
        let temp = {pageNum:page,pageSize:this.state.pageSize}
        this.getInformationContentList(temp)
    }
    // modal框
    showModal = () => {
        this.setState({
            title:'新增资讯',
            visible: true,
        });
    };
    showUpdateModal = (record) => {
        console.log(record,'11',record.img,record.img == null,record.img == 'null')
        // console.log(record,'11',record.img.length)
        if(record.img == null) record.img = []
        if(record.img.length>0){
        // if(record.img != null&record.img.length>0){
            const imgArr = record.img.map((imgUrl,index)=>({
                uid: '-' +　index,
                name: imgUrl,
                status: 'done',
                url: imgUrl,
            }))
            // console.log(imgArr,'imgARR')
            record.fileList = imgArr
        }
        this.setState({
         current:record,
         title:'修改资讯',
         visible: true,
        });
    };
   
   
    onClose() {
        this.setState({
          visible:false,
          current:{}
        })
    }
    submit (data) { 
        console.log(data,'submit')
        let page ={pageNum:this.state.pageNum,pageSize:this.state.pageSize}
        if(this.state.current.id){
            data.id = this.state.current.id
            api.putInformationContent(data).then(res =>{
                if(res.code == 200) {
                    this.onClose()
                    this.getInformationContentList(page)
                }
            })
        } else {
            api.postInformationContent(data).then(res =>{
                if(res.code == 200) {
                    this.getInformationContentList(page)
                    this.setState({
                        visible:false,
                        current:{}
                    })
                }
            })
        }
    }
    getInformationContentList(data){
        api.getinformationContentPage(data).then( res => {
            let temp =[]
            res.data.content.map((item,index)=>{
                // console.log(item.img,item.img!='',item.img!=null)
                if(item.img!=''&&item.img!=null){
                    temp = item.img.split(',')
                    item.img = temp
                }
               
            })
            console.log(temp,'资讯',res)
            this.setState({
                data:res.data.content,
                total:res.data.totalElements
            })
        }).catch( err => {
            console.log(err,'err')
        }) 
        
    }
  

    render() {
        return (
            <div >
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 10 }}>
                    <div> <Button   type='primary' onClick={this.showModal} style={{margin: 30}}>新增</Button></div>
                </div>
               
                <BasicTable 
                    data={this.state.data} 
                    columns={this.state.columns} 
                    row={this.state.row} 
                    changePage={this.changePage.bind(this)}
                    total={this.state.total} 
                    rowKey='id'
                >
                </BasicTable>
                <BasicModal 
                 visible={this.state.visible} 
                 onClose={this.onClose.bind(this)} 
                 type='realtime'
                 title={this.state.title}
                 data={this.state.current} 
                 submit={this.submit.bind(this)}>
                </BasicModal>
            </div>
            
        )
    }
}
export default Form.create()(Realtime); 