import React,{Component} from 'react'
import {Button} from 'antd'
class OrderExpress extends Component {
    render() {
        return(
            <div>
                快递单模板
            </div>
        )
    }
}
export default OrderExpress;