import React, { Component } from 'react'
import { Modal,Table,message  } from 'antd'
import api from '@/fetch/api'
class PerModal extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        columns:  [
            { title: '用户Id', dataIndex: 'userId', key: 'userId' },
            { title: '微信名称', dataIndex: 'userName', key: 'userName' },
            { title: '手机号', dataIndex: 'phone', key: 'phone' },
            { title: '报名时间', dataIndex: 'createdTime', key: 'createdTime' },
            
          ],
          data:[],
        //   data:[
        //       {userId:'1',userName:'hahha',phone:'182121221222',createdTime:'2022-09-11 12:34:23'},
        //       {userId:'1',userName:'hahha',phone:'182121221222',createdTime:'2022-09-11 12:34:23'},
        //       {userId:'1',userName:'hahha',phone:'182121221222',createdTime:'2022-09-11 12:34:23'},
        //   ],
        
    }
   
    componentWillUpdate(newProps, newState){
        if(newProps.perData != newState.data){
          this.setState({
            data:newProps.perData
          })
        }
       
    }
    confirm() {
        console.log('1332')
        this.props.perSubmit()
    }
    close() {
        this.props.onClose()
        console.log(this)
    }
    // componentWillReceiveProps() {
        
    // }
    
   
    render() {
        return (
          <Modal 
          title={this.props.title}  
          visible={this.props.visible} 
          onCancel={this.props.onClose} 
          onOk={this.confirm.bind(this)} 
          okText="导出"
          width={800} 
         >
            <Table 
            columns={this.state.columns} 
            // dataSource={this.props.perData} 
            dataSource={this.state.data} 
            rowKey='id'
            pagination={false} 
            scroll={{
                y: 240,
              }}
             />
          </Modal>
        )
    }

}

export default PerModal;