import {combineReducers} from 'redux'
import counter from './modules/counter'
import list from './modules/list'
import userLevel from './modules/userLevel'
import admin from './modules/admin'
import order from './modules/order'
import orderDetail from './modules/order_detail'
import engineer from './modules/engineer'
export default combineReducers({
    ...counter,
    ...list,
    ...userLevel,
    ...admin,
    ...order,
    ...orderDetail,
    ...engineer
})