import React, { Component } from "react"
import { connect } from "react-redux"
import { Upload, Button, Icon, message } from "antd"
import BasicTable from "../../components/Table"
import SearchHeader from "@/components/search_header"
import Tools from "../../global/tool"
import API from "@/fetch/api"
class GoodsList extends Component {
	state = {
		data: [],
		pageNum:1,
		columns: [
			{ title: "编号", dataIndex: "id", key: "id", width: 100 },
			{ title: "名称", dataIndex: "name", key: "name" },
			{ title: "价格", dataIndex: "iprice", key: "iprice", width: 130 },
			{ title: "库存", dataIndex: "stock", key: "stock", width: 100 },
			{ title: "销量", dataIndex: "total", key: "total", width: 100 },
			{
				title: "添加时间",
				dataIndex: "startTime",
				key: "startTime",
				width: 100,
			},
			{
				title: "更新时间",
				dataIndex: "updatedTime",
				key: "updatedTime",
				width: 180,
			},
			{
				title: "操作",
				dataIndex: "action",
				key: "action",
				render: (text, record) => (
					<div style={{ minWidth: "210px" }}>
						<span>
							<Button onClick={() => this.checkGood(record)} type="primary">
								查看
							</Button>
							{/* <Button onClick={() => this.checkManager(record)}>编辑</Button> */}
							{record.off ? (
								<Button
									onClick={() => this.removeGood(record)}
									type="default"
									style={{ marginLeft: "10px" }}
								>
									{" "}
									上架
								</Button>
							) : (
								<Button
									onClick={() => this.removeGood(record)}
									type="danger"
									style={{ marginLeft: "10px" }}
								>
									{" "}
									下架
								</Button>
							)}
							<Button
								style={{ marginLeft: "10px" }}
								onClick={() => this.deleteGoods(record)}
								type="danger"
							>
								删除
							</Button>
						</span>
					</div>
				),
			},
		],
		row: {
			onChange: (selectedRowKeys, selectedRows) => {
				console.log(
					`selectedRowKeys: ${selectedRowKeys}`,
					"selectedRows: ",
					selectedRows
				)
			},
			onSelect: (record, selected, selectedRows) => {
				console.log(record, selected, selectedRows)
			},
			onSelectAll: (selected, selectedRows, changeRows) => {
				console.log(selected, selectedRows, changeRows)
			},
		},
		initColumn: [
			{
				title: "商品名称",
				dataIndex: "title",
				key: "title",
			},
			{
				title: "编号",
				dataIndex: "address",
				key: "address",
			},
			{
				title: "数量",
				dataIndex: "date",
				key: "date",
			},
			{
				title: "销量",
				dataIndex: "contact",
				key: "contact",
			},
		],

		attendanceInfoList: [
			{
				name: "张三",
				grade: "2017级",
				department: "前端部门",
			},
			{
				name: "李四",
				grade: "2017级",
				department: "程序部门",
			},
		],
		total: 18,
		searchObj: {
			name: "",
			page: 1,
			isDel: false,
		},
	}
	//  获取商品列表
	getList(data) {
		API.getGoods(data).then(res => {
			console.log("data", res)
			if (res.code == 200) {
				this.setState({
					total: res.data.totalElements,
					data: res.data.content,
				})
			}
		})
	}
	getExcel() {
		Tools.exportExcel(this.state.initColumn, this.state.data, "测试excel.xlsx")
	}
	loadExcel(event) {
		Tools.importExcel(event).then(res => {
			console.log(res)
		})
	}
	checkManager(data) {
		console.log(data, "check manager ")
	}
	// 点击跳转到查看商品页面
	checkGood(data) {
		this.props.history.push({
			pathname: "/app/goods/add/" + data.id + "?count=" + data.id,
		})
	}
	// 根据id删除商品
	deleteGoods(data) {
		console.log(data)
		API.delGoods(data.id).then(res => {
			console.log(res)
			if(res.code === 200 ) {
				message.success("操作成功！")
				this.getList(this.state.searchObj)
			}
		})
	}
	// 根据id修改商品
	removeGood(data) {
		API.removeGood(data.id).then(res => {
			if (res.code == 200) {
				message.success("操作成功！")
				this.getList(this.state.searchObj)
			}
		})
	}
	// 刷新即返回
	refreshHandler(){
		let obj = {}
		obj.page = this.state.pageNum
        this.getList(obj)
        message.info('刷新成功')
    }
	// 根据页码渲染数据
	changePage(page) {
		console.log(page)
		this.setState({
			pageNum : page
		})
		let obj = this.state.searchObj
		obj.page = page
		this.getList(obj)
	}
	// 查找商品
	searchHandler(data) {
		let obj = {}
		obj.id = data["商品编号"] || ""
		obj.name = data["商品名称"] || ""
		if (obj.id || obj.name) {
			this.getList(obj)
		} else {
			message.warning("请输入商品编号或商品名称！")
		}
	}
	// 点击跳转到添加商品页面
	addGoods() {
		this.props.history.push("/app/goods/add")
	}
	// 组件加载完成
	componentDidMount() {
		console.log("enter what happened")
		this.getList(this.state.searchObj)
		document.addEventListener("oncontextmenu", e => {
			console.log(e, "eeee")
			return false
		})
	}
	render() {
		return (
			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "row-reverse",
						borderBottom: "solid 1px #ccc",
					}}
				>
					<Button
						style={{ margin: "10px 30px 10px 0" }}
						onClick={() => this.addGoods()}
					>
						添加商品
					</Button>
				</div>
				<SearchHeader
					firstKey="商品编号"
					select
					secondKey="商品名称"
					headerType="normal-header"
					title="商品列表"
					refreshHandler={this.refreshHandler.bind(this)}
					searchHandler={this.searchHandler.bind(this)}
				></SearchHeader>
				<BasicTable
					data={this.state.data}
					columns={this.state.columns}
					row={this.state.row}
					total={this.state.total}
					changePage={this.changePage.bind(this)}
					rowKey="id"
				></BasicTable>
			</div>
		)
	}
}
export default connect()(GoodsList)
