import React, { Component } from 'react'
import { Button, Steps, Select, Cascader, Form, message } from 'antd'
import API from '@/fetch/api'
import GoodsForm from '@/components/form_add_goods'
import BasicUpload from '@/components/upload'
import 'braft-editor/dist/index.css'
class AddGoods extends Component {
    state = {
        step: 1,
        current: 0,
        goodsObj: {
        },
        // msg:{},
        categories: [],
        typeList:{},
        type:'请选择商品类型',
        goodsData: {},
        nextSign: true,
        typeList: [],

        // 富文本需要的变量
        content:'',


        uploadConfig: {
            action: 'https://up-z2.qiniup.com',
            listType: 'picture',
            accept: 'image/*',
            fileList:[],
            multiple: false,
            data: { token: '' },
            type: 'image',
            name: 'file',
            limit: 1,
        },
        images: '',
        detailImages: []

    }
    // 富文本编辑器
    async componentDidMount () {
        // 假设此处从服务端获取html格式的编辑器内容
        // const htmlContent = await fetchEditorContent()
        // 使用BraftEditor.createEditorState将html字符串转换为编辑器需要的editorStat
        this.setState({
            // editorState: BraftEditor.createEditorState(htmlContent)
        })
    }

    async getDetail(id){
        // console.log(id)
        // 如果是编辑页面在获取数据
        let detail = await API.getDetail(id)
        console.log("商品详情",detail)
        this.setState({
            goodsData:detail.data
        })
    }
    // 点击提交时执行
    submit(data) {
        // console.log(data, 'data')
        let id = this.props.query.count
        if (id) data.id = id
        const { goodsData } = this.state
        let obj = goodsData
        obj = { ...obj, ...data }
        console.log(obj, '合并obj')
        
        this.setState(
            { goodsData: obj },
            () => {
                // 在这里发请求，添加商品
                console.log('提交表单', this.state.goodsData)
                console.log('提交表单', this.state.goodsData.context)
                if (id) {
                    // 在这里调用修改的接口
                    API.deleteGood(this.state.goodsData).then(res => {
                        if(res.code === 200 ){
                            message.success("操作成功！")
                            obj.iconLoading = 2
                            this.setState({
                                goodsData:obj
                            })
                            this.props.history.push('/app/goods/list')
                        }
                        // console.log('res del',res)
                        
                    })
                    return
                }
                API.addGood(this.state.goodsData).then(res=>{
                    if(res.code === 200 ){
                        message.success("操作成功！")
                        obj.iconLoading = 2
                        this.setState({
                            goodsData:obj
                        })
                        this.props.history.push('/app/goods/list')
                    }
                })
            }
        );
    }
    getUrlList(data) {
        // console.log(data, 'url List')
        let obj = this.state.goodsData
        // if(data[0].response && data[0].response.hash){
        //     obj.image='qav5o8iet.bkt.clouddn.com/'+data[0].response.hash
        //    }
        this.setState({
            images: data,
            uploadConfig:{
                ...this.state.uploadConfig,
                fileList: data.fileList
            }
        })
        // console.log(this.state.goodsData, 'goodsData')
    }
    // 从商品添加列表获取到的数据
    renderStep = () => {
        const { goodsData } = this.state
        goodsData.iconLoading = 2
        console.log('render-------', this.state.goodsData)
        return <div>
            {/* 子组件GoodsForm */}
            <GoodsForm  msg={goodsData} submit={this.submit.bind(this)} ref='goods'></GoodsForm>
            
        </div>
    }
    // 获取后台返回的token值
    getQi() {
        API.QiAuth().then(res => {
            // console.log('get qi token')
            if (res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = { token: res.data }
                // let jb = this.state.uploadConfigExtra
                // jb.data = { token: res.data }
                this.setState({
                    uploadConfig: obj,
                    // uploadConfigExtra: jb
                })
            }
        })
    }

    componentWillMount() {
        this.getQi();
        // 获取该商品的id
        const id = this.props.query.count
        if (id) this.getDetail(id)
    }
    render() {
        return (
            <div className='add-goods-container'>
                <div className='add-goods-content'>
                    {/* msg={this.state.msg} */}
                    {this.renderStep()}
                    {/* {this.renderStep(this.state.msg)} */}
                </div>
            </div>
        )
    }
}
export default AddGoods