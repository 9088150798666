import counter from './modules/counter'
import list from './modules/list'
import admin from './modules/admin'
import userLevel from './modules/userLevel'
import order from './modules/order'
import engineer from './modules/engineer'
import hotspot from './modules/hotspot'
export default {
  ...counter,
  ...list,
  ...admin,
  ...userLevel,
  ...order,
  ...engineer,
  ...hotspot
}