import React, {Component} from 'react'
import { Button } from 'antd'

class NotFound extends Component {
    toHome(props){
        window.history.go(-1)
    }
    render() {
        const BgStyle={
            'height':'100%',
            'width':'100%'
        }
        const btnStyle={
            'position':'absolute',
            'top':'60%',
            'left':'47%',
        }
        return(
            <div style={{height:'100%',position:'relative'}}>
                    <img src="https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3748640012,975017494&fm=26&gp=0.jpg" alt="" style={BgStyle}/>
                    <Button style={btnStyle} ghost onClick={this.toHome}>跳往首页</Button>
            </div>
        )
    }
}
export default NotFound;