import React,{Component} from 'react'
import {Form,Input,Button,Select,message,Checkbox } from 'antd'
import API from '@/fetch/api'
import { connectAdvanced } from 'react-redux'
const renderOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.districtName}</Select.Option>)
class RawForm extends Component {
    constructor(props) {
        super(props)
        // console.log(props)
    }
    state={
        role:'',
        username:'',
        name:'',
        phone:'',
        districtId:'',
        password:'',
        addressList:[],
        editStatus:false,
        options :['Apple', 'Pear', 'Orange', 'Pea1r', 'Pea12r','Pe4a1r'],
        positionList:[
            {districtName:'超级管理员',id:'ADMINX'}, 
            {districtName:'管理员',id:'ADMIN'},
        ],
        formStyle:{
            display:'flex',
            flexWrap:'wrap',
            alignItems:'center',
            justifyContent:'space-between'
        }
    }
    submitForm=(e) => {
        // console.log(this,'this')
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if(values.category.length > 0){
            values.category = values.category.toString()
          }
         
            if (!err) {
                this.props.submit(values)
            }
        });
    }
    // 获取所有的城市名
    getFullList=()=>{
        API.districtFullList().then(res=>{
            // console.log(res.data)
            this.setState({
                addressList:res.data
            })
        })
    }
    //属性选择
    onChange = (checkedValues) => {
      console.log('checked = ', checkedValues);
    }
    // 初始化页面
    componentDidMount() {
        console.log(this.props.data.id)
        this.getFullList()
        // this.props.form.setFieldsValue(this.props.data)
        API.adminDetail(this.props.data.id).then(res=>{
            console.log(res.data)
            this.props.form.setFieldsValue(res.data)
        })
        API.getCategory().then(res => {
           if(res.data.length > 0){
             res.data.forEach((item,index)=>{
               item.label = item.khlb
               item.value = item.khlb
             })
           }
           this.setState({
            options:res.data
          })
        })
    }
    submit=()=> {
        this.submitForm()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form onSubmit={this.submitForm} >
                <div style={this.state.formStyle} className="edit-form-container">
                <Form.Item label="零件编号">
                    {123213124}
                </Form.Item>
                <Form.Item label="姓名" name='name'>
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message:'姓名'
                            }
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>
                <Form.Item label="账号" name='username'>
                    {getFieldDecorator('username', {
                        rules: [
                            {
                                required: true,
                                message:'请输入账号'
                            }
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>  
                <Form.Item label="密码" name='password'>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,message:'请输入密码'
                            }
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>
                <Form.Item label="联系方式" name='phone'>
                    {getFieldDecorator('phone', {
                        rules: [
                            {
                                required: true,
                                message:'请输入联系方式'
                            },{
                                pattern: /^1\d{10}$/, message: '请输入正确的手机号'
                            }
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>
                <Form.Item label="角色">
                    {getFieldDecorator('role', {
                        rules: [
                            {
                                required: true,
                                message:'请选择角色'
                            }
                        ]
                    })(
                    <Select placeholder='请选择角色' style={{ width: 200 }} showSearch>
                    {this.state.positionList.map(item => (
                        renderOptions(item)
                    )
                    )}
                </Select>)}
                </Form.Item>
                <Form.Item label="地区">
                    {getFieldDecorator('districtId', {
                        rules: [
                            {
                                required: true,
                                message:'请选择地区'
                            }
                        ]
                    })(
                    <Select placeholder='请选择地区' optionFilterProp="children" style={{ width: 200 }} showSearch>
                        {this.state.addressList.map(item => (
                            renderOptions(item)
                        ))}
                    </Select>
                )}
                </Form.Item>
                <Form.Item label="属性" style={{width:'90%'}}>
                    {getFieldDecorator('category', {
                        rules: [
                            {
                                required: true,
                                message:'请选择属性'
                            }
                        ]
                    })(
                      <Checkbox.Group options={this.state.options}  onChange={this.onChange.bind(this)} />
                )}
                </Form.Item>
                </div>
                <Form.Item >
                    <Button htmlType='submit' type='primary' 
                    >提交</Button>
                </Form.Item>
            </Form>
        )
    }
}
const AdminForm =Form.create({name:'edit'})(RawForm)
export default AdminForm;