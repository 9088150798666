// 社区
import React,{Component } from 'react'
import {Button,message, Form,} from 'antd'
import BasicModal from '../../components/modal_edit'
import BasicTable from '../../components/Table'
import api from '../../fetch/api'

class Community extends Component {
    state = {
        fileList:'',
        content:'',
        name:'',
        img:'',
        issuerName:'',
        issuerImg:'',
        current:{},
        id:'',
        title:'',
        pageNum:'1',
        pageSize:'10',
        total:1,
        visible: false ,
        data:[],
        columns:[
        { title: '社区Id', dataIndex: 'id', key: 'id' },
        { title: '名称', dataIndex: 'name', key: 'name' },
        { title: '发布人名称', dataIndex: 'issuerName', key: 'issuerName' },
        { title: '点赞数', dataIndex: 'likeNum', key: 'likeNum' },
        { title: '收藏数', dataIndex: 'privateNum', key: 'privateNum' },
        { title: '发布人头像', dataIndex: 'issuerImg', key: 'issuerImg',render: (text, record) => (
            <div style={{width:"60px",heigth:"60px",margin:"5px",overflow:"hidden"}}>
              <img src={text} style={{width:"100%"}}></img>
            </div>
        )  },
        { title: '图片', dataIndex: 'img', key: 'img', render: (text, record) => (
            <div style={{display:'flex'}}>
               {   Array.isArray(text)?
                   text.map((items,index)=>{
                    // console.log(items,'text',typeof items,typeof items=='string' )
                    let bool=(typeof items=='string')
                    return(
                      <div key={index}
                       style={{width:"60px",heigth:"60px",margin:"5px",overflow:"hidden",display:"flex"}}>
                        <img src={bool?items:items.url} style={{width:"100%"}}></img>
                      </div>
                    )
                })
                :<span>loading...</span> 
               }
            </div>
        ) 
       },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                { 
                  <span>
                    <Button   type='primary' onClick={this.showUpdateModal.bind(this,record)} style={{margin:'0 10px'}}>修改</Button>
                    <Button type='danger' onClick={this.deleteCommunityContent.bind(this,record)}>删除</Button>
                  </span>
                }
             </div>
            )
        }],
    }
    componentDidMount() {
        this.getCommunityContentList()
    }
    componentWillMount(){
        this.getCommunityContentList()
    }
    deleteCommunityContent(record){
          api.deleteCommunityContent(record.id).then( res => {
            //   console.log(res,'删除 ')
              if(res.code == 200 ){
                message.success('删除成功');
                this.getCommunityContentList({pageNum:this.state.pageNum})
              }else{
                message.error('删除失败');  
              }
          })
    }
    
    changePage(page) {
        console.log(page,'page')
        this.setState({
            pageNum:page
        })
        let temp = {pageNum:page,pageSize:this.state.pageSize}
        this.getCommunityContentList(temp)
       
    }
    // modal框
    showModal = () => {
        console.log(this.state.name,this.state.form)
        this.setState({
            title:'新增社区',
            visible: true,
        });
    };
    showUpdateModal = (record) => {
        console.log(record,'11',record.img,record.img == null,record.img == 'null')
        // console.log(record,'11',record.img.length)
        if(record.img == null) record.img = []
        if(record.img.length>0){
        // if(record.img != null&record.img.length>0){
            const imgArr = record.img.map((imgUrl,index)=>({
                uid: '-' +　index,
                name: imgUrl,
                status: 'done',
                url: imgUrl,
            }))
            // console.log(imgArr,'imgARR')
            record.fileList = imgArr
            console.log(imgArr,'imgARR')
        }
        this.setState({
         current:record,
         title:'修改社区',
         visible: true,
        });
        // console.log(record,'11',this.state.current)
    };
    onClose() {
        this.setState({
          visible:false,
          current:{}
        })
    }
    submit (data) { 
        console.log(data,'submit')
        let page ={pageNum:this.state.pageNum,pageSize:this.state.pageSize}
        if(this.state.current.id){
            data.id = this.state.current.id
            api.putCommunityContent(data).then(res =>{
                if(res.code == 200) {
                    this.onClose()
                    this.getCommunityContentList(page)
                }
            })
        } else {
            api.postCommunityContent(data).then(res =>{
                if(res.code == 200) {
                    this.getCommunityContentList(page)
                    this.setState({
                        visible:false,
                        current:{}
                    })
                }
            })
        }
    }
   
    getCommunityContentList(data){
       api.getCommunityContentPage(data).then( res => {
        // api.getCommunityContentList(data).then( res => {
            console.log(res,'社区')
            let temp =[]
            res.data.content.map((item,index)=>{
            //     if(item.img.indexOf('"') != -1){
            //       item.img = item.img.substring(0,item.img.length-1)
            //     }
                if(item.img!=''&&item.img!=null){
                    // temp = item.img.split(';').slice(1)
                    temp = item.img.split(',')
                    item.img = temp
                    // index>0 ? item.img = temp.slice(1):item.img = temp
                }
            })
            // console.log(temp,'temp.length',temp.length,res)
            this.setState({
                data:res.data.content,
                // data:res,
                total:res.data.totalElements
            })
        }).catch( err => {
            console.log(err,'err')
        }) 
        console.log(this.state.total,'总条数') 
    }
    render() {
       return (
            <div >
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 10 }}>
                     <Button   type='primary' onClick={this.showModal} style={{margin: 30}}>新增</Button>
                     {/* <span>头像图片尺寸：34px*34px；其它图片：328px*328px</span> */}
                </div>
                <BasicTable 
                    data={this.state.data} 
                    columns={this.state.columns} 
                    row={this.state.row} 
                    changePage={this.changePage.bind(this)}
                    total={this.state.total} 
                    rowKey='id'
                >
                </BasicTable>
                <BasicModal 
                 visible={this.state.visible} 
                 onClose={this.onClose.bind(this)} 
                 type='community'
                 title={this.state.title}
                 data={this.state.current} 
                 submit={this.submit.bind(this)}>
                </BasicModal>
            </div>
            
        )
    }
}
export default Form.create()(Community); 