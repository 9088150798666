import React,{Component} from 'react'
import {message,Button, Form, Input,Select} from 'antd'
import BasicUpload from '@/components/upload'
import BraftEditor from 'braft-editor'
import API from '@/fetch/api'
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={
        popoutType:'',
        typeValue:'',
        typeInputValue:'',
        getpopoutList:[],
        informationContentList:[],
        name:'',
        id:'',
        img:'',
        visible: false ,
        form:{},
        formName:'文章1',
        relId:'',
        offlineActivityList:[],
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        formStyle:{
            display:'flex',
            flexDirection:'column',
            flexWrap:'wrap'
        },
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            fileList: this.props.data.fileList,
            accept:'image/*',
            multiple:true,
            data:{token:''},
            type:'image',
            name:'file',
        },
        
    }
    
    submitForm=(e) => {
        console.log(this.props.data,'this',this.state.formName)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(values.img == 'undefined',values.img == undefined)
            // values.img = this.state.img
            values.name = this.state.formName
            console.log(values,'submitForm',values.img)
            if(values.img == undefined){
              values.img = ''
            }
            if(this.state.img.length != 0) {
                values.img = this.state.img
            }else{
                values.img =  values.img.toString()
            }
            if(this.state.typeValue.length!=0) values.typeValue =  this.state.typeValue
            if (!err) {
                this.props.submit(values)
            }
        });
    }
    // 初始化页面
    componentDidMount() {
        console.log(this.props.data,this.state.popoutType)
        this.getQi()
        this.tagList()
        let data= this.props.data
        if(data.type == 'ARTICLE') {
          this.getArticleList()
        }else {
          this.getOfflineActivityList()
        }
        this.props.form.setFieldsValue(data)
        if(data.informationContent !=null) {
            this.setState({
                typeValue: data.informationContent.id,
            })   
        }
    }
    submit=()=> {
        this.submitForm()
    }
    //获取标签列表tagList
    tagList(){
      API.getEjectTypeList().then(res=>{
          this.setState({
              getpopoutList:res.data
          })
      })
    }
    // 获取文章列表
    getArticleList(data){
      API.getArticleList(data).then( res => {
        // console.log(res,'文章列表')
        let temp = [{name:'文章1',id:0},{name:'文章2',id:1}]
        this.setState({
            // offlineActivityList:res.data,
            offlineActivityList:temp,
        })
    })
    }
    // 获取线下活动列表
    getOfflineActivityList(data){
      API.getOfflineActivityPage(data).then( res => {
          let temp =[]
          res.data.content.map((item,index)=>{
              if(item.img!=''&&item.img!=null){
                  // temp = item.img.split(';').slice(1)
                  temp = item.img.split(',')
                  item.img = temp
              }
              
          })
          this.setState({
              offlineActivityList:res.data.content,
              total:res.data.totalElements
          })
      })
      
    }
    handleChangeType = (key, event) => {
        // console.log(key ,event)
        if(event == 'ARTICLE') {
          this.getArticleList()
        }else {
          this.getOfflineActivityList()
        }
    }
    handleChangeName= (key, event) => {
      console.log( this.state.offlineActivityList,this.state.formName,'offlineActivityList') 
     this.state.offlineActivityList.map((item) =>{
        if(item.id == event){
          this.setState({
            formName:item.name,
          })
        }
      
      })
       
  }
    onSearchSelect= (value) =>{
        this.state.typeValue = String(value)
        this.setState({
            typeValue:value,
            typeInputValue:value,
        })
        console.log('6',value,'this.state.typeValue',this.state.typeValue);
    }
    // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            // console.log(res)
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                this.setState({
                    uploadConfig:obj
                })
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data){
        let urlTemp =  this.state.img
        if(data.length > 0){
            urlTemp='' 
        }
        console.log(data,'data',urlTemp)
        if(data.length == 3){
           message.warning('最多上传四张')
        }
        let title = [];
        let saveImg = [];
        data.forEach((fileInfo, index) => {
            // if(index <= 0) {
            //     return focusImg = fileInfo;
            // }
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }else{
                return saveImg.push(fileInfo.url)
            }
            // console.log('data', fileInfo.url)
            // title.push(fileInfo.url);
        })
        // console.log('data', saveImg.concat(title))
        this.setState({
            img:saveImg.concat(title).toString(),
            // focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form  onSubmit={this.submitForm} style={this.state.formStyle} >
                {/* <Form.Item {...formItemLayout}  label="名称">
                    {getFieldDecorator('name', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<Input onChange={this.handleChange.bind(this,'name')} placeholder="请输入热点内容" />)}
                </Form.Item> */}
                <Form.Item {...formItemLayout}  label="弹窗类型" >
                    {getFieldDecorator('type', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        }
                        ],
                    })(
                        <Select placeholder='请选择弹窗类型 ' onChange={this.handleChangeType.bind(this,'type')}   showSearch>
                        {this.state.getpopoutList.map((item,index) =>{
                            return(
                              <Select.Option value={item.key} key={item.key}>{item.key=='ARTICLE'? '文章':'线下活动'}</Select.Option>)
                        } )}
                    </Select>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="弹窗名称" >
                    {getFieldDecorator('relId', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        }
                        ],
                    })(
                      <Select placeholder='请选择弹窗名称 ' onChange={this.handleChangeName.bind(this,'relId')}   showSearch>
                      {this.state.offlineActivityList.map((item,index) =>{
                          return(
                            <Select.Option  value={item.id} key={item.id}>{item.name || item.type}</Select.Option>)
                      } )}
                  </Select>)}
                </Form.Item>
                <Form.Item {...formItemLayout}  label="弹窗图片">
                    {getFieldDecorator('img', {
                        rules: [
                        {
                            required: false,
                            message: '内容不能为空',
                        },
                        ],
                    })(
                        <BasicUpload 
                        {...this.state.uploadConfig} 
                        componentType={this.props.componentType}
                        getUrlList={this.getUrlList.bind(this)}>
                        </BasicUpload>)
                    }
                </Form.Item> 
                <Form.Item style={{
                   width: 'calc(100% + 48px)',
                   marginLeft:'-24px',
                   marginBottom:'0',
                   paddingTop:'3px',
                   justifyContent: 'flex-end',
                   paddingRight: '24px',
                   borderTop:'1px solid #ebebeb'}}>
                    <Button onClick={this.props.onClose} style={{marginRight:'10px'}}>取消</Button>
                    <Button htmlType='submit' type='primary' >确定</Button>
                </Form.Item>
            </Form>
        )
    }
}
const EjectFrom =Form.create({name:'edit'})(RawForm)
export default EjectFrom;