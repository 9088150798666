
export default {
   menus: [
      // 菜单相关路由
      {
         key: '/app/home',
         title: '首页',
         icon: 'mobile',
         component: 'Home'
      },
      {
         key: '/app/custom',
         title: '客户查询',
         icon: 'mobile',
         component: 'Custom'
      },
      {
            key: '/app/find',
            title: '发现',
            icon: 'mobile', 
            subs: [
                  {
                        key: '/app/find/community',
                        title: '社区',
                        icon: 'mobile',
                        component: 'Community'
                  },
                  {
                        key: '/app/find/realtimeInfo',
                        title: '资讯',
                        icon: 'mobile',
                        component: 'Realtime'
                  },
                  {
                        key: '/app/find/hotspot',
                        title: '热点',
                        icon: 'mobile',
                        component: 'Hotspot'
                  },
                  {
                        key: '/app/find/ask',
                        title: '百问百答',
                        icon: 'mobile',
                        component: 'Ask'
                  },
                  {
                        key: '/app/find/tag',
                        title: '标签管理',
                        icon: 'mobile',
                        component: 'Tag'
                  },
                  {
                    key: '/app/find/eject',
                    title: '弹窗管理',
                    icon: 'mobile',
                    component: 'Eject'
              },
            ]
      },
     
      {
         key: '/app/repairman',
         title: '维修工管理',
         icon: 'mobile', 
         subs: [{
                  key: '/app/repairman/repairman',
                  title: "维修工列表",
                  icon: 'mobile',
                  component: 'Repairman'
            },
            {
                  key: '/app/repairman/position',
                  title: "职位列表",
                  icon: 'mobile',
                  component: 'PositionList'
            }]
      },
      {
         key: '/app/maintain',
         title: '报修管理',
         icon: 'mobile',
         subs: [{
               key: '/app/maintain/unsolved',
               title: "待接单",
               icon: 'mobile',
               component: 'Maintain'
            },
            {
               key: '/app/maintain/maintain',
               title: "维修中",
               icon: 'mobile',
               component: 'Maintain'
            },
            {
                  key: '/app/maintain/unconfirm',
                  title: "待确认",
                  icon: 'mobile',
                  component: 'Maintain'
            },
            {
                  key: '/app/maintain/confirmed',
                  title: "已确认",
                  icon: 'mobile',
                  component: 'Maintain'
               },
               {
                  key: '/app/maintain/sure',
                  title: "已支付",
                  icon: 'mobile',
                  component: 'Maintain'
               },
            {
               key: '/app/maintain/complete',
               title: "已完成",
               icon: 'mobile',
               component: 'Maintain'
            },
            {
               key: '/app/maintain/cancel',
               title: "已取消",
               icon: 'mobile',
               component: 'Maintain'
            },
         ]
      },
      {
         key: '/app/maintenance',
         title: '保养管理',
         icon: 'mobile',
         subs: [{
               key: '/app/maintenance/part',
               title: "零件库",
               icon: 'mobile',
               component: 'PartManage'
            },
            {
               key: '/app/maintenance/area',
               title: "面积套餐管理",
               icon: 'mobile',
               component: 'AreaManage'
            },
            {
               key: '/app/maintenance/strainer',
               title: "风量滤网价格",
               icon: 'mobile',
               component: 'StrainerManage'
            },
            {
               key: '/app/maintenance/index',
               title: "保养工单",
               icon: 'mobile',
               component: 'Maintenance'
            },
         ]
      },
      // {
      //       key: '/app/maintenance/part',
      //       title: "零件库",
      //       icon: 'mobile',
      //       component: 'PartManage'
      // },
      // {
      //       key: '/app/maintenance/area',
      //       title: "面积套餐管理",
      //       icon: 'mobile',
      //       component: 'AreaManage'
      // },
      // {
      //       key: '/app/maintenance/strainer',
      //       title: "风量滤网价格",
      //       icon: 'mobile',
      //       component: 'StrainerManage'
      // },
      {
         key: '/app/richText',
         title: '文章管理',
         icon: 'mobile',
         component: 'RichText'
      },
      
      
      
      {
         key: '/app/goods',
         title: '商品管理',
         icon: 'mobile',
         subs: [{
               key: '/app/goods/list',
               title: "商品列表",
               icon: 'mobile',
               component: 'GoodsList'
            },
            {
               key: '/app/goods/comments',
               title: "商品评价",
               icon: 'mobile',
               component: 'Comments'
            },
            {
               key: '/app/goods/sort',
               title: "商品分类",
               icon: 'mobile',
               component: 'Sorts'
            },
            {
               key: '/app/goods/recycle',
               title: "商品回收站",
               icon: 'mobile',
               component: 'GoodsRecycle'
            },

         ]
      },
      {
         key: '/app/active',
         title: '活动管理',
         icon: 'mobile',
         subs: [{
               key: '/app/active/seckill',
               title: "活动管理",
               icon: 'mobile',
               component: 'Seckill'
            },
            // {
            //       key: '/app/active/activityRegist',
            //       title: "活动报名",
            //       icon: 'mobile',
            //       component: 'ActivityRegist'
            //    },
            {
                  key: '/app/active/activity',
                  title: "线下活动",
                  icon: 'mobile',
                  component: 'Activity'
               },
      ]
      },
      {
         key: '/app/integral',
         title: '积分管理',
         icon: 'mobile',
         subs: [{
               key: '/app/integral/list',
               title: "积分查询",
               icon: 'mobile',
               component: 'Integral'
            },
            /* {
               key: '/app/integral/ruler',
               title: "积分规则",
               icon: 'mobile',
               component: 'IntegralRuler'
            }, */
         ]
      },
      {
         key: '/app/order',
         title: '订单管理',
         icon: 'mobile',
         subs: [{
               key: '/app/order/list',
               title: "订单列表",
               icon: 'mobile',
               component: 'OrderList'
            },
            // { 
            //     key: '/app/order/receipt',
            //     title:"确认收货",
            //     icon:'mobile',
            //     component:'OrderReceipt'
            // } ,
         
            // {
            //    key: '/app/order/cancel',
            //    title: "订单取消",
            //    icon: 'mobile',
            //    component: 'OrderCancel'
            // },
            // {
            //    key: '/app/order/refund',
            //    title: "退款",
            //    icon: 'mobile',
            //    component: 'Refund'
            // },
         ]
      },
      {
         key: '/app/manager',
         title: '系统管理',
         icon: 'mobile',
         subs: [{
            key: '/app/manager/user',
            title: "用户管理",
            icon: 'mobile',
            component: 'Manager'
         }, ]
      },
      
      // {
      //    key: '/app/chart',
      //    title: '图表管理',
      //    icon: 'mobile',
      //    component: 'ChartView'
      // },
      // {
      //    key: '/app/complaint',
      //    title: '投诉管理',
      //    icon: 'mobile',
      //    component: 'Complaint'
      // },
      {
         key: '/app/satisfaction',
         title: '满意度管理',
         icon: 'mobile',
         component: 'Satisfaction'
      },
      {
         key: '/app/objection',
         title: '意见反馈',
         icon: 'mobile',
         component: 'Objection'
      },
      {
         key: '/app/banner',
         title: '图片管理',
         icon: 'mobile',
         component: 'Banner'
      },
      {
         key: '/app/store',
         title: '门店管理',
         icon: 'mobile',
         component: 'Store'
      },
      {
         key: '/app/projectmanage',
         title: '项目管理',
         icon: 'mobile',
         component: 'Projectmanage'
      },

   ],
   others: [
      {
         key:'/app/active/addSeckill/:count',
         title:'活动详情',
         component:'AddSeckill'
      },
      {
         key:'/app/active/addSeckill',
         title:'新增',
         component:'AddSeckill'
      },
      {
         key: '/app/goods/add/:count',
         title: '详情',
         component: 'AddGoods'
      },
      {
         key: '/app/goods/add',
         title: '添加商品',
         component: 'AddGoods'
      },
      {
         key: '/app/goods/comments/detail/:count',
         title: '评论详情',
         component: 'CommentDetail'
      },
      {
         key: '/app/integral/detail/:count',
         title: '积分详情',
         component: 'IntegralDetail'
      },
      { 
         key: '/app/order/detail/:id',
         title:"订单详情",
         icon:'mobile',
         component:'OrderDetail'
   } ,
   ], 
};