const setToken =(data) =>({
    type:'set-token',
    data
})
const regist =(data,cb) =>(
    {
        type:'add-person',
        data
    }
)

export default {setToken,regist}