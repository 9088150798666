import fetch from '../fetch'
// 修改文章
const putArticle=(data)=>{
    return fetch('article','PUT',data)
}
// 新增文章
const articleUpload=(data)=>{
    return fetch('article','POST',data)
}
export default {
    putArticle,
    articleUpload
}