import React,{Component} from 'react'
import {Form,Input,Button,Select,Radio} from 'antd'
const renderOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
class RawForm extends Component {
    const 
    constructor(props) {
        super(props)
    }
    state={
        addressList:[
          {name:'上海',id:'1'}, 
          {name:'北京',id:'2'}, 
          {name:'广州',id:'3'}, 
        ],
        positionList:[
            {name:'超级管理员',id:'ADMINX'}, 
            {name:'管理员',id:'ADMIN'}, 
            {name:'客服',id:'SERVICER'}, 
          ],
          formStyle:{
            //   display:'flex',
            //   flexWrap:'wrap',
            //   alignItems:'center',
            //   justifyContent:'space-between'
          }
    }
    submitForm=(e) => {
        console.log(this,'this')
        e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.submit(values)
      }
     
    });
    }
    componentDidMount() {
        console.log('enter')
      this.initial()
    }
    initial() {
        let obj={
            name:'',
            state:'',
            phone:'',
            username:'',
            password:'',
        }
        // if (this.props.data.name) {
        //     let data = this.props.data
        //     console.log(data,'data')
        //    obj.name= data.name
        //    obj.state=data.state
        //    obj.phone = data.phone
        //    obj.password = data.password
        //    obj.username=data.username
        // } 
        // this.props.form.setFieldsValue(obj)
    }
    submit() {
        this.submitForm()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.submitForm} >
                <div style={this.state.formStyle} className="add-goods-form-container">
      
        <Form.Item label="分类名称">
            {getFieldDecorator('content', {
                rules: [
                    {
                        required: true,
                        message:'请输入分类'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
        {/* <Form.Item label="上级分类">
            {getFieldDecorator('role', {
            })(<Select placeholder='请选择上级分类' style={{ width: 200 }} showSearch>
            {this.state.positionList.map(item => (
                renderOptions(item)
            ))}
        </Select>)}
        </Form.Item>
        <Form.Item label="数量单位">
            {getFieldDecorator('username', {
                rules: [
                    {
                        required: false,
                        message:'请输入售价'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item> 
        <Form.Item label='是否显示'>
                <Radio.Group>
                <Radio value={1}>是</Radio>
        <Radio value={2}>否</Radio>
                </Radio.Group>
        </Form.Item>
        <Form.Item label='是否显示在导航栏'>
                <Radio.Group>
                <Radio value={1}>是</Radio>
        <Radio value={2}>否</Radio>
                </Radio.Group>
        </Form.Item>
        <Form.Item label="关键词">
            {getFieldDecorator('username', {
                rules: [
                    {
                        required: true,
                        message:'关键词'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
        <Form.Item label="分类描述">
            {getFieldDecorator('phone', {
                rules: [
                    {
                        required: true,
                        message:'请输入分类描述'
                    }
                ]
            })(<Input.TextArea width={300} style={{display:'inline-block',width:200}}></Input.TextArea>)}
        </Form.Item>
        <Form.Item label="库存">
            {getFieldDecorator('username', {
                rules: [
                    {
                        required: true,
                        message:'请输入库存'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item> <Form.Item label="单位">
            {getFieldDecorator('username', {
                rules: [
                    {
                        required: true,
                        message:'请输入单位'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
        */}
        </div>
        <Form.Item >
          <Button htmlType='submit' type='primary'>提交</Button>
        </Form.Item>
    </Form>
        )
    }
}
const ClassForm =Form.create({name:'edit'})(RawForm)
export default ClassForm;