import React,{Component} from 'react'
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Checkbox } from 'antd';
import API from '@/fetch/api'
const layout = {
    labelCol: {
        span: 4,
        },
        wrapperCol: {
        span: 16,
        },
    };
    const tailLayout = {
        wrapperCol: {
        offset: 4,
        span: 8,
        },
    };
    
    
    // 根据id获取商品的详细信息，渲染到页面上
class GoodsDetail extends Component {
    state={
        name:"",
        description:'',
        price:'',
        stock:'',
        count:'',
        coupons:[],
        nowCoupons:'',
        nowRecommend:''
    }
    // 获取优惠券列表
    getCoupon() {
        API.couponList().then(res =>{
            console.log(res)
            if(res.code == 200){
                console.log(JSON.parse(res.data))
                this.setState(
                    {
                        coupons:JSON.parse(res.data).ReturnData.BizObjectArray
                    }
                )
            }
        })
    }
    // 根据id获取商品的详细信息
    getDetail(){
        // 获取该商品的id
        const id = this.props.query.count
        console.log(id)
        API.getDetail(id).then(res=>{
            // 根据id获取商品详情
            console.log(res)
            console.log(res.data.name)
            this.setState({
                name:res.data.name,
                description:res.data.description,
                price:res.data.price,
                stock:res.data.stock
            })
        })
    }
    componentWillMount() {
        this.getDetail()
    }
    render() {
        return(
        <Form
            {...layout}
            name="basic"
            initialValues={{
                remember: true,
            }}
        >
                <Form.Item label="商品名称" >
                    <Input value={this.state.name}/>
                </Form.Item>

                <Form.Item label="介绍" >
                    <Input value={this.state.description}/>
                </Form.Item>
                
                <Form.Item label="商品售价" >
                    <Input value={this.state.price}/>
                </Form.Item>

                <Form.Item label="库存" >
                    <Input value={this.state.stock}/>
                </Form.Item>

                <Form.Item label="优惠券" >
                    <Input value={this.state.nowCoupons}/>
                </Form.Item>

                <Form.Item label="推荐度" >
                    <Input value={this.state.nowRecommend}/>
                </Form.Item>
                <Form.Item>
                    <Button style={{ marginLeft: 60 }} type="danger" className='add-goods-footer-btn' type='default' onClick={() => this.submit()}>提交</Button>
                </Form.Item>
                
            </Form>
        )
    }
}
export default withRouter(GoodsDetail);