import React,{Component} from 'react'
import {connect} from 'react-redux'
import {Button,message,Modal, Form, Input} from 'antd'
import BasicTable from '../../components/Table'
import api from '../../fetch/api'

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};
class PositionList extends Component {
    state = {
        content:'',
        name:'',
        id:'',
        form:{
            content:'',
            name:'',
            id:''
        },
        updateForm:{
            updatecontent:'',
            updateName:'',
            id:''
        },
        pageNum:'1',
        pageSize:'10',
        total:1,
        visible: false ,
        updateVisible: false ,
        data:[],
        columns:[
        { title: '职位Id', dataIndex: 'id', key: 'id' },
        { title: '职位名称', dataIndex: 'name', key: 'name' },
        { title: '职位描述', dataIndex: 'content', key: 'content' },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                { 
                  <span>
                    <Button   type='primary' onClick={this.showUpdateModal.bind(this,record)} style={{margin:'0 10px'}}>修改</Button>
                    <Button type='danger' onClick={this.deletePosition.bind(this,record)}>删除</Button>
                  </span>
                }
             </div>
            )
        }],
        row:{
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },

        },
    }
    componentWillMount(){
        this.getPositionList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
    }
    deletePosition(record){
          api.deletePosition(record.id).then( res => {
            //   console.log(res,'删除 ')
              if(res.code == 200 ){
                message.success('删除成功');
                this.getPositionList({pageNum:this.state.pageNum})
              }else{
                message.error('删除失败');  
              }
          })
    }
    changePage(page) {
        console.log(page,'page')
        this.setState({
            pageNum:page
        })
        this.getPositionList({pageNum:page,pageSize:this.state.pageSize})
    }
    // modal框
    showModal = () => {
    this.setState({
        visible: true,
    });
    };
    showUpdateModal = (record) => {
    //   console.log(this.state.form,'11')
        this.setState({
        id:record.id,
        name:record.name,
        content:record.content,
        updateVisible: true,
        });
        this.props.form.setFieldsValue({ updatecontent: record.content })
    };
    handleOk = e => {
        console.log(this.state.form,'新增')
        if(JSON.stringify(this.state.form) != "{}"){
            api.addPosition(this.state.form).then( res => {
                console.log(res,'res')
                if(res.code == 200){
                    message.success('新增成功')
                    this.getPositionList({pageNum:this.state.pageNum})
                }else{
                    message.errpor('新增失败')
                }
            })
        }
        this.setState({
          visible: false,
        });
      };
    
    handleCancel = e => {
        // console.log(e);
        this.setState({
          visible: false,
        });
      };
    handleUpdateOk = (e) => {
        this.state.form.id = this.state.id
        this.state.form.content = this.state.form.updatecontent
        this.state.form.name = this.state.form.updateName
        console.log(this.state.form,'修改')
        // if(JSON.stringify(this.state.form) != "{}"){
            api.updatePosition(this.state.form).then( res => {
                console.log(res,'res')
                if(res.code == 200){
                    message.success('修改成功')
                    this.getPositionList({pageNum:this.state.pageNum})
                }else{
                    message.errpor('修改失败')
                }
            })
            this.setState({
                updateVisible: false,
            });
      };
    
    handleUpdateCancel = e => {
        this.setState({
            updateVisible: false,
        });
      };
    handleChange = (key, event) => {
        // console.log(key,event.target.value,this.state.form)
        let form = this.state.form
        for (let item in this.state.form) {
            if (item === key) {
                form[item] = event.target.value
                this.setState({form: form})
          }
      }
    }
    handleUpdateChange = (key, event) => {
        // console.log(key,event.target.value,this.state.updateForm)
        let form = this.state.updateForm
        for (let item in this.state.updateForm) {
            if (item === key) {
                form[item] = event.target.value
                this.setState({form: form})
          }
        //   console.log(this.state.updateForm)
      }
    }
    getPositionList(data){
       api.positionPageList(data).then( res => {
            console.log(res,'职位')
            this.setState({
                data:res.data.content,
                total:res.data.totalElements
            })
        }).catch( err => {
            console.log(err,'err')
        }) 
        console.log(this.state.total,'总条数') 
    }
    // getPositionList(data){
    //    api.positionPageList(data).then( res => {
    //        console.log(res,'职位')
    //        this.setState({
    //         data:res.data,
    //         total:res.data.totalElements
    //        })
    //    })
    // }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div >
                <Button   type='primary' onClick={this.showModal} style={{margin: 30}}>新增</Button>
                <BasicTable 
                    data={this.state.data} 
                    columns={this.state.columns} 
                    row={this.state.row} 
                    changePage={this.changePage.bind(this)}
                    total={this.state.total} 
                    rowKey='id'
                >
                </BasicTable>
                <Modal
                title="新增职位"
                width={500}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                 <Form.Item {...formItemLayout} style={{width:480}} label="职位名称">
                    {getFieldDecorator('name', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<Input onChange={this.handleChange.bind(this,'name')} placeholder="请输入职位名称" />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} style={{width:480}} label="职位描述" >
                    {getFieldDecorator('content', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        }
                        ],
                    })(<TextArea onChange={this.handleChange.bind(this,'content')}/>)}
                    </Form.Item>
                </Modal>
                <Modal
                title="修改职位名称"
                width={500}
                visible={this.state.updateVisible}
                onOk={this.handleUpdateOk.bind(this,this.state.id,this.state.name,this.state.content)}
                onCancel={this.handleUpdateCancel}
                >
                 <Form.Item {...formItemLayout} style={{width:480}}  label="职位名称">
                    {getFieldDecorator('updateName', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<Input  onChange={this.handleUpdateChange.bind(this,'updateName')} placeholder={this.state.name} />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} style={{width:480}}  label="职位描述" >
                    {getFieldDecorator('updatecontent', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<TextArea  onChange={this.handleUpdateChange.bind(this,'updatecontent')}/>)}
                    </Form.Item>
                </Modal>
            </div>
            
        )
    }
}
export default Form.create()(PositionList); 