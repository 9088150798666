import React from 'react'
import { Cascader ,Table, Button,Modal,Input,message,space, Upload,Icon} from 'antd'
// import { PlusOutlined } from '@ant-design/icons';
import ImagesUpload from '@/components/upload'
import API from '@/fetch/api'
import options from './areaTree.js'

export default class BasicDemo extends React.Component {
  state = {
    popupTitle:'新增门店信息',
    key:'',
    storeName:'',
    detailAddress:'',
    phone:'',
    province:'',
    imgs:'',
    county:'',
    city:'',
    positionX:'',
    positionY:'',
    provinceCode:'',
    countyCode:'',
    cityCode:'',
    visible: false,
    page:1,
    size:10,
    totalPages:'',
    data: [],
    uploadConfig:{
      action:'https://up-z2.qiniup.com',
      listType:'picture',
      accept:'image/*',
      multiple:true,
      fileList: [],
      data:{token:''},
      type:'image',
      name:'file',
      // multi_selection: false
  },
   
  }
  componentDidMount () {
    this.getStoreList()
    this.getQi()
  }

  // 获取门店列表
  getStoreList(pageNum=0){
    let {size}=this.state
    let data={
      page:pageNum,
      size:size
    }
    API.getStoreList(data).then(res=>{
      res.data.content.forEach(item => {
        item.area=item.province+item.city+item.county
        item.key=item.id
        if(item.imgs == null) item.imgs = []
        if(item.imgs.length>0){
            const imgArr = item.imgs.split(',').map((imgUrl,index)=>({
                uid: '-' +　index,
                name: imgUrl,
                status: 'done',
                url: imgUrl,
            }))
            item.fileList = imgArr
            console.log(imgArr,'imgARR')
        }
      });
      console.log(res.data,'data');
      this.setState({
        data:res.data.content,
        totalPages:res.data.totalElements
      })
    })
  }
  onSearchAddress = (searchAdd,key) =>{
    if(key){
      fetch(`https://restapi.amap.com/v3/geocode/geo?key=c019b0131580735d613514581d611b49&address=${searchAdd}`)
      .then(res=>{
        if (res.ok) {
          res.json().then(data =>{
            if(data.geocodes.length!=0){
              this.setState({
                positionX:data.geocodes[0].location.split(',')[0],
                positionY:data.geocodes[0].location.split(',')[1],
              })
            }
            let params={
              id:key,
              positionX:this.state.positionX,
              positionY:this.state.positionY,
              province:this.state.province,
              county:this.state.county,
              city:this.state.city,
              detail:this.state.detailAddress,
              name:this.state.storeName,
              phone:this.state.phone,
              imgs:this.state.imgs,
              openingHours:this.state.openingHours,
              provinceCode:this.state.provinceCode,
              countyCode:this.state.countyCode,
              cityCode:this.state.cityCode,
            }
            API.editStore(params).then(res=>{
              if(res.code==200){
                message.success('修改成功');
                this.getStoreList()
              }else {
                message.error('修改失败');
              }
            })
           
          })
        }else {
          message.info('该地址无效');
        }
      })
    }else {
      fetch(`https://restapi.amap.com/v3/geocode/geo?key=c019b0131580735d613514581d611b49&address=${searchAdd}`)
      .then(res=>{
        if (res.ok) {
          res.json().then(data =>{
            this.setState({
              positionX:data.geocodes[0].location.split(',')[0],
              positionY:data.geocodes[0].location.split(',')[1],
            })
            let params={
              positionX:this.state.positionX,
              positionY:this.state.positionY,
              province:this.state.province,
              county:this.state.county,
              city:this.state.city,
              detail:this.state.detailAddress,
              name:this.state.storeName,
              phone:this.state.phone,
              openingHours:this.state.openingHours,
              imgs:this.state.imgs,
              provinceCode:this.state.provinceCode,
              countyCode:this.state.countyCode,
              cityCode:this.state.cityCode,
            }
             API.addStore(params).then(res=>{
              if(res.code==200){
                message.success('新增成功');
                this.setState({
                  storeName:"",
                  phone:'',
                  openingHours:'',
                  imgs:'',
                  detailAddress:'',
                  province:'',
                  county:'',
                  city:'',
                  area:'',
                  provinceCode:'',
                  countyCode:'',
                  cityCode:''
                })
                this.getStoreList()
              }else {
                message.error('新增失败');
              }
            })
           
          })
        }else {
          message.info('该地址无效');
        }
      })
    }
  }
  // 关闭弹窗
  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };
  handleOk = () => {
    let {key}=this.state
    console.log(key,'key')
    if(key){
      let searchAdd=this.state.province+this.state.city+this.state.county+this.state.detailAddress+this.state.openingHours+this.state.imgs
      this.onSearchAddress(searchAdd,key)
    }else {
      if(
        this.state.province&&this.state.city
        &&this.state.county&&this.state.detailAddress
        &&this.state.storeName&&this.state.phone
        &&this.state.openingHours){
        let searchAdd=this.state.province+this.state.city+this.state.county+this.state.detailAddress+this.state.openingHours+this.state.imgs
        this.onSearchAddress(searchAdd,key)
      }else {
        message.info('参数缺少')
      }
    }
    
    this.setState({
      visible: false,
    });
  }
// 分页
  changePage(current){
      this.setState({
          page:current
      })
      if(current==1){
        this.getStoreList()
      }else {
        this.getStoreList(current-1)
      }
  }
  // 删除
  delete(key){
    console.log(key,'key');
    API.deleteStore(key).then(res=>{
      if(res.code==200){
        message.success('删除成功');
        this.getStoreList()
      }else {
        message.info('删除失败')
      }
    })
  }
  // 编辑
  edit(key){
    let {data}=this.state
    data.forEach(item=>{
      if(item.key==key){
        console.log(item,'datafileList')
        let tmpAreaCodeArr=[]
        tmpAreaCodeArr.push(item.provinceCode)
        tmpAreaCodeArr.push(item.cityCode)
        tmpAreaCodeArr.push(item.countyCode)
        this.setState({
          storeName:item.name,
          phone:item.phone,
          openingHours:item.openingHours,
          detailAddress:item.detail,
          visible: true,
          popupTitle:'修改门店信息',
          key:key,
          area:tmpAreaCodeArr,
          positionX:item.positionX,
          positionY:item.positionY,
          province:item.province,
          county:item.county,
          city:item.city,
          provinceCode:item.provinceCode,
          cityCode:item.cityCode,
          countyCode:item.countyCode,
          uploadConfig:{
            ...this.state.uploadConfig,
            fileList:item.fileList
          }
          // area:["500000", "500100", "500101"]
        })
      }
    })
    console.log('编辑',key)
  }
  // 新增
  newAdd(){
    this.setState({
      visible: true,
      popupTitle:'新增门店信息',
      key:'',
      storeName:'',
      detailAddress:'',
      phone:'',
      openingHours:'',
      area:'',
      province:'',
      county:'',
      city:'',
      provinceCode:'',
      countyCode:'',
      cityCode:'',
    });
  }

 

  inputChange1(event){
    this.setState({
      storeName:event.target.value
    })
  }
  onChange(value,list) {
    if(list.length!=0){
    this.setState({
      province:list[0].label,
      city:list[1].label,
      county:list[2].label,
      provinceCode:value[0],
      cityCode:value[1],
      countyCode:value[2],
      area:value
    })
    }else {
      this.setState({
        province:'',
        county:'',
        city:'',
        area:'',
        provinceCode:'',
        countyCode:'',
        cityCode:'',
      })
    }
    console.log(value,'省市区');
  }
  inputChange3(event){
    this.setState({
      detailAddress:event.target.value
    })
  }
  inputChange4(event){
    this.setState({
      phone:event.target.value
    })
  }
  inputChange5(event){
    this.setState({
      openingHours:event.target.value
    })
  }
  
  // 从后台获取七牛云的token值
  getQi() {
    API.QiAuth().then(res =>{
        console.log(res)
        if(res.code == 200) {
            let obj = this.state.uploadConfig
            obj.data = {token:res.data}
            console.log(obj.data)
            this.setState({
                uploadConfig:obj
            })
            // console.log(this.state.uploadConfig)
        }
    })
}
// 图片上传之后获取图片信息
getUrlList(data){
  data = [...data];
  console.log('data', data)
  let title = [];
  let saveImg = [];
  data.forEach((fileInfo, index) => {
      if(fileInfo.response) {
          return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
      }else{
          return saveImg.push(fileInfo.url)
      }
  })
  console.log('data', saveImg.concat(title))
  this.setState({
      imgs:saveImg.concat(title).toString(),
      uploadConfig: {
          ...this.state.uploadConfig,
          fileList: data
      }
  })
    
}
  render () {
    const columns=[
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key'
        },
        {
          title: '门店名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '地区',
          dataIndex: 'area',
          key: 'area',
        },
        {
          title: '详细地址',
          dataIndex: 'detail',
          key: 'detail',
        },
        {
          title: '联系电话',
          key: 'phone',
          dataIndex: 'phone'
        },
        {
          title: '营业时间',
          key: 'openingHours',
          dataIndex: 'openingHours'
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <space size="middle">
              <a style={{marginRight:'10px'}} onClick={this.delete.bind(this,record.key)}>删除</a>
              <a onClick={this.edit.bind(this,record.key)}>修改</a>
              {/* <a onClick={this.delete.bind(this,record.key)}>删除</a> */}
            </space>
          ),
        },
      ]
    const paginationProps = {
            showSizeChanger: false,
            showQuickJumper: false,
            pageSize: this.state.size,
            current: this.state.page,
            total:this.state.totalPages,
            onChange: (current) => this.changePage(current),
        }
  

    return (
    
        <div>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:'20px',marginBottom:'20px',marginRight:'80px'}}><Button onClick={this.newAdd.bind(this)}>新增</Button></div>
            <div><Table columns={columns} dataSource={this.state.data} pagination={paginationProps}/></div>
            <Modal
              title={this.state.popupTitle}
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <p style={{display:'flex',justifyContent:'center',alignItems:'center'}}>门店名称<Input placeholder="请输入门店名称" style={{width:'70%',marginLeft:'20px'}} onChange={event=>this.inputChange1(event)} value={this.state.storeName}/></p>
              <p style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px',paddingLeft:'30px'}}>地区 <Cascader options={options} onChange={(value,list)=>this.onChange(value,list)} placeholder="请选择省、市、区、乡镇等" style={{width:'75%',marginLeft:'15px'}} value={this.state.area}/></p>
              <p style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px'}}>详细地址<Input placeholder="请输入详细地址" style={{width:'70%',marginLeft:'20px'}} onChange={event=>this.inputChange3(event)} value={this.state.detailAddress}/></p>
              <p style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px'}}>联系电话<Input placeholder="请输入联系电话" style={{width:'70%',marginLeft:'20px'}} onChange={event=>this.inputChange4(event)} value={this.state.phone}/></p>
              <p style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px'}}>营业时间<Input placeholder="请输入营业时间" style={{width:'70%',marginLeft:'20px'}} onChange={event=>this.inputChange5(event)} value={this.state.openingHours}/></p>
              <p style={{display:'flex',marginTop:'10px',paddingLeft:'60px'}}>
              <p style={{width:'10%',marginRight:'14px'}}>图片</p>
                <ImagesUpload 
                  style={{width:'75%',marginLeft:'15px'}}
                  {...this.state.uploadConfig} 
                  componentType='store'
                  getUrlList={this.getUrlList.bind(this)}>
                </ImagesUpload>
              </p>
            </Modal>
      </div>
    )

  }

}