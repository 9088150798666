import 'braft-editor/dist/index.css'
import React from 'react'
import BraftEditor from 'braft-editor'
import { Select,Button,message,Space } from 'antd'
import API from '@/fetch/api'


// 下拉菜单获取数据
const { Option } = Select;
const children = [];

async function getTypelist(){
  let titles = await API.articleTypeList();
  const data = titles.data ||　[]
  console.log(data)
  data.splice(data.indexOf('外链'), 1)
  for (let i = 0; i < data.length; i++) {
    // console.log(titles.data[i])
    children.push(<Option key={titles.data[i]} value={titles.data[i]}>{titles.data[i]}</Option>);
  }
}
getTypelist();
// 获取文章
export default class BasicDemo extends React.Component {
  // constructor(){
  //   this.text = text;
  // }
  state = {
    createdTime:[],
    id:'',
    data:{},
    titile:'',
    isSingle:'',
    value:'',
    content:'',
    editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
    outputHTML: '<p></p>',
    size:'default',
    selectValue:'',
  }
//   上传
  upload=()=>{
      let {data}=this.state
    //   console.log(this.state.selectValue)
      // console.log(this.state.outputHTML)
      data.content=this.state.outputHTML
      // data.type=this.state.selectValue
      data.showArticle=true
      data.type=this.state.value
      data.title=this.state.title
      data.isSingle=this.state.isSingle
      // data.updatedTime = new Date()
      data.id=this.state.id
      // data.createdTime=this.state.createdTime
      // console.log(data.createdTime)
      console.log(1,data)
      // return
      // 修改文章
      API.putArticle(data).then(res =>{
        // console.log(data)
        // console.log(res)
    })
  }
// 选择框
    handleChangeSelect=(value)=> {
      // console.log(value,'type')
      this.setState({
        value:value
      })
      // console.log(value)
        this.setState({selectValue: value}, () => {
          // console.log(value)
          // console.log(selectValue)
          API.articleByType({type:value}).then(
            res=>{
              // console.log(res)
              this.state.id = res.data.id
              this.state.createdTime = res.data.createdTime
              // console.log(res.data.content)
              this.state.content = res.data.content
              // console.log(this.state.cotent)
              this.isLivinig && this.setState({
                editorState: BraftEditor.createEditorState(this.state.content)
              })
            }
          )
          //这里打印的是最新的state值
          // console.log(this.state.selectValue);
        })
  }

  // handleChangeSelect = async value => {
      
  //   await this.setState({selectValue: value}, async () => {
  //     let res = await API.articleByType({type:value})
  //     this.state.cotent = res.data.content
  //     console.log(this.state.cotent)
  //   })
  //   console.log(this.state.cotent)
  // }

  // handleChangeSelect = value => {
      
  //   this.setState({selectValue: value}, async () => {
  //     let res = await API.articleByType({type:value})
  //     this.state.cotent = res.data.content
  //     // 在这里写逻辑
  //     console.log(this.state.cotent)
  //   })
  //   console.log(this.state.cotent)
  // }

  componentDidMount () {
    
    this.isLivinig = true
    // 3秒后更改编辑器内容
    setTimeout(this.setEditorContentAsync, 3000)
    
  }

  componentWillUnmount () {
    this.isLivinig = false
    
  }
  created(){
    API.articleByType('').then(
      res=>{
        console.log(res,'234')
        this.state.id = res.data.id
        this.state.createdTime = res.data.createdTime
        // console.log(res.data.content)
        this.state.content = res.data.content
        // console.log(this.state.cotent)
        this.isLivinig && this.setState({
          editorState: BraftEditor.createEditorState(this.state.content)
        })
      }
    )
  }
  handleChange = (editorState) => {
    // 在点击文章内容的时候执行
    // console.log(editorState)
    this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML()
    })
  }


//   成功或失败弹框
  success = (value) => {
    message.success(value);
  }
  
  error = (value) => {
    message.error(value);
  }
  // setEditorContentAsync = () => {
  //   this.isLivinig && this.setState({
  //     editorState: BraftEditor.createEditorState('<p>请先选择文本类型<p>')
  //   })
  // }

  render () {
    

    const { editorState, outputHTML, size ,ifShowLoading} = this.state

    return (
        <div style={{height:'100%'}}>

        <Select size={size} 
          //  defaultValue="" 
            onChange={this.handleChangeSelect} 
            style={{ width: 300,marginTop:30,marginLeft:50,marginBottom:20 }} 
            placeholder="请选择类型">
          {children}
        </Select>
        <Button type="primary" style={{ width:100,marginLeft:20}} onClick={this.upload} loading={ifShowLoading}>上传</Button>
        <span> 图片高度不超过500px</span>

        <div className="editor-wrapper" style={{height:'90%'}}>
          <BraftEditor
            style={{height:'100%'}}
            value={editorState}
            onChange={this.handleChange}
          />
        </div>
        {/* {/* <h5>输出内容</h5> */}
        {/* <div className="output-content">{outputHTML}</div>  */}

      </div>
    )

  }

}