import Home from './Home'
import Custom from './custom/index'
import RichText from './richText/index'
import Store from './store/index'
import Maintain from './maintain/index'
import Manager from './manager/index'
import ManagerRole from './manager/role'
import Complaint from './complaint/index';
import Objection from "./user/Objection";
import Repairman from './repairman/index';
import PositionList from './repairman/position';
import Seckill from './active/seckill';
import AddSeckill from './active/addSeckill';
import Activity from './active/activity';
import Realtime from './realtime/index';
import Hotspot from './realtime/hotspot';
import Ask from './ask/index';
import Community from './community/index';
// import ActivityRegist from './active/activity_registration';

import Satisfaction from './satisfaction/index';
import ChartView from './chart/index'
import Maintenance from './maintenance/index'
import PartManage from './maintenance/part_manage'
import AreaManage from './maintenance/area_manage'
import StrainerManage from './maintenance/strainer_manage'
import GoodsList from './goods/index'
import Comments from './goods/comments'
import Sorts from './goods/sort'
import GoodsDetail from './goods/goods_detail'
import CommentDetail from './goods/comment_detail'
import order from './order/order'
import AddGoods from './goods/add_goods';
import Integral from '@/pages/integral'
import IntegralDetail from '@/pages/integral/integral_detail'
import IntegralRuler from '@/pages/integral/integral_ruler'
import GoodsRecycle from '@/pages/goods/recycle'
import Banner from '@/pages/banner'
import Tag from '@/pages/active/tag'
import Eject from '@/pages/active/eject'
import Projectmanage from '@/pages/projectmanage'
// console.log(order,'order,')
export default {
    Home,
    Custom,
    RichText,
    Maintain,
    Manager,
    Complaint,
    Objection,
    ManagerRole,
    Repairman,
    Seckill,
    Activity,
    Realtime,
    Hotspot,
    Ask,
    Community,
    // ActivityRegist,
    PositionList,
    Satisfaction,
    ChartView,
    Maintenance,
    PartManage,
    AreaManage,
    StrainerManage,
    GoodsList,
    Comments,
    Sorts,
    GoodsRecycle,
    GoodsDetail,
    CommentDetail,
    ...order,
    AddGoods,
    Integral,
    IntegralDetail,
    IntegralRuler,
    Banner,
    Tag,
    Eject,
    Store,
    Projectmanage,
    AddSeckill,
}