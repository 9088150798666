import React,{Component} from 'react'
import {Button} from 'antd'
class OrderSetting extends Component {
    render() {
        return(
            <div>
                订单设置页
            </div>
        )
    }
}
export default OrderSetting;