import React, { Component } from 'react';
import { Menu, Icon, Button } from 'antd';
import routes from '../routes/config'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
const { SubMenu } = Menu;
// 2023-8-4 给管理员添加图片管理和线下活动权限  8-7给管理员添加社区权限,8-9文章管理，8-16门店导航
const ignoreList = ['/app/goods','/app/chart','/app/custom','/app/objection','/app/projectmanage','/app/active/seckill','/app/maintenance/part','/app/maintenance/strainer', '/app/maintenance/area','/app/order','/app/manager','/app/integral']
var MenuList = routes.menus;
const renderMenu = (item, role) => {
    return role === 'ADMIN' &&　ignoreList.includes(item.key) ? null : <Menu.Item key={item.key}>
    <Link to={item.key}>
        {item.icon && <Icon type={item.icon}></Icon>}
            <span>{item.title}</span>
    </Link>
</Menu.Item>
}

const renderSubMenu = (item, role) => {
    // console.log(MenuList,item,'renderSubmenu')
    return role === 'ADMIN' && ignoreList.includes(item.key) ? null : <SubMenu key={item.key} title ={<span>
        {item.icon && <Icon type={item.icon}></Icon>}
        <span>{item.title}</span>
    </span>}>
    {item.subs.map(cell => renderMenu(cell, role))}
    </SubMenu>
}

class SideMenu extends Component {

    constructor(props) {
        super(props)
    }
    componentWillMount() {
        
        
    }
    componentDidMount() {
    
    }
    componentDidUpdate() {
        
    }
    state = {
        collapsed: false,
        list: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
    changeStatus = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        const role = localStorage.getItem('role');
        return (
            <div className="menu-container" style={ !this.props.collapsed?{ width: 200}:{width:76}}>
                {/* <Button onClick={this.changeStatus}>

                </Button> */}
                <Menu collapsed={this.props.collapsed.toString()} theme={'dark'} mode={!this.props.collapsed?'inline':'vertical'}>
                    {
                        MenuList.map(item =>(
                            item.subs? renderSubMenu(item, role) : renderMenu(item, role))    )
                    }
                </Menu>
            </div>
        )
    }
}
function mapProps(state) {
return {
    level:state.login
}
}
export default connect(mapProps)(SideMenu); 