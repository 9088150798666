import React, { Component } from 'react'
import { Form, Input, Button, Select ,Checkbox} from 'antd'
import api from '@/fetch/api'
const renderOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.districtName}</Select.Option>)
class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state = {
        addressList: [],
        positionList: [ ],
        options :['Apple', 'Pear', 'Orange', 'Pea1r', 'Pea12r','Pe4a1r'],
        formStyle: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }
    submitForm = (e) => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
          if(values.category.length > 0){
            values.category = values.category.toString()
          }
          console.log(values,'111111')
          if (!err) {
              this.props.submit(values)
          }

        });
    }
    componentWillReceiveProps() {
        // console.log('enter')
       
    }
    componentDidMount() {
        // 获取地区数据
        api.districtList('').then( res => {
            this.setState({
                addressList:res.data
            })
        })
        // 获取职位信息
        api.positionList('').then( res => {
            console.log(res,'position')
            this.setState({
                positionList:res.data
            })
        })
        // 获取工程师属性
        api.getCategory().then(res => {
          if(res.data.length > 0){
            res.data.forEach((item,index)=>{
              item.label = item.khlb
              item.value = item.khlb
            })
          }
          this.setState({
           options:res.data
         })
       })
        console.log('enter')
      this.initial()
    }
    initial() {
        let obj={
            name:'',
            districtId:'',
            phone:'',
            category:'',
            positionId:''
        }
        if (this.props.data.name) {
            let data = this.props.data
            console.log(data,'data')
            obj.name= data.name
            obj.districtId=data.districtId
            obj.phone = data.phone
            obj.positionId = data.positionId
            obj.category = data.category
        } 
        this.props.form.setFieldsValue(obj)
    }
     //属性选择
    onChange = (checkedValues) => {
      console.log('checked = ', checkedValues);
    }
    submit() {
        this.submitForm()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.submitForm}>
                <div style={this.state.formStyle} className="edit-form-container">
                    <Form.Item label="成员姓名">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入姓名'
                                }
                            ]
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    <Form.Item label="所在地区">
                        {getFieldDecorator('districtId', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择地区'
                                }
                            ]
                        })(<Select placeholder='请选择地点' style={{ width: 200 }} showSearch>
                            {this.state.addressList.map(item => (
                                renderOptions(item)
                            ))}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label="联系方式">
                        {getFieldDecorator('phone', {
                            rules: [
                                {
                                    required: true, message: '请输入手机号'
                                }, {
                                    pattern: /^1\d{10}$/, message: '请输入正确的手机号'
                                }
                            ]
                        })(<Input width={200} style={{ display: 'inline-block', width: 200 }}></Input>)}
                    </Form.Item>
                    <Form.Item label="职位职能">
                        {getFieldDecorator('positionId', {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择职能'
                                }
                            ]
                        })(<Select placeholder='请选择' style={{ width: 200 }} showSearch>
                            {this.state.positionList.map((ele,index) =>{
                                return(
                                    <Select.Option key={ele.id} value={ele.id}>{ele.name}</Select.Option>
                                )
                            } )}
                        </Select>)}
                    </Form.Item>
                    <Form.Item label="属性" style={{width:'90%'}}>
                    {getFieldDecorator('category', {
                        rules: [
                            {
                                required: true,
                                message:'请选择属性'
                            }
                        ]
                    })(
                      <Checkbox.Group options={this.state.options}  onChange={this.onChange.bind(this)} />
                )}
                </Form.Item>
                </div>
                <Form.Item label="状态">
                    {'派单中'}
                </Form.Item>
                <Form.Item >
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        )
    }
}
const EditForm = Form.create({ name: 'edit' })(RawForm)
export default EditForm;