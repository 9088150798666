import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button,message,Modal } from 'antd'
import actions from '../../store/actions/index'
import { bindActionCreators } from 'redux'
import BasicTable from '../../components/Table'
import SearchHeader from '../../components/search_header'
import BasicModal from '@/components/modal_order'
import BasicDrawer from '@/components/drawer'
import API from '@/fetch/api'
import Tool from '@/global/tool'
class Maintenance extends Component {

    componentWillMount(){
        // console.log(this,actions,'this maintain page')
        this.props.getOrderList()
        // console.log(this.props)
        this.getList(this.state.objSearch)       
        this.getMealList('')
        this.getEngineerList({pageSize:'1000'})
    }
    state = {
        sureComplete:false,
        engineerId:'',
        childData:{},
        mealId:'',
        data: [],
        pageNum:1,
        pageSize:10,
        remark:'',
        cyInfo:'',
        reserveTime:'',
        maintenanceState:'CHECK_IN',
        clear:true,
        show:true,
        sfgb:false,
        showState:true,
        mealData:[],
        engineerData:[],
        total: 30,
        columns: [ { title: '订单编号', dataIndex: 'maintenanceCode', key: 'maintenanceCode' },
        { title: '报修人', dataIndex: 'username', key: 'username' },
        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
        { title: '联系方式', dataIndex: 'userPhone', key: 'userPhone' },
        { title: '状态', dataIndex: 'status', key: 'status' },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                { <span>
                        <Button onClick={() => this.checkItem(record)} type='primary'>查看</Button>
                        <Button type='danger'style={{marginLeft:'10px'}} onClick={this.deleteFn.bind(this,record)}>删除</Button>
                        
                    </span>}
                    {
                        (this.state.objSearch.state == 'CHECK_IN' || this.state.objSearch.state == 'ACCEPT_AND_HEAR_A_CASE')?
                        <span>
                        <Button onClick={() => this.cancelFn(record)} type='danger' style={{marginLeft:'10px'}}>取消</Button>
                    </span>:''
                    }
                    {localStorage.getItem('role') === "ADMINX" ? 
                        ((this.state.objSearch.state == 'TO_BE_CONFIRMED' || this.state.objSearch.state == 'CONFIRMED')?
                        <span>
                        <Button onClick={() => this.sureConfirm(record)} type='primary' style={{marginLeft:'10px'}}>确认完成</Button>
                    </span>:''):""
                    }

                </div>
            )
        },
        ],
        fakerData:[
            { name: '小明', status: '1', statusInfo: '空闲中', order: '2020-03-06', time: '60', orderStatus: '1' },
            { name: '小小明', status: '2', statusInfo: '空闲中', order: '2020-03-06', time: '60', orderStatus: '1' },
            { name: '小明明', status: '3', statusInfo: '空闲中', order: '2020-03-06', time: '60', orderStatus: '1' }
        ],
        rowSelections: {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // console.log(selected, selectedRows, changeRows);
            },

        },
        drawerStatus:false,
        modalStatus:false,
        modalAdd:false,
        orderInfo:{
            order:'202012021',
            maintainer:{
                name:'王大狗'
            },
            maintainInfo:{
                reason:'圆圆圆'
            },
            parts:[
                {name:'螺丝钉',count:1,price:0.7}
            ],
            payStatus:'已付款',
                },
        assignPerson:'',
        modalType:'',
        currentOrder:{},
        objSearch:{
            pageNum:1,
            pageSize:10,
            state:""
        }
    }
    checkItem(item) {
        // console.log(item,'维保待接单')
        this.getDetail(item.id)
        this.setState({
            modalStatus:true,
            orderInfo:item
        })
        // this.refs.basicDrawer.openDrawer()
    }
     // 删除
     deleteFn(record){
        API.maintainceDel(record.id).then( res => {
          //   console.log(res,'删除 ',record.id)
            if(res.code == 200 ){
              message.success('删除成功');
              this.getList(this.state.objSearch)
            }else{
              message.error('删除失败');  
            }
        })
    }
    cancelFn(data){
        API.cancelMaintenance(data.id).then(res=>{
            // 删除之后重新获取数据
            if(res.code == 200){
                message.success('订单取消成功'); 
                // let data = this.state.objSearch
                // data.state = "CANCEL"
                // this.setState({
                //     objSearch:data
                // })
                this.getList(this.state.objSearch)
                }else{
                message.error('订单取消失败');
            }
        })
    }
    // 确认完成
    sureConfirm(data){
        // console.log(data.maintenanceCode)
        this.setState({
            sureComplete: true,
            code:data.maintenanceCode
        });
    }
    handleOk = e => {
        // console.log(e);
        // console.log(this.state.code)
        API.putMaintenance(this.state.code).then(res=>this.getList(this.state.objSearch))
        this.setState({
            sureComplete: false,
        });
    };
    
    handleCancel = e => {
        // console.log(e);
        this.setState({
            sureComplete: false,
        });
    };
    closeModal() {
        this.setState({
            modalStatus:false
        })
    }
    onHandleType=(value)=>{
    //  console.log(value,'套餐ID')
        /* this.setState({
            mealId:value
        }) */
        this.state.mealId = value
    //  console.log(this.state.mealId)
    }
    onHandle=(val)=>{
        // console.log(val,'huikejishuj ')
        this.state.cyInfo = JSON.stringify(val)
        //  console.log(typeof this.state.cyInfo)  
    }
    onhandleText=(value)=>{
        // console.log(value,'备注值hhhhhhhhhh')
        this.state.remark = value
        // console.log( this.state.remark)
    }
    onhandleDate=(value)=>{
        // console.log(value,'修改维修时间')
        this.state.reserveTime = value
    }
    changeState(value) {
        // console.log("子组件传的值",value)
        /* this.setState({
            childData:value
        }) */
        this.setState({
            childData:value
        },() => {
            // childData:value
        })
        // this.state.childData = value
        /* console.log('enter?')
        let obj = {}
        obj.state ='REPAIR_SUCCESS'
        obj.id = this.state.orderInfo.id */
        /* API.maintainceUpdate(obj).then(res=>{
            if(res.code == 200){
                this.setState({
                    modalStatus:false
                })
            }
        }) */
    }
    closeSure() {
        // console.log(this.state.cyInfo,typeof (this.state.cyInfo),'慧科技数据')
        // console.log(this.state.reserveTime,'mealId')
        // console.log(this.state.childData)
        let obj1 = this.state.childData
        // console.log(obj1)

        let obj = {...obj1}

        obj.engineerId =this.state.engineerId
        obj.mealId = this.state.mealId
        // obj.maintenanceId = this.state.currentOrder.id
        obj.orderId = this.state.currentOrder.id
        obj.reserveTime = this.state.reserveTime
        obj.remark = this.state.remark
        obj.cyInfo = this.state.cyInfo
        // console.log(this.state.orderInfo,'11111',this.state.mealData)
        // console.log("+++++++++",obj)
        API.maintainceAssign(obj).then(res =>{
            // console.log(res,'dbsfblsifuwilufew')
            if(res.code == 200){
                this.setState({
                    modalStatus:false
                })
            }
        })
        // console.log("1111111111111")
    }
    openDrawer() {
        this.setState({
            drawerStatus:true
        })
    }
    changePage(page) {
        // console.log(page)
        this.state.objSearch.pageNum = page
        this.setState({
            objSearch:this.state.objSearch 
        })
        this.getList(this.state.objSearch) 
    }
    onClose() {
        this.setState({
            drawerStatus:false
        })
    }
    getDetail(data) {
        API.maintainceDetail(data).then(res =>{
            // console.log(res,'222222222')
            if(res.code == 200){
                // console.log(res,'order detail')
                let obj = this.state.orderInfo
                obj.parts = res.data.maintenanceStrainerList
                obj.columns=[{ title: '滤网风量', dataIndex: 'airVolume', key: 'airVolume', id: 1 },
                { title: '数量', dataIndex: 'quantity', key: 'quantity', id: 2 },
                { title: '单价', dataIndex: 'price', key: 'price', id: 3 }]
                this.setState({
                    modalStatus:true,
                    currentOrder:obj,
                    orderInfo:obj,
                    // sfgb:JSON.parse(this.state.orderInfo.cyInfo).sfgb
                })
                // console.log("+++++++++++++",this.state.currentOrder)
                if(this.state.orderInfo.cyInfo != null){
                    this.setState({
                        sfgb:JSON.parse(this.state.orderInfo.cyInfo).sfgb
                    })
                }
                // console.log(this.state.currentOrder,this.state.orderInfo,'???')
                // console.log(JSON.parse(this.state.orderInfo.cyInfo).sfgb,'为什么')
            }
        })
    }
    getPerson(data) {
        // console.log(data,this.state.currentOrder)
        this.setState({
            engineerId:data.id,
        })
        this.setState({
            drawerStatus: false,
            assignPerson: data.name
        })
    }
    getEngineerList(data){
        API.engineerList(data).then(res =>{
        // console.log(res,'3333333')
            if(res.code == 200) {
                if(res.data && res.data.result)
                this.state.engineerData = res.data.result
            }
        })
    }
    getMealList(data) {
        API.mealList(data).then(res=>{
            // console.log(res,'55555')
            if(res.code == 200) {
                // console.log(this,'this')
                let list = res.data.content
                list.forEach(item =>{
                    item.createdTime = Tool.timeChecker(item.createdTime)
                    item.reserveTime = Tool.timeChecker(item.reserveTime)
                })
                this.setState({
                    mealData:list,
                })
            }
            // console.log(this.state.mealData,'1321231')
        }) 
    }
    searchHandler(data) {
        console.log(data["姓名"],data["联系电话"],this.state.objSearch)
        let obj={}
        obj.name = data["姓名"]? data["姓名"]:''
        obj.phone= data["联系电话"]?data["联系电话"]:''
        obj.state = this.state.objSearch.state
        this.getList(obj)
    }
    refreshHandler(){
        this.setState({
          clear:true,
        })
        // console.log(this.state.defaultValue)
        this.getList(`pageNum=${this.state.objSearch.pageNum}`)
        message.info('刷新成功')
    }
    handleSelect =(event) =>{
        console.log(event)
        this.state.objSearch.state = event
        this.setState({
            objSearch:this.state.objSearch,
            selectValue:event,
            clear:false
        })
        this.getList(this.state.objSearch)
    }

    getList(data) {
        API.maintainceList(data).then(res=>{
            // console.log(res,'666666')
            if(res.code == 200) {
                // console.log(this,'this')
                if(res.data && res.data.result){
                    let list = res.data.result
                    list.forEach(item =>{
                        item.createdTime = Tool.timeChecker(item.createdTime)
                        item.reserveTime = Tool.timeChecker(item.reserveTime)
                      
                        switch(item.state){
                            case 'CHECK_IN':item.status='待接单';  break;
                            case 'ACCEPT_AND_HEAR_A_CASE':item.status='保养中';  break;
                            case 'TO_BE_CONFIRMED':item.status='待确认';  break;
                            case 'CONFIRMED':item.status='已确认';  break;
                            case 'PAY_SUCCESS':item.status='已支付';  break;
                            case 'REPAIR_SUCCESS':item.status='已完成';  break;
                            case 'CANCEL':item.status='已取消';  break;  
                            case 'ADMINCANCEL':item.status='后台取消';  
                            break;
                            // default 
                        }
                    })
                    this.setState({
                        data:res.data.result,
                        total:res.data.totalCount
                    })
                }
                // console.log(this.state.total,'维保数据条数')
            }
        })
    }
    render() {
        return (
            <div>
                <SearchHeader title="保养订单管理" 
                firstKey="姓名" 
                secondKey="联系电话" 
                addShow='show'
                headerType='refresh-header'
                show={this.state.show}
                showState={this.state.showState}
                getList={this.getList.bind(this)}
                handleSelect={this.handleSelect}
                clear= {this.state.clear}
                selectValue={this.state.selectValue}
                refreshHandler={this.refreshHandler.bind(this)}
            //  addMaintenance={this.addMaintenance}
                searchHandler={this.searchHandler.bind(this)} 
                ></SearchHeader>
                <BasicTable data={this.state.data} total={this.state.total} columns={this.state.columns} changePage={this.changePage.bind(this)} row={this.state.rowSelections} rowKey='id'></BasicTable>
                <BasicDrawer data={this.state.engineerData} visible={this.state.drawerStatus} onClose={this.onClose.bind(this)} ref="basicDrawer" getPerson={this.getPerson.bind(this)} ></BasicDrawer>
                <BasicModal orderType='maintenance' 
                    orderInfo={this.state.orderInfo} 
                    sfgb={this.state.sfgb}
                    onHandleType={this.onHandleType}
                    onHandle={this.onHandle}
                    onhandleText={this.onhandleText}
                    onhandleDate={this.onhandleDate}
                    visible={this.state.modalStatus} 
                    mealData={this.state.mealData} 
                    onOk={this.closeSure.bind(this)} 
                    onClose={this.closeModal.bind(this)} 
                    title="订单详情" 
                    assign={this.openDrawer.bind(this)} 
                    assignPerson={this.state.assignPerson == ''?'未指派人员':this.state.assignPerson} changeState={this.changeState.bind(this)}>
                </BasicModal>
                <Modal
                    visible={this.state.sureComplete}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>是否确认完成？</p>
                </Modal>
            </div>
        )
    }
    
}
function mapGetter(state) {
    return {
        list:state.list
    }
}
function mapActions(dispatch) {
    return {
        getOrderList: bindActionCreators(actions.getOrders,dispatch)
    }
}
export default connect(mapGetter,mapActions)(Maintenance);
