import React,{Component,useState} from 'react'
import {message,Button, Form, Input,Select, Upload} from 'antd'
import { LoadingOutlined, PlusOutlined ,UploadOutlined} from '@ant-design/icons';
import BasicUpload from '@/components/upload'
import BraftEditor from 'braft-editor'
import API from '@/fetch/api'
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const renderOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
)
var imgTest =''
var configImgToken = ''
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
function UpdateImgFn(props){
  // console.log(props.form.issuerImg,'IMg',props.form)
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.form.issuerImg);
  const tokenData = {}
  tokenData.token = configImgToken

  const handleChangeImg = (info) => {
      console.log(info,'image')
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
        const url ='http://cdn.huikeji.shop/'+ info.file.response.hash
        setImageUrl(url);
        imgTest = url
    }
  };
  return (
    <div>
       <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          multiple={true}
          data={tokenData}
          maxCount={1}
          type='image'
          action="https://up-z2.qiniup.com"
          beforeUpload={beforeUpload}
          // onChange={()=>dispatch()}
          onChange={handleChangeImg}
          >
          {imageUrl ? (
              <img
              src={imageUrl}
              alt="avatar"
              style={{
                  width: '100%',
              }}
              />
          ) : (
              <div>
               {loading ? <LoadingOutlined /> : <PlusOutlined />}
               <div style={{marginTop: 8,}}>Upload</div>
              </div>
          )}
          <span>34px*34px</span>
        </Upload> 
    </div>
  )
}

class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={
        content:'',
        name:'',
        id:'',
        img:'',
        form:{},
        getTagList:[],
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        formStyle:{
            display:'flex',
            flexDirection:'column',
            flexWrap:'wrap'
        },
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            accept:'image/*',
            multiple:true,
            fileList: this.props.data.fileList,
            data:{token:''},
            type:'image',
            name:'file',
        },
        
    }
    
    // submitForm=(e) => {
    //     console.log(this.state.img,'this',e)
    //     e.preventDefault();
    //     this.props.form.validateFieldsAndScroll((err, values) => {
    //         console.log(values,'submitForm',values.img)
    //         values.img = this.state.img
    //         if (!err) {
    //             this.props.submit(values)
    //         }
    //     });
    // }
    submitForm=(e) => {
      console.log(this.state.img,'this',e)
      e.preventDefault();
      let imgArr = []
      this.props.form.validateFieldsAndScroll((err, values) => {
          console.log(values,'submitForm',this.state.img,imgTest)
          // console.log(values,'submitForm',this.state.img.length,imgTest.length)
          if(imgTest.length != 0) values.issuerImg =  imgTest
          if(this.state.img.length != 0) {
              values.img = this.state.img.toString()
          }else{
              // values.img.map((item,index)=>{
              //     console.log(item.name,index)
              //     imgArr.push(item.name) 
              // })
              // // console.log(imgArr)
              // values.img = imgArr.toString()
              values.img =  values.img.toString()
          }
          
          // values.issuerImg =  imgTest
          values.content = this.state.outputHTML
          if (!err) {
              this.props.submit(values)
          }
      });
  }
    componentWillMount(){
        this.getQi()  
        this.tagList()
    }
    // 初始化页面
    componentDidMount() {
        console.log(this.props.data,this.props.data.id)
        this.getQi()
        this.tagList()
        let data = this.props.data
        data.content = BraftEditor.createEditorState(data.content)
        this.props.form.setFieldsValue(data)
        // this.props.form.setFieldsValue({ content:BraftEditor.createEditorState(data.content) })
    }
    submit=()=> {
        this.submitForm()
    }
    handleChange = (key, event) => {
        // console.log(key,event.target.value,this.state.form)
        let form = this.state.form
        for (let item in this.state.form) {
            if (item === key) {
                form[item] = event.target.value
                this.setState({form: form})
          }
      }
    }
     //获取标签列表tagList
    tagList(){
        let data =`tagTypeItems=${'ANSWER_CONTENT'}`
        API.getTagList(data).then(res=>{
            // console.log(res.data)
            this.setState({
                getTagList:res.data.result
            })
        })
    }
    // 富文本
    changeRichText=(editorState) => {
      // 在点击文章内容的时候执行
      // console.log(editorState,'editorState',editorState.toHTML)
      this.setState({
          editorState: editorState,
          outputHTML: editorState.toHTML()
      })
    }
    // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            // console.log(res)
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                configImgToken = res.data
                this.setState({
                    uploadConfig:obj
                })
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data){
        let urlTemp =  this.state.img
        if(data.length > 0){
            urlTemp='' 
        }
        console.log(data,'data',urlTemp)
        if(data.length == 3){
           message.warning('最多上传四张')
        }
        let title = [];
        let saveImg = [];
        data.forEach((fileInfo, index) => {
            // if(index <= 0) {
            //     return focusImg = fileInfo;
            // }
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }else{
                return saveImg.push(fileInfo.url)
            }
            // console.log('data', fileInfo.url)
            // title.push(fileInfo.url);
        })
        console.log('data', saveImg.concat(title))
        this.setState({
            img:saveImg.concat(title).toString(),
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form  onSubmit={this.submitForm} style={this.state.formStyle} >
              <Form.Item {...formItemLayout}  style={{width:"100%"}} wrapperCol={{span:18}} label="问答名称">
                {getFieldDecorator('name', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    },
                    ],
                })(<Input onChange={this.handleChange.bind(this,'name')} placeholder="请输入问答内容" />)}
              </Form.Item>
              <Form.Item {...formItemLayout}  style={{width:"100%"}} wrapperCol={{span:18}} label="问答类型">
                {getFieldDecorator('tagId', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    },
                    ],
                })(
                // <Input onChange={this.handleChange.bind(this,'type')} placeholder="请输入问答类型" />
                 <Select  placeholder='请选择类型' optionFilterProp="children"  showSearch>
                    {this.state.getTagList.map(item => (
                        renderOptions(item)
                    ))}
                </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout}  style={{width:"100%"}} wrapperCol={{span:18}} label="发布人">
                {getFieldDecorator('issuerName', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    },
                    ],
                })(<Input onChange={this.handleChange.bind(this,'issuerName')} placeholder="请输入发布人姓名" />)}
              </Form.Item>
              <Form.Item {...formItemLayout}  style={{width:"100%"}} label="头像">
                {getFieldDecorator('issuerImg', {
                })( <UpdateImgFn form={this.props.data}></UpdateImgFn>)}
              </Form.Item>
              <Form.Item {...formItemLayout}  style={{width:"100%"}} label="问答图片">
                {getFieldDecorator('img', {
                    rules: [
                    {
                        required: false,
                        message: '内容不能为空',
                    },
                    ],
                })(
                    <BasicUpload 
                    {...this.state.uploadConfig} 
                    componentType={this.props.componentType}
                    getUrlList={this.getUrlList.bind(this)}>
                    </BasicUpload>)
                }
                </Form.Item>
                <Form.Item {...formItemLayout} style={{width:"100%"}}  wrapperCol={{span:24}}  label="社区内容" >
                {getFieldDecorator('content', {
                })( <BraftEditor
                    style={{height:'100%',margin:"10px",width:"80%",paddinf:"5px",border:"1px solid #CCC"}}
                    value={this.state.outputHTML}
                    onChange={this.changeRichText}
                    />)}
                </Form.Item>
                {/* <Form.Item {...formItemLayout} style={{width:"100%"}} wrapperCol={{span:18}} label="问答内容" >
                {getFieldDecorator('content', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    }
                    ],
                })(<TextArea onChange={this.handleChange.bind(this,'content')}/> )}
                </Form.Item> */}
               
                <Form.Item style={{
                   width: 'calc(100% + 48px)',
                   marginLeft:'-24px',
                   marginBottom:'0',
                   paddingTop:'3px',
                   justifyContent: 'flex-end',
                   paddingRight: '24px',
                   borderTop:'1px solid #ebebeb'}}>
                    <Button onClick={this.props.onClose} style={{marginRight:'10px'}}>取消</Button>
                    <Button htmlType='submit' type='primary' >确定</Button>
                </Form.Item>
            </Form>
        )
    }
}
const AskFrom =Form.create({name:'edit'})(RawForm)
export default AskFrom;