import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Button} from 'antd'
import { Chart } from '@antv/g2'
import API from '@/fetch/api'
class ChartView extends Component {
    state={
        active:1,
        current:1,
    }
    changeData(data) {
        this.changeType(data)
    }
    renderChart(data,type) {
        if(type === 'week'){
          data.forEach(item =>{
            item.date = item.date.substr(0,4)+'年第'+item.date.substr(4,5)+'周'
            console.log(item.date)
          })
        }else{
          data.forEach(item =>{
            console.log(item.date)
            item.date = item.date.substr(0,4)+'年'+item.date.substr(4,2)+'月'+item.date.substr(6,7)+'日'
            console.log(item.date,item.date.substr(5,2))
          })
        }
        const c = this.refs.maintain
        console.log(c,'ccc')
        // c.innerHTML = ''
        // c.removeChild(child)
        const chart = new Chart({
          container: c,
          height: 500,
          width: 500
        })
        chart.init()
        chart.clear()
        chart.data(data);
        chart.scale({
          // date: {
          //   range: [0, 1],
          // },
          count: {
            min: 0,
            nice: true,
          },
        });
        chart.tooltip({
          showCrosshairs: true, // 展示 Tooltip 辅助线
          shared: true,
        });
        chart.interval().position('date*count').label('count');
        chart.point().position('date*count');
        chart.render();
      }
    drawChart(chart,data,type='interval',column,row) {
        let c = this.refs[chart]
        chart = new Chart({
            container:c,
            width:800,
            height:800
        })
        chart.data(data)
        chart.interval().position('genre*sold')
          chart.render();
    }
    changeType(data){
        console.log(data)
        this.setState({
          current:data === 'week'?1:2
        })
        this.getChartData(data)
      }
      getChartData(type){
        API.maintainChart({type:type}).then(res =>{
          if(res.code === 200){
            // this.drawChart(data)
            this.renderChart(res.data,type)
          }
        })
      }
    componentDidMount() {
        this.getChartData('week')
        /* const data = [
            { genre: 'Sports', sold: 275 },
            { genre: 'Strategy', sold: 115 },
            { genre: 'Action', sold: 120 },
            { genre: 'Shooter', sold: 350 },
            { genre: 'Other', sold: 150 },
          ]; */
        // this.drawChart('maintain',data)
        // this.drawChart('work',data)
        // this.drawChart('over',data)
        // this.drawChart('order',data)
    }

    render() {
        return (
            // <div>
                <div className="chart-container">
                    <div className="chart-header">
                        <p>维修单量统计</p>
                        <div>
                        {/* <Button className={this.state.active === 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>年</Button> */}
                        <Button className={this.state.active === 2?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>季</Button>
                        <Button className={this.state.active === 3?'button-selected':'button-theme'} onClick={() =>this.changeData('week')}>周</Button>
                        <Button className={this.state.active === 4?'button-selected':'button-theme'} onClick={() =>this.changeData('day')}>日</Button>
                        </div>
                    </div>
                    <div ref='maintain' className="chart-content" >

                    </div>
                {/* </div> */}
                {/* <div className="chart-container">
                    <div className="chart-header">
                        <p>工作量统计</p>
                        <div>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>年</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>季</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>周</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>日</Button>
                        </div>
                    </div>
                    <div ref='work' className="chart-content" >

                    </div>
                </div> */}
                {/* <div className="chart-container">
                    <div className="chart-header">
                        <p>派单统计</p>
                        <div>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>年</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>季</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>周</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>日</Button>
                        </div>
                    </div>
                    <div ref='order' className="chart-content" >

                    </div>
                </div> */}
                {/* <div className="chart-container">
                    <div className="chart-header">
                        <p>超时统计</p>
                        <div>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>年</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>季</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>周</Button>
                        <Button className={this.state.active == 1?'button-selected':'button-theme'} onClick={() =>this.changeData(1)}>日</Button>
                        </div>
                    </div>
                    <div ref='over' className="chart-content" >

                    </div>
                </div> */}
            </div>
        )
    }
}
export default connect()(ChartView);