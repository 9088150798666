import React,{Component} from 'react'
import {Button,Input} from 'antd'
import goods from '.'
class CommentsDetail extends Component {
    state={
        count:'',
        goods:{
            title:'银色星芒刺绣网纱底裤',
            type:'肤色/M',
            commentLevel:'5星',
            date:'2017-06-15 14:35:51',
            read:'998',
            comments:'棉布莱卡的出现，不过使内衣面料又多了一种选择与表现。最舒适的面料仍当数棉布，今天的女性依然偏爱棉质内衣，这因为棉布本身独一无二的透气性和天然性，使穿着感受绝不同于其他面料。从美感来说，平织棉布的印花效果和针织棉布的染色效果，都有一种天然纯朴和青春气息，为其他面料所难取代。棉布莱卡的出现，不过使内衣面料又多了一种选择与表现。最舒适的面料仍当数棉布，今天的女性依然偏爱棉质内衣，这因为棉布本身独一无二的透气性和天然性，使穿着感受绝不同于其他面料。从美感来说，平织棉布的印花效果和针织棉布的染色效果，都有一种天然纯朴和青春气息，为其他面料所难取代。',
            imgList:[],
            commentsList:[{user:'哦哦',owner:'啊啊',comment:'商品很好，好平'},{user:'啊小明',owner:'哦哦',comment:'商品很好，好平'},{user:'问我',owner:'哥哥',comment:'商品很好，好平'},{user:'小明',owner:'下单',comment:'商品很好，好平'}]
        }
    }
    componentDidMount() {
        console.log(this.props)
        this.setState({
            count:this.props.query.count
        })
    }
    commentsRender=(data)=>(
        <div style={{marginTop:'30px'}}>
            <p>{data.user}</p>
    <p>回复 {data.owner} ：  {data.comment}</p>
    <p>{}</p>
        </div>
    )
    render() {
        return(
            <div className='comment-container'>
                <div className='comment-left'>
                    <div style={{marginTop:'100px'}}>
                        <span className='comment-left-title'>商品 :  </span>
        <span className="comment-left-name" style={{color:'lightseagreen'}}>{this.state.goods.title}</span>
                    </div>
                    <div>
                        <span className='comment-left-title'>购买 :  </span>
        <span className="comment-left-name">{this.state.goods.type}</span>
                    </div>
                    <div>
                        <span className='comment-left-title'>评价 :  </span>
        <span className="comment-left-name">{this.state.goods.commentLevel}</span>
                    </div>
                    <div>
                        <span className='comment-left-title'>日期 :  </span>
        <span className="comment-left-name">{this.state.goods.date}</span>
                    </div>
                </div>
                <div className="comment-right">
                    <p>阅读：{this.state.goods.read}</p>
                    <p>{this.state.goods.comments}</p>
                    <div style={{marginTop:'30px'}}>
                        <Input.TextArea></Input.TextArea>
                        <Button type='primary' style={{marginTop:'30px'}}>回复</Button>
                    </div>
                    <div className="line"></div>
                    <div className="comments-list">
                        {
                            this.state.goods.commentsList.map(item =>{
                            return this.commentsRender(item)
                            })
                        }
                        </div>
                </div>
            </div>
        )
    }
}
export default CommentsDetail;