import XLSX from 'xlsx'

function importExcel (file) {
    const {files} = file.target 
    const fileReader = new FileReader()
    var total =[]
    let promise = new Promise((resolve,reject) =>{
    fileReader.onload = (event) => {
            let data = []
            try {
                const {result} = event.target
                const workBook = XLSX.read(result,{type:'binary'})
              
                for(const sheet in workBook.Sheets){
                    if (workBook.Sheets.hasOwnProperty(sheet)) {
                       
                        data = data.concat(XLSX.utils.sheet_to_json(workBook.Sheets[sheet]));
                    }
                }
                total = data
                resolve(data)
                return total
            }
            catch(error){
                console.log(error)
            }
           
        }
    
    })
    fileReader.readAsBinaryString(files[0]);
    return promise

  
  
}

function exportExcel(headers,data,fileName) {
    const _headers = headers
    .map((item, i) => Object.assign({}, { key: item.key, title: item.title, position: String.fromCharCode(65 + i) + 1 }))
    .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {});

const _data = data
    .map((item, i) => headers.map((key, j) => Object.assign({}, { content: item[key.key], position: String.fromCharCode(65 + j) + (i + 2) })))
   
    .reduce((prev, next) => prev.concat(next))
  
    .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { v: next.content } }), {});


const output = Object.assign({}, _headers, _data);

const outputPos = Object.keys(output);

const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;


const wb = {
    SheetNames: ['mySheet'],
    Sheets: {
        mySheet: Object.assign(
            {},
            output,
            {
                '!ref': ref,
                '!cols': [{ wpx: 45 }, { wpx: 100 }, { wpx: 200 }, { wpx: 80 }, { wpx: 150 }, { wpx: 100 }, { wpx: 300 }, { wpx: 300 }],
            },
        ),
    },
};

XLSX.writeFile(wb, fileName);
}

function dateFilter(date) {
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDay()
    if (m < 10) m = '0' + '' + m
    if (d < 10) d = '0' + '' + d
    return (y + '-' + m + '-' + d)
  }
  
  function timeFilter(date) {
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()
    if (h < 10) h = '0' + '' + h
    if (m < 10) m = '0' + '' + m
    if (s < 10) s = '0' + '' + s
    return (h + ':' + m + ':' + s)
  }
  function timeChecker(data) {
    // console.log(data,'data时间格式')
    if(data != null){
    let date = data.slice(0, 3)
    let time = data.slice(3, data.length)
    if (time.length < 3) time.push(0);
    date.forEach((item, index) => {
      if (item < 10) {
        date[index] = '0' + '' + item + ''
      }
    })
    time.forEach((item, index) => {
      if (item < 10) {
        time[index] = '0' + '' + item + ''
  
      }
    })
  
    return (date.join('-') + ' ' + time.join(':'))
    }else{
      console.log('数据为空')
    }
  }
export default {
    importExcel,
    exportExcel,
    dateFilter,
    timeFilter,
    timeChecker
}