import React,{Component} from 'react'
import {Form,Input,Radio,Button} from 'antd'

class OrderCancel extends Component {
    render() {
        return(
            <div>
                <span style={{fontSize:16,margin:10}}>取消订单</span>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Form style={{display:"flex",flexDirection:'column',justifyContent:'center'}}>
                    <Form.Item name={['user', 'remarks']} label="操作备注：" style={{display:"flex"}}>
                        <Input.TextArea  style={{width:400,height:120}}/>
                    </Form.Item>
                    <Form.Item name={['user', 'reason']} label="取消原因：" style={{display:"flex"}}>
                        <Input.TextArea style={{width:400,height:120}}/>
                    </Form.Item>
                    <Form.Item name={['user', 'price']} label="订单金额：" style={{display:"flex"}}>
                        <span>￥200.00</span>
                    </Form.Item>
                    <Form.Item name={['user', 'freight']} label="退运费：" style={{display:"flex"}}>
                        <Radio.Group>
                            <Radio value="a">不退运费</Radio>
                            <Radio value="b">退运费</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name={['user', 'reason']} label="确认退款金额：" style={{display:"flex"}}>
                        <Input placeholder='￥200'/>
                    </Form.Item>
                    <Form.Item name={['user', 'method']} label="退款方式：" style={{display:"flex"}}>
                        <Radio.Group>
                            <Radio value="a">退回到原支付渠道</Radio>
                            <Radio value="b">生成退款申请</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name={['user', 'explain']} label="退款说明：" style={{display:"flex"}}>
                        <Input.TextArea  style={{width:400,height:120}}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit"  style={{marginRight:20,width:80}}> 取消 </Button>
                        <Button type="primary" htmlType="submit"  style={{width:80}}> 确定 </Button>
                    </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}
export default OrderCancel;