import React, { Component } from 'react'
import { Button,Icon,Dropdown,Menu,Avatar,message,Badge,Popover,Modal,Input, Form } from 'antd'
import { withRouter } from 'react-router-dom'
import { BellOutlined,EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import api from '../fetch/api';

class Header extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    username:localStorage.getItem('username'),
    count:0,
    visible: false,
    password:"",
    passwordOnce:""
  }
  componentDidMount(){
    this.initWebSocket()
  }
  initWebSocket() {
    let token=localStorage.getItem('token')
    // const url = 'ws://192.168.1.123:443/websocket/' + token
    // const url = 'ws://192.168.1.161:443/websocket/' + token
    const url = 'ws://www.huikeji.shop/websocket/' + token
    // const url = 'ws://www.huikeji.shop:8045/websocket/' + token
    this.websock = new WebSocket(url)
    this.websock.onopen = this.websocketonopen
    this.websock.onerror = this.websocketerror
    this.websock.onmessage = this.websocketonmessage.bind(this)
    this.websock.onclose = this.websocketclose
  }
  websocketonopen(){ //连接建立之后执行send方法发送数据
    // let actions = {"test":"12345"};
    // this.websocketsend(JSON.stringify(actions));
    console.log("连接成功")
  }
  websocketerror() {
    console.log('连接发生错误')
  }
  websocketonmessage(e) {
    // 接收数据
    // console.log(e,"+++++++++")
    message.info("有新的信息",5)
    // message.info(e.data,5)
    this.setState({
      count:this.state.count + 1
    })
    // console.log(this.state.count)
  }
  
  updata(){
    window.location.reload()
  }
  menu = (
    <div>
      <Menu>
        <Menu.Item>
          <div>{this.state.username}</div>
        </Menu.Item>
        <Menu.Item>
          <span onClick={this.setFn.bind(this)}>修改密码</span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={this.outLogin.bind(this)}>退出</span>
        </Menu.Item>
      </Menu>
    </div>
  )
  setFn(){
    this.setState({
      visible: true,
    });
    let districtId=localStorage.getItem('districtId')
    console.log(districtId)
    console.log('设置密码')
  }
  outLogin() {
    localStorage.removeItem('role')
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    this.props.history.push('/')
  }

  handleOk = e => {
    if(this.state.password === this.state.passwordOnce){
      api.updateCurrent({password:this.state.password}).then(res=>{
        // console.log(res)
        this.setState({
          visible: false,
        });
        localStorage.removeItem('role')
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        this.props.history.push('/')
      }) 
    }else{
      message.warning('两次密码输入不一致')
    }
  }

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  }
  // 修改密码
  onChange(e){
    // console.log(e.target.value)
    this.setState({
      password:e.target.value
    })
  }
  onChangeOnce(e){
    // console.log(e.target.value)
    this.setState({
      passwordOnce:e.target.value
    })
  }
  render() {
    const headerStyle = {
      'display':'flex', 
      'justifyContent':'space-between',
      'alignItems':'center',
    }
    
    return (
      <div style={headerStyle}>
        <Button onClick={this.props.changeStatus}>
         {/* {React.createElement(!this.props.collapsed?MenuUnfoldOutlined : MenuFoldOutlined)} */}
          <Icon type={!this.props.collapsed?'menu-fold':'menu-unfold'} style={{fontSize:18}}></Icon>
        </Button>
        <Modal
          title="修改密码"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
            <Form>
              <Form.Item name="password" label="请输入密码">
                <Input.Password onChange={this.onChange.bind(this)}/>
              </Form.Item>
              <Form.Item name="password" label="请确认密码">
                <Input.Password onChange={this.onChangeOnce.bind(this)}/>
              </Form.Item>
            </Form>
        </Modal>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:100}}>
          <Popover content={<div>新增了 {this.state.count} 条信息</div>}>
            <Badge count={this.state.count}>
              <div className="icons-list">
                <BellOutlined  onClick={this.updata.bind(this)} style={{color:'white',marginRight:10,fontSize:32}}/>
              </div>
            </Badge>
          </Popover>
        <Dropdown overlay={this.menu}>

        
          <div style={{display:'inline-block',width:50,}}>
          
          <Avatar size="large" icon="user" />
            {/* <img style={{height:'50',width:'50',borderRadius:'25'}} src={imageUrl} alt="图片加载失败..."/> */}
          </div>
        </Dropdown>
        {/* <div style={{display:'inline-block',width:50}}>
          <img style={{height:'50',width:'50',borderRadius:'25'}} src={imageUrl} alt="图片加载失败..."/>
        </div> */}
      </div>
      </div>
    )
  }
}
export default withRouter(Header)
