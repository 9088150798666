import React,{Component} from 'react'
import {Modal,Button,Pagination,Table} from 'antd'
import BasicTable from './Table'
class ListModal extends Component{
    constructor(props) {
        super(props)
    }
    state={

    }
    changePage(page) {
        this.props.changePage(page)
    }
    render() {
        return (
            <div>
                <Modal title={this.props.title} visible={this.props.visible} onCancel={this.props.onClose} width={800} onOk={this.props.onClose}>
                <BasicTable data={this.props.data} columns={this.props.columns} pagination={false} changePage={this.changePage.bind(this)} total={this.props.total}></BasicTable>
                </Modal>
            </div>
        )
    }

}
export default ListModal;