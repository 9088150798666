import React,{Component} from 'react'
import {Button} from 'antd'
class OrderBatchPrint extends Component {
    render() {
        return(
            <div>
                订单批量打印
            </div>
        )
    }
}
export default OrderBatchPrint;