// import api from '../../../fetch/api'

const orderDetail = (state={}, action) => {
    switch (action.type) {
        case 'ORDER_CONDITION':
            console.log('condition')
            break;
            case 'ORDER_CONFIRM':
                console.log('confirm')
                    break;
                    case 'ORDER_PERSON':
                        console.log('person')
                        break;
        default:
            return {}
    }

}

export default {
    orderDetail
}