import React,{Component} from 'react'
import {Table,Button,Input,message,Popconfirm,Select} from 'antd'
import API from '@/fetch/api'
import Tools from '@/global/tool'


import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const { Option } = Select;
var children = [];


class Tag extends Component {
    state={
        dataSource: [],
        type:'OFFLINE_ACTIVITY',
        data:[],
        columns:[
            {
                title: 'Sort',
                dataIndex: 'sort',
                width: 100,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            {
                title:'ID',
                key:'id',
                dataIndex:'id'

            },
            {
                title:'名称',
                key:'name',
                dataIndex:'name'

            },
            {
                title:'更新时间',
                key:'updatedTime',
                dataIndex:'updatedTime'

            },
            {
                title: "操作", dataIndex: 'action', key: 'action',
                render : (text, record, index) => (
                    <div>
                        <Button style={{marginLeft:'10px'}}
                        onClick={() => this.deleteTag(record)} 
                        type='danger'>删除</Button>
                    </div>
                ),
            },
        ],
        inpValue:'',
        defaultValue:'',
        imgType:[],
    }


    onSortEnd = ({ oldIndex, newIndex }) => {
        const { data } = this.state;
        if (oldIndex !== newIndex) {
            console.log(data,"+++++++++")
            const newData = arrayMove([].concat(data), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items: ', newData);
            // console.log("1111")
            this.setState({ data: newData });
            let dataCeshi = []
            for(let i = 0;i<newData.length;i++){
                delete newData[i].self
                console.log(newData[i])
                dataCeshi.push(newData[i])
            }
            // console.log(dataCeshi)
            API.putImageType(dataCeshi).then((res)=>{
                console.log(res)
            })
        }
        
    };    
    
  
    // 获取标签类型
    async  getTagType(){
        let img = await API.getTagType();
        console.log(img,'hahhahhhas')
        // img.data.forEach
        this.setState({
            imgType:img.data,
            defaultValue:img.data[0].value,
            type:img.data[0].key,
        })
        // console.log(img.data[0],this.state.defaultValue)
        img.data = img.data || []
        children = img.data.map(item => (
            <Option key={item.key}>{ item.value }</Option>
        ))
    }
    // 获取列表
    getTagList(obj){
        // let obj = {}
        // obj.tagTypeItems = this.state.type
        API.getTagList(obj).then(res=>{
            console.log(res)
            if(res.code == 200){
                res.data.result.forEach((item,index)=>{
                    item.updatedTime = Tools.timeChecker(item.updatedTime)
                })
                this.setState({
                    data:res.data.result
                })
            }
        })
    }
    componentWillUnmount (){
        this.setState({
            imgType:''
        })
    }
    deleteTag(data) {
        console.log(data,"++++++++")
        API.deleteTag(data.id).then((res) => {
            if(res.code == 200){
                message.success('删除成功');
                this.getTagList(`tagTypeItems=${this.state.type}`)
            }else{
                message.error('删除失败');   
            }
        });
    }
    
    // 选择框改变时获取选择框内的值
    handleChangeSelect(value) {
        console.log(value,'hahha')
        this.getTagList(`tagTypeItems=${value}`)
        // 设置type值
        this.setState({
            type:value
        })
    }  
    // 标签内容
    tagChange(e){
        console.log(e.target.value,'tag')
        this.setState({
            inpValue:e.target.value
        })
    }
    // 点击提交时执行
    submit(){
        let data = {}
        data.type = this.state.type
        data.name = this.state.inpValue
        console.log(data)
        this.setState({
            inpValue:'',
        })
        API.postTag(data).then(res=>{
            this.getTagList(`tagTypeItems=${this.state.type}`)
        })
    }
    componentDidMount() {
        let obj = `tagTypeItems=${this.state.type}&pageNum=${1}&pageSize=${10}`
        this.getTagType()
        this.getTagList(obj)
    }
    componentWillMount(){
        let obj = `tagTypeItems=${this.state.type}&pageNum=${1}&pageSize=${10}`
        this.getTagType()
        this.getTagList(obj)
    }
   
    render() {
        const { data } = this.state;
        const defaultValue = this.state.defaultValue
        return (
            <div> 
                <div style={{display:'flex',marginTop:15,marginBottom:20}}>
                <Select   onChange={this.handleChangeSelect.bind(this)} style={{ width: 300,marginLeft:20 }} placeholder={defaultValue}>
                    {children}
                 </Select>
                 <Input allowClear value={this.state.inpValue} onChange={this.tagChange.bind(this)}  placeholder="请输入标签"   style={{marginLeft:20,display:'inline-block',width:200}} />
                 <Button style={{marginLeft:20,display:this.state.isNewButton }} type='primary' onClick={this.submit.bind(this)}>新增</Button>
                </div>
                <Table 
                pagination={false}
                dataSource={data} 
                columns={this.state.columns} 
                rowKey="index"
                >
                </Table>
            </div>
        )
    }
}
export default Tag;