import React,{Component,useState,useReducer } from 'react'
import {message,Button, Form, Input, Upload, Space} from 'antd'
import { LoadingOutlined, PlusOutlined ,UploadOutlined} from '@ant-design/icons';
import ImagesUpload from './image-upload-banner'
import BasicUpload from '@/components/upload'
import BraftEditor from 'braft-editor'
import API from '@/fetch/api'

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={
        img:'',
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        formStyle:{
            display:'flex',
            flexDirection:'column',
            flexWrap:'wrap'
        },
        uploadConfig: {
            action: 'https://up-z2.qiniup.com',
            listType: 'picture',
            accept: 'image/*',
            multiple: false,
            fileList: this.props.data.fileList,
            data: { token: '' },
            type: 'image',
            name: 'file',
            limit: 1,
            maxCount:2,
            title: '上传图片'
        },
        
    }
    
    submitForm=(e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(values,'submitForm',this.state.img)
           if(this.state.img.length != 0) {
                values.img = this.state.img.toString()
            }else{
                values.img =  values.img.toString()
            }
            values.content = this.state.outputHTML
            if (!err) {
                this.props.submit(values)
            }
        });
    }
    componentWillMount(){
        this.getQi()  
    }
    // 初始化页面
    componentDidMount() {
        // console.log(this.props.data,this.props.data.id)
        this.getQi()
        let data = this.props.data
        data.content = BraftEditor.createEditorState(data.content)
        this.props.form.setFieldsValue(data)
     }
    submit=()=> {
        this.submitForm()
    }
    handleChange = (key, event) => {
        // console.log(key,event.target.value,this.state.form)
        let form = this.state.form
        for (let item in this.state.form) {
            if (item === key) {
                form[item] = event.target.value
                this.setState({form: form})
          }
      }
    }
    
     // 富文本
    changeRichText=(editorState) => {
    // 在点击文章内容的时候执行
    // console.log(editorState,'editorState',editorState.toHTML)
    this.setState({
        editorState: editorState,
        outputHTML: editorState.toHTML()
    })
    }
   
    // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                this.setState({
                    uploadConfig:obj
                })
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data) {
        data = [...data];
        console.log('data', data)
        let title = [];
        let saveImg = [];
        data.forEach((fileInfo, index) => {
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }else{
                return saveImg.push(fileInfo.url)
            }
        })
        this.setState({
            img:saveImg.concat(title).toString(),
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form  onSubmit={this.submitForm} style={this.state.formStyle} >
                <Form.Item {...formItemLayout} labelCol={{span:3}} style={{width:"100%"}} label="资讯名称">
                    {getFieldDecorator('name', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    },
                    ],
                })(<Input onChange={this.handleChange.bind(this,'name')} placeholder="请输入资讯内容" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} labelCol={{span:3}} style={{width:"100%"}} label="资讯图片">
                    {getFieldDecorator('img', {
                        rules: [
                        {
                            required: false,
                            message: '内容不能为空',
                        },
                        ],
                    })(
                        <BasicUpload 
                        {...this.state.uploadConfig} 
                        componentType={this.props.componentType}
                        getUrlList={this.getUrlList.bind(this)} 
                      ></BasicUpload>)
                    }
            
                </Form.Item>
                <Form.Item {...formItemLayout} style={{width:"100%"}} labelCol={{span:3}} wrapperCol={{span:24}}  label="资讯内容" >
                {getFieldDecorator('content', {
                    rules: [
                    {
                        required: false,
                        message: '内容不能为空',
                    }
                    ],
                })( <BraftEditor
                    style={{height:'100%',margin:"10px",width:"80%",paddinf:"5px",border:"1px solid #CCC"}}
                    // value={this.state.editorState}
                    onChange={this.changeRichText}
                    />)}
                </Form.Item>
                <Form.Item style={{
                   width: 'calc(100% + 48px)',
                   marginLeft:'-24px',
                   marginBottom:'0',
                   paddingTop:'3px',
                   justifyContent: 'flex-end',
                   paddingRight: '24px',
                   borderTop:'1px solid #ebebeb'}}>
                    <Button onClick={this.props.onClose} style={{marginRight:'10px'}}>取消</Button>
                    <Button htmlType='submit' type='primary' >确定</Button>
                </Form.Item>
            </Form>
        )
    }
}
const RealTimeForm =Form.create({name:'edit'})(RawForm)
export default RealTimeForm;