import React,{Component} from 'react'
import {Button} from 'antd'
class OrderBatch extends Component {
    render() {
        return(
            <div>
                订单批量发货
            </div>
        )
    }
}
export default OrderBatch;