import React,{Component} from 'react'
import {Button,message} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import actions from '@/store/actions'
import BasicTable from '../../components/Table'
import ListModal from '../../components/modal_list'
import EditModal from '../../components/modal_edit'
import SearchHeader from '../../components/search_header'
import API from '@/fetch/api'
class Repairman extends Component {
    state={
        pageNum:'1',
        pageSize:'10',
        total:1,
        data:[],
        currentEngineer:{},
        columns:[
            { title: '人员', dataIndex: 'name', key: 'name' },
            { title: '手机', dataIndex: 'phone', key: 'phone' },
            { title: '职能', dataIndex: 'position', key: 'position' },
            { title: '属性', dataIndex: 'category', key: 'category' },
            { title: '地区', dataIndex: 'district', key: 'district' },
            { title: '时间', dataIndex: 'time', key: 'time' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                    {/* <span>
                        <Button onClick={() => this.checkMan(record)}>业绩</Button>
                    </span> */}
                    <span>
                        <Button  style={{marginLeft:'10px'}}  type='primary' onClick={() => this.eiditMan(record)}>编辑</Button>
                    </span>
                    <span>
                        <Button  style={{marginLeft:'10px'}} type='danger' onClick={() => this.delMan(record)}>删除</Button>
                    </span>
                    </div>
                )
            },
        ],
        row:{
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },

        },
        listStatus:false,
        editStatus:false,
        manData:[ ],
        manColumns:[
            { title: '人员', dataIndex: 'title', key: 'title' },
            { title: '地址', dataIndex: 'address', key: 'address' },
            { title: '日期', dataIndex: 'date', key: 'date' },
            { title: '联系人', dataIndex: 'contact', key: 'concact' }
        ]
    }
    checkMan(data) {
        console.log(data,this)
        this.getDetail(data.id)
        this.setState({
            listStatus:true
        })
    }
    // 点击执行时删除
    delMan(data){
        console.log(data.id)
        API.delEngineer(data.id).then(res=>{
            // 删除之后重新获取数据
            if(res.code == 200){
                message.success('删除成功')
                this.getList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
            }else{
                message.error('删除失败')
            }
            
            // console.log(res)
        })
    }
    eiditMan(data) {
        console.log(this.refs.refs,'edit')
        this.refs.edit.initial()
        this.setState({
            editStatus:true,
            currentEngineer:data
        })
    }
    changePage(page) {
        console.log('1312312421',page)
        this.setState({
            pageNum:page
        })
        this.getList({pageNum:page,pageSize:this.state.pageSize})
    }
    onClose() {
        this.setState({
            listStatus:false,
            editStatus:false,
            currentEngineer:{}
        })
    }
    searchHandler(data) {
        console.log(data,'repairman')
        let obj ={}
        obj.pageNum = this.state.pageNum
        obj.pageSize = this.state.pageSize
        obj.name = data['姓名'] || ""
        obj.phone=data['联系方式'] || ""
        if(obj.phone || obj.name  ){
            this.getList(obj)
        }else{
            message.warning('请输入姓名或手机号！')
        }
    }
    refreshHandler(){
        this.getList(`pageNum=${this.state.pageNum}`)
        message.info('刷新成功')
    }
    getList(data) {
        API.engineerList(data).then(res=>{
            console.log(res,'工程师列表1111')
            if(res.code == 200) {
                if(res.data.result.length == 0){
                    message.warning('无相关数据……')
                }
                this.setState({
                    data:res.data.result,
                    total:res.data.totalCount
                })
            }
            // console.log(this.state.total,'haahahah s')
        })
    }
    getDetail(data) {
        console.log('enter')
        API.engineerDetail(data).then(res =>{
            console.log(res)
        })
    }
    componentWillReceiveProps() {
        console.log(this.props.list)
    }
    componentWillMount() {
        console.log(this,'thisss',actions)
        // this.props.getList('GET_ENIGINEER_LIST')
        this.getList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
    }
    add() {
        console.log('新增维修工')
        console.log('enter add ')
        this.refs.edit.initial()
        this.setState({
            editStatus:true
        })
    }
    submit(data) {
        if(this.state.currentEngineer.id) {
            data.id = this.state.currentEngineer.id
            data.jobNumber = this.state.currentEngineer.jobNumber
            API.updateEngineer(data).then(res =>{
                console.log(res,'update,engineer')
                if(res.code == 200){
                    message.success('修改成功');
                    this.onClose()
                    this.getList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
                }else{
                    message.error('修改失败');
                }
            })
        } else {
            API.addEngineer(data).then(res =>{
                console.log(res,'update,engineer')
                if(res.code == 200){
                    message.success('添加成功')
                    this.onClose()
                    this.getList({pageNum:this.state.pageNum,pageSize:this.state.pageSize})
                }else{
                    message.error(res.message)
                    this.onClose()
                }
            })
        }
        
    }
    
    render() {
        return (
            <div>
                <div style={{margin:'20px'}}><Button onClick={() =>this.add()}>新增</Button></div>
                <SearchHeader 
                    title="维修人员管理" 
                    firstKey="姓名" 
                    secondKey="联系方式"
                    headerType='refresh-header' 
                    searchHandler={this.searchHandler.bind(this)} 
                    refreshHandler={this.refreshHandler.bind(this)}
                    onAdd={this.add.bind(this)}>
                    </SearchHeader>
                    <BasicTable 
                    data={this.state.data} 
                    columns={this.state.columns} 
                    row={this.state.row} 
                    changePage={this.changePage.bind(this)}
                    total={this.state.total} 
                    rowKey='id'>
                    </BasicTable>
              <EditModal submit={this.submit.bind(this)} data={this.state.currentEngineer} visible={this.state.editStatus} onClose={this.onClose.bind(this)} type='maintainer' ref='edit'></EditModal>
            </div>
        )
    }
}
function mapGetter(state) {
    return {
        list:state
    }
}
function mapAction(dispatch) {
    return {
        getList:bindActionCreators(actions.getEngineerList,dispatch)
    }
}
export default connect(mapGetter,mapAction)(Repairman);