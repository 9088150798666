import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, DatePicker } from 'antd'
import BasicTable from '../../components/Table'
import SearchHeader from '../../components/search_header'
import API from '@/fetch/api'
import Tool from '@/global/tool'
class Complaint extends Component {
    componentDidMount() {
        // const str = "javasctipt is the best language in the world"
        this.getList('')
    }
    state = {
        data: [
        ],
        columns: [
            { title: '用户昵称', dataIndex: 'username', key: 'username' },
            { title: '联系方式', dataIndex: 'phone', key: 'phone' },
            { title: '投诉时间', dataIndex: 'createdTime', key: 'createdTime' },
            { title: '投诉内容', dataIndex: 'serviceComplaint', key: 'serviceComplaint' },
            { title: '状态', dataIndex: 'time', key: 'time' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                        <span>
                            <Button onClick={() => this.checkCustom(record)}>处理</Button>
                        </span>
                    </div>
                )
            },
        ],
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },
        },
        total: 1,
        searchObj: {}
    }
    checkCustom(data) {
        console.log(data, 'custom data')
    }
    getList(data) {
        API.complaintList(data).then(res => {
            console.log(res)
            if (res.code == 200) {
                console.log(this, 'this')
                let list = res.data.result
                list.forEach(item => {
                    item.createdTime = Tool.timeChecker(item.createdTime)
                })
                this.setState({
                    data: res.data.result,
                    total: res.data.totalCount
                })
            }
        })
    }
    commitSearch() {
        this.getList(this.state.searchObj)
    }
    getValue = (e) => {
        let data = this.state.searchObj
        data.username = e.target.value
        this.setState({
            searchObj: data
        })
    }
    getPhone = (e) => {
        let data = this.state.searchObj
        data.phone = e.target.value
        this.setState({
            searchObj: data
        })
    }
    getDate = (date, time) => {
        let data = this.state.searchObj
        data.startTime = time[0]
        data.endTime = time[1]
        this.setState({
            searchObj: data
        })
    }
    getStatus(e) {
        console.log(e)
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                    {/* <p style={{width:"20%"}}>{this.props.title}</p> */}
                    <div className="flex-row" style={{ width: "20%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>姓名:</p><Input onChange={this.getValue} className="inline-input" width={200}></Input></div>
                    <div className="flex-row" style={{ width: "20%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>电话：</p><Input onChange={this.getPhone} className="inline-input" width={200}></Input></div>
                    <div className="flex-row" style={{ width: "20%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>投诉时间:</p><DatePicker.RangePicker className="RangePicker" onChange={this.getDate}></DatePicker.RangePicker></div>
                    {/* <div className="flex-row" style={{ width: "15%" }}> <p style={{ maxWidth: 100, minWidth: 60, marginBottom: 0 }}>处理状态:</p>
                        <Select className="inline-input" defaultValue='0' onChange={this.getStatus}>
                            <Select.Option value='1'>未处理</Select.Option>
                            <Select.Option value='2'>已处理</Select.Option>
                            <Select.Option value='0'>所有</Select.Option>
                        </Select></div> */}
                    {/* {<div ><Button onClick={() => this.props.searchHandler}>刷新</Button></div>} */}
                    {/* <div><Button onClick={() => this.addPart()}></Button></div> */}
                    <div style={{ width: "10%" }}><Button onClick={() => this.commitSearch()}>搜索</Button></div>
                </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} total={this.state.total} rowKey='id'></BasicTable>
            </div>

        )
    }
}
export default connect()(Complaint)