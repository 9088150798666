import React, { Component } from "react"
import { connect } from "react-redux"
import { Upload, Button, Icon, message, Input } from "antd"
import BasicTable from "../../components/Table"
import Tools from "../../global/tool"
import SearchHeader from "../../components/search_header"
import API from "@/fetch/api"
const { Search } = Input
class Comments extends Component {
	state = {
		data: [],
		columns: [
			{ title: "编号", dataIndex: "merchandiseName", key: "merchandiseName" },
			{ title: "评分", dataIndex: "score", key: "score", width: 80 },
			{ title: "姓名", dataIndex: "username", key: "username"},
			{ title: "手机号", dataIndex: "phone", key: "phone" },
			{ title: "日期",dataIndex: "createdTime",key: "createdTime",width: 180,},
			{ title: "内容", dataIndex: "comment", key: "comment", width: 100 },
			{ title: "时间", dataIndex: "time", key: "time", width: 80 },
			{
				title: "操作",
				dataIndex: "action",
				key: "action",
				render: (text, record) => (
					<div>
						<span>
							{/* <Button onClick={() => this.checkManager(record)}>查看</Button> */}
							<Button
								style={{ marginLeft: "10px" }}
								type="danger"
								onClick={() => this.deleteCommon(record)}
							>
								删除
							</Button>
						</span>
					</div>
				),
			},
		],
		row: {
			onChange: (selectedRowKeys, selectedRows) => {
				console.log(
					`selectedRowKeys: ${selectedRowKeys}`,
					"selectedRows: ",
					selectedRows
				)
			},
			onSelect: (record, selected, selectedRows) => {
				console.log(record, selected, selectedRows)
			},
			onSelectAll: (selected, selectedRows, changeRows) => {
				console.log(selected, selectedRows, changeRows)
			},
		},
		initColumn: [
			{
				title: "商品名称",
				dataIndex: "title",
				key: "title",
				className: "text-monospace",
			},
			{
				title: "编号",
				dataIndex: "address",
				key: "address",
			},
			{
				title: "数量",
				dataIndex: "date",
				key: "date",
			},
			{
				title: "销量",
				dataIndex: "contact",
				key: "contact",
			},
		],
		total: 18,
		searchObj: {
			pageNum: 1,
			keyword: "",
			merchandiseId: "",
			userId: "",
		},
	}
	getExcel() {
		Tools.exportExcel(this.state.initColumn, this.state.data, "测试excel.xlsx")
	}
	loadExcel(event) {
		Tools.importExcel(event).then(res => {
			console.log(res)
		})
	}
	checkManager(data) {
		console.log(data, "check manager ")
		this.props.history.push({
			pathname: "/app/goods/comments/detail/" + data.key + "?count=" + data.key,
		})
	}
	changePage = page => {
		console.log(page)
		this.setState({
			page: page,
		})
		this.getList({ page: page })
	}
	deleteCommon(data) {
		API.delComment(data.id).then(res => {
			if (res.code == 200) {
				this.getList(this.state.searchObj)
				message.success("删除成功")
			} else {
				message.error("删除失败")
			}
		})
	}
	refreshHandler() {
		this.getList(`page=${this.state.searchObj.pageNum}`)
		message.info("刷新成功")
	}
	getList(data) {
		API.goodsComments(data).then(res => {
			if (res.code == 200) {
				let list = res.data.content
				list.forEach(item => {
					item.createdTime = Tools.timeChecker(item.createdTime)
					item.score = item.score + "分"
				})
				this.setState({
					data: res.data.content,
					total: res.data.totalElements,
				})
			}
		})
	}
	onSearch(value) {
		console.log(value)
		let obj = { ...this.state.searchObj }
		obj.keyword = value
		this.getList(obj)
	}
	componentDidMount() {
		this.getList(this.state.searchObj)
	}
	render() {
		return (
			<div>
				<div className="flex-row" style={{ margin: "20px" }}>
					<p>商品名称：</p>
					<Search
						placeholder="请输入商品名称"
						allowClear
						onSearch={this.onSearch.bind(this)}
						style={{ width: 200 }}
					/>
				</div>
				<BasicTable
					data={this.state.data}
					columns={this.state.columns}
					row={this.state.row}
					total={this.state.total}
					changePage={this.changePage.bind(this)}
				></BasicTable>
				{/* <Button onClick={() => this.getExcel()}>
                    <Icon type="download"/>下载
                </Button>
                <Upload type="file" accept=".xlsx, .xls" onChange={(event) => this.loadExcel(event)}>
                    <Button>
                        <Icon type="upload"/>上传
                    </Button>
                </Upload> */}
			</div>
		)
	}
}
export default connect()(Comments)
