import React from 'react'
import { Tabs,Form,Radio,Input,DatePicker,Button,Select,message,Modal,Popconfirm } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import API from '@/fetch/api'
import ImagesUpload from '@/components/image-upload'
import Style from './addSeckill.module.css'
moment.locale('zh-cn');
const { TabPane } = Tabs;
const { RangePicker } = DatePicker
const { Option } = Select;

class Demo extends React.Component {
    flag = false
    state = {
        size: 'default',
        checkNick: false,
        userData:[],
        userOnSearchValue:'',
        awardType:"空",
        typeValue:"",
        goodsData:[],
        wxUserIds:[],
        isLowAward:false,
        isOneWinning:false,
        count:"",
        awardImg:"",
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            accept:'image/*',
            multiple:false,
            data:{token:''},
            type:'image',
            name:'file',
            fileList: [],
            limit: 1,
            // multi_selection: false
        },
        awardTypeGoods:"",
        awardTypeJifen:"",
        jifenInput:true,
        goodsSelect:true,
        awardConfig: {},
        val:""
    };
    handleSubmit = e => {
        
        // console.log(1111)
        // e.preventDefault();
        // this.props.form.validateFields((err, values) => {
        // if (!err) {
        //     console.log('Received values of form: ', values);
        //     this.props.content(values)
        // }
        // });
    };
    check = () => {
        this.props.form.validateFields(err => {
            if (!err) {
            console.info('success');
            }
        });
    };
    getList(data){
        // 获取用户
        API.getAllUser(data).then(res => { 
            if (res.code === 200 ) {
                // console.log(res, 'this11111')
                this.setState({
                    userData: res.data,
                })
                // children = res.data.map(item => (
                //     <Option key={item.realname}>{item.realname?item.realname+","+item.phone+","+item.address:[]}</Option>
                // ))
            }
        })
        // 获取优惠券
        API.couponList({size:1000,page:1}).then(res=>{
            // console.log(res)
            this.setState({
                goodsData: res.code === 200 ? res.data : []
            })
        })
    }
    // 奖项类型多选框
    onChangeRadio(e){
        // console.log(this.state.awardType)
        // console.log(e || e.target.value)
        if(e.target){
            this.setState({
                awardType:e.target.value
            })
            if(e.target.value === "优惠券"){
                this.setState({
                    typeValue:this.state.goods,
                    goodsSelect:false,
                    jifenInput:true,
                })
            }else if(e.target.value === "积分"){
                this.setState({
                    typeValue:this.state.jifen,
                    jifenInput:false,
                    goodsSelect:true
                })
            }else{
                this.setState({
                    jifenInput:true,
                    goodsSelect:true
                })
            }
        }else{
            this.setState({
                awardType:e
            })
            if(e === "优惠券"){
                this.setState({
                    typeValue:this.state.goods,
                    goodsSelect:false,
                })
            }else if(e === "积分"){
                this.setState({
                    typeValue:this.state.jifen,
                    jifenInput:false,
                })
            }else{
                this.setState({
                    goodsSelect:true,
                    jifenInput:true,
                })
            }
        }
        
        // console.log(e.target.value)
        
        // console.log(this.state.typeValue)
    }
    // 奖项为优惠券
    goodsClick(){
        // console.log(e)
        this.props.form.setFieldsValue({
            // awardTypeGoods:"",
            awardTypeJifen:"",
        })
    }
    goodsChange(e){
        // console.log(e)
        this.setState({
            awardTypeGoods:e,
            awardTypeJifen:'',
        })
    }
    // 奖项为积分
    jifenClick(){
        // console.log(e)
        this.props.form.setFieldsValue({
            awardTypeGoods:"",
            // awardTypeJifen:"",
        })
    }
    jifenChange(e){
        // console.log(e)
        // this.state.awardType.push("积分")
        // this.state.awardType.push(e.target.value)
        this.setState({
            awardTypeGoods:"",
            awardTypeJifen:e.target.value,
        })
        // this.onChangeRadio("积分")
    }
    // 奖项数量
    handleChangeCount(e){
        // console.log(e.target.value)
        this.setState({
            count:e.target.value
        })
    }
    // 选择中奖用户
    handleChange(value) {
        // console.log("=============",value)
        // var data = []
        // for(var i = 0;i<value.length;i++){
        //     let index = this.state.userData.findIndex(x=>x.realname === value[i])
        //     // console.log(this.state.userData[index].id)
        //     data.push(this.state.userData[index].id)
        // }
        // console.log(data)
        // this.props.form.setFieldsValue({
        //     wxUserIds:data
        // })
        this.setState({
            wxUserIds:value
        },()=>{
            // console.log("wxUserIds",this.state.wxUserIds)
        })
        if(value.length <= this.state.count){
        }else{
            message.error("中奖用户数量不得大于奖项数量")
        }
    }
    componentDidMount(){
        this.getQi()
        if(this.props.award)this.editActivityRender()
        this.getList()
        
    }
    componentWillReceiveProps(props, next){
        // console.log("+++++++++++",this.state.val)
        const {isFinish} = props
        // console.log(isFinish)
        // console.log(this.flag)
        if (isFinish && !this.flag) {
            this.props.form.validateFields((err, values,index) => {
                console.log("values",values,index)
                if (!err) {
                    this.flag = true
                    // console.log('Received values of form: ', values);
                    this.props.content(values)
                }
            })
        }
    }

    // 每个用户只可获奖一次
    isOneWinning(e){
        // console.log(e.target.value)
        this.setState({
            isOneWinning:e.target.value
        })
    }
    // 是否设为最低奖
    isLowAward(e){
        // console.log(e.target.value)
        this.setState({
            isLowAward:e.target.value
        })
    }
    // 从七牛云获取图片token
    getQi() {
        API.QiAuth().then(res => {
            // console.log(res)
            if (res.code === 200) {
                let obj = this.state.uploadConfig
                obj.data = { token: res.data }
                this.setState({
                    uploadConfig: obj,
                })
            }
        })
    }
    // 获取图片地址
    getUrlList(data){
        // console.log(data, 'getUrlList')
        data.forEach(item =>{
            // debugger;
            // console.log(item)
            if(item.response && item.response.hash){
                this.props.form.setFieldsValue({
                    awardImg : 'http://cdn.huikeji.shop/' + item.response.hash
                })
                this.setState({
                    uploadConfig: {
                        ...this.state.uploadConfig,
                        fileList: data
                    }
                })
            }
            // console.log(this.state.awardImg)
        })
    }
    getHash(data) {
        if(data.hash){
            const awardImg = 'http://cdn.huikeji.shop/' + data.hash
            this.setState({
                uploadConfig: {
                    ...this.state.uploadConfig,
                    fileList: [{
                        url: awardImg,
                        uid: '-1'
                    }]
                }
            })
            this.props.form.setFieldsValue({
                awardImg : 'http://cdn.huikeji.shop/' + data.hash
            })
            // console.log(this.state.awardImg)
        }
    }
    // 点击空单选框
    clickRadio(){
        this.props.form.setFieldsValue({
            awardTypeGoods:"",
            awardTypeJifen:"",
        })
    }
    // 编辑回显
    editActivityRender() {
        const {award, form} = this.props
        this.setState({
            awardType:award.awardType,
            count:award.count,
            wxUserIds:award.wxUserIds
        })
        if(award.awardType==="优惠券"){
            this.setState({
                goodsSelect:false,
                awardTypeGoods:award.typeValue,
                jifenInput:true,
            })
        }else if(award.awardType==="积分"){
            this.setState({
                awardTypeJifen:award.typeValue,
                goodsSelect:true,
                jifenInput:false,
            })
        }
        form.setFieldsValue({
            ...award,
            awardTypeGoods:award.awardType === "优惠券" ? award.typeValue : "",
            awardTypeJifen:award.awardType === "积分" ? award.typeValue : "",
            awardImg:award.awardImg,
            wxUserIds:award.wxUserIds?award.wxUserIds:[]
        })
        this.setState({
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: award.awardImg ?  [{
                    url: award.awardImg,
                    uid: '-1'
                }]: []
            }
        })
    }
    onSearch(val){
        // console.log(val,"val")
        API.getAllUser({str:val}).then(res => {
            if(res.code === 200){
                this.setState({
                    userData:res.data
                })
                // console.log(this.state.userData)
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        // console.log(this.state.userData)
        // console.log(this.state.awardTypeGoods,this.state.awardTypeJifen)
        const renderItemUser=(item) =>{
            return (
                <Select.Option value={item.id} key={item.id}>
                    {item.realname?item.realname+","+item.phone+","+item.address:[]}
                </Select.Option>
            )
        }
        const renderItemGoods=(item) =>(
            <Select.Option value={item.seqNo} key={item.seqNo}>{item.name ? item.seqNo +" "+ item.name:''}
            </Select.Option>
        )
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <div>
                <Form.Item label="奖项ID：">
                {getFieldDecorator('id')(<Input disabled style={{display:'inline-block',width:600}} />)}
                </Form.Item>
                </div>
                <div>
                <Form.Item label="奖项名称：">
                {getFieldDecorator('awardName', {
                    rules: [
                    {
                        required: true,
                        message: '请输入奖项名称',
                    },
                    ],
                })(<Input style={{display:'inline-block',width:600}} />)}
                </Form.Item>
                <span style={{display:"block",marginLeft:114,marginBottom:20,color:"red",fontSize:12}}>奖项名称最多输入十个字符</span>
                </div>
                <div>
                <Form.Item label="奖项类型：">
                <Radio.Group onChange={this.onChangeRadio.bind(this)} value={this.state.awardType}>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Radio onClick={this.goodsClick.bind(this)} value={"优惠券"}>优惠券</Radio> 
                {getFieldDecorator('awardTypeGoods')(
                    <Select
                    showSearch
                    disabled={this.state.goodsSelect}
                    style={{ display:'inline-block', width: 520,marginTop:"10px" }}
                    optionFilterProp="children"
                    onChange={this.goodsChange.bind(this)}
                    >
                        {this.state.goodsData.map(item =>(
                            renderItemGoods(item)
                        ))}
                    </Select>
                )}
                </div>
                
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Radio value={"积分"} onClick={this.jifenClick.bind(this)} style={{ marginTop:"10px",marginBottom:"10px" }}>积分</Radio>
                {getFieldDecorator('awardTypeJifen')(
                    <Input disabled={this.state.jifenInput} onChange={this.jifenChange.bind(this)} style={{width:520}}></Input>
                )}</div>
                <Radio value={"空"} onClick={this.clickRadio.bind(this)}>空</Radio>
                    </Radio.Group>
                </Form.Item>
                </div>
                <div>
                <Form.Item label="奖项图片" style={{width:'650px'}}>
                {getFieldDecorator('awardImg')(<ImagesUpload {...this.state.uploadConfig}  getUrlList={this.getUrlList.bind(this)} getHash={this.getHash.bind(this)}>添加</ImagesUpload>)}
                <span style={{color:"red",fontSize:12}}>图片建议像素为150px*150px，格式为jpg/png/bmp/gif</span>
                </Form.Item>
                </div>
                <div>
                <Form.Item label="奖项数量：">
                {getFieldDecorator('count', {
                    rules: [
                    {
                        required: true,
                        message: '请输入奖项数量',
                    },
                    ],
                })(<Input onChange={this.handleChangeCount.bind(this)} style={{display:'inline-block',width:600}} />)}
                </Form.Item>
                </div>
                <div>
                <Form.Item label="设置中奖用户">
                    {getFieldDecorator('wxUserIds')(<Select 
                        mode="multiple" 
                        placeholder='请选择用户'
                        style={{width:'600px'}}
                        filterOption={false}
                        onChange={this.handleChange.bind(this)}
                        onSearch={this.onSearch.bind(this)}
                    >
                        {this.state.userData.map(item =>(
                            renderItemUser(item)
                        ))}
                        {/* {children} */}
                    </Select>)}
                </Form.Item>

                </div>
                <div>
                <Form.Item label="每个用户只可获奖一次：">
                    {getFieldDecorator('isOneWinning', {initialValue: this.state.isOneWinning})(
                        <Radio.Group onChange={this.isOneWinning.bind(this)} style={{width:'800px'}}>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                </div>
                <div>
                <Form.Item label="设为最低奖项：">
                {
                    getFieldDecorator('isLowAward', {
                        initialValue: this.state.isLowAward
                    })(
                    <Radio.Group style={{width:'850px'}} onChange={this.isLowAward.bind(this)} value={this.state.isLowAward}>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>)
                    }
                </Form.Item>
                </div>
            </Form>
            </div>
        );
    }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(Demo);

class Seckill extends React.Component  {
    state = {
        activityType: "大转盘",
        activityImg:"",
        activityName:"",
        isRestrict:false,
        dayLotteryCount:0,
        lotteryCount:0,
        winningCount:0,
        dayWinningCount:0,
        beginDate:"",
        endDate:"",
        explains:"",
        awards:[],
        awardsCopy:[],
        actvityData:{},
        activityStatus:"",
        activeKey: "1",
        panes: [],
        disabled:false,
        vips:"",
        key: 'tab1',
        number:"",
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            accept:'image/*',
            multiple:false,
            fileList: [],
            data:{token:''},
            type:'image',
            name:'file',
            limit: 1,
            // multi_selection: false
        },
        EBNum:5,
        isFinish: false,
        visible: false,
        memberLevels:[],
        signedStartTime: "",
        signedEndTime: "",
        deliveryStartTime: "",
        deliveryEndTime: "",
        restricts:[],
        allAreas:[],
        areas:[],
        index:'',
        sellType:undefined
    };
    newTabIndex = 0;
    
    componentDidMount() {
        this.getQi()
        // console.log(11111)
        const id = this.props.query.count
        // console.log(id)
        // 编辑
        if (id) this.editActivity(id)
        this.getAllAreas()
    }
    // 活动形式的选择
    onChange = e => {
        // console.log('radio checked', e.target.value);
        this.setState({
            activityType: e.target.value,
        });
    };
    // 会员是否开启
    valueVIPChange= e => {
        // console.log('radio checked', e.target.value);
        this.setState({
            isRestrict: e.target.value,
        });
        if(e.target.value === false){
            this.setState({
                vips: '',
            });
        }
        
    };
    // 活动标题的输入
    inputChange(e){
        this.setState({
            activityName: e.target.value
        })
        // console.log(e.target.value)
        // console.log(this.state.inputValue)
    }
    callback(key) {
        // console.log(key);
    }
    // 活动说明
    textAreaChange(e){
        // console.log(e.target.value)
        this.setState({
            explains:e.target.value
        })
    }

    /* 奖项设置部分 */
    onTabChange = (key, type) => {
        // console.log(key, type);
        this.setState({ [type]: key });
    };

    onChangePrize = activeKey => {
        // console.log(activeKey)
        this.setState({ activeKey });
    };
    
    onEdit = (targetKey, action) => {
        // console.log(action,targetKey,"action")
        this[action](targetKey);
    };
    
    
    add = (e) => {
        // console.log(e)
        const { panes,awards } = this.state;
        // console.log(panes,"panes")
        let num =  Math.random() 
        // console.log(num,"num")
        if(this.props.query.count){
            this.setState({ 
                awards: [...awards,num + ""],
                activeKey: num + ""
            })
            console.log("activeKey",this.state.activeKey)
        }else{
            this.setState({ 
                panes: [...panes,num + ""],
                activeKey: num + ""
            })
        }
        // console.log(this.state.panes) // []
    };
    
    remove = targetKey => {
        let { activeKey } = this.state;
        let lastIndex;
        // console.log("targetKey",targetKey)
        // console.log("activeKey",activeKey)
        if(this.props.query.count){
            // if(targetKey>=1){
            //     let data = this.state.awards.filter(x=>x.id != this.state.awards[targetKey-1].id)
            //     let arr = this.state.awardsCopy.filter(x=>x.id != this.state.awardsCopy[targetKey-1].id)
            //     // console.log(arr)
            //     this.setState({
            //         awards:data,
            //         awardsCopy:arr
            //     },()=>{
            //         console.log("awards",this.state.awards)
            //         console.log("awardsCopy",this.state.awardsCopy)
            //     })
            // }else{
            //     let array = this.state.awards
            //     const index = array.findIndex(pane => {
            //         return pane.toString() === targetKey.toString()
            //     })
            //     array.splice(index,1)
            //     this.setState({
            //         awards:array
            //     })
            // }
            /** 我是分隔线 */
            // this.state.awards.forEach((pane, i) => {
            //     if(pane.id.toString() === targetKey.toString() ){
            //         lastIndex = i - 1;
            //     }else if(pane.toString() === targetKey.toString()) {
            //         // console.log("+++++++")
            //         lastIndex = i - 1;
            //     }
            // });
            // const awards = this.state.awards.filter(pane => {
            //     if(pane.id){ 
            //         return pane.id.toString() !== targetKey.toString() 
            //     } else { 
            //         return pane.toString() !== targetKey.toString()
            //     }
            // })
            // const index = awards.findIndex(pane => {
            //     if(pane.id){ 
            //         return pane.id.toString() === targetKey.toString()
            //     } else { 
            //         return pane.toString() === targetKey.toString()
            //     }
            // })
            // console.log(index)
            // awards.splice(index, 1)
            // console.log(awards)
            // if (awards.length && activeKey === targetKey) {
            //     if (lastIndex >= 0) {
            //         activeKey = awards[lastIndex].id;
            //     } else {
            //         activeKey = awards[0].id;
            //     }
            // }else{
            //     activeKey = this.state.activeKey;
            // }
            // // console.log(panes)
            // this.setState({ 
            //     awards,
            //     activeKey, 
            //     awardsCopy:awards
            // });
        }else{
            this.state.panes.forEach((pane, i) => {
                // console.log(pane)
                // console.log(i)
                if (pane === targetKey) {
                    // console.log("+++++++")
                    lastIndex = i - 1;
                }
            });
            const panes = this.state.panes.filter(pane => pane !== targetKey);
            if (panes.length && activeKey === targetKey) {
                if (lastIndex >= 0) {
                    activeKey = panes[lastIndex];
                } else {
                    activeKey = panes[0];
                }
            }else{
                activeKey = this.state.activeKey;
            }
            // console.log(panes)
            this.setState({ panes, activeKey });
        }
    };
    dateChange(current){
        return current && current <moment().subtract(1, "days");
    }

    // 图片上传代码
    getQi() {
        // console.log(111)
        API.QiAuth().then(res => {
            // console.log(res)
            if (res.code === 200) {
                let obj = this.state.uploadConfig
                obj.data = { token: res.data }
                this.setState({
                    uploadConfig: obj,
                })
            }
        })
        // console.log(this.state.uploadConfig)
    }
    // 获取图片地址
    getUrlList(data){
        data = [...data]
        // console.log(data)
        // console.log(this.state.uploadConfig)
        data.forEach(item =>{
            // debugger;
            // console.log(item)
            if(item.response && item.response.hash){
                this.setState({
                    activityImg : 'http://cdn.huikeji.shop/'+item.response.hash,
                    uploadConfig: {
                        ...this.state.uploadConfig,
                        fileList: data
                    }
                })
            }
            // console.log(this.state.activityImg)
        })
    }
    getHash(data) {
        if(data.hash){
            const activityImg = 'http://cdn.huikeji.shop/' + data.hash
            this.setState({
                activityImg : 'http://cdn.huikeji.shop/' + data.hash,
                uploadConfig: {
                    ...this.state.uploadConfig,
                    fileList: [{
                        url: activityImg,
                        uid: '-1'
                    }]
                }
            })
        }
    }
    async onChangeVIP(value){
        // console.log(value)
        await this.setState({
            vips:value
        })
        // console.log(a)
        // console.log(this.state.vips)
    }
    // 每人时间段内抽奖次数
    lotteryCount(value){
        // console.log(value)
    }
    LCInputChange(e){
        // console.log(e.target.value)
        this.setState({
            lotteryCount:e.target.value
        })
        // console.log(this.state.dayLotteryCount)
    }
    LCRadio(){
        this.setState({
            lotteryCount:0
        }) 
    }
    // 每人每日抽奖次数
    dayLotteryCount(value){
        // console.log(value)
    }
    DLCInputChange(e){
        // console.log(e.target.value)
        this.setState({
            dayLotteryCount:e.target.value
        })
        // console.log(this.state.dayLotteryCount)
    }
    DLCRadio(){
        this.setState({
            dayLotteryCount:0
        }) 
    }
    // 每人最多中奖次数
    EBNumChange(value){
        // console.log(value)
    }
    EBNumInputChange(e){
        // console.log(e.target.value)
        this.setState({
            winningCount:e.target.value
        })
    }
    EBNumRadio(){
        this.setState({
            winningCount:0
        }) 
    }
    // 每人每日中奖次数
    dayWinningCount(value){
        // console.log(value)
    }
    DWCInputChange(e){
        // console.log(e.target.value)
        if(e.target.value > this.state.winningCount){
            message.error("每日中奖次数不得大于每人中奖次数")
            this.setState({
                dayWinningCount:0
            })
        }
        this.setState({
            dayWinningCount:e.target.value
        })
    }
    DWCRadio(){
        this.setState({
            dayWinningCount:0
        }) 
    }
    // 时间
    getDate(value){
        // console.log(value)
        this.setState({
            beginDate : value[0] ? moment(value[0]._d).format('YYYY-MM-DD HH:mm:ss') : [],
            endDate: value[1] ? moment(value[1]._d).format('YYYY-MM-DD HH:mm:ss') : [],
        })
        // console.log(this.state.beginDate)
    }

    getValue(val){
        console.log("val",val)
        // console.log("this.state.awards",this.state.awards)
        // const arr = this.state.awards.filter((x => typeof x !== "string"))
        // arr.splice(index,1,val)
        // if(this.props.query.count){
        //     arr = arr.filter(x => typeof x !== "string")
        //     // let index = arr.findIndex((x => typeof x === "string"))
        //     // arr.splice(index,1)
        // }else{
        //     arr = this.state.awards
        // }
        // findIndex 会导致标签页被点击时就删除 且只能往awards里push一个数据
        // filter 删除正常，编辑作用无效，新增时数据都没有push进awards里
        // 什么都不用时，新增正常，编辑时新加奖项错误
        // let index = arr.findIndex((x => typeof x === "string"))
        // arr.splice(index,1)
        // const arr = array.filter((x => typeof x !== "string"))
        // console.log(val)
        const arr = this.state.awardsCopy
        if(val.awardTypeGoods){
            val.typeValue = val.awardTypeGoods 
            val.awardType = "优惠券"
        }else if(val.awardTypeJifen){
            val.typeValue = val.awardTypeJifen
            val.awardType = "积分"
        }else{
            val.awardType = "空"
            val.typeValue = ""
        } 
        // console.log("++++",val)
        if(val.id){
            let index = arr.findIndex(x => x.id === val.id)
            arr[index] = val
            // console.log("findIndex",arr)
        }else{
            arr.push(val)
        }
        // arr.push(val)
        // alert('ok')
        // console.log(arr, 'getvalue')
        // console.log("++++++++++++",arr)
        arr[0] && (arr[0].number = 0.1)
        arr[1] && (arr[1].number = 0.2)
        arr[2] && (arr[2].number = 0.3)
        arr[3] && (arr[3].number = 0.4)
        arr[4] && (arr[4].number = 0.5)
        arr[5] && (arr[5].number = 0.7)
        arr[6] && (arr[6].number = 0.8)
        arr[7] && (arr[7].number = 0.9)
        arr[8] && (arr[8].number = 0)
        arr[9] && (arr[9].number = 0.6)
        
        this.setState({
            awardsCopy:[...arr]
        }
        , () => {
            
        }
        )
    }
    // 提交按钮
    sumbitBtn() {
        this.setState({
            isFinish: true
        })
        let obj = {}
        obj.activityType=this.state.activityType
        obj.activityImg=this.state.activityImg
        obj.activityName=this.state.activityName
        obj.isRestrict=this.state.isRestrict
        obj.restricts=JSON.stringify(this.state.restricts)
        obj.dayLotteryCount=this.state.dayLotteryCount
        obj.lotteryCount=this.state.lotteryCount
        obj.winningCount=this.state.winningCount
        obj.dayWinningCount=this.state.dayWinningCount
        obj.beginDate=this.state.beginDate
        obj.endDate=this.state.endDate
        obj.explains=this.state.explains
        obj.awards=this.state.awardsCopy
        // 判断标题和时间是否输入
        if(obj.activityName && obj.beginDate){
            // 新增和修改
            if(this.props.query.count){
                obj.id = this.props.query.count
                // console.log('修改', obj)
                API.putActivi(obj).then(res=>{
                    if(res.code === 200){
                        message.success('修改成功')
                        this.props.history.push({pathname:'/app/active/seckill'})
                    }else{
                        message.error('修改失败')
                        this.props.history.push({pathname:'/app/active/seckill'})
                    }
                })
            }else{
                // console.log("新增",obj)
                API.postActivi(obj).then(res=>{
                    if(res.code === 200){
                        message.success('新增成功')
                        this.props.history.push({pathname:'/app/active/seckill'})
                    }else{
                        message.error('新增失败')
                        this.props.history.push({pathname:'/app/active/seckill'})
                    }
                })
            }
        }else{
            message.error("请输入活动标题或活动时间")
        }
    }

    
    back(){
        this.props.history.push({pathname:'/app/active/seckill'})
    }
    // 编辑
    editActivity(id){
        API.getActivityDetail(id).then(res=>{
            // console.log(res.data)
            this.setState({
                // actvityData:res.data,
                disabled:true,
                activityType:res.data.activityType,
                activityName:res.data.activityName,
                activityImg:res.data.activityImg,
                activityStatus:res.data.activityStatus,
                beginDate:res.data.beginDate,
                endDate:res.data.endDate,
                dayLotteryCount:res.data.dayLotteryCount,
                lotteryCount:res.data.lotteryCount,
                dayWinningCount:res.data.dayWinningCount,
                winningCount:res.data.winningCount,
                isRestrict:res.data.isRestrict,
                restricts:JSON.parse(res.data.restricts),
                awards:res.data.awards,
                awardsCopy:res.data.awards,
                uploadConfig: {
                    ...this.state.uploadConfig,
                    fileList: res.data.activityImg ? [{
                        url: res.data.activityImg,
                        uid: '-1'
                    }] : []
                }
            })
            this.props.form.setFieldsValue({
                ...res.data
            })
        })
    }
    scree(){
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {
        // console.log(this.state.sellType,"11111")
        let data = {}
        data.memberLevels = this.state.memberLevels
        data.sellType = this.state.sellType
        data.areas = this.state.areas
        data.signedStartTime = this.state.signedStartTime
        data.signedEndTime = this.state.signedEndTime
        data.deliveryStartTime = this.state.deliveryStartTime
        data.deliveryEndTime = this.state.deliveryEndTime
        if(this.state.index){
            this.state.restricts.splice(this.state.index-2,1,data)
        }else if(data.memberLevels.length === 0 && data.areas.length === 0 && !data.signedStartTime && !data.signedEndTime && !data.deliveryStartTime && !data.deliveryEndTime && !data.sellType){
            console.log("222")
        }else{
            this.state.restricts.push(data)
        }
        this.setState({
            visible: false,
            memberLevels:[],
            sellType:undefined,
            areas:[],
            signedStartTime:'',
            signedEndTime:'',
            deliveryStartTime:"",
            deliveryEndTime:"",
            index:""
        });
    };
    handleCancel = e => {
        this.setState({
            visible: false,
            memberLevels:[],
            sellType:undefined,
            areas:[],
            signedStartTime:'',
            signedEndTime:'',
            deliveryStartTime:"",
            deliveryEndTime:"",
            index:""
        });
    };
    // 会员等级
    member(value){
        // console.log(value,"huiyuan")
        this.setState({
            memberLevels:value
        })
    }
    // 门店属性
    sellTypeChange(value){
        this.setState({
            sellType:value
        })
    }
    // 签约时间起始
    signTimeStart(value, dateString) {
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            signedStartTime:dateString
        })
    }
    // 签约时间终止
    signTimeEnd(value, dateString) {
        // console.log('Formatted Selected Time: ', dateString);
        // console.log(this.state.signedStartTime,dateString)
        let d1 = moment(this.state.signedStartTime)
        let d2 = moment(dateString)
        let d3 = d2.diff(d1,'minute');
        // console.log(d3)
        if(d3<=0){
            message.info("结束时间不能小于开始时间")
            this.setState({
                signedEndTime:""
            })
        }else{
            this.setState({
                signedEndTime:dateString
            })
        }
    }
    // 交付时间起始
    deliverTimeStart(value, dateString) {
        // console.log('Formatted Selected Time: ', dateString);
        this.setState({
            deliveryStartTime:dateString
        })
    }
    // 交付时间终止
    deliverTimeEnd(value, dateString) {
        // console.log('Formatted Selected Time: ', dateString);
        let d1 = moment(this.state.deliveryStartTime)
        let d2 = moment(dateString)
        let d3 = d2.diff(d1,'minute');
        console.log(d3)
        if(d3<=0){
            message.info("结束时间不能小于开始时间")
            this.setState({
                deliveryEndTime:""
            })
        }else{
            this.setState({
                deliveryEndTime:dateString
            })
        }
    }
    // 编辑条件组
    editCondition(item,index){
        console.log(index,"tiaojian")
        this.setState({
            visible: true,
            memberLevels:item.memberLevels,
            sellType:item.sellType,
            areas:item.areas,
            signedStartTime:item.signedStartTime,
            signedEndTime:item.signedEndTime,
            deliveryStartTime:item.deliveryStartTime,
            deliveryEndTime:item.deliveryEndTime,
            index:index+2
        })
    }
    // 删除条件组
    delCondition(index){
        // console.log(index)
        this.state.restricts.splice(index,1)
        this.setState({
            restricts:this.state.restricts
        })
        // console.log(this.state.restricts)
    }
    // 取消删除条件组
    cancel(){}
    // 获取所有的所属区域
    getAllAreas(){
        API.getAreas().then(res=>{
            console.log(res)
            this.setState({
                allAreas:res.data
            })
        })
    }
    // 获取选中的所属区域组
    areas(value){
        console.log(value)
        this.setState({
            areas:value
        })
        
    }
    render (){
        const { panes, activeKey, isFinish,beginDate,endDate, awards,signedStartTime,signedEndTime,deliveryStartTime,deliveryEndTime,memberLevels,areas,sellType } = this.state;
        const { form } = this.props;
        const { getFieldDecorator } = form;
        // console.log('isFinish', awards)
        // console.log('this.state.uploadConfig seckill', this.state.uploadConfig)
        // console.log(this.state.restricts)
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft:'40px',
        };
        const modalStyle = {
            marginBottom:"20px"
        }
        return(
            <div>
                <Tabs type="card" tabBarGutter="0" onChange={this.callback.bind(this)} className={Style.cardContainer}>
                    <TabPane tab="基础设置" key="1" className={Style.basics}>
                        <div>
                            <Form name="validate_other" style={{marginLeft:'50px'}}>
                                <h3 style={{marginLeft:'-30px'}}>基本选项</h3><hr style={{marginLeft:'-30px'}}/>
                                <div>
                                    <Form.Item label="抽奖形式：">
                                    {getFieldDecorator('activityType',{
                                        initialValue:this.state.activityType
                                    })(
                                        <Radio.Group disabled={this.state.disabled} onChange={this.onChange.bind(this)} value={this.state.activityType}>
                                            <Radio value= {"大转盘"} >大转盘</Radio>
                                            <Radio value= {"九宫格"} >九宫格</Radio>
                                            <span style={{color:"red",fontSize:12}}>九宫格需要设置八个奖项，大转盘需要设置6-10个奖项</span>
                                        </Radio.Group>)}
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item name="username" label="背景图片" rules={[{required: true,}]}>
                                    {/* <Avatar></Avatar> */}
                                        <ImagesUpload {...this.state.uploadConfig} msg={this.props.query.count} getUrlList={this.getUrlList.bind(this)} getHash={this.getHash.bind(this)}>添加</ImagesUpload>
                                        <span style={{color:"red",fontSize:12}}>图片建议像素为640px*1600px，格式为jpg/png/bmp/gif</span>
                                    </Form.Item>
                                </div>
                                <div>
                                <Form.Item label="活动标题：">
                                    {getFieldDecorator('activityName', {
                                        rules: [
                                            {
                                                required: true,
                                                message:'请输入活动标题'
                                            }
                                        ]
                                    })(<Input onChange={this.inputChange.bind(this)} style={{display:'inline-block',width:600}} placeholder="请输入活动标题" />)}
                                </Form.Item>
                                </div>
                                <div>
                                <Form.Item label="活动时间：">
                                    {/* <Form.Item label="RangePicker[showTime]">
                                        {getFieldDecorator('range-time-picker', rangeConfig)(
                                            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />,
                                        )}
                                    </Form.Item> */}
                                    <RangePicker
                                    style={{display:'inline-block',width:600}}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    disabledDate={this.dateChange.bind(this)}
                                    onChange={this.getDate.bind(this)}
                                    // onOk={this.onOk.bind(this)}
                                    value={ beginDate && endDate? [ moment(beginDate), moment(endDate) ] : ''}
                                    />
                                </Form.Item>
                                </div>
                                <div>
                                {/* <Form.Item label="会员专属：">
                                {getFieldDecorator('isRestrict',{
                                    initialValue:this.state.isRestrict
                                })(
                                    <Radio.Group onChange={this.valueVIPChange.bind(this)}>
                                        <Radio value={true}>开启</Radio>
                                        <Radio value={false}>关闭</Radio>
                                    </Radio.Group>)}
                                </Form.Item> */}
                                </div>
                                {/* {this.state.isRestrict === true ? <div>
                                    <Form.Item>
                                        {getFieldDecorator('vips', {
                                            initialValue: ['普通会员', 'VIP会员','铂金VIP','钻石VIP','超级VIP'],
                                        })(
                                            // <Checkbox.Group style={{ width: '100%',marginLeft:70 }} onChange={this.onChangeVIP.bind(this)}>
                                            //     <Checkbox value="普通会员">普通会员</Checkbox>
                                            //     <Checkbox value="VIP会员">VIP会员</Checkbox>
                                            //     <Checkbox value="铂金VIP">铂金VIP</Checkbox>
                                            //     <Checkbox value="钻石VIP">钻石VIP</Checkbox>
                                            //     <Checkbox value="超级VIP">超级VIP</Checkbox>
                                            // </Checkbox.Group>
                                        )}
                                        </Form.Item>
                                </div>:""} */}
                                
                                <div>
                                <Form.Item label="活动说明：">
                                    {getFieldDecorator('explains')(<Input.TextArea onChange={this.textAreaChange.bind(this)} style={{display:'inline-block',width:600,height:120}}/>)}
                                </Form.Item>
                                </div>
                                <h3 style={{marginLeft:'-30px'}}>中奖率</h3><hr  style={{marginLeft:'-30px'}}/>
                                <div>
                                    <Form.Item name="radio-group" label="每人时间段内抽奖次数">
                                        <Radio.Group onChange={this.lotteryCount.bind(this)} value={this.state.lotteryCount}>
                                            <Radio onClick={this.LCRadio.bind(this)} value={0}>不限</Radio>
                                            {/* <Radio value={4}>限制</Radio> */}
                                            每人时间段内抽奖次数 <Input onChange={this.LCInputChange.bind(this)} 
                                            value={this.state.lotteryCount === 0 ? "" : this.state.lotteryCount} style={{display:'inline-block',width:60}} /> 次
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item name="radio-group" label="每人每日抽奖次数">
                                        <Radio.Group onChange={this.dayLotteryCount.bind(this)} value={this.state.dayLotteryCount}>
                                            <Radio onClick={this.DLCRadio.bind(this)} value={0}>不限</Radio>
                                            {/* <Radio value={4}>限制</Radio> */}
                                            每人每日最多可抽奖 <Input onChange={this.DLCInputChange.bind(this)} 
                                            value={this.state.dayLotteryCount === 0 ? "" : this.state.dayLotteryCount} style={{display:'inline-block',width:60}} /> 次
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item name="username" label="每人中奖次数" rules={[{required: true,}]}>
                                        <Radio.Group onChange={this.EBNumChange.bind(this)} value={this.state.winningCount}>
                                            <Radio style={{marginRight:"10px"}} value={0} onClick={this.EBNumRadio.bind(this)}>不限</Radio>
                                            每人最多可中奖 <Input onChange={this.EBNumInputChange.bind(this)} 
                                            value={this.state.winningCount === 0 ? "" : this.state.winningCount} style={{display:'inline-block',width:60}} /> 次
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item name="radio-group" label="每日中奖次数">
                                        <Radio.Group onChange={this.dayWinningCount.bind(this)} 
                                        value={this.state.dayWinningCount}>
                                            <Radio onClick={this.DWCRadio.bind(this)} value={0}>不限</Radio>
                                            {/* <Radio value={4}>限制</Radio> */}
                                            每人每日最多可中奖 <Input onChange={this.DWCInputChange.bind(this)} 
                                            value={this.state.dayWinningCount === 0 ? "" : this.state.dayWinningCount} style={{display:'inline-block',width:60}} /> 次
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                </div>
                            </Form>
                        </div>
                    </TabPane>
                    <TabPane tab="活动参与资格设置" key="3">
                        <div>
                            <h3 style={{ width: '100%',marginLeft:20 }}>设置活动参与用户：</h3>
                            <Radio.Group onChange={this.valueVIPChange.bind(this)} value={this.state.isRestrict}>
                                <Radio style={radioStyle} value={false}>不限</Radio>
                                <Radio style={radioStyle} value={true}>指定用户参与活动
                                    {this.state.isRestrict === true ? <Button type="primary" style={{ marginLeft:70}} onClick={this.scree.bind(this)}>添加筛选条件</Button> : ""}
                                </Radio>
                            </Radio.Group>
                            <p style={{color:"red",marginLeft:'64px',marginTop:'10px'}}>说明：满足任意一组筛选条件即可参与活动</p>
                            <hr></hr>
                            {this.state.restricts && this.state.isRestrict === true ? this.state.restricts.map((item,index)=>(
                                <div style={{marginLeft:40,marginTop:30}}>
                                    <Popconfirm
                                        title="确定删除该条件组吗?"
                                        onConfirm={this.delCondition.bind(this,index)}
                                        onCancel={this.cancel.bind(this)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="danger">删除</Button>
                                    </Popconfirm>
                                    
                                    <Button type="primary" style={{marginLeft:10,marginRight:10}} onClick={this.editCondition.bind(this,item,index)}>编辑</Button>
                                    <span style={{fontWeight:600}}>条件组{index+1}</span>
                                    {/* {console.log(item,"条件组")} */}
                                    {item.memberLevels.length !== 0 ? <div style={{marginLeft:147}}><span style={{fontWeight:600}}>会员等级：</span>{item.memberLevels.join()}</div> : ""}
                                    {item.sellType ? <div style={{marginLeft:147}}><span style={{fontWeight:600}}>门店属性：</span>{item.sellType}</div> : ""}
                                    {item.areas.length !== 0 ? <div style={{marginLeft:147}}><span style={{fontWeight:600}}>所属区域：</span>{item.areas.join()}</div> : ""}
                                    {item.signedStartTime || item.signedEndTime ? <div style={{marginLeft:147}}><span style={{fontWeight:600}}>签约时间：</span>{item.signedStartTime?item.signedStartTime:"不限"} ~ {item.signedEndTime?item.signedEndTime:"不限"}</div> : ""}
                                    {item.deliveryStartTime || item.deliveryEndTime ? <div style={{marginLeft:147}}><span style={{fontWeight:600}}>交付时间：</span>{item.deliveryStartTime?item.deliveryStartTime:"不限"} ~ {item.deliveryEndTime?item.deliveryEndTime:"不限"}</div> : ""}
                                    <hr style={{backgroundColor: "#D3D3D3",height: "1px",border: "none"}} />
                                </div>
                            )) : ""}
                            <Modal
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                width="600px"
                            >
                                <div>
                                    <div style={modalStyle}>
                                        <span>会员等级：</span>
                                        <Select
                                            mode="multiple"
                                            style={{ width: '408px'}}
                                            placeholder="全部"
                                            value={memberLevels}
                                            onChange={this.member.bind(this)}
                                        >
                                            <option value="普通会员" key='1'>普通会员</option>
                                            <option value="VIP会员" key='2'>VIP会员</option>
                                            <option value="铂金VIP" key='3'>铂金VIP</option>
                                            <option value="钻石VIP" key='4'>钻石VIP</option>
                                            <option value="超级VIP" key='5'>超级VIP</option>
                                        </Select>
                                    </div>
                                    <div style={modalStyle}>
                                        <span>门店属性：</span>
                                        <Select
                                            showSearch
                                            style={{ width: '408px'}}
                                            placeholder="全部"
                                            value={sellType}
                                            onChange={this.sellTypeChange.bind(this)}
                                        >
                                            <option value="直营" key='6'>直营</option>
                                            <option value="经销" key='7'>经销</option>
                                        </Select>
                                    </div>
                                    <div style={modalStyle}>
                                        <span>所属区域：</span>
                                        <Select
                                            mode="multiple"
                                            style={{ width: '408px'}}
                                            placeholder="全部"
                                            value={areas}
                                            onChange={this.areas.bind(this)}
                                        >
                                            {
                                                this.state.allAreas.map((item) =>
                                                <Option key={item.id} value={item.qy}>{item.qy}</Option>)
                                            }
                                        </Select>
                                    </div>
                                    <div style={modalStyle}>
                                        <span>签约时间：</span>
                                        <DatePicker
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value={signedStartTime ? moment(signedStartTime) : ""}
                                            placeholder="不限"
                                            onChange={this.signTimeStart.bind(this)} 
                                            value={signedStartTime ? moment(signedStartTime) : ""}
                                        /> ~ <DatePicker 
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value={signedEndTime ? moment(signedEndTime) : ""}
                                            placeholder="不限" onChange={this.signTimeEnd.bind(this)} 
                                        />
                                    </div>
                                    <div>
                                        <span>交付时间：</span>
                                        <DatePicker showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value={deliveryStartTime ? moment(deliveryStartTime) : ""} placeholder="不限" onChange={this.deliverTimeStart.bind(this)} 
                                        /> ~ <DatePicker
                                            showTime={{ format: 'HH:mm:ss' }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value={deliveryEndTime ? moment(deliveryEndTime) : ""} placeholder="不限" onChange={this.deliverTimeEnd.bind(this)} 
                                        />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </TabPane>
                    <TabPane tab="奖项设置" key="2" className={Style.basics}>
                        <div style={{ paddingLeft:"20px",backgroundColor:"#FAFAFA",height:"100%",width:"100%" }}>
                        <p style={{color:"red",fontSize:12,paddingTop:20,paddingBottom:20}}>请设置{this.state.activityType === "九宫格" ? "8" : "6-10"}个奖项</p>
                        <Tabs
                            type="editable-card"
                            onChange={this.onChangePrize.bind(this)}
                            activeKey={activeKey}
                            onEdit={this.onEdit}
                            className={Style.cardContainer}
                        >
                            {/* {console.log(awards)} */}
                            {this.props.query.count ? awards.map((pane, i) => (
                                <TabPane tab={`奖项${i + 1}`}
                                key={pane.id ? `${i + 1}` : Number(pane)} 
                                className={Style.basics}>
                                    {/* {console.log("+++++",awards[i])} */}
                                    <WrappedRegistrationForm award={pane.id ? pane:""} isFinish={isFinish} content={this.getValue.bind(this)}/>
                                </TabPane>
                            )) : panes.map((pane, i) => (
                                <TabPane tab={`奖项${i + 1}`} key={pane} className={Style.basics}>
                                    <WrappedRegistrationForm isFinish={isFinish} content={this.getValue.bind(this)}/>
                                </TabPane>
                            ))}
                        </Tabs>
                        </div>
                        <div style={{ padding:"40px",backgroundColor:"#FAFAFA",height:"40px",width:"100%",marginBottom:30 }}>
                            {this.state.activityStatus === "进行中" ? "" : <Button type="primary" onClick={this.sumbitBtn.bind(this)}>
                                提交
                            </Button>}
                            
                            <Button style={{marginLeft:20}} type="primary" onClick={this.back.bind(this)}>
                                返回
                            </Button>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
};
const AddSeckill = Form.create({ name: 'edit' })(Seckill)
export default AddSeckill