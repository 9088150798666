import React,{Component} from 'react'
import {Button} from 'antd'
class OrderReturnDetail extends Component {
    render() {
        return(
            <div>
                退单详情
            </div>
        )
    }
}
export default OrderReturnDetail;