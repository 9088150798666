import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { Layout, Divider, ConfigProvider,Button,message } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
// import Header from './components/Header'
import { connectAlita } from 'redux-alita'
import Routes from './routes'
import SideMenu from './components/sideMenu'
import NavBar from './components/Header'
import './App.less'
const { Sider, Footer, Header, Content } = Layout
// console.log(Layout, 'layout')
// const Footer = Layout.Footer;
// console.log(Content, 'content')
const imageUrl ='https://www.iwitech.com.cn/t/huikeji/img/1.png'

class App extends Component {
  // constructor(props) {
  //   super(props)
  //   this.changeStatus = this.changeStatus.bind(this)
  // }
  componentDidMount() {
    // 判断是否登录后进入后台
    let username=localStorage.getItem('username')
    if(!username){
      this.props.history.push('/login')
    }
  }
  componentDidUpdate() {

  }
  state = {
    title: '首页',
    collapsed: false
  }
  // componentDidMount() {}
  changeStatus = () => {
  
    this.setState({
      collapsed: !this.state.collapsed
    })
  }
  render() {
    const { title } = this.state
    const SiderStyle = {
      height : '100%',
      overflowY : 'auto'
    }
    return (
      <ConfigProvider locale={zhCN}>
        <DocumentTitle title={title}>
          <Layout>
            <Sider style={SiderStyle}  collapsed={this.state.collapsed}>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',}}>
               {/* <div > */}
                
                <img  src={imageUrl} style={{height:50,lineHeight:'50px',textAlign:'center',width:'80%',color:'#fff',borderRadius:'5px'}} />
               {/* </div>  */}
                </div>
              <SideMenu collapsed={this.state.collapsed} style={ !this.state.collapsed?{ width: 200}:{width:76}}></SideMenu>
            </Sider>
            <Layout style={{ flexDirection: 'column' }}>
              <Header>
                <NavBar
                  changeStatus={this.changeStatus}
                  collapsed={this.state.collapsed}
                ></NavBar>
              </Header>
              <Content
              >
                {' '}
                <Routes></Routes>{' '}
              </Content>
              {/* <Footer></Footer> */}
            </Layout>
          </Layout>
        </DocumentTitle>
      </ConfigProvider>
    )
  }
}

export default App
