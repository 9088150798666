import React,{Component} from 'react'
import {Table,Button,Pagination} from 'antd'

class BasicTable extends Component{
    constructor(props){
        super(props)
    }
    static defaultProps = {
        pageShow: true,
        pageNum: 1
    }
    state={
        columns :[],
        data :[]
    }
    componentWillMount() {
        // console.log(this.props.columns,"pagenum",this.props.data)
        this.setState({
            columns :[
                {title:'标题',dataIndex:'title',key:'title'},
                {title:'地址',dataIndex:'address',key:'address'},
                {title:'日期',dataIndex:'date',key:'date'},
                {title:'联系人',dataIndex:'contact',key:'concact'},
                {title:'时间',dataIndex:'time',key:'time'},
                {title:'操作',dataIndex:'action',key:'action',render:(text,record) =>(
                    <span>
                        <Button onClick={() => this.props.checkItem(record)}>查看</Button>  
                    </span>
                )},
                
            ],
            data :[
                {title:'唱',address:'南京新世界中心',date:'2020-03-03,2020-03-05',contact:'寻鲲菜菜子',time:'08:00-18:00',key:1},
                {title:'跳',address:'南京新世界中心',date:'2020-03-03,2020-03-05',contact:'寻鲲菜菜子',time:'08:00-18:00',key:2},
                {title:'rap',address:'南京新世界中心',date:'2020-03-03,2020-03-05',contact:'寻鲲菜菜子',time:'08:00-18:00',key:3}
            ]
        })
    }
    render() {
        
        return (
            <div>
            <Table columns={this.props.columns} dataSource={this.props.data} pagination={false} rowSelection={this.props.row} rowKey={this.props.rowKey}>
            </Table>
            {
                this.props.pageShow ? 
            <Pagination total={this.props.total} defaultCurrent={this.props.pageNum} onChange={this.props.changePage} style={{display:'flex',justifyContent:'center',marginTop:'20px'}}></Pagination> 
            : ''
            }
            </div>
            
        )
    }
}

export default BasicTable;