import React,{Component} from 'react'
import {Form,Input,Button,Select} from 'antd'
const renderOptions = (item) => (
    <Select.Option value={item.name} key={item.id}>{item.name}</Select.Option>)
class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={
        data:[],
        formStyle:{
            display:'flex',
            flexWrap:'wrap',
            alignItems:'center',
            justifyContent:'space-between'
        }
    }
    submitForm=(e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.id = this.props.data.id
                console.log(values)
                this.props.submit(values)
            }
        })
    }
    // 从父组件拿到数据进行渲染
    initial() {
        let obj = {
            name: '',
            type: '',
            price: '',
            // 工费
            laborCharges:'',
            // 数量
            stockQuantity:'',
            // 风量
            airVolume: '',
            // id:''
        }
        // console.log(this.props)
        if (this.props.data) {
            let data = this.props.data
            console.log(data, 'data++++++++')
            obj.name = data.name
            obj.type = data.type
            obj.price = data.price
            obj.laborCharges = data.laborCharges
            obj.stockQuantity = data.stockQuantity
            obj.airVolume = data.airVolume

        }
        this.props.form.setFieldsValue(obj)
    }
    // 组件加载完成
    componentDidMount() {
        console.log(this.props)
        this.initial()
    }
    submit=()=> {
        this.submitForm()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.submitForm} >
                <div style={this.state.formStyle} className="edit-form-container">
                    <Form.Item label="零件名称">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message:'请输入零件名称'
                                }
                            ]
                        })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                    </Form.Item>
                    <Form.Item label="风量">
                        {getFieldDecorator('airVolume', {
                            rules: [
                                {
                                    required: true,
                                    message:'请输入风量'
                                }
                            ]
                        })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                    </Form.Item>
    
                    <Form.Item label="零件型号">
                        {getFieldDecorator('type', {
                            rules: [
                                {
                                    required: true,message:'请输入零件型号'
                                }
                            ]
                        })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                    </Form.Item>
                    <Form.Item label="价格">
                        {getFieldDecorator('price', {
                            rules: [
                                {
                                    required: true,
                                    message:'请输入价格'
                                }
                            ]
                        })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                    </Form.Item>
                    <Form.Item label="工费">
                        {getFieldDecorator('laborCharges', {
                            rules: [
                                {
                                    required: true,
                                    message:'请输入工费'
                                }
                            ]
                        })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                    </Form.Item>
                    <Form.Item label="库存">
                        {getFieldDecorator('stockQuantity', {
                            rules: [
                                {
                                    required: true,
                                    message:'请输入库存'
                                }
                            ]
                        })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                    </Form.Item>
                </div>
                <Form.Item >
                    <Button htmlType='submit' type='primary'>提交</Button>
                </Form.Item>
            </Form>
        )
    }
}
const PartForm =Form.create({name:'edit'})(RawForm)
export default PartForm;