import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from 'antd'
import actions from '../../store/actions/index'
import { bindActionCreators } from 'redux'
import BasicTable from '../../components/Table'
import EditModal from '../../components/modal_edit'
import SearchHeader from '../../components/search_header'
import API from '@/fetch/api'
import Tool from '@/global/tool'
class StrainerManage extends Component {
    state = {
        keyword:"",
        pageNum:'',
        pageSize:'10',
        data: [],
        columns: [
            { title: '滤网名称', dataIndex: 'airVolume', key: 'airVolume' },
            { title: '滤网价格', dataIndex: 'price', key: 'price' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                        <span>
                            <Button type='primary' onClick={() => this.eiditMan(record)} style={{marginRight:'10px'}}>编辑</Button>

                        </span>
                        <span>
                            <Button type='danger' onClick={() => this.deleteItem(record)}>删除</Button>

                        </span>
                        
                    </div>
                )
            },
        ],
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                // console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                // console.log(selected, selectedRows, changeRows);
            },

        },
        editStatus: false,
        currentEngineer:{},
        searchObj:{
            pageNum:1,
            pageSize:10,
            keyword:""
        }
    }
    onClose() {
        this.setState({
            editStatus: false,
            currentEngineer:[]
        })
    }
    eiditMan(data) {
        console.log(data)
        this.setState({
            editStatus:true,
            currentEngineer:data
        })
    }
    addPart() {
        this.setState({
            editStatus: true
        })
    }
    // 根据页码
    changePage(page) {
        // console.log(page)
        let obj = {...this.state.searchObj}
        obj.pageNum = page
        this.getList(obj) 
    }
    // 根据搜索框的内容
    commitSearch() {
        console.log("111")
        this.getList(this.state.searchObj)
    }
    // 提交
    submit(data){
        // data.LQBM = data.couponName
        console.log(data,'submit')
        if(data.id){
            // 调用修改滤网的接口
            API.putStrainer(data).then(res=>{
                // console.log('修改成功')
                this.getList(this.state.searchObj)
                this.setState({
                    editStatus: false
                })
            })
            // console.log(data)
            
        }else{
            API.strainerAdd(data).then(res =>{
                this.getList(this.state.searchObj)
                // console.log(res,)
                this.setState({
                    editStatus: false
                })
            })
        }
        
    }
    // 删除滤网
    deleteItem(data) {
        API.strainerRemove(data.id).then(res =>{
            if(res.code == 200) {
                this.getList(this.state.searchObj)
            }
        })
    }
    // 获取搜索框内的值
    getValue(e) {
        console.log(e.target.value)
        let obj = {...this.state.searchObj}
        obj.keyword = e.target.value
        this.setState({
            searchObj:obj
        })
    }
    // 获取滤网
    getList(data) {
        API.strainerList(data).then(res=>{
            if(res.code == 200) {
                // console.log(this,'this')
                let list = res.data.content
                // console.log(list)
                /* list.forEach(item =>{
                    item.createdTime = Tool.timeChecker(item.createdTime)
                }) */
                console.log(list,'this')
                this.setState({
                    data:list,
                    total:res.data.totalElements
                })
            }
        })
    }
    componentDidMount() {
        this.getList(this.state.searchObj)
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                    {/* <p style={{width:"20%"}}>{this.props.title}</p> */}
                    <div className="flex-row" style={{ width: "25%" }}> <p style={{ width: 100 }}>滤网名称：</p><Input onChange={this.getValue.bind(this)} className="inline-block" width={200}></Input></div>
                    {/* <div className="flex-row" style={{width:"25%"}}> <p style={{width:100}}>{this.props.secondKey+'：'}</p><Input value={this.state.second} onChange={this.changeSecond} className="inline-block" width={200}></Input></div> */}
                    {/* {<div ><Button onClick={() => this.props.searchHandler}>刷新</Button></div>} */}
                    <div><Button type='primary' onClick={() => this.addPart()}>新增</Button></div>
                    <div style={{ width: "20%" }}><Button onClick={this.commitSearch.bind(this)}>搜索</Button></div>
                </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} changePage={this.changePage.bind(this)} total={this.state.total} rowKey='id'>

                </BasicTable>

                <EditModal data={this.state.currentEngineer} visible={this.state.editStatus} onClose={this.onClose.bind(this)} type='area' submit={this.submit.bind(this)}></EditModal>
            </div>
        )
    }
}
export default connect()(StrainerManage);
