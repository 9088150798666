import React,{Component,useState,useReducer } from 'react'
import {message,Button, Form, Input, Upload, Space} from 'antd'
import { LoadingOutlined, PlusOutlined ,UploadOutlined} from '@ant-design/icons';
import ImagesUpload from './image-upload-banner'
import BasicUpload from '@/components/upload'
import BraftEditor from 'braft-editor'
import API from '@/fetch/api'

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
var imgTest =''
var configImgToken = ''
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

function UpdateImgFn(props){
    // console.log(props.form.issuerImg,'IMg',props.form)
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.form.issuerImg);
  const tokenData = {}
  tokenData.token = configImgToken
  
  const handleChangeImg = (info) => {
      console.log(info,'image')
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
        const url ='http://cdn.huikeji.shop/'+ info.file.response.hash
        setImageUrl(url);
        imgTest = url
    }
  };
  return (
      <div>
         <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            multiple={true}
            data={tokenData}
            maxCount={1}
            type='image'
            action="https://up-z2.qiniup.com"
            beforeUpload={beforeUpload}
            // onChange={()=>dispatch()}
            onChange={handleChangeImg}
            >
            {imageUrl ? (
                <img
                src={imageUrl}
                alt="avatar"
                style={{
                    width: '100%',
                }}
                />
            ) : (
                <div>
                 {loading ? <LoadingOutlined /> : <PlusOutlined />}
                 <div style={{marginTop: 8,}}>Upload</div>
                </div>
            )}
            <span>34px*34px</span>
          </Upload> 
      </div>
  )
}

class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={
        content:'',
        name:'',
        img:'',
        issuerName:'',
        issuerImg:'',
        id:'',
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        formStyle:{
            display:'flex',
            flexDirection:'column',
            flexWrap:'wrap'
        },
        uploadConfig: {
            action: 'https://up-z2.qiniup.com',
            listType: 'picture',
            accept: 'image/*',
            multiple: false,
            fileList: this.props.data.fileList,
            data: { token: '' },
            type: 'image',
            name: 'file',
            limit: 1,
            maxCount:2,
            title: '上传图片'
        },
        
    }
    
    submitForm=(e) => {
        console.log(this.state.img,'this',e)
        e.preventDefault();
        let imgArr = []
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(values,'submitForm',this.state.img,imgTest)
            // console.log(values,'submitForm',this.state.img.length,imgTest.length)
            if(imgTest.length != 0) values.issuerImg =  imgTest
            if(this.state.img.length != 0) {
                values.img = this.state.img.toString()
            }else{
                // values.img.map((item,index)=>{
                //     console.log(item.name,index)
                //     imgArr.push(item.name) 
                // })
                // // console.log(imgArr)
                // values.img = imgArr.toString()
                values.img =  values.img.toString()
            }
            
            // values.issuerImg =  imgTest
            values.content = this.state.outputHTML
            if (!err) {
                this.props.submit(values)
            }
        });
    }
    componentWillMount(){
        this.getQi()  
    }
    // 初始化页面
    componentDidMount() {
        console.log(this.props.data,this.props.data.id)
        console.log(JSON.stringify(this.props.data)==='{}')
        this.getQi()
        let data = this.props.data
        data.content = BraftEditor.createEditorState(data.content)
        this.props.form.setFieldsValue(data)
        console.log(this.props.form.getFieldsValue())
        console.log(this.state.uploadConfig)
     }

     // 获取父组件传递过来的参数
    // async componentWillReceiveProps(nextProps){
     componentWillReceiveProps(nextProps){
        console.log(this.props.data,'nextProps', nextProps)
    }
    submit=()=> {
        this.submitForm()
    }
    handleChange = (key, event) => {
        // console.log(key,event.target.value,this.state.form)
        let form = this.state.form
        for (let item in this.state.form) {
            if (item === key) {
                form[item] = event.target.value
                this.setState({form: form})
          }
      }
    }
    
     // 富文本
    changeRichText=(editorState) => {
    // 在点击文章内容的时候执行
    // console.log(editorState,'editorState',editorState.toHTML)
    this.setState({
        editorState: editorState,
        outputHTML: editorState.toHTML()
    })
    }
    isEqual(a, b) {
        //如果a和b本来就全等
        if (a === b) {
            //判断是否为0和-0
            return a !== 0 || 1 / a === 1 / b;
        }
        //判断是否为null和undefined
        if (a == null || b == null) {
            return a === b;
        }
        //接下来判断a和b的数据类型
        var classNameA=toString.call(a),
            classNameB=toString.call(b);
        //var classNameA = typeof(a), (兼容IE的写法)
            //classNameB = typeof(b);
        //如果数据类型不相等，则返回false
        if (classNameA !== classNameB) {
            return false;
        }
        if (a instanceof Date)
        {
            classNameA = '[object Date]';
        }
    
        //如果数据类型相等，再根据不同数据类型分别判断
        switch (classNameA) {
            case '[object RegExp]':
            case '[object String]':
            case 'string':
                //进行字符串转换比较
                return '' + a === '' + b;
            case 'number':
            case '[object Number]':
                //进行数字转换比较,判断为NaN
                if (+a !== +a) {
                    return +b !== +b;
                }
                //判断是否为0或-0
                return +a === 0 ? 1 / +a === 1 / b : +a === +b;
            case '[object Date]':
            case '[object Boolean]':
            case 'boolean':
                return +a === +b;
        }
        //如果是对象类型
        if (classNameA == '[object Object]' || classNameA=='object') {
            //获取a和b的属性长度
            var propsA = Object.getOwnPropertyNames(a),
                propsB = Object.getOwnPropertyNames(b);
            if (propsA.length != propsB.length) {
                return false;
            }
            for (var i = 0; i < propsA.length; i++) {
                var propName = propsA[i];
                //如果对应属性对应值不相等，则返回false
                if (a[propName] !== b[propName]) {
                    return false;
                }
            }
            return true;
        }
        //如果是数组类型
        if (classNameA == '[object Array]') {
            if (a.toString() == b.toString()) {
                return true;
            }
            return false;
        }
    }
    // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            // console.log(res,this.state.uploadConfig)
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                configImgToken = res.data
                this.setState({
                    uploadConfig:obj
                })
                // console.log(this.state.uploadConfig)
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data) {
        data = [...data];
        console.log('data', data)
        let title = [];
        let saveImg = [];
        data.forEach((fileInfo, index) => {
            // if(index <= 0) {
            //     return focusImg = fileInfo;
            // }
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }else{
                return saveImg.push(fileInfo.url)
            }
            // console.log('data', fileInfo.url)
            // title.push(fileInfo.url);
        })
        console.log('data', saveImg.concat(title))
        this.setState({
            img:saveImg.concat(title).toString(),
            // focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form  onSubmit={this.submitForm} style={this.state.formStyle} >
              <Form.Item {...formItemLayout} labelCol={{span:3}} style={{width:"100%"}} label="社区名称">
                {getFieldDecorator('name', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    },
                    ],
                })(<Input onChange={this.handleChange.bind(this,'name')} placeholder="请输入社区名称" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} labelCol={{span:3}} style={{width:"100%"}} label="发布人">
                {getFieldDecorator('issuerName', {
                    rules: [
                    {
                        required: true,
                        message: '内容不能为空',
                    },
                    ],
                })(<Input onChange={this.handleChange.bind(this,'issuerName')} placeholder="请输入发布人姓名" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} labelCol={{span:3}} style={{width:"100%"}} label="头像">
                {getFieldDecorator('issuerImg', {
                    // rules: [
                    // {
                    //     required: true,
                    //     message: '内容不能为空',
                    // },
                    // ],
                })( <UpdateImgFn form={this.props.data}></UpdateImgFn>)}
                </Form.Item>
                <Form.Item {...formItemLayout} labelCol={{span:3}} wrapperCol={{span:24}} style={{width:"100%"}} label="图片">
                {getFieldDecorator('img', {
                    // rules: [
                    // {
                    //     required: true,
                    //     message: '内容不能为空',
                    // },
                    // ],
                })(<BasicUpload 
                    {...this.state.uploadConfig}
                    componentType={this.props.componentType} 
                    getUrlList={this.getUrlList.bind(this)} 
                  ></BasicUpload>
                    
                // <ImagesUpload 
                //     {...this.state.uploadConfig} 
                //     getUrlList={this.getUrlList.bind(this)}>
                //     </ImagesUpload>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} style={{width:"100%"}} labelCol={{span:3}} wrapperCol={{span:24}}  label="社区内容" >
                {getFieldDecorator('content', {
                    // rules: [
                    // {
                    //     required: true,
                    //     message: '内容不能为空',
                    // }
                    // ],
                })( <BraftEditor
                    style={{height:'100%',margin:"10px",width:"80%",paddinf:"5px",border:"1px solid #CCC"}}
                    value={this.state.outputHTML}
                    onChange={this.changeRichText}
                    />)}
                </Form.Item>
                <Form.Item style={{
                   width: 'calc(100% + 48px)',
                   marginLeft:'-24px',
                   marginBottom:'0',
                   paddingTop:'3px',
                   justifyContent: 'flex-end',
                   paddingRight: '24px',
                   borderTop:'1px solid #ebebeb'}}>
                    <Button onClick={this.props.onClose} style={{marginRight:'10px'}}>取消</Button>
                    <Button htmlType='submit' type='primary' >确定</Button>
                </Form.Item>
            </Form>
        )
    }
}
const CommunityFrom =Form.create({name:'edit'})(RawForm)
export default CommunityFrom;