// import React,{Component} from 'react'
import {connect} from 'react-redux'
import {Upload,Button, Popconfirm, Icon} from 'antd'
import BasicTable from '../../components/Table'
import Tools from '../../global/tool'
import API from '@/fetch/api'
import React from 'react';
import { Table, Divider } from 'antd';
import BaseTable from '../../components/Table'
import PageButton from '../../components/pageButton'
// getList();
// API.getGoods({isDel:true}).then(res=>{
//     console.log(res)
// })

const {  useState, useEffect } = React;
function GoodsRecycle (props){
    
    const columns = [
        {
        title: '编号',
        dataIndex: 'id',
        },
        {
        title: '名称',
        dataIndex: 'name',
        },
        {
        title: '价格',
        dataIndex: 'iprice',
        },
        {
            title: '库存',
            dataIndex: 'stock',
        },
        {
            title: '销量',
            dataIndex: 'sales',
        },
        {
            title: "操作",
            render(text, record, index) {
                return (
                    <>
                    <Button
                        onClick={() =>
                        API.getResume(record.id).then((res)=>{
                            // 重新加载数据
                            getGoodsList()
                        })
                        } type='primary'
                    >回收</Button>
                    <Popconfirm
                        title="是否确认删除?"
                        onConfirm={() => {
                        // console.log("删除");
                        API.delPhysicalGoods(record.id).then((res) => {
                            // 重新加载数据
                            getGoodsList()
                        });
                        // message.success("删除成功");
                        }}
                        // onCancel={() => {
                        // console.log("取消");
                        // message.info("用户取消删除");
                        // }}
                        // okText="确定"
                        // cancelText="取消"
                    >
                        <Button style={{marginLeft:'10px'}} type='danger'>删除</Button>
                    </Popconfirm>
                    </>
                );
                },
            }
    ];
    let preVis = true
    let nextVis = false
    let page = 1
    let size = 10
    // const data = [
    //     {
    //     id:id,
    //     name:name,
    //     iprice:iprice,
    //     stock:stock,
    //     sales:sales,
    //     },
    // ];
    const [data, setData] = useState([]);
    // checkGood(()=>{
    //     console.log(1)
    // })
    useEffect(() => {
        getGoodsList();
    }, [])
    // 获取被删除的商品
    const getGoodsList = ()=>{
        API.getGoods({isDel:true}).then((res) => {
            console.log(res.data.content)
            setData(res.data.content)
        }
        )
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
            }),
    };
    /* const changePage=(page)=> {
        console.log(page)
        let obj = {}
        obj.size=1
        obj.page=page
        this.getList(obj)
    } */
    // checkManager((data)=>{
    //     onsole.log(data,'check manager ')
    // })
    // checkGood((data)=>{
        
    // })
    
    const [selectionType] = useState('checkbox');
    return (

        <div>
        <Divider />
        <Table
            rowSelection={{
            type: selectionType,
            ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
            // showPage={ false }
            // changePage={changePage}
        />
        {/* <Table
            row={{
            type: selectionType,
            ...rowSelection,
            }}
            columns={columns}
            // dataSource={data}
            data={data}
            pageShow={ false }
        >
        </Table> */}
        {/* { alert(flag) } */}
        {/* <PageButton preVis={preVis} nextVis={nextVis} pre={ pre } next={next}></PageButton> */}
        </div>
    );
}
export default connect()(GoodsRecycle);