export const Time=(today)=>{
//   let  today = new Date(t)
  let  year = today.getFullYear();
  let  month  = today.getMonth()+1;
  let  day = today.getDate();
  if (month < 10) month = '0' + '' + month
  if (day < 10) day = '0' + '' + day
  let  H = today.getHours();
  let   m = today.getMinutes();
  let   s = today.getSeconds();
  if (H < 10) H = '0' + '' + H
  if (m < 10) m = '0' + '' + m
  if (s < 10) s = '0' + '' + s
    console.log(year+'-'+month+'-'+day+' '+H+':'+m+':'+s,'toooo')
    return (year+'-'+month+'-'+day+' '+H+':'+m+':'+s)
}