import React,{Component} from 'react'
import {connect} from 'react-redux'
import {Form,Button, Icon,message} from 'antd'
import BasicTable from '../../components/Table'
import Tools from '../../global/tool'
import BasicModal from '../../components/modal_edit'
import {bindActionCreators} from 'redux'
import actions from '../../store/actions'
import API from '@/fetch/api'
import Tool from '@/global/tool'
import { rsort } from 'semver'
class Manager extends Component {
state={
    pageNum:'1',
    pageSize:'5',
    data: [],
    columns: [
        { title: '姓名', dataIndex: 'name', key: 'name' },
        { title: '联系方式', dataIndex: 'phone', key: 'phone' },
        { title: '属性', dataIndex: 'category', key: 'category' },
        { title: '账号', dataIndex: 'username', key: 'username' },
        { title: '密码', dataIndex: 'password', key: 'password' },
        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                    {<span>
                        <Button type='primary' onClick={() => this.checkManager(record)}>编辑</Button>
                    </span>}
                    <span>
                        <Button style={{marginLeft:'10px'}} type='danger' onClick={() => this.deleteManager(record)}>删除</Button>
                    </span>
                
                </div>
            )
        },
    ],
    row: {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    },
    initColumn : [{
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        className: 'text-monospace',
    }, {
        title: '联系方式',
        dataIndex: 'phone',
        key: 'phone',
    }, {
      title: '属性',
      dataIndex: 'category',
      key: 'category',
   },{
        title: '账号',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: '创建时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
    }
    ],
    total:1,
    editStatus:false,
    current:{}
    }
    getExcel() {
        Tools.exportExcel(this.state.initColumn,this.state.data,'测试excel.xlsx')
    }
    loadExcel(event) {
        Tools.importExcel(event).then(res =>{
            console.log(res)
        })
    }
    // 点击编辑的时候执行
    checkManager(data) {
        // debugger;
        // 管理员账户进行修改
        // API.removeAdmin(data).then(res=>{
        //     console.log(res)
        // })
        // console.log(data.id)
        
        this.setState({
            current:data,
            editStatus:true
        })
        // console.log(data,'admin',this.state.current)
    }
    deleteManager(data) {
        // 删除管理员账户
        API.adminRemove(data.id).then(res =>{
            if(res.code == 200) {
                this.getList({pageNum:this.state.pageNum})
                message.success('删除成功')
            }else{
                message.error('删除失败')  
            }
        })
    }
    addAdminHandler() {
        this.setState({
            editStatus:true
        })
    }
    onClose() {
        this.setState({
            editStatus:false,
            current:{}
        })
    }
    submit(data) {
        // console.log(this)
        // console.log(data,'传递的值')
        //    this.props.regist(data).then(res =>{
        //        console.log(res,'skdjfsl')
        //    })
        if(this.state.current.id){
            data.id = this.state.current.id
            API.adminUpdate(data).then(res =>{
                if(res.code == 200) {
                    this.onClose()
                    this.getList('')
                }
            })
        } else {
            API.addAdmin(data).then(res =>{
                if(res.code == 200) {
                    // this.onClose()
                    this.getList('')
                    this.setState({
                        editStatus:false,
                        current:{}
                    })
                }
            })
        }
    }
    componentDidMount() {
        this.getList({pageSize:this.state.pageSize,pageNum:this.state.pageNum})
    }
    componentWillReceiveProps() {
        console.log(this.props.userObj.admin,'is that can be got')
    }
    getList(data) {
        // 获取管理员账户
        API.adminList(data).then(res =>{
            // console.log(res)
            if(res.code == 200) {
                let list = res.data.content
                list.forEach(item =>{
                    item.createdTime = item.createdTime?Tool.timeChecker(item.createdTime):''
                })
                this.setState({
                    data:list,
                    total:res.data.totalElements
                })
            }
        })
    }
    // 分页功能
    changePage(page) {
        this.setState({
            pageNum:page 
        })
        this.getList({pageNum:page})
    }
    render(){
        return(
            <div>
                <div style={{display:'flex',justifyContent:'space-between',margin:'10px 30px'}}>
            
                <Button onClick={() =>{this.addAdminHandler()}}>添加</Button>
                <Button onClick={() => this.getExcel()}>
                    <Icon type="download"/>导出
                </Button>
                </div>
                {/* 管理员列表页面的弹出层 */}
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} total={this.state.total} changePage={this.changePage.bind(this)}></BasicTable>
            
                {/* <Upload type="file" accept=".xlsx, .xls" onChange={(event) => this.loadExcel(event)}>
                    <Button>
                        <Icon type="upload"/>上传
                    </Button>
                </Upload> */}
                {/* 点击出现添加和编辑页面的弹出层 */}
                <BasicModal visible={this.state.editStatus} onClose={this.onClose.bind(this)} type='admin' data={this.state.current} submit={this.submit.bind(this)}></BasicModal>
            </div>
        )
    }
}
function mapGetter(state) {
    return {
        userObj:state
    }
}
function mapAction(dispatch) {
return {
    regist:bindActionCreators(actions.regist,dispatch)
}
}
export default connect(mapGetter,mapAction)(Manager)
