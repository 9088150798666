import React, { Component } from 'react'
import { Modal } from 'antd'
import EditForm from './form_edit_maintainer'
import PartForm from './form_edit_part'
import MenuForm from './form_edit_menu'
import StrainerForm from './form_edit_strainer'
import AdminForm from './form_edit_admin'
import HotspotForm from './form_edit_hotspot'
import AcivityForm from './form_edit_activity'
import AskForm from './form_edit_ask'
import CommunityForm from './form_edit_community'
import EjectForm from './form_edit_eject'
import RealTimeForm from './form_edit_realtime'
class EditModal extends Component {
    constructor(props) {
        super(props)
    }
    state = {

    }
    confirm() {
        this.refs.editForm.submit()
    }
    initial() {
        console.log(this,this.refs,this.refs.editForm,'editForm')
        
    }
    submit(data) {
        this.props.submit(data)
        console.log(data)
    }
    close() {
        this.props.onClose()
        console.log(this)
    }
    componentWillReceiveProps() {
        // console.log(this.refs)
        // this.refs.editForm.initial()
    }
    
    renderForm() {
        switch (this.props.type||this.props.perType) {
            case 'maintainer':
                return <EditForm ref='editForm' data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'part':
                return <PartForm ref='editForm' data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)}  />
            case 'area':
                return <MenuForm ref='editForm' data={this.props.data}  onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'strainer':
                return <StrainerForm ref='editForm' data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)}/>
            case 'admin':
                return <AdminForm ref='editForm' data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'acivity':
                return <AcivityForm ref='editForm' componentType={this.props.type} data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'hotspot':
                return <HotspotForm ref='editForm' componentType={this.props.type}  data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'ask':
              return <AskForm ref='editForm' componentType={this.props.type} data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'community':
              return <CommunityForm ref='editForm' componentType={this.props.type} data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'eject':
              return <EjectForm ref='editForm' componentType={this.props.type} data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            case 'realtime':
              return <RealTimeForm ref='editForm' componentType={this.props.type} data={this.props.data} onClose={this.close.bind(this)} submit={this.submit.bind(this)} />
            default:
              return <div ref='editForm'>no match</div>
        }

    }
    render() {
        let type = (this.props.type=='ask')||(this.props.type=='community')||(this.props.type=='realtime')||(this.props.type=='acivity')
        return (
            <div>
               <Modal 
                title={this.props.title}  
                visible={this.props.visible} 
                onCancel={this.props.onClose} 
                onOk={this.confirm.bind(this)} 
                // width={this.props.foot?500:(type?'80%':800)} 
                width={type?'80%':800} 
                footer={false} 
                destroyOnClose='true'>
                  {this.renderForm()}
              </Modal>
            </div>
        )
    }

}

export default EditModal;