import React,{Component} from 'react'
import {Button,Table,Card,Input} from 'antd'
import {Link} from 'react-router-dom'

const { Search } = Input;
const columns = [
    {
      title: '订单编号',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '提交时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '用户账号',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: '收货人',
      key: 'receipt',
      dataIndex: 'receipt',
    },
    {
      title: '订单金额',
      dataIndex: 'price',
      key: 'price',
    },
    {
        title: '订单状态',
        dataIndex: 'state',
        key: 'state',
     },
    {
        title: '自动确认收货时间',
        dataIndex: 'zdshsj',
        key: 'zdshsj',
    },
    {
        title: '应收货时间',
        dataIndex: 'yshsj',
        key: 'yshsj',
    },
    {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        render: tags => (
            <span>
              <Link to="/app/order/detail"> 
                <Button size="small" style={{marginRight:8}}>查看订单</Button> 
              </Link> 
              <Button size="small">确认收货</Button> 
            </span>
        ),
    },
  ];
  const data =[];
  for(let i = 0; i < 46; i++) {
    data.push({
      key: i,
      id: i,
      time: '2020-01-11  12:00',
      user: 'New',
      receipt: '大力',
      price: '1200',
      state: '已发货',
      zdshsj: '15天',
      yshsj: '2020-01-11 12:09',
    })
  }
  
class OrderReceipt extends Component {
    
    render() {
        
        return(
            <div>
                <Card title="确认收货列表" extra={
                    <div>
                        <Search
                        placeholder="请输入搜索关键字"
                        enterButton="搜索"
                        onSearch={value => console.log(value)}
                        />
                    </div>
                } style={{ width: '100%' }}>
                    <Table columns={columns} dataSource={data} style={{textAlign:'center'}}/>
                </Card>
               
            </div>
        )
    }
}
export default OrderReceipt;