import React,{Component} from 'react'
import {Form,Input,Button,message,DatePicker,Switch,Select,Checkbox } from 'antd'
import API from '@/fetch/api'
import BasicUpload from '@/components/upload'
import BraftEditor from 'braft-editor'
import { ActivityTags } from './tag'
import {Time} from '../tool/util.js'
import moment from 'moment';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};
const renderAddressOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.districtName}</Select.Option>)
const renderTagOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
class RawForm extends Component {
    constructor(props) {
        super(props)
        // console.log(props)
    }
    state={
        img:'',
        city:'',
        name:'',
        content:'',
        beginDate:'',
        endDate:'',
        startEenTime:[],
        expirationTime:[],
        address:'',
        price:'',
        tagIds:[],
        cancelDeadline:'',
        expirationDate:'',
        isTop:false,
        addressList:[],
        getTagList:[],
        data:[],
        formStyle:{
            display:'flex',
            flexWrap:'wrap',
            // marginLeft:'50px',
            // alignItems:'center',
            justifyContent:'space-between'
        },
        optionLists: [
            {
              value: 'zhejiang',
              label: 'Zhejiang',
              isLeaf: false,
              children: [
                {
                  label: ` Dynamic 1`,
                  value: 'dynamic1',
                },
                {
                  label: ` Dynamic 2`,
                  value: 'dynamic2',
                },
             ]
            },
            {
              value: 'jiangsu',
              label: 'Jiangsu',
              isLeaf: false,
            },
        ],
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            accept:'image/*',
            multiple:true,
            disabled:false,
            fileList: this.props.data.fileList,
            maxCount:1,
            data:{token:''},
            type:'image',
            name:'file',
        },
    }
    submitForm=(e) => {
        console.log( this.state.isTop,'this',this.state.img,this.state.tagIds)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log( values,'values1',this.state.img)
            values.cancelDeadline =parseInt(values.cancelDeadline)
            values.willNum =parseInt(values.willNum)
            values.img = this.state.img
            // if(this.state.img.length != 0) {
            //     // values.img = this.state.img.toString()
            //     values.img = this.state.img
            // }else{
            //     values.img =  values.img.toString()
            // }
            values.content = this.state.outputHTML
            values.isTop=this.state.isTop
            values.tagIds = this.state.tagIds
            values.expirationDate = Time(values.expirationDate._d)
            values.beginDate =  Time(values.date[0]._d)
            values.endDate =  Time(values.date[1]._d)
            if (!err) {
                this.props.submit(values)
            }
            console.log( values.date,'values1')
        });
    }
    componentWillMount(){
        this.getQi()
        this.districtFullList()
        this.tagList()
    }
    // 初始化页面
    componentDidMount() {
        this.getQi()
        this.tagList()
        this.districtFullList()
        let data = this.props.data
        if(JSON.stringify(data) != "{}"){
            this.setState({
                isTop:data.isTop,
                tagIds:data.tagIds,
                img:data.img.toString(),
            })
        }
        // let arr =[]
        // data.label.split(',').forEach(item=>{
        //  arr.push(`label:${item}`)
        // })
        // data.label = arr
        // console.log(this.formRef.current.getFieldsValue('tagIds'))
        console.log(data,console.log( this.props.form.getFieldsValue()),)
        data.content = BraftEditor.createEditorState(data.content)
        data.expirationDate=moment(data.expirationDate)
        data.date = [moment(data.beginDate),moment(data.endDate)]
        // if(data.content != undefined){
        //     data.content = data.content.substr(1,data.content.length-2)
        // }
        this.props.form.setFieldsValue(data)
    }
    //获取标签列表tagList
    tagList(){
        let data =`tagTypeItems=${'OFFLINE_ACTIVITY'}`
        API.getTagList(data).then(res=>{
            // console.log(res.data)
            res.data.result.forEach((item,index)=>{
                item.value = item.id
                item.label = item.name
            })
            this.setState({
                getTagList:res.data.result
            })
        })
    }
    // 获取城市列表
    districtFullList(){
        API.districtFullList().then(res=>{
            // console.log(res.data)
            this.setState({
                addressList:res.data
            })
        })
    }
    
    submit=()=> {
        this.submitForm()
    }
     // 富文本
    changeRichText=(editorState) => {
        // 在点击文章内容的时候执行
        // console.log(editorState,'editorState',editorState.toHTML)
        this.setState({
            editorState: editorState,
            outputHTML: editorState.toHTML()
        })
    }
    handleChange = (key, event) => {
        // console.log(key,event.target.value,this.state.form)
        let form = this.state.form
        for (let item in this.state.form) {
            if (item === key) {
                form[item] = event.target.value
                this.setState({form: form})
          }
      }
    }
    // 置顶
    onChangeTop = (checked,event) => {
        console.log(`switch to ${checked} --`,checked,event);
        this.setState({
            isTop:event
        })
    };
    // 报名截止时间
    onChangeExpirationDate=(format) => {
        // console.log(moment().fotmat("YYYY-MM-DD HH:mm:ss"));expirationTime
        this.setState({
            expirationDate:format
        })
        console.log(this.state.expirationDate,this.state.expirationTime,format);
    };

    // 活动起止时间
    onChangeDate=(date,format) => {
        console.log(format,'起止时间')
        this.setState({
            // beginDate:Time(format[0]._d),
            // beginDate:Time(new Date (format[0]._d)),
            // endDate:Time(new Date (format[1]._d)),
            startEenTime:format
        })
    };
    changeCity(value, selectedOptions) {
        console.log(value, selectedOptions);
    }
    // 标签多选
    onChangeLabel= (e,key) => {
        console.log('checked = ', e,key,key.join());
        // let arr =[]
        // key.forEach((i,d)=>{
        //     this.state.getTagList.forEach((item,index)=>{
        //      if(i === item.id) {
        //         arr.push(item.name)
        //      }
        //   })
        // })
        // console.log(arr,arr.join())
        this.setState({
            tagIds:key
        })
    };
    // onClickTag(tag){
    //     console.log(tag,tag.join(),'taghhhhhhhhhhhh')
    //     this.setState({
    //         label:tag.join()
    //     })
    // }
     // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            // console.log(res)
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                // console.log(this.state.img.split(';').length)
                // if(this.state.img.split(',').length > 5){
                //     obj.disabled = true
                // }
                this.setState({
                    uploadConfig:obj
                })
                // console.log(this.state.uploadConfig)
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data){
        data = [...data];
        // console.log(data,'data',data.length)
        let title = [];
        let saveImg = [];
        data.forEach((fileInfo, index) => {
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }else{
                return saveImg.push(fileInfo.url)
            }
        })
        console.log('data', saveImg.concat(title))
        this.setState({
            img:saveImg.concat(title).toString(),
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form onSubmit={this.submitForm} >
              <div style={this.state.formStyle} className="edit-form-container">
                <Form.Item label="活动名称" name='name' >
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message:'姓名'
                            }
                        ]
                    })(<Input  width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item> 
                <Form.Item label="是否置顶" name='isTop' >
                    {getFieldDecorator('isTop', {
                        valuePropName: 'checked',
                    })(<Switch  onChange={this.onChangeTop.bind(this,'isTop')} />)}
                </Form.Item>
                <Form.Item label="报名费用" name='price' >
                    {getFieldDecorator('price', {
                        rules: [
                            {
                                required: true,
                                message:'请输入费用'
                            }
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>
                <Form.Item label="截止报名时间" name='expirationDate' >
                    {getFieldDecorator('expirationDate', {
                         rules: [
                            {
                                required: true,message:'请选择时间'
                            }
                        ]
                    })(<DatePicker  format="YYYY-MM-DD HH:mm:ss" showTime  onChange={this.onChangeExpirationDate.bind(this,'expirationDate')} />)}
                </Form.Item>
                <Form.Item label="详细地址" name='address' >
                    {getFieldDecorator('address', {
                        rules: [
                            {
                                required: true,
                                message:'请输入详细地址'
                            },
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>
                <Form.Item label="取消报名限期" name='cancelDeadline' >
                    {getFieldDecorator('cancelDeadline', {
                         rules: [
                            {
                                required: true,message:'请输入时间'
                            }
                        ]
                    })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
                </Form.Item>  
                <Form.Item label="所属城市" name='city' >
                    {getFieldDecorator('districtId', {
                        rules: [
                            {
                                required: true,
                                message:'请选择城市'
                            }
                        ]
                    // })(<Input  width={200} style={{display:'inline-block',width:200}}></Input>
                    })(
                    <Select placeholder='请选择城市' optionFilterProp="children" style={{ width: 200 }} showSearch>
                        {this.state.addressList.map(item => (
                            renderAddressOptions(item)
                        ))}
                    </Select>
                    // <Cascader options={this.state.optionLists}  onChange={this.changeCity.bind(this,'city')} changeOnSelect />
                   )}
                </Form.Item>
                <Form.Item label="起止时间" name='date'>
                    {getFieldDecorator('date', {
                         rules: [
                            {
                                required: true,message:'请选择时间'
                            }
                        ]
                    })(
                       
                      <RangePicker  format="YYYY-MM-DD HH:mm:ss" onChange={this.onChangeDate.bind(this,'date')} />
                    )}
                </Form.Item>
                <Form.Item   labelCol={{span:3}} name='tagIds' label="标签" >
                   {getFieldDecorator('tagIds', {
                        rules: [
                            {
                                required: false,
                                message:'请选择标签'
                            }
                        ]
                    })(
                        <Checkbox.Group  style={{marginLeft:'15px'}} options={this.state.getTagList}   onChange={this.onChangeLabel.bind(this,'label')} />
                    //  <Select placeholder='请选择标签' optionFilterProp="children" style={{ width: 200 }} showSearch>
                    //   {this.state.getTagList.map(item => (
                    //     renderTagOptions(item)
                    //   ))}
                    //  </Select>
                    )}
                    {/* <ActivityTags  tags={this.props.data.label} onClickTag={this.onClickTag.bind(this)} /> */}
                </Form.Item>
                <Form.Item {...formItemLayout} wrapperCol={{span:24}}  label="活动图片" name="img">
                    {getFieldDecorator('img', {
                        rules: [
                        {
                            required: false,
                            message: '内容不能为空',
                        },
                        ],
                    })(
                        <BasicUpload 
                        {...this.state.uploadConfig}
                        componentType={this.props.componentType} 
                        getUrlList={this.getUrlList.bind(this)} 
                      ></BasicUpload>)
                    }
                </Form.Item>
                <Form.Item {...formItemLayout}  style={{width:"100%",marginLeft:'-2%'}}  labelCol={{span:2}} wrapperCol={{span:24}}   label="社区内容" >
                {getFieldDecorator('content', {
                    // rules: [
                    // {
                    //     required: true,
                    //     message: '内容不能为空',
                    // }
                    // ],
                })( <BraftEditor
                    style={{height:'100%',margin:"10px",width:"80%",paddinf:"5px",border:"1px solid #CCC"}}
                    value={this.state.outputHTML}
                    onChange={this.changeRichText}
                    />)}
                </Form.Item>
              </div>
              <Form.Item style={{
                width: 'calc(100% + 48px)',
                marginLeft:'-24px',
                marginBottom:'0',
                paddingTop:'3px',
                justifyContent: 'flex-end',
                paddingRight: '24px',
                borderTop:'1px solid #ebebeb'}}>
                  <Button onClick={this.props.onClose} style={{marginRight:'10px'}}>取消</Button>
                  <Button htmlType='submit' type='primary' >确定</Button>
              </Form.Item>
            </Form>
        )
    }
}
const ActivityFrom =Form.create({name:'edit'})(RawForm)
export default ActivityFrom;