import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

class CountTextArea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      targetValue: '',
    };
  }

  handleChange = value => {
    let targetValue = value.target.value;
    this.setState({
      targetValue,
    });
    console.log(this.state.targetValue,11)
  };

  render() {
    const {  setRef } = this.props;
    const { targetValue } = this.state;

    return (
      <>
        <TextArea
          onChange={this.handleChange}
          onTextValue={ this.props.onTextValue(this.state.targetValue) }
          value={targetValue}
          ref={setRef}
        />
        <p>{targetValue.length}/100</p>
      </>
    );
  }
}

export default CountTextArea;