import api from '../../../fetch/api'
import React from 'react'
import {Button} from 'antd'
import {Route,Redirect} from 'react-router-dom'
import { render } from '@testing-library/react'

const list = (state = [], action) => {
  console.log(Route,'rouer')
  //
  switch (action.type) {
    
    case 'fetch-list':
     console.log(state,action)
    return  [
        { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 2 },
        { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 3 },
        { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 11 },
        { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 22 },
        { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 33 },
        { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 12 },
        { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 21 },
        { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 31 },
        { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 71 },
        { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 72 },
        { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 73 },
        { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 711 },
        { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 722 },
    ]
      api.getList().then(res => {
        console.log(res)
        if (res.code == 200) {
          return res.data

        }
      })
      case 'order-person':
        console.log(action.data)
        return [
          { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 2 },
          { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 3 },
          { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 11 },
          { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 21 },
          { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 31 },
          { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 71 },
          { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 72 },
          { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 73 },
          { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 711 },
          { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 722 },
      ]
    default:
      
      return []
  }
}

export default {
  list
}
