import OrderList from './index'
import OrderCancel from './order_cancel'
import OrderBatch from './order_batch'
import OrderBatchPrint from './order_batch_print'
import OrderChange from './order_change'
import OrderDeliveryLocation from './order_change'
import OrderDetail from './order_detail'
import OrderExpress from './order_express'
import OrderReceipt from './order_receipt'
import OrderReturnDetail from './order_return_detail'
import OrderReturnReason from './order_return_reason'
import OrderReturn from './order_return'
import OrderSetting from './order_setting'
import OrderTrack from './order_track'
import Refund from './refund'
import RefundDetail from './refund_detail'

export default {
    OrderList,
    OrderCancel,
    OrderBatch,
    OrderBatchPrint,
    OrderChange,
    OrderDeliveryLocation,
    OrderDetail,
    OrderExpress,
    OrderReceipt,
    OrderReturnDetail,
    OrderReturnReason,
    OrderReturn,
    OrderSetting,
    OrderTrack,
    Refund,
    RefundDetail
}