import fetch from '../fetch'
// 添加商品分类
const addCategory=(data)=>{
    return fetch('goods/category','POST',data)
}
// 获取商品分类
const getCategory=(data)=>{
    return fetch('goods/category/list','GET',data)
}

const updateCategory = (data) => {
    return fetch('goods/category', 'PUT', data)
}

// 删除商品分类
const deleteCategory = (data) => {
    return fetch('goods/category/'+ data, 'DELETE', '')
}

// 添加商品
const addGood = (data)=>{
    return fetch('goods','POST',data)
}
// 修改具体商品
const deleteGood = (data) => {
    return fetch('goods/edit','PUT',data)
}
// 修改商品
const removeGood = (data)=>{
    return fetch('goods?id='+data,'PUT',data)
}
// 根据id获取商品详情
const getDetail = (data)=>{
    return fetch('goods/'+data,'get','')
}
// 删除商品列表里的商品
const delGoods = (data)=>{
    return fetch('goods?id='+data,'DELETE','')
}
// 将删除的商品恢复
const getResume = (data)=>{
    return fetch('goods/resume?id=' + data,'PUT','')
}
// 删除回收站的商品
const delPhysicalGoods=(data)=>{
    return fetch('goods/physical?id='+data,'DELETE','')
}
// 获取商品列表
const getGoods=(data)=>{
    return fetch('goods/list','GET',data)
}
// 获取商品评价列表
const goodsComments=(data)=>{
    return fetch('goods/comment/list','GET',data)
}
// 获取订单列表
const orderList=(data)=>{
    return fetch('order/list','GET',data)
}
// 根据id删除订单 +data +'?reason=' + reason
const delOrder=(data,reason)=>{
    return fetch('order/cancel','DELETE','')
}
const orderDetail=(data)=>{
    return fetch('order/'+data,'GET','')
}
// 审核不通过
const orderCancel=(data)=>{
    return fetch('order/reject/'+data,'PUT','')
}

const orderDeliver=(data)=>{
    console.log(data,'request data')
    return fetch('order/'+data.id+'?logistics='+data.logistics+'&logisticsNumber='+data.logisticsNumber,'PUT','')
}
// 
const QiAuth =()=>{
    return fetch('qiniu/auth','GET','')
}
// 获取图片类型列表
const getImageType=()=>{
    return fetch('image/type/list','GET','') 
}
// 更新图片排序
const putImageType=(data)=>{
    return fetch('image/updateImage','PUT',data) 
}
// 获取某一类型下的所有图片
const getAllImages=(data)=>{
    return fetch('image/getAllImages','GET',data) 
}
// 获取所有的焦点图
const bannerList=()=>{
    return fetch('image/getAllFocus','GET','') 
}
// 上传焦点图
const uploadBanner=(data) =>{
    return fetch('image/uploadFocus','POST',data)
}
// 上传图片
const uploadImage=(data) =>{
    return fetch('image/uploadImage','POST',data)
}
// 根据id删除图片
const deleteImage=(data) =>{
    return fetch('image/'+data,'DELETE','')
}
// 获取优惠券列表
const couponList=(data) =>{
    return fetch('goods/coupon/list','GET',data)
}
export default {
    orderCancel,
    putImageType,
    delOrder,
    uploadImage,
    delPhysicalGoods,
    delGoods,
    getResume,
    getAllImages,
    getImageType,
    getDetail,
    addCategory,
    getCategory,
    addGood,
    deleteGood,
    removeGood,
    getGoods,
    goodsComments,
    orderList,
    orderDetail,
    orderDeliver,
    QiAuth,
    bannerList,
    uploadBanner,
    deleteImage,
    couponList,
    updateCategory,
    deleteCategory
}