import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import pages from '../pages';
import {  message, Button } from 'antd'
import routesConfig from './config';
import queryString from 'query-string';
import {connect} from 'react-redux'
 class CRouter extends Component {
  
    componentWillMount() {
      
    }
      warning  = (value) => {
        message.warning (value);
      }
    render() {
     
        return (
            <Switch>
                {  
                    Object.keys(routesConfig).map(key =>
                       
                    routesConfig[key].map((r,index) => {
                        // console.log(r)
                        // console.log(routesConfig,key,'routesConfig')
                        const username = localStorage.getItem('username')
                        if(username == 'guest'&& r.title == '商品管理'){
                            this.warning.bind (this,'该用户赞无商品管理权限')
                            routesConfig[key].splice(index,1)
                            console.log(routesConfig[key],'2222')
                            routesConfig[key].map(item => {
                                const route = r => {
                                    const Component = pages[item.component];
                                    return (
                                        <Route
                                            key={ item.key}
                                            exact
                                            path={ item.key}
                                            render={props => {
                                                const reg = /\?\S*/g;
                                                const queryParams = window.location.hash.match(reg);                                 
                                                const { params } = props.match;
                                                Object.keys(params).forEach(key => {
                                                    params[key] =
                                                        params[key] && params[key].replace(reg, '');
                                                });
                                                props.match.params = { ...params };
                                                const merge = {
                                                    ...props,
                                                    query: queryParams
                                                        ? queryString.parse(queryParams[0])
                                                        : {},
                                                };
                                                const wrappedComponent = (
                                                    <DocumentTitle title={item.title}>
                                                        <Component  {...merge} />
                                                    </DocumentTitle>
                                                );
                                                return wrappedComponent
                                            }}
                                        />
                                    );
                                };
                                return item.component ? route(item) : item.subs.map(item => route(item)); 
                            })
                        }
                    
                        const route = r => {
                            // console.log(r,'rrrrrrrr')
                            const Component = pages[r.component];
                            return (
                                <Route
                                    key={ r.key}
                                    exact
                                    path={ r.key}
                                    render={props => {
                                        const reg = /\?\S*/g;
                                        const queryParams = window.location.hash.match(reg);                                 
                                        const { params } = props.match;
                                        Object.keys(params).forEach(key => {
                                            console.log(params,'params')
                                            params[key] =
                                                params[key] && params[key].replace(reg, '');
                                        });
                                        props.match.params = { ...params };
                                        const merge = {
                                            ...props,
                                            query: queryParams
                                                ? queryString.parse(queryParams[0])
                                                : {},
                                        };
                                        const wrappedComponent = (
                                            <DocumentTitle title={r.title}>
                                                <Component  {...merge} />
                                            </DocumentTitle>
                                        );
                                        return wrappedComponent
                                    }}
                                />
                            );
                        };
                        return r.component ? route(r) : r.subs.map(r => route(r));
                    })                
                    
                )}
                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        );
    }
}

export default connect()(CRouter);