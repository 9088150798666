import React,{Component} from 'react'
import {Input,Button,DatePicker, Select} from 'antd'

import {connect} from 'react-redux'
import BasicTable from '../../components/Table'
import API from '@/fetch/api'
import Tool from '@/global/tool'
class Objection extends Component {
    state={
        data:[],
        columns:[
            { title: '用户id', dataIndex: 'id', key: 'id' },
            { title: '昵称', dataIndex: 'username', key: 'username' },
            { title: '日期', dataIndex: 'createdTime', key: 'createTime' },
            { title: '联系方式', dataIndex: 'phone', key: 'phone' },
            { title: '反馈内容', dataIndex: 'feedback', key: 'feedback' },
        ],
        rows:{
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },
        },
        total:1,
        searchObj:{
            pageNum:1,
            pageSize:10,
            startTime:'',
            endTime:'',
            phone:"",
            name:"",
        }
    }
    commitSearch() {
        this.getList(this.state.searchObj)
    }
    getValue=(e)=>{
        let obj = {...this.state.searchObj}
        obj.name = e.target.value
        this.setState({
            searchObj:obj
        })
    }
    getPhone=(e)=>{
        let obj = {...this.state.searchObj}
        obj.phone = e.target.value
        this.setState({
            searchObj:obj
        })
    }
    getDate=(date,time)=>{
        let obj = {...this.state.searchObj}
        obj.startTime =time[0]
        obj.endTime =time[1]
        this.setState({
            searchObj:obj
        })
    }
    getStatus(e){
        console.log(e)
    }
    componentWillMount() {
        this.getList(this.state.searchObj)
        
    }
    changePage(data){
        let obj = {...this.state.searchObj}
        obj.pageNum = data
        this.getList(obj)
    }
    getList(data) {
        API.feedBackList(data).then(res=>{
            console.log(res)
            if(res.code == 200) {
                // console.log(this,'this')
                let list = res.data.result
                list.forEach(item =>{
                    item.createdTime = Tool.timeChecker(item.createdTime)
                })
                this.setState({
                    data:res.data.result,
                    total:res.data.totalCount
                })
            }
        })
    }
    render() {
        
        return (
            <div >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                        {/* <p style={{width:"20%"}}>{this.props.title}</p> */}
                        <div className="flex-row" style={{ width: "25%" }}> <p style={{ width:60 }}>姓名：</p><Input onChange={this.getValue} className="inline-input" style={{width:300}}></Input></div>
                        <div className="flex-row" style={{width:"25%"}}> <p style={{ width:60 }}>电话：</p><Input style={{width:300}} onChange={this.getPhone} className="inline-input" width={400}></Input></div>
                        <div className="flex-row" style={{width:"25%"}}> <p style={{ width:100 }}>投诉时间：</p><DatePicker.RangePicker className="RangePicker" onChange={this.getDate}></DatePicker.RangePicker></div>
                        {/* <div className="flex-row" style={{width:"15%"}}> <p style={{ maxWidth: 100,minWidth:60 ,marginBottom:0 }}>处理状态：</p>
                        <Select className="inline-input" defaultValue='' onChange={this.getStatus}>
                            <Select.Option value='1'>未处理</Select.Option>
                            <Select.Option value='2'>已处理</Select.Option>
                            <Select.Option value=''>所  有</Select.Option>
                        </Select></div> */}
                        {/* {<div ><Button onClick={() => this.props.searchHandler}>刷新</Button></div>} */}
                        {/* <div><Button onClick={() => this.addPart()}></Button></div> */}
                        <div style={{ width: "10%" }}><Button onClick={() => this.commitSearch()}>搜索</Button></div>
                    </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.rows} total={this.state.total} changePage={this.changePage.bind(this)} rowKey='id'></BasicTable>
            </div>
            
        )
    }
}
export default connect()(Objection); 