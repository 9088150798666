import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, message} from 'antd'
import actions from '../../store/actions/index'
import { bindActionCreators } from 'redux'
import BasicTable from '../../components/Table'
import EditModal from '../../components/modal_edit'
import SearchHeader from '../../components/search_header'
import API from '@/fetch/api'
import axios from 'axios';
import './part_manage.css';
import Tool from '@/global/tool'
// excel导入导出
import XLSX from 'xlsx';
class PartManage extends Component {
    state = {
        data: [],
        columns: [
            { title: '名称', dataIndex: 'name', key: 'name' },
            { title: '类型', dataIndex: 'type', key: 'type' },
            { title: '价格', dataIndex: 'price', key: 'price' },
            // { title: '工费', dataIndex: 'laborCharges', key: 'laborCharges' },
            { title: '数量', dataIndex: 'stockQuantity', key: 'stockQuantity' },
            { title: '风量', dataIndex: 'airVolume', key: 'airVolume' },
            {
                title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                    <div>
                        <span>
                            <Button style={{marginRight:10}} type='primary' onClick={() => this.eiditMan(record)}>编辑</Button>
                        </span>
                        <span>
                            <Button type='danger' onClick={() => this.delMan(record)}>删除</Button>
                        </span>
                    </div>
                )
            },
        ],
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },

        },
        editStatus: false,
        total:1,
        searchObj:{
            keyword:'',
            pageNum:1
        },
        defaultCurrent:1,
        current:{}
    }
    onClose() {
        this.setState({
            editStatus: false
        })
    }
    addPart() {
        this.setState({
            editStatus: true,
            current:{}
        })
    }
    // 删除零件
    delMan(recode){
        console.log(recode)
        API.delSpare(recode.id).then(res=>{
            console.log(res)
            this.getList(this.state.searchObj)
        })
    }
    // 零件编辑
    eiditMan(recode){
        console.log(recode)
        this.setState({
            editStatus: true,
            current:recode
        })
    }
    // 页码改变时
    changePage=(page)=> {
        let {searchObj} = this.state
        searchObj.pageNum=page
        this.setState({
            searchObj
        })
        this.getList(this.state.searchObj)
        console.log(page)

    }
    // 搜索
    commitSearch(data) {
        this.getList(this.state.searchObj)
    }
    // 零件名称
    getValue=(e)=> {
        console.log(e.target.value)
        let {searchObj} = this.state
        searchObj.keyword= e.target.value
        searchObj.pageNum=1
        this.setState({
            searchObj
        })
    }
    // 新增或修改零件
    submit(data) {
        console.log(data)
        // console.log(this.state.current)
        if(data.id){
            API.putSpare(data).then(res=>{
                console.log(res)
                this.getList(this.state.searchObj)
                this.setState({
                    editStatus: false,
                })
                
            })
        }else{
            API.partAdd(data).then(res=>{
                console.log(res)
                this.getList(this.state.searchObj)
                this.setState({
                    editStatus: false,
                })
            })
        }
    }
    // 获取零件列表
    getList(data) {
        API.partList(data).then(res=>{
            if(res.code == 200) {
                this.setState({
                    data:res.data.content,
                    total:res.data.totalElements
                })
            }
        })
    }
    componentDidMount() {
        this.getList(this.state.searchObj)
    }
    // 成功或失败弹框
    success = (value) => {
        message.success(value);
    }

    error = (value) => {
        message.error(value);
    }

//   excel导入
importExcel=(event)=>{
    let _this=this
    // 使用formdata的形式传给后端，注意formdata里面添加的excelFile需与后端定义的一致,且Content-Type为multipart/form-data
    var file = event.target.files[0];
    var formData = new FormData();
    formData.append( "excelFile", file)
    axios({
        method: 'post',
        // url: 'http://192.168.1.110:443/admin/spareParts/uploadExcel',
        url: 'https://www.huikeji.shop/admin/spareParts/uploadExcel',
        // url: 'https://www.huikeji.shop:8045/admin/spareParts/uploadExcel',
        data:formData,
//              data:{appkey: "njssdjtkj",sign:sign,sign_method:"MD5",timestamp:time,mobile:"15951971791",engine_no:"070939",plate_type:"02",plate_no:"苏EXW365"},
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'token': localStorage.getItem('token'),
            }
        })
        .then(function (res) {
            if(res.data.code===200){
                _this.success('上传成功')
                let {searchObj} = _this.state
                searchObj.keyword= ''
                searchObj.pageNum=1
                _this.setState({
                    searchObj
                })
                _this.getList(_this.state.searchObj)
            }else {
                _this.error('上传失败')
            }
        })
    }
//   excel导出
    exportExcel(headers, data, fileName = '零件库.xlsx') {
        const _headers = headers
            .map((item, i) => Object.assign({}, { key: item.key, title: item.title, position: String.fromCharCode(65 + i) + 1 }))
            .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {});
        const _data = data
            .map((item, i) => headers.map((key, j) => Object.assign({}, { content: item[key.key], position: String.fromCharCode(65 + j) + (i + 2) })))
            // 对刚才的结果进行降维处理（二维数组变成一维数组）
            .reduce((prev, next) => prev.concat(next))
            // 转换成 worksheet 需要的结构
            .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { v: next.content } }), {});

        // 合并 headers 和 data
        const output = Object.assign({}, _headers, _data);
        // 获取所有单元格的位置
        const outputPos = Object.keys(output);
        // 计算出范围 ,["A1",..., "H2"]
        const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;

        // 构建 workbook 对象
        const wb = {
            SheetNames: ['mySheet'],
            Sheets: {
                mySheet: Object.assign(
                    {},
                    output,
                    {
                        '!ref': ref,
                        '!cols': [{ wpx: 45 }, { wpx: 100 }, { wpx: 200 }, { wpx: 80 }, { wpx: 150 }, { wpx: 100 }, { wpx: 300 }, { wpx: 300 }],
                    },
                ),
            },
        };

        // 导出 Excel
        XLSX.writeFile(wb, fileName);
    }
// excel模板下载
    excelDownload(){
        // window.location.href = `http:192.168.1.110:443/excel/spareParts.xlsx`  
        // const href = `http://192.168.1.110:443/admin/spareParts/getExcelDemo`
        const href = `https://www.huikeji.shop/admin/spareParts/getExcelDemo`
        // const href = `https://www.huikeji.shop:8045/admin/spareParts/getExcelDemo`
              axios.get(href,{
                headers: {
                'Access-Control-Allow-Origin': '*',
                'token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
                },
                responseType:'blob'             //转化后台传过来的文件流。很重要！
              },
              )
                .then(res=>{
                    const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a');
                    link.href = url;
                    // 从header中获取服务端命名的文件名
                    const fileName = '零件库.xlsx';
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
        })
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 10 }}>
                    <div className="flex-row" style={{ width: "25%" }}> <p style={{ width: 100 }}>零件名称：</p><Input onChange={this.getValue} className="inline-block" width={200} value={this.state.searchObj.keyword}></Input></div>
                    <div  style={{ width:"6%",marginLeft:"20px"}}><Button type='primary' onClick={() => this.commitSearch()}>搜索</Button></div>
                    <div><Button type='primary' onClick={() => this.addPart()} style={{marginLeft:"20px"}}>新增零件</Button></div>
                    <div className="file">excel上传<input type='file' accept='.xlsx, .xls' onChange={this.importExcel}/></div>
                    <div><Button style={{marginLeft:"20px"}} onClick={this.excelDownload}>excel模板下载</Button></div>
                </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} changePage={this.changePage} total={this.state.total}  rowKey='id'>

                </BasicTable>

                <EditModal data={this.state.current} visible={this.state.editStatus} onClose={this.onClose.bind(this)} type='part' submit={this.submit.bind(this)}></EditModal>
            </div>
        )
    }
}
export default connect()(PartManage);
