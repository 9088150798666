import React,{Component} from 'react'
import {Drawer,Badge ,Button} from 'antd'
const maintainRender = (data,self) => (
    <div className="flex-row space-between" key={data.id}>
        <div className="flex-column">
        <div>{data.name}</div>

        <div>
            <Badge status="success" />{data.status}</div>
        </div>
    <div><Button style={{backgroundColor:'#800080',border:'none',color:'#fff'}} onClick={() =>{self.props.getPerson(data)}}>指派</Button></div>
        </div>
);
class BasicDrawer extends Component {
    constructor(props){
        super(props)
    }
    state={
        visible:false
    }
    render () {
        return (
            <div>
               <Drawer title={this.props.title} placement='right' visible={this.props.visible} onClose={this.props.onClose} closable={false}>
                    {this.props.data.map(data =>{
                       return  maintainRender(data,this)
                    })}
               </Drawer>
            </div>
        )
    }
}
export default BasicDrawer;