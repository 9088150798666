import React,{Component} from 'react'
import {Form,Input,Button,Select} from 'antd'
const renderOptions = (item) => (
    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
class RawForm extends Component {
    const 
    constructor(props) {
        super(props)
    }
    state={
        addressList:[
          {name:'上海',id:'1'}, 
          {name:'北京',id:'2'}, 
          {name:'广州',id:'3'}, 
        ],
        positionList:[
            {name:'超级管理员',id:'ADMINX'}, 
            {name:'管理员',id:'ADMIN'}, 
            {name:'客服',id:'SERVICER'}, 
          ],
          formStyle:{
            //   display:'flex',
            //   flexWrap:'wrap',
            //   alignItems:'center',
            //   justifyContent:'space-between'
          }
    }
    submitForm=(e) => {
        console.log(this,'this')
        e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // this.props.submit(values)
        console.log(values,'values');
      }
     
    });
    }
    componentDidMount() {
        console.log('enter')
      this.initial()
    }
    initial() {
        let obj={
            name:'',
            state:'',
            phone:'',
            username:'',
            password:'',
        }
        // if (this.props.data.name) {
        //     let data = this.props.data
        //     console.log(data,'data')
        //    obj.name= data.name
        //    obj.state=data.state
        //    obj.phone = data.phone
        //    obj.password = data.password
        //    obj.username=data.username
        // } 
        // this.props.form.setFieldsValue(obj)
    }
    submit() {
        this.submitForm()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 8 },
          };
        return (
            <div style={{marginLeft:'100px'}} >
            <Form onSubmit={this.submitForm} labelAlign='left'>
                <div style={this.state.formStyle} className="add-goods-form-container">
    
        <div>成长规则</div>
        <Form.Item label="每连续签到" {...formItemLayout}>
            {getFieldDecorator('Sign', {
                rules: [
                    {
                        required: true,
                        message:'请输入连续签到天数'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
        <Form.Item label="额外奖励" {...formItemLayout}>
            {getFieldDecorator('exrta', {
                rules: [
                    {
                        required: true,
                        message:'额外奖励积分'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
      
        {/* <Form.Item label="密码">
            {getFieldDecorator('password', {
                rules: [
                    {
                        required: true,message:'请输入密码'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item> */}
        <Form.Item label="购物满" {...formItemLayout}>
            {getFieldDecorator('total', {
                rules: [
                    {
                        required: true,
                        message:'请输入商品总价'
                    }
                ]
            })(<Input.TextArea width={300} style={{display:'inline-block',width:200}}></Input.TextArea>)}元奖励1积分
        </Form.Item>
        <Form.Item label="商品单价低于" {...formItemLayout}>
            {getFieldDecorator('unitPrice', {
                rules: [
                    {
                        required: true,
                        message:'请输入商品单价'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}不送积分
        </Form.Item> <Form.Item label="市场价" {...formItemLayout}>
            {getFieldDecorator('marketPrice', {
                rules: [
                    {
                        required: true,
                        message:'请输入市场价'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
        <Form.Item label="库存" {...formItemLayout}>
            {getFieldDecorator('inventory', {
                rules: [
                    {
                        required: true,
                        message:'请输入库存'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item> <Form.Item label="单位" {...formItemLayout}>
            {getFieldDecorator('unit', {
                rules: [
                    {
                        required: true,
                        message:'请输入单位'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
        <div>消费规则</div>
        <Form.Item label="订单金额每" {...formItemLayout}>
            {getFieldDecorator('totalAmount', {
                rules: [
                    {
                        required: true,
                        message:'请输入抵扣规则'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}抵扣1元
        </Form.Item>
        <Form.Item label="最高可抵扣订单金额比例" {...formItemLayout}>
            {getFieldDecorator('discount', {
                rules: [
                    {
                        required: true,
                        message:'请输入抵扣比率'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item>
      
        {/* <Form.Item label="密码">
            {getFieldDecorator('password', {
                rules: [
                    {
                        required: true,message:'请输入密码'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}
        </Form.Item> */}
        <Form.Item label="使用时的倍数" {...formItemLayout}>
            {getFieldDecorator('phone', {
                rules: [
                    {
                        required: true,
                        message:'请输入倍数'
                    }
                ]
            })(<Input.TextArea width={300} style={{display:'inline-block',width:200}}></Input.TextArea>)}元奖励1积分
        </Form.Item>
        <Form.Item label="商品单价低于" {...formItemLayout}>
            {getFieldDecorator('danjia', {
                rules: [
                    {
                        required: true,
                        message:'请输入单价'
                    }
                ]
            })(<Input width={200} style={{display:'inline-block',width:200}}></Input>)}不送积分
        </Form.Item>
        </div>
        <Form.Item >
            <Button htmlType='submit' type='primary'>提交</Button>
        </Form.Item>
    </Form>
    </div>
        )
    }
}
const IntegralRulerForm =Form.create({name:'edit'})(RawForm)
export default IntegralRulerForm;