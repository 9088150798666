import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, message} from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators, bindActionCreator } from 'redux'
import actions from '../store/actions'
import LoginForm from '../components/form_login'
import axios from 'axios'
import API from '@/fetch/api'

class Login extends Component {
    
    state={
        loading:false
    }
    login(data) {
        // this.props.history.push({pathname:'/app/home'})
        // this.props.setToken(10)http://192.168.1.110:443/admin/
        // console.log(data)
        // axios.post('https://www.huikeji.shop/admin/auth/login',data).then(res =>{
        // 统一设置登录按钮loading
        this.setState({
            loading:true
        })
        API.login(data).then( res => {
            console.log("登录用户的信息",res.data)
          // console.log(res,'resss')
            // console.log(res)
            // console.log(res.data.data.role)
            // localStorage.setItem("role",res.data.data.role)
            // var role = localStorage.getItem("role")
            // console.log(role)
            // if(res.data.code == 200) {
            if(res.code == 200) {
                message.success('登录成功');
                localStorage.setItem('token',res.data.token)
                localStorage.setItem('username',res.data.username)
                localStorage.setItem('districtId',res.data.districtId)
                localStorage.setItem('role',res.data.role)
                this.props.history.push({pathname:'/app/home'})
                this.setState({
                    loading:false
                })
            }else{
                message.error(res.message);
                this.setState({
                    loading:false
                })
            }
        })
    
    }
    render() {
        return (
            <div style={{display:'flex',justifyContent:'center',height:'100%',alignItems:'center'}}>
                <LoginForm login={this.login.bind(this)} loading={this.state.loading}></LoginForm>
            </div>
        )
    }
}
function mapAction(dispatch) {
    return {
        setToken: bindActionCreators(actions.setToken, dispatch)
    }
}
export default connect(null, mapAction)(Login);