import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Upload, Button, Icon,DatePicker,Select } from 'antd'
import BasicTable from '@/components/Table'
import Tools from '../../global/tool'
class IntegralDetail extends Component {
    state = {
        data: [
            { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 2 },
            { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 3 },
            { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 11 },
            { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 22 },
            { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 33 },
            { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 12 },
            { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 21 },
            { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 31 },
            { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 71 },
            { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 72 },
            { title: 'rap', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 73 },
            { title: '唱', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 711 },
            { title: '跳', address: '南京新世界中心', date: '2020-03-03,2020-03-05', contact: '寻鲲菜菜子', time: '08:00-18:00', key: 722 },
        ],
        columns: [
            { title: '积分来源', dataIndex: 'title', key: 'title' },
            { title: '积分变化', dataIndex: 'address', key: 'address' },
            { title: '时间', dataIndex: 'date', key: 'date' },
            { title: '备注', dataIndex: 'contact', key: 'concact' },
          
        ],
        row: {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected, selectedRows) => {
                console.log(record, selected, selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);
            },
        },
        initColumn: [{
            title: '商品名称',
            dataIndex: 'title',
            key: 'title',

        }, {
            title: '编号',
            dataIndex: 'address',
            key: 'address',
        }, {
            title: '数量',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '销量',
            dataIndex: 'contact',
            key: 'contact',
        }
        ],

        attendanceInfoList: [
            {
                name: "张三",
                grade: "2017级",
                department: "前端部门"

            },
            {
                name: "李四",
                grade: "2017级",
                department: "程序部门"

            }],
        total: 18
    }
    getExcel() {
        Tools.exportExcel(this.state.initColumn, this.state.data, '测试excel.xlsx')
    }
    loadExcel(event) {
        Tools.importExcel(event).then(res => {
            console.log(res)
        })
    }
    eidtItem(data) {
        console.log(data, 'check manager ')
    }
    checkItem(data) {
        this.props.history.push({ pathname: '/app/goods/detail/' + data.key + '?count=' + data.key })
    }
    changePage(page) {
        console.log(page)
    }
    getDate=(e,date)=>{
        console.log(e,date)
    }
    addGoods() {
        this.props.history.push('/app/goods/add')
    }
    render() {
        return (
            <div>
                <div><span className='custom-integral-item'>用户账号：233
                    </span> 
                    <span className='custom-integral-item'>用户昵称：233
                    </span>
                    <span className='custom-integral-item'>会员等级：233
                    </span>
                    <span className='custom-integral-item'>可用积分：233
                    </span>
                    <span className='custom-integral-item'>历史积分
                    </span>  </div>
                <div style={{display:'flex'}}>
                    <div style={{margin:'10px'}}>
                        积分来源：<Select  defaultValue='1'>
                            <Select.Option value='1'>会员充值</Select.Option>
                            <Select.Option value='2'>手动修改</Select.Option>
                        </Select>
                    </div>
                    <div  style={{margin:'10px'}}>
                        操作时间：<DatePicker.RangePicker onChange={this.getDate}></DatePicker.RangePicker>
                    </div>
                </div>
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} total={this.state.total} changePage={this.changePage}></BasicTable>
                <Button onClick={() => this.getExcel()}>
                    <Icon type="download" />下载
                </Button>
                <Upload type="file" accept=".xlsx, .xls" onChange={(event) => this.loadExcel(event)}>
                    <Button>
                        <Icon type="upload" />上传
                    </Button>
                </Upload>
            </div>
        )
    }
}
export default connect()(IntegralDetail);