import React from 'react';
import { Input, InputNumber, Form } from 'antd';
import { EditableContext } from './TableContext';

const FormItem = Form.Item;

class Editable extends React.Component {
    getInput = () => {
        const { inputType } = this.props;
        if (inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };
    renderCell = ({ getFieldDecorator }) => {
        const {editing, dataIndex,title,inputType,record,index,children,...restProps} = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    // message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput())}
                    </FormItem>
                ) : (
                    children
                )}
            </td>
        );
    };
    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}
const EditableCell = Form.create({name:'edit'})(Editable)
export default EditableCell;