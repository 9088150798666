import React,{Component} from 'react'
import {message,Button, Form, Input,Select} from 'antd'
import BasicUpload from '@/components/upload'
import BraftEditor from 'braft-editor'
import API from '@/fetch/api'
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={
        hotspotType:'',
        typeValue:'',
        typeInputValue:'',
        informationContentList:[],
        name:'',
        id:'',
        img:'',
        visible: false ,
        form:{},
        hotspotTypeList:['资讯','外链'],
        editorState: BraftEditor.createEditorState('<p>请先选择文本类型！</p>'), // 设置编辑器初始内容
        outputHTML: '<p></p>',
        formStyle:{
            display:'flex',
            flexDirection:'column',
            flexWrap:'wrap'
        },
        uploadConfig:{
            action:'https://up-z2.qiniup.com',
            listType:'picture',
            fileList: this.props.data.fileList,
            accept:'image/*',
            multiple:true,
            data:{token:''},
            type:'image',
            name:'file',
        },
        
    }
    
    submitForm=(e) => {
        console.log(this.props.data,this.state.img.length,'this',this.state.typeValue)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(values,'submitForm',values.img)
            console.log(values.img == 'undefined',values.img == undefined)
            // values.img = this.state.img
            if(values.img == undefined){
              values.img = ''
            }
            if(this.state.img.length != 0) {
                values.img = this.state.img
            }else{
                values.img =  values.img.toString()
            }
            if(this.state.typeValue.length!=0) values.typeValue =  this.state.typeValue
            if (!err) {
                this.props.submit(values)
            }
        });
    }
    // 初始化页面
    componentDidMount() {
        console.log(this.props.data,this.props.data.id)
        this.getQi()
        let data= this.props.data
        this.getInformationContentList()
        this.props.form.setFieldsValue(data)
        if(data.informationContent !=null) {
            this.setState({
                typeValue: data.informationContent.id,
                // img:data.img.toString()
            })   
        }
    }
    submit=()=> {
        this.submitForm()
    }
    getInformationContentList(){
        API.getInformationContentList().then(res=>{
            this.setState({
                informationContentList:res
            })
        })
    }
    handleChange = (key, event) => {
        console.log(key,event,this.state.form,'34234')
        let form = this.state.form
        if(key === 'hotspotType'){
            form.hotspotType = event
            this.setState({form: form})
        }else if(key === 'typeValue'){
            console.log('key,event,',this.state.updateForm)
            form.typeValue = event
            this.setState({
                form: form,
                typeValue : event,
            })
        }else{
          for (let item in this.state.form) {
            if (item === key) {
                form[item] = event.target.value||event
                this.setState({form: form})
            }
          }
        }
    }
    blurHandle=(selectValue) => {
        // console.log(selectValue, 'selectValue-blur',this.state.typeValue);
        // this.handleUpdateChange(selectValue,this.state.typeValue);
       };
    onSearchSelect= (value) =>{
        this.state.typeValue = String(value)
        this.setState({
            typeValue:value,
            typeInputValue:value,
        })
        console.log('6',value,'this.state.typeValue',this.state.typeValue);
    }
    // 从后台获取七牛云的token值
    getQi() {
        API.QiAuth().then(res =>{
            // console.log(res)
            if(res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = {token:res.data}
                this.setState({
                    uploadConfig:obj
                })
            }
        })
    }
    // 图片上传之后获取图片信息
    getUrlList(data){
        let urlTemp =  this.state.img
        if(data.length > 0){
            urlTemp='' 
        }
        console.log(data,'data',urlTemp)
        if(data.length == 3){
           message.warning('最多上传四张')
        }
        let title = [];
        let saveImg = [];
        data.forEach((fileInfo, index) => {
            // if(index <= 0) {
            //     return focusImg = fileInfo;
            // }
            if(fileInfo.response) {
                return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
            }else{
                return saveImg.push(fileInfo.url)
            }
            // console.log('data', fileInfo.url)
            // title.push(fileInfo.url);
        })
        console.log('data', saveImg.concat(title))
        this.setState({
            img:saveImg.concat(title).toString(),
            // focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
            uploadConfig: {
                ...this.state.uploadConfig,
                fileList: data
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        this.getFieldsValue = this.props.form.getFieldsValue;//获得表单所有控件的值
        return (
            <Form  onSubmit={this.submitForm} style={this.state.formStyle} >
                <Form.Item {...formItemLayout}  label="热点ID">
                    {getFieldDecorator('newId', {
                        rules: [
                        {
                            required: false,
                            message: '内容不能为空',
                        },
                        ],
                    })(<Input type="number"  onChange={this.handleChange.bind(this,'newId')} placeholder="请输入热点ID" />)}
                </Form.Item>
                <Form.Item {...formItemLayout}  label="热点名称">
                    {getFieldDecorator('name', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<Input onChange={this.handleChange.bind(this,'name')} placeholder="请输入热点内容" />)}
                </Form.Item>
                <Form.Item {...formItemLayout}  label="热点类型" >
                    {getFieldDecorator('hotspotType', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        }
                        ],
                    })(
                        <Select placeholder='请选择热点类型 ' onChange={this.handleChange.bind(this,'hotspotType')}   showSearch>
                        {this.state.hotspotTypeList.map((ele,index) =>{
                            return(
                                <Select.Option key={index} value={ele}>{ele}</Select.Option>
                            )
                        } )}
                    </Select>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="热点链接" >
                    {getFieldDecorator('typeValue', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        }
                        ],
                    })(
                        <Select
                          allowClear
                          showSearch
                          showArrow 
                          placeholder="请输入热点链接" 
                          onSearch={value => this.onSearchSelect(value)}
                          onBlur={this.blurHandle.bind(this,this.state.typeValue,'typeValue')}
                          value={this.state.typeValue}
                          onChange={this.handleChange.bind(this,'typeValue')}   >
                         {
                              this.state.typeInputValue?<Select.Option value={this.state.typeValue} >{this.state.typeValue}</Select.Option> 
                              :this.state.informationContentList.map((ele,index) =>{
                                return(
                                 <Select.Option key={ele.id} value={ele.id}>{ele.name}</Select.Option>
                                )
                             } )
                         }
                         
                       </Select> )}
                </Form.Item>
                <Form.Item {...formItemLayout}  label="热点图片">
                    {getFieldDecorator('img', {
                        rules: [
                        {
                            required: false,
                            message: '内容不能为空',
                        },
                        ],
                    })(
                        <BasicUpload 
                        {...this.state.uploadConfig} 
                        componentType={this.props.componentType}
                        getUrlList={this.getUrlList.bind(this)}>
                        </BasicUpload>)
                    }
                </Form.Item> 
                <Form.Item style={{
                   width: 'calc(100% + 48px)',
                   marginLeft:'-24px',
                   marginBottom:'0',
                   paddingTop:'3px',
                   justifyContent: 'flex-end',
                   paddingRight: '24px',
                   borderTop:'1px solid #ebebeb'}}>
                    <Button onClick={this.props.onClose} style={{marginRight:'10px'}}>取消</Button>
                    <Button htmlType='submit' type='primary' >确定</Button>
                </Form.Item>
            </Form>
        )
    }
}
const HotspotFrom =Form.create({name:'edit'})(RawForm)
export default HotspotFrom;