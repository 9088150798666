const getlist = (data) => ({
  type: 'fetch-list',
  data,
  
})
const orderPerson =(data)=>({
  type:'order-person',
  data
})
export default {
  getlist,
  orderPerson
}
