import React from 'react'
import { Select,Table, Button,Modal,Tag,Input,message, Space} from 'antd'
import API from '@/fetch/api'
import ImagesUpload from '@/components/projectImgUpload'
// import options from './areaTree.js'
const { Option } = Select;
export default class BasicDemo extends React.Component {
  state = {
    proId:'',
    popupTitle:'新增项目信息',
    visible: false,
    page:1,
    size:10,
    totalPages:'',
    imageDesc:'',
    city:'',
    cityName:'',
    area:[],
    data:[],
    cityList:[],
    areaList:[],
      uploadConfig: {
        action: 'https://up-z2.qiniup.com',
        listType: 'picture',
        accept: 'image/*',
        multiple: false,
        fileList: [],
        data: { token: '' },
        type: 'image',
        name: 'file',
        limit: 1,
        title: '上传项目图片'
    }
  }
  componentDidMount () {
    this.getQi()
    this.getProjectList()
  }
      // 获取后台返回的token值
      getQi() {
        API.QiAuth().then(res => {
            // console.log(res)
            if (res.code == 200) {
                let obj = this.state.uploadConfig
                obj.data = { token: res.data }
                this.setState({
                    uploadConfig: obj,
                })
            }
        })
    }
    getUrlList(data) {
      data = [...data];
      console.log('data', data)
      let title = [];
      let focusImg = {};
      data.forEach((fileInfo, index) => {
          if(index <= 0) {
              return focusImg = fileInfo;
          }
          if(fileInfo.response) {
              return title.push(`http://cdn.huikeji.shop/${fileInfo.response.hash}`)
          }
          title.push(fileInfo.url);
      })
      // console.log('获取url列表', {
      //     images:title,
      //     focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
      //     uploadConfig: {
      //         ...this.state.uploadConfig,
      //         fileList: data
      //     }
      // })
      this.setState({
          images:title,
          focusImage: focusImg.response ? `http://cdn.huikeji.shop/${focusImg.response.hash}` : focusImg.url,
          uploadConfig: {
              ...this.state.uploadConfig,
              fileList: data
          }
      })
      // console.log(this.state.images,'第二张及后面的图（数组）')
      // console.log(this.state.focusImage, '第一张图')
  }
  // 关闭弹窗
  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };
  handleOk = () => {
    // console.log(this.state.focusImage,this.state.imageDesc,this.state.cityName,this.state.city,this.state.area,'参数与')
    let {proId}=this.state
    if(proId){
      let data={
        cityName:this.state.city,
        description:this.state.imageDesc,
        districtId:this.state.cityName,
        districtNames:this.state.area,
        picUrl:this.state.focusImage,
        id:this.state.proId
      }
      API.editProject(data).then(res=>{
        if(res.code==200){
          message.success('修改成功')
        }else {
          message.error('修改失败')
        }
        this.getProjectList()
      })
    }else {
      let data={
        cityName:this.state.city,
        description:this.state.imageDesc,
        districtId:this.state.cityName,
        districtNames:this.state.area,
        picUrl:this.state.focusImage
      }
      API.addProject(data).then(res=>{
        if(res.code==200){
          message.success('新增成功')
        }else {
          message.error('新增失败')
        }
        this.getProjectList()
      })
    } 
    this.setState({
      visible: false,
    });
  }
// 获取项目列表
getProjectList(page){
  let data={
    page:page||this.state.page,
    size:this.state.size
  }
  API.getProjectList(data).then(res=>{
    res.data.content.forEach((item,index)=>{
      item.key=item.id
    })
    this.setState({
      data:res.data.content,
      totalPages:res.data.totalElements
    })
  })
}
// 获取地区全部可用区域
getUsefulCity(){
  let {cityList}=this.state
  cityList=[]
  API.getUsefulCity().then(res=>{
    for(var key in res.data){
     let obj={
        name:res.data[key].districtName,
        id:res.data[key].id
      }
      cityList.push(obj)
    }
    this.setState({
      cityList
    })
  })
}
// 分页
  changePage(current){
      this.setState({page: current}, () => {
        //这里打印的是最新的state值
      })
      this.getProjectList(current)
  }
  // 删除
  delete(key){
    API.deleteProject(key).then(res=>{
      if(res.code==200){
        message.success('删除成功')
       this.getProjectList(1)
      }else {
        message.error ('删除失败')
        this.getProjectList(1)
      }
      this.setState({
        page:1
      })
    })

  }
  // 编辑
  edit(key){
    let {data}=this.state
    data.forEach(item=>{
      if(item.key==key){
        this.setState({
          visible: true,
          popupTitle:'修改项目信息',
          proId:key,
          focusImage:item.picUrl,
          imageDesc:item.description,
          cityName:item.districtId,
          city:item.cityName,
          area:item.districtNames
        });
    // console.log(this.state.focusImage,this.state.imageDesc,this.state.cityName,this.state.city,this.state.area,'参数与')
      }
    })
  }
  // 新增
  newAdd(){
    this.setState({
      visible: true,
      popupTitle:'新增项目信息',
      proId:'',
      focusImage:'',
      imageDesc:'',
      cityName:'',
      city:'',
      area:[]
    });
  }

  inputChange1(event){
    this.setState({
        imageDesc:event.target.value
    })
  }
  // 城市选择
   onChange(value) {
     let {cityList}=this.state
     cityList.forEach(item=>{
       if(item.name==value){
         this.setState({
          city:item.name,
          cityName:item.id
         })
         API.getUsefulArea(item.id).then(res=>{
          this.setState({
            areaList:res.data
          })
        })
       }
     })
  }
  // 区乡镇选择
  handleChange(value) {
    this.setState({
      area:value
    })
  }
  onfocues(){
    this.getUsefulCity()
  }
  render () {
    const columns=[
         {
          title: '序号',
          dataIndex: 'key',
          key: 'key'
          },
          {
            title: '图片',
            dataIndex: 'picUrl',
            key: 'picUrl',
            width:'100px',
            render:(image)=><img src={image} style={{width:'100px',height:'100px'}}/>
          },
          {
            title: '地区',
            dataIndex: 'cityName',
            key: 'cityName',
          },
          {
            title: '区、乡镇等',
            key: 'districtNames',
            dataIndex: 'districtNames',
            render: address => (
                <>
                  {address.map(address => {
                    return (
                      <Tag color={'geekblue'} key={address}>
                        {address}
                      </Tag>
                    );
                  })}
                </>
              )
          },
          {
            title: '图片描述',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: '操作',
            key: 'action',
            render: (text, record) => (
              <space size="middle">
                <a style={{marginRight:'10px'}} onClick={this.delete.bind(this,record.key)}>删除</a>
                <a onClick={this.edit.bind(this,record.key)}>修改</a>
                {/* <a onClick={this.delete.bind(this,record.key)}>删除</a> */}
              </space>
            ),
          },
        ]
    const paginationProps = {
            showSizeChanger: false,
            showQuickJumper: false,
            pageSize: this.state.size,
            current: this.state.page,
            total:this.state.totalPages,
            onChange: (current) => this.changePage(current)
        }

    return (
        <div>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:'20px',marginBottom:'20px',marginRight:'80px'}}><Button onClick={this.newAdd.bind(this)}>新增</Button></div>
            <div><Table columns={columns} dataSource={this.state.data} pagination={paginationProps}/></div>
            <Modal
              title={this.state.popupTitle}
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
               <div>
               <ImagesUpload {...this.state.uploadConfig} getUrlList={this.getUrlList.bind(this)} style={{ marginRight: '30px' }}></ImagesUpload>
              </div>

              <p style={{display:'flex',justifyContent:'center',alignItems:'center'}}>图片描述<Input placeholder="请输入图片描述" style={{width:'70%',marginLeft:'20px'}} onChange={event=>this.inputChange1(event)} value={this.state.imageDesc}/></p>

              <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'27px',marginTop:'20px'}}><p style={{marginRight:'19px'}}>地区</p>
                  <Select
                      showSearch
                      style={{ width: '75%' }}
                      placeholder="请选择地区"
                      optionFilterProp="children"
                      onChange={value=>this.onChange(value)}
                      onFocus={this.onfocues.bind(this)}
                      value={this.state.city}
                      // filterOption={(input, option) =>
                      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {
                        (this.state.cityList || []).map((item,index)=>{
                          return <Option key={index} value={item.name}>{item.name}</Option>
                        })
                      }
                    </Select>
              </div>

              <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}><p>区、乡镇等</p>
              <Select mode="tags" style={{ width: '71%',marginLeft:'18px',marginRight:'7px' }} onChange={value=>this.handleChange(value)} value={this.state.area} tokenSeparators={[',']}>
                      {
                        (this.state.areaList || []).map((item,index)=>{
                          return <Option key={index} value={item.districtName}>{item.districtName}</Option>
                        })
                      }
              </Select>
              </div>
    
            </Modal>
      </div>
    )

  }

}