import React, { Component } from 'react'
import { Button, Steps, Form, Input, Table, Modal, Cascader,message } from 'antd'
import API from '@/fetch/api'
import Tools from '@/global/tool'
// import {ExclamationCircleOutlined} from 'antd'
const { Step } = Steps;

const basicColumns = [
  // {
  //   title: '商品图片',
  //   dataIndex: 'focusImage',
  //   key: 'focusImage',
  // },
  {
    title: '商品名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '价格',
    dataIndex: 'truePrice',
    key: 'truePrice',
  },
  {
    title: '数量',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: '折扣',
    dataIndex: 'sales',
    key: 'sales',
  },
  {
    title: '优惠券编码',
    dataIndex: 'couponSeqNo',
    key: 'couponSeqNo',
  },
  {
    title: '优惠券数量',
    dataIndex: 'sYSL',
    key: 'sYSL',
  },
  {
    title: '是否使用优惠券',
    dataIndex: 'cyFullCoupon',
    key: 'cyFullCoupon',
  }
];
const deliverColumns = [
  {
    title: '订单编号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '物流公司',
    dataIndex: 'logistics',
    key: 'logistics',
  },
  {
    title: '快递单号',
    dataIndex: 'logisticsNumber',
    key: 'logisticsNumber',
  },
];
const data = [{
  id: 1,
  fhdlsh: '13234555',
  pay: '支付宝',
  ddlx: '普通订单',
  psfs: '未发货',
  wldh: '132435465',
  zdshsj: '2022-01-11 12:12',
  hdxx: '活动信息',
}];

class OrderDetail extends Component {
  state = {
    visible: false,
    sales:'',
    sYSL:'',
    integralPayment:'',
    wxPayment:'',
    truePrice:'',
    current: 0,
    createdTime:'',
    payTime:'',
    finishTime:'',
    shipTime:'',
    goodsObj: {},
    snapshot: [],
    detail: [],
    cyFullCouponNum:""
  };
  // 修改费用信息弹框
  changeCost(costVisible) {
    this.setState({ costVisible });
  }
  // 关闭订单弹框
  colseOrder(colseVisible) {
    this.setState({ colseVisible });
  }
  // 备注订单弹框
  noteOrder(noteVisible) {
    this.setState({ noteVisible });
  }
  //修改收货人信息弹框
  changeConsignee = () => {
    this.setState({
      visible: true,
    });
  };
  // 返回
  backConsignee = () =>{
    console.log(this.props.location.query.page)
    this.props.history.push({ pathname: '/app/order/list',query:{
      page:this.props.location.query.page
    } })
    // this.push app/order/list
  };
  costHandleOk = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        // this.props.login()
      }
    })
  };

  costandleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleOk = e => {
    console.log(e,'物流和但')
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values,this);
        // this.props.login()
        let obj ={}
        obj.id = this.state.goodsObj.id
        obj = {...values,...obj}
        console.log(obj)
        this.setDeliver(obj)
      }
    })
  };
  setDeliver(data){
    API.orderDeliver(data).then(res =>{
      console.log(res,'物流')
      if(res.code == 200) {
        message.success('修改成功！')
        this.getDetail()
        this.setState({
          visible:false
        })
      }else{
        message.warning('单号已填写')
      }
    })
  }
  getDetail(data) {
    API.orderDetail(this.props.query.id).then(res => {
      if (res.code == 200) {
        console.log(res.data)
        // if(res.data.cyFullCoupon != '[null]'){
        //   this.setState({
        //      sYSL:JSON.parse(res.data.cyFullCoupon)[0].sYSL,
        //   })
        // }
        res.data.snapshot = JSON.parse(res.data.snapshot)
        const cyFullCouponMoney = res.data.cyFullCoupon ? JSON.parse(res.data.cyFullCoupon) : []
        console.log(cyFullCouponMoney)
        let sum = 0
        cyFullCouponMoney.forEach((item,index)=>{
          if(item){
            sum += item.f0000001
          }else{
            sum = 0
          }
        })
        // console.log(sum)
        this.setState({
          goodsObj: res.data,
          detail: [res.data],
          integralPayment:res.data.integralPayment,
          wxPayment:res.data.wxPayment,
          truePrice:res.data.truePrice,
          sales:res.data.sales,
          snapshot: res.data.snapshot,
          createdTime:Tools.timeChecker(res.data.createdTime),
          cyFullCouponNum:sum
          // payTime:Tools.timeChecker(res.data.payTime)
        })
        console.log(this.state.cyFullCouponMoney)
        console.log(JSON.parse(res.data.cyFullCoupon).length)
        if(res.data.payTime != null){
          this.setState({
            payTime:Tools.timeChecker(res.data.payTime)
          })
        }
        if(res.data.shipTime != null){
          this.setState({
            shipTime:Tools.timeChecker(res.data.shipTime)
          })
        }
        if(res.data.finishTime != null){
          this.setState({
            finishTime:Tools.timeChecker(res.data.finishTime)
          })
        }
        // console.log(res.data.payTime,res.data.payTime == null)
        this.statusChecker(res.data.status)
        // console.log(this.state.goodsObj.cyFullCoupon,typeof(this.state.goodsObj.cyFullCoupon))
        console.log(this.state.snapshot)
        this.state.snapshot.forEach((item,index)=>{
          console.log(item)
          item.focusImage = item.merchandiseSpec.focusImage
          item.couponSeqNo = item.merchandiseSpec.couponSeqNo
          item.sales = this.state.sales
          // item.sYSL = this.state.sYSL
          // item.sYSL = item.sYSL
          item.truePrice = item.merchandiseSpec.discountPrice ? item.merchandiseSpec.discountPrice : item.merchandiseSpec.iPrice
          console.log(item.cyFullCoupon,item.cyFullCoupon == null)
          if(item.cyFullCoupon == null){
            item.sYSL = 0
            item.cyFullCoupon = '未用'
          }else{
            item.sYSL = item.cyFullCoupon.sYSL
            item.cyFullCoupon = '已用'
          }
          if(item.couponSeqNo == ''){
            if(item.merchandise.couponSeqNo){
              item.couponSeqNo = item.merchandise.couponSeqNo
            }else{
              item.couponSeqNo = '无'
            }
            
          }else{
            // console.log('遍历优惠券')
            // item.couponName = item.cyFullCoupon.name
          }
        })
        this.setState({
          snapshot:this.state.snapshot
        })
      }
    })
  }
  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  componentWillMount() {
    this.getDetail('')
  }
  statusChecker(data) {
    switch (data) {
      case 'UNPAID':
        this.setState({
          current: 0,
          createdTime:this.state.createdTime
        })
        break;
        case 'PAID':
          this.setState({
            current: 1
          })
          break;
          case 'TRANSIT':
            this.setState({
              current: 2
            })
            break;
            case 'COMPLETE':
              this.setState({
                current: 3
              })
              break;
              default :



    }
  }
  render() {
    // 弹框
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };
    function onChange(value) {
      console.log(value);
    }
    //   -------------------------
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{ margin: '0 18px' }}>
        <span style={{ fontSize: '18px' }}>订单详情</span>
        {/* 订单进度 */}
        <Steps current={this.state.current} style={{ background: '#ffffff', marginTop: '10px', padding: 10 }}>
          <Step title="提交订单" description={this.state.createdTime} />
          <Step title="支付订单" description={this.state.payTime} />
          <Step title="平台发货" description={this.state.shipTime} />
          <Step title="订单完成" description={this.state.finishTime} />
        </Steps>
        <div className="deliver-modal">
          <Button onClick={this.changeConsignee} style={{ margin: '10px' }}>填写物流号</Button>
          <Modal
            title="物流信息"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Form
              {...layout}
              name="basic"
              style={{width:500}}
              initialValues={{ remember: true }}
              onSubmit={onFinish}
              // style={{ textAlign: 'center' }}
            >
              <Form.Item
                label="物流公司"
                name="logistics"
                style={{width:500}}
              >
                {getFieldDecorator('logistics', {
                  rules: [
                    { required: true, message: '输入信息不能为空' }
                  ]
                })(<Input style={{width:300}}/>)}
              </Form.Item>
              <Form.Item
                label="订单号"
                style={{width:500}}
                name="logisticsNumber"
              >
                {getFieldDecorator('logisticsNumber', {
                  rules: [
                    { required: true, message: '输入信息为空!' }
                  ]
                })(<Input style={{width:300}} />)}
              </Form.Item>
            </Form>
          </Modal></div>
        {/* 按钮部分 */}
        {/* <div style={{display:'flex',justifyContent:'space-between',margin:'20px 6px'}}>
                  <div style={{color:'red',fontSize:15}}>
                        当前订单状态：待付款
                  </div> 
                  <div style={{width:'50%',display:'flex',justifyContent:'space-between',float:'right'}}>
                      <Button onClick={this.changeConsignee}>修改收货人信息</Button>
                      <Modal
                        title="修改收货人信息"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        >
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{textAlign:'center'}}
                            >
                            <Form.Item
                                label="收货人姓名"
                                name="username"
                            >
                            {getFieldDecorator('username',{
                                rules:[
                                    { required:true,message:'输入信息不能为空' }
                                ]
                            })( <Input />)}
                            </Form.Item>
                            <Form.Item
                                label="手机号码"
                                name="phone"
                            >
                            {getFieldDecorator('phone',{
                                rules:[
                                    { required: true, message: '输入信息为空!'},
                                    { pattern: /^1\d{10}$/,message: '手机号输入错误'}
                                ]
                            })(<Input maxLength={11} />)}
                            </Form.Item>
                            <Form.Item
                                label="邮政编码"
                                name="code"
                            >
                            {getFieldDecorator('code',{
                                rules:[
                                    { required: true, message: '输入信息不能为空!'},
                                    { pattern: /^[1-9][0-9]{5}$/,message: '请输入正确编码'}
                                ]
                            })(<Input maxLength={6} />)}
                            </Form.Item>
                            <Form.Item
                                label="所在区域"
                                name="region"
                            >
                            {getFieldDecorator('region',{
                                rules:[
                                    { required: true, message: '请选择所在区域!'},
                                ]
                            })(<Cascader options={options} onChange={onChange} placeholder="请选择所在区域" />)} 
                            </Form.Item>
                            <Form.Item
                                label="详细地址"
                                name="address"
                            >
                            {getFieldDecorator('address',{
                                rules:[
                                    { required:true,message:'详细地址不能为空' }
                                ]
                            })(<Input.TextArea />)}
                            </Form.Item>
                        </Form>
                        </Modal>
                        <Button >修改商品信息</Button>
                        <Button onClick={() => this.changeCost(true)}>修改费用信息</Button>
                        <Modal
                        title="修改费用信息"
                        visible={this.state.costVisible}
                        onOk={() => {this.changeCost(false)}}
                        onCancel={() => this.changeCost(false)}
                        // style={{display:'flex',justifyContent:'space-between'}}
                        >
                        <Table bordered pagination={false} columns={columns} dataSource={data} />
                        </Modal>
                        <Button onClick={() => this.colseOrder(true)}>关闭订单</Button>
                        <Modal
                        title="关闭订单"
                        visible={this.state.colseVisible}
                        onOk={() => {this.colseOrder(false)}}
                        onCancel={() => this.colseOrder(false)}
                        // style={{display:'flex'}}
                        >
                        <Form>
                        <Form.Item
                                label="操作备注"
                                name="colseOrder"
                            >
                            {getFieldDecorator('colseOrder',{
                                rules:[
                                    { required:true,message:'备注不能为空' }
                                ]
                            })(<Input.TextArea />)}
                            </Form.Item>
                        </Form>
                        </Modal>
                        
                        <Button onClick={() => this.noteOrder(true)}>备注订单</Button>
                        <Modal
                        title="备注订单"
                        visible={this.state.noteVisible}
                        onOk={() => {this.noteOrder(false)}}
                        onCancel={() => this.noteOrder(false)}
                        // style={{display:'flex',flexWrap:'wrap'}}
                        >
                        <Form>
                        <Form.Item
                                label="操作备注"
                                name="noteOrder"
                            >
                            {getFieldDecorator('noteOrder',{
                                rules:[
                                    { required:true,message:'备注不能为空' }
                                ]
                            })(<Input.TextArea />)}
                            </Form.Item>
                        </Form>
                        </Modal>
                  </div>
                </div> */}
        {/* 基本信息 */}
        <div style={{ background: '#fff', width: '100%', padding: 10 }}>
          <div style={{ marginTop: '10px', padding: '10px 0', color: '#333', width: '100%' }} rowKey='merchandiseId'>基本信息</div>
          <Table bordered pagination={false} columns={basicColumns} dataSource={this.state.snapshot} style={{ textAlign: 'center' }} />
          <div style={{background:'#FAFAFA',width:'100%',height:50,color:'red',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
            <span style={{padding:'0 30px',fontSize:'16px',color:'#666'}}>优惠券：￥{this.state.cyFullCouponNum}</span>
            <span style={{padding:'0 30px',fontSize:'16px',color:'#666'}}>积分支付：￥{this.state.integralPayment}</span>
            <span style={{padding:'0 30px',fontSize:'16px',color:'#666'}}>微信支付：￥{this.state.wxPayment}</span>
            <span style={{padding:'0 30px',fontSize:'16px'}}>合计：￥{this.state.truePrice}</span>
          </div>
          <div style={{ marginTop: '10px', padding: '10px 0', color: '#333', width: '100%' }} rowKey='id'>物流信息</div>
          <Table bordered pagination={false} columns={deliverColumns} dataSource={this.state.detail} style={{ textAlign: 'center' }} />
          {/* <div style={{marginTop:'10px',padding:'10px 0',color:'#333',width:'100%'}}>商品信息</div>
                    <Table bordered pagination={false} columns={columns} dataSource={data} style={{textAlign:'center'}}/>
                    <div style={{background:'#FAFAFA',width:'100%',height:50,color:'red',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                      <span style={{padding:'0 30px',fontSize:'16px'}}>合计：￥2000.0</span>
                    </div>
                    <div style={{marginTop:'10px',padding:'10px 0',color:'#333',width:'100%'}}>费用信息</div>
                    <Table bordered pagination={false} columns={columns} dataSource={data} style={{textAlign:'center'}}/>
                    <div style={{marginTop:'10px',padding:'10px 0',color:'#333',width:'100%'}}>操作信息</div>
                    <Table bordered pagination={false} columns={columns} dataSource={data} style={{textAlign:'center'}}/> */}
        </div>

        <Button type="primary" style={{margin:'10px'}} onClick={this.backConsignee}>返回</Button>


      </div>
    )
  }
}
export default Form.create()(OrderDetail);