
const getOrders = (data) => ({
    type: 'ORDER_LIST',
    data
})
const addFile=(data)=>(
    {
        type: 'ADD_FILE',
        data
    }
)
const removeFile=(data)=>(
    {
        type: 'REMOVE_FILE',
        data
    }
)

export default {
    getOrders,
    addFile,
    removeFile
   
}