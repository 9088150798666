import React,{Component} from 'react'
import {Button,Form,Input} from 'antd'
class RawForm extends Component {
    constructor(props) {
        super(props)
    }
    state={

    }
    loginCheck= (e)=>{
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              this.props.login(values)
            }
           
          });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (

            <Form onSubmit={this.loginCheck} style={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'20px'}}>
                    <img src="https://www.iwitech.com.cn/t/huikeji/img/1.png" alt="" style={{width:'144px',height:'41px'}}/>
                    <div style={{color:'#2C634D',fontWeight:'bolder',fontSize:'44px',marginTop:'-7px',fontStyle:'italic'}}>后台管理系统</div>
                </div>
            <Form.Item label='账号' name='name' style={{display:'flex',alignItems:'center'}} className='flex-row'>
                {getFieldDecorator('username',{
                    rules:[{
                        required:true,
                        message:'请输入用户名'
                    }]
                })(<Input style={{display:'inline-block',width:200}}></Input>)}
                
            </Form.Item>
            <Form.Item label='密码' name='password' className='flex-row' style={{display:'flex',alignItems:'center'}}>
            {getFieldDecorator('password',{
                    rules:[{
                        required:true,
                        message:'请输入密码'
                    }]
                })(<Input.Password style={{display:'inline-block',width:200}}></Input.Password>)}
                
            </Form.Item>
            {/* <Form.Item name='remember'>
                <Checkbox>记住密码</Checkbox>
            </Form.Item> */}
            <Form.Item style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Button htmlType='submit' type='primary' loading={this.props.loading}>
                    登录
                </Button>
                {/* <Button onClick={() => this.props.history.push({pathname:'/app/home'})}>
                    跳转
                </Button> */}
            </Form.Item>
            <div style={{position:"fixed",bottom:"8px"}}>Copyright 2019-2025，南京慧和建筑技术有限公司    
              <a href='https://beian.miit.gov.cn' target="_blank" rel="noopener noreferrer" style={{marginLeft:'8px'}}>
                苏ICP备15050170号-3
              </a>
            </div>
        </Form>

        )
    }
}
const LoginForm = Form.create({name:'login'})(RawForm)
export default LoginForm;