import React,{Component} from 'react'
import {Button} from 'antd'
class RefundDetail extends Component {
    render() {
        return(
            <div>
                退款详情页
            </div>
        )
    }
}
export default RefundDetail;