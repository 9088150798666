import React,{Component} from 'react'
import {connect} from 'react-redux'
import {Upload,Button, Icon,Form,Input,DatePicker ,Modal,message} from 'antd'
import Tools from '../../global/tool'
import API from '@/fetch/api'
import BasicTable from '../../components/Table'
import ClassForm from '@/components/form_add_class'

const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};

class Sorts extends Component {
    state={
    page:1,
    size:10,
    Time:'',
    content:'',
    id:'',
    updateForm:{
        updateTime:'',
        updateName:'',
        id:''
    },
    data: [],
    columns: [
        { title: '编号', dataIndex: 'id', key: 'id' },
        { title: '名称', dataIndex: 'content', key: 'content' },
        { title: '创建时间', dataIndex: 'createdTime', key: 'createdTime' },
        {
            title: '操作', dataIndex: 'action', key: 'action', render: (text, record) => (
                <div>
                    <span>
                        <Button onClick={() => this.showUpdateModal(record)} type="primary">修改</Button>
                        <Button type="danger" onClick={() => this.deleteItem(record)} style={{marginLeft:'6px'}}>删除</Button>
                    </span>
                
                </div>
            )
        },
    ],
    row: {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    },
    initColumn : [
        {
        title: '商品名称',
        dataIndex: 'title',
        key: 'title',
        className: 'text-monospace',
    }, {
        title: '编号',
        dataIndex: 'address',
        key: 'address',
    }, {
        title: '数量',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: '销量',
        dataIndex: 'contact',
        key: 'contact',
    }
],

attendanceInfoList : [
    {
        name:"张三",
        grade:"2017级",
        department:"前端部门"

    },
    {
        name:"李四",
        grade:"2017级",
        department:"程序部门"

    }],
    total:18,
    modalStatus:false,
}
    getExcel() {
        Tools.exportExcel(this.state.initColumn,this.state.data,'测试excel.xlsx')
    }
    loadExcel(event) {
    Tools.importExcel(event).then(res =>{
        console.log(res)
    })
    }
    addClass(){
        this.setState({
            modalStatus:true,
        })
    }
    warning  = (value) => {
        message.warning (value);
    }
    deleteItem(record){
        API.deleteCategory(record.id).then( res => {
            // console.log(res,'resdel')
            if(res.code == 200){
                message.success('删除成功'); 
            }else if(res.code == 400){
                this.warning ('不能删除存在商品的分类')
            }else{
                message.success('删除成功');   
            }  
            this.getList({page:this.state.page,size:this.state.size})
        }).catch(err => {
            console.log(err,'errdel')
        })
    }
        // modal框
        showUpdateModal = (record) => {
            console.log(record,'record')
            //   console.log(this.state.form,'11')
                this.setState({
                id:record.id,
                content:record.content,
                Time:record.createdTime,
                updateVisible: true,
                });
            };
        success  = (value) => {
            message.success (value);
        }
        error  = (value) => {
            message.error (value);
        }
        handleUpdateOk = (e) => {
            console.log(e,this.state.updateForm)
            this.state.updateForm.id = e
            if(this.state.updateForm.createdTime != '' && this.state.updateForm.content != ''){
                API.updateCategory(this.state.updateForm).then( res => {
                    console.log(res,'xiugai')
                    if(res.code == 200 ){
                        this.success('修改成功')
                    }else{
                        this.error('修改失败')
                    }
                    
            this.getList({page:this.state.page,size:this.state.size})
        
                })
            }
        this.setState({
            updateVisible: false,
        });
        };
        handleUpdateCancel = e => {
        this.setState({
            updateVisible: false,
        });
        };
        handleUpdateChangeTime=(value, dateString)=>{
            let moment = require('moment');
            const tmp= moment(dateString. _d).format('YYYY-MM-DD HH:mm:ss')
        //   console.log(tmp)
            for (let item in this.state.updateForm) {
            // console.log(item)
            if (item === value) {
                this.state.updateForm[item] = tmp
                this.setState({updateForm: this.state.updateForm})
            }
          this.state.updateForm.createdTime = this.state.updateForm.updateTime
        //   console.log(this.state.updateForm)
    }
        }
        handleUpdateChange = (key, event) => {
            // console.log(key,event.target.value)
            for (let item in this.state.updateForm) {
                // console.log(item)
                if (item === key) {
                    this.state.updateForm[item] = event.target.value
                    this.setState({updateForm: this.state.updateForm})
            }
            this.state.updateForm.content = this.state.updateForm.updateName
            // console.log(this.state.updateForm)
        }
    
        }
    changePage(page) {
        console.log('enter?')
        console.log(page)
        this.setState({
            page:page
        })
        this.getList({page:page})
    }
    submit(data){
        API.addCategory(data).then(res =>{
            console.log(res,'res')
            if(res.code == 200) {
                message.success('添加成功！')
                this.getList({page:this.state.page})
            }else{
                message.error('添加失败！')
            }
            this.setState({
                modalStatus:false,
            })
        })
    }
    closeModal(){
        this.setState({
            modalStatus:false
        })
    }
    getList(data){
        API.getCategory(data).then(res =>{
            console.log(res,'分类列表')
            if(res.code == 200) {
                let list = res.data.content
                list.forEach(item =>{
                    item.createdTime = Tools.timeChecker(item.createdTime)
                })
            
                this.setState({
                    total:res.data.totalElements,
                    data:res.data.content
                })
            }
            console.log(this.state.total,'235')
        })
    }
    componentDidMount() {
        this.getList({page:this.state.page,size:this.state.size})
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                <div style={{display:'flex',flexDirection:'row-reverse',marginRight:'20px'}}> <Button onClick={()=>this.addClass()} style={{margin:'10px'}}>创建分类</Button></div>
            
                <BasicTable data={this.state.data} columns={this.state.columns} row={this.state.row} total={this.state.total} changePage={this.changePage.bind(this)}></BasicTable>
                <Modal visible={this.state.modalStatus} title='添加分类' onCancel={()=>this.closeModal()} onOk={()=>this.closeModal()}>
                    <ClassForm data={{}} submit={this.submit.bind(this)}></ClassForm>
                </Modal>
                <Modal
                title="修改类别"
                width={500}
                visible={this.state.updateVisible}
                onOk={this.handleUpdateOk.bind(this,this.state.id,this.state.content,this.state.Time)}
                onCancel={this.handleUpdateCancel}
                >
                <Form.Item {...formItemLayout} style={{width:480}} label="分类名称">
                    {getFieldDecorator('updateName', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<Input  onChange={this.handleUpdateChange.bind(this,'updateName')} placeholder={this.state.content} />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} style={{width:480}} label="创建时间" >
                    {getFieldDecorator('updateTime', {
                        rules: [
                        {
                            required: true,
                            message: '内容不能为空',
                        },
                        ],
                    })(<DatePicker showTime 
                        placeholder="Select Time"
                        format='YYYY-MM-DD HH:mm'
                        onChange={this.handleUpdateChangeTime.bind(this,'updateTime')}/>)}
                    </Form.Item>
                </Modal> 
                
            </div>
        )
    }
}
export default Form.create()(Sorts);